import { doc, collection, onSnapshot } from 'firebase/firestore'
import { db } from '../../../firebase/config'
import '../../../global'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setLoadedData } from '../../../state/actions'
import { ReduxStoreState } from '../../../state/reducer'
import _ from 'lodash'
import { formatModItems } from '../getModifierItemData'
import { DATABASE_NAME } from '@env'

const useModItemListener = ({ setUpdateData }) => {
	const loadedData = useSelector<ReduxStoreState, ReduxStoreState['loadedData']>(
		state => state.loadedData
	)
	const [modItemData, setModItemData] = useState({})
	const dispatch = useDispatch()

	useEffect(() => {
		const modItemRef = collection(doc(db, DATABASE_NAME, global.org), 'ModifierItems')
		const unsubscribe = onSnapshot(modItemRef, snapshot => {
			const newModItemData = { ...modItemData }
			let updated = false

			snapshot.docChanges().forEach(change => {
				const data = change.doc.data()
				if (change.type === 'modified' && data !== undefined && !data.isDeleted) {
					const modItemReturn = formatModItems(data)
					const key = 'items_for_' + data.modifierListId

					if (!loadedData[key]) {
						newModItemData[key] = []
					} else {
						newModItemData[key] = [...loadedData[key]]
					}

					const index = newModItemData[key].findIndex(i => i.id === data.id)
					if (index >= 0) {
						newModItemData[key][index] = modItemReturn
					} else {
						newModItemData[key].push(modItemReturn)
					}
					updated = true
				}
			})

			if (updated) {
				const updatedData = _.merge({}, loadedData, newModItemData)
				setModItemData(newModItemData)
				dispatch(setLoadedData(updatedData))
				if (Object.keys(newModItemData).length > 0) {
					setUpdateData([])
				}
			}
		})

		return () => {
			unsubscribe()
		}
	}, [loadedData, modItemData, dispatch, setUpdateData])

	return null
}

export default useModItemListener

// import { doc, collection, onSnapshot } from 'firebase/firestore'
// import { db } from '../../../firebase/config'
// import '../../../global'
// import { useEffect, useState } from 'react'
// import { useDispatch, useSelector } from 'react-redux'
// import { setLoadedData } from '../../../state/actions'
// import { ReduxStoreState } from '../../../state/reducer'
// import _ from 'lodash'
// import { formatModItems } from '../getModifierItemData'
// import { DATABASE_NAME } from '@env'

// const useModItemListener = ({ setUpdateData }) => {
// 	const loadedData = useSelector<ReduxStoreState, ReduxStoreState['loadedData']>(
// 		state => state.loadedData
// 	)

// 	const [modItemData] = useState({})

// 	const dispatch = useDispatch()

// 	useEffect(() => {
// 		const modItemRef = collection(doc(db, DATABASE_NAME, global.org), 'ModifierItems')

// 		const unsubscribe = onSnapshot(modItemRef, snapshot => {
// 			snapshot.docChanges().forEach(change => {
// 				const data = change.doc.data()
// 				if (change.type === 'modified' && data !== undefined && !data.isDeleted) {
// 					const modItemReturn = formatModItems(data)
// 					const key = 'items_for_' + data.modifierListId
// 					if (!loadedData[key]) {
// 						modItemData[key] = []
// 					} else {
// 						modItemData[key] = loadedData[key]
// 					}

// 					const index = modItemData[key].findIndex(i => i.id === data.id)
// 					if (index >= 0) {
// 						modItemData[key][index] = modItemReturn // Replace the item at the found index with modItemReturn
// 					} else {
// 						modItemData[key].push(modItemReturn) // If the item with the given id is not found, push modItemReturn to the end
// 					}
// 				}
// 			})

// 			const updatedData = _.merge({}, loadedData, modItemData)
// 			dispatch(setLoadedData(updatedData))
// 			if (Object.keys(modItemData).length > 0) {
// 				setUpdateData([])
// 			}
// 		})

// 		return () => {
// 			unsubscribe()
// 		}
// 	}, [])
// }

// export default useModItemListener
