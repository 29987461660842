import React, { useContext, useEffect, useState } from 'react'
import {
	Modal,
	View,
	TouchableOpacity,
	StyleSheet,
	ScrollView,
	Dimensions,
	Platform,
} from 'react-native'
import { DataContext } from '../state/context'
import Colors from '../constants/Colors'
import GlobalStyle, {
	formStyle,
	largeTextSize,
	mediumTextSize,
	mlTextSize,
	smallTextSize,
	xlTextSize,
} from '../constants/GlobalStyle'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../state/reducer'
import { Text } from './Themed'
import { alertResponseText } from './Alerts/Alerts'
import AsyncStorage from '@react-native-async-storage/async-storage'

export function PickupPointKioskModal({ visible, setVisible, pickupPointObject, setShowPinpads }) {
	const { setSelectedPickupPoint, selectedPickupPoint } = useContext(DataContext)
	const loadedData = useSelector<ReduxStoreState, ReduxStoreState['loadedData']>(
		state => state.loadedData
	)
	const handleConfirm = () => {
		setVisible(false)
	}

	const handleCancel = () => {
		setVisible(false)
		setShowPinpads(false)
	}

	const PickupPointCard = ({ location }) => {
		let addressSpace = ''
		const address = location.address
		if (address.addressLine2 !== '') {
			addressSpace = ' '
		}
		const formattedAddressLine = `${location.address.addressLine1} ${
			location.address.addressLine2 ? location.address.addressLine2 : ''
		}`
		const formattedCityLine = `${location.address.city}, ${location.address.provinceState} ${location.address.postCode}`

		return (
			<View style={styles.container}>
				<TouchableOpacity
					onPress={() => {
						setSelectedPickupPoint(location)
						AsyncStorage.setItem('pickupPoint', JSON.stringify(location))
					}}
					style={styles.row}
					activeOpacity={0.7}
				>
					<MaterialCommunityIcons
						name={
							selectedPickupPoint !== null && selectedPickupPoint.id === location.id
								? 'checkbox-marked-circle-outline'
								: 'checkbox-blank-circle-outline'
						}
						size={24}
						color={global.orgColor}
						style={styles.icon}
					/>

					<View style={styles.textContainer}>
						<Text style={styles.boldText}>{location.name}</Text>
						<Text style={styles.normalText}>{formattedAddressLine + ', ' + formattedCityLine}</Text>
					</View>
				</TouchableOpacity>
			</View>
		)
	}

	return (
		<Modal
			animationType="fade"
			transparent={true}
			visible={visible}
			onRequestClose={() => {
				setVisible(false)
				setShowPinpads(false)
			}}
		>
			<View style={styles.centeredView}>
				<View style={styles.modalView}>
					<Text style={[formStyle.headerText, { marginVertical: 20, fontSize: largeTextSize }]}>
						SELECT YOUR PICKUP POINT
					</Text>

					<View style={styles.separator} />

					<ScrollView
						style={{ maxHeight: Dimensions.get('window').height * 0.5, maxWidth: '100%' }}
					>
						{pickupPointObject &&
							pickupPointObject.map((location, i) => {
								return (
									<View key={'location_' + i}>
										<PickupPointCard key={i} location={location} />
										{i === pickupPointObject.length - 1 ? null : <View style={styles.separator} />}
									</View>
								)
							})}
					</ScrollView>
					<View style={styles.separator} />

					<View style={styles.buttonRow}>
						<TouchableOpacity
							style={{ ...styles.button, backgroundColor: Colors.greyscale[5] }}
							onPress={handleCancel}
						>
							<Text style={styles.buttonText}>Cancel</Text>
						</TouchableOpacity>
						<TouchableOpacity
							style={{ ...styles.button, backgroundColor: 'black' }}
							onPress={handleConfirm}
						>
							<Text style={styles.buttonText}>Confirm</Text>
						</TouchableOpacity>
					</View>
				</View>
			</View>
		</Modal>
	)
}

const styles = StyleSheet.create({
	container: {
		backgroundColor: 'white',
	},
	row: {
		flexDirection: 'row',
		alignItems: 'center',
		padding: 15,
	},
	icon: {
		width: '10%',
		marginLeft: '5%',
	},
	textContainer: {
		width: '100%',
	},
	boldText: {
		fontSize: mediumTextSize,
		fontWeight: 'bold',
		color: 'black',
	},
	normalText: {
		fontSize: mediumTextSize,
		color: 'grey',
		marginTop: 5,
	},
	openUntilText: {
		marginLeft: 5,
		paddingRight: 5,
		alignSelf: 'flex-start',
		fontSize: smallTextSize,
		width: '40%',
		textAlign: 'right',
	},
	separator: {
		height: 1,
		backgroundColor: Colors.greyscale[4],
		width: '100%',
	},

	centeredView: {
		flex: 1,
		justifyContent: 'center',
		paddingTop: 22,
		paddingHorizontal: '5%',
		backgroundColor: 'rgba(0,0,0,0.5)',
		alignItems: 'center',
	},
	modalView: {
		backgroundColor: 'white',
		borderRadius: 20,
		alignItems: 'center',
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5,
		maxWidth: 600,
		width: '100%',
	},
	button: {
		borderRadius: 10,
		padding: 10,
		paddingHorizontal: 25,
	},
	buttonText: {
		fontSize: xlTextSize,
		color: 'white',
		fontWeight: '500',
		textAlign: 'center',
	},
	buttonRow: {
		paddingHorizontal: 20,
		paddingVertical: 15,
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
	},
})
