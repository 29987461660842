import { useContext, useEffect } from 'react'
import { CheckoutScreenContext, DataContext } from '../../../state/context'
import { auth } from '../../../firebase/config'
import { getLatestPrepTimeCheckout } from '../../../helpers/prepTimeFunctions'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../state/reducer'
import { getFunctions, httpsCallable } from 'firebase/functions'

const useDataRefresh = ({ navigation, priceSet }) => {
	const functions = getFunctions()

	const cart = useSelector<ReduxStoreState, ReduxStoreState['items']>(state => state.items)
	const loadedData = useSelector<ReduxStoreState, ReduxStoreState['loadedData']>(
		state => state.loadedData
	)

	const {
		items,
		setUserAvailableCodes,
		setUserRedeemedCodes,
		couponRefresh,
		setReset,
		prices,
		setAllowOrder,
		couponCode,
	} = useContext(CheckoutScreenContext)

	const { setPointsEarned, setDefPrep, isDelivery, deliveryEstimate } = useContext(DataContext)

	function checkMinSpend() {
		const itemInCart = cart.filter(
			c => c.appliedCoupon && c.appliedCoupon.type && c.appliedCoupon.type === 'freeItem'
		)
		itemInCart.forEach(item => {
			if (item.appliedCoupon.minSpend > prices.subTotal) {
				item.appliedCoupon = null
				navigation.navigate('restaurant')
				setTimeout(() => {
					navigation.navigate('TabCheckout')
				}, 100)
			}
		})
	}

	useEffect(() => {
		setDefPrep(getLatestPrepTimeCheckout(cart, loadedData))
	}, [navigation, items])

	useEffect(() => {
		if (auth.currentUser) {
			const getUserCoupons = httpsCallable(functions, 'getUserCoupons')

			const fetchData = async () => {
				var data: any = await getUserCoupons({
					orgId: global.org,
					userId: auth.currentUser.uid,
				})
				setUserAvailableCodes(data.data.available)
				setUserRedeemedCodes(data.data.redeemed)
			}

			fetchData()
		}
	}, [navigation, couponRefresh])

	useEffect(() => {
		setReset([])
	}, [prices])

	useEffect(() => {
		const unsubscribe = navigation.addListener('focus', () => {
			setAllowOrder(true)
			setReset([])
		})
		return () => {
			unsubscribe()
		}
	}, [navigation])

	useEffect(() => {
		if (items.length > 0) {
			priceSet(couponCode)
		}
	}, [cart, items, couponCode, isDelivery, deliveryEstimate])

	useEffect(() => {
		checkMinSpend()
		// add loyalty points
		if (loadedData[global.org].loyaltyEnabled) {
			const pointsPerDollar = loadedData[global.org].loyaltyPointsPerDollar || 0

			const totalPoints = (prices.subTotal + prices.discount) * pointsPerDollar

			setPointsEarned(Math.round(totalPoints))
		}
	}, [prices])
}

export default useDataRefresh
