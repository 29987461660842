import * as React from 'react'
import { StyleSheet, TextInput, TouchableOpacity, View, Image } from 'react-native'
import { RootStackScreenProps } from '../../navigation/types'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../state/reducer'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { formStyle } from '../../constants/GlobalStyle'
import { Text } from '../../components/Themed'
import { WebHeader } from '../../components/Headers/WebHeader'

/**
 * Screen on web that shows a user's reset password screen
 * User is redirected here from the email sent from pressing Forgot Password?
 */
export default function ResetPasswordScreen({
	navigation,
	route,
}: RootStackScreenProps<'ResetPassword'>) {
	const token = route.params?.token.trim()

	const loadedData = useSelector<ReduxStoreState, ReduxStoreState['loadedData']>(
		state => state.loadedData
	)

	const [isLoading, setIsLoading] = useState(false)
	const [responseText, setResponseText] = useState('')
	const [resetSuccess, setResetSuccess] = useState(false)

	const [newPassword, setNewPassword] = useState('')

	const validatePassword = password => {
		const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[_?!@#$%^&*])(?=.{8,})/
		return passwordRegex.test(password)
	}

	const handleResetPassword = async () => {
		setResponseText('')
		setResetSuccess(false)
		setIsLoading(true)
		const isValidPassword = validatePassword(newPassword)
		if (!isValidPassword) {
			setResponseText(
				'Your new password must contain 8 characters, one uppercase, one lowercase, one number and one special case character.'
			)
			setResetSuccess(false)
		} else {
			const functions = getFunctions()
			const resetPassword = httpsCallable(functions, 'handleEmailPasswordReset')
			try {
				const response: any = await resetPassword({ password: newPassword, token: token })
				console.log(response)
				setResetSuccess(response.data?.success)

				if (response.data?.success) {
					setResponseText(response.data?.message + ' Redirecting to login page...')

					setTimeout(() => {
						navigation.navigate('AccountStack', { screen: 'Login' })
					}, 7000)
				} else {
					setResponseText(response.data?.message)
				}
			} catch (error) {
				setResponseText('There was an error resetting your password. Please try again.')
				setResetSuccess(false)
			}
		}
		setIsLoading(false)
	}

	return (
		<>
			<WebHeader logo={loadedData[global.org].organizationLogo} navigation={navigation} />
			<View style={styles.container}>
				<Text style={styles.title}>Reset Your Password</Text>
				<TextInput
					style={styles.input}
					secureTextEntry
					placeholder="Enter new password"
					value={newPassword}
					onChangeText={setNewPassword}
				/>
				<TouchableOpacity
					style={[formStyle.button, { backgroundColor: global.orgColor }]}
					onPress={isLoading || resetSuccess ? null : handleResetPassword}
				>
					{isLoading ? (
						<Image
							style={{
								width: 90,
								height: 90,
								marginTop: -34,
								marginBottom: -34,
							}}
							source={require('../../assets/images/loadImg.gif')}
						/>
					) : (
						<Text style={formStyle.btnText}>{'RESET PASSWORD'}</Text>
					)}
				</TouchableOpacity>
				<Text style={{ color: resetSuccess ? 'green' : 'red', marginTop: 10, textAlign: 'center' }}>
					{responseText}
				</Text>
			</View>
		</>
	)
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: 'flex-start',
		marginTop: 30,
		padding: 15,
		width: '80%',
		maxWidth: 720,
		alignSelf: 'center',
	},
	title: {
		fontSize: 24,
		marginBottom: 16,
		textAlign: 'center',
	},
	input: {
		height: 50,
		borderColor: 'gray',
		borderWidth: 1,
		marginBottom: 12,
		paddingHorizontal: 8,
		borderRadius: 15,
	},
})
