import React from 'react'
import { StyleSheet, Image, Dimensions, Platform, View } from 'react-native'
import ImageObject from '../../../components/Image/ImageObject'
import { SharpTriangle, SidebarCompatibleSharpTriangle } from '../../../components/Headers/Triangle'
import Colors from '../../../constants/Colors'
import ExitIcon, { SearchIcon } from '../../../components/Headers/UIButtons'
import { responsiveHeight } from 'react-native-responsive-dimensions'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../state/reducer'

export type HeaderProps = {
	image: string
	logo: string
	navigation: any
	fill?: string
	toggle?: any
	sidebarVisible?: boolean
}

/**
 * Displays restaurant/category hero image & restaurant logo for brand menu only
 */
export default function ImageHeader(props: HeaderProps) {
	const { image, logo, navigation, fill, toggle = null, sidebarVisible = false } = props

	const loadedData = useSelector<ReduxStoreState, ReduxStoreState['loadedData']>(
		state => state.loadedData
	)

	const singleBrandEnabled = loadedData[global.org].singleBrandEnabled || false

	return (
		<View
			style={[
				styles.imageContainer,
				{
					height: Dimensions.get('window').height * 0.25,
					zIndex: 2,
				},
			]}
		>
			{toggle && sidebarVisible && !global.kiosk && <SearchIcon toggle={toggle} />}

			{image === '' ? (
				<Image source={require('../../../assets/images/menuHeader.png')} style={styles.image} />
			) : image === null ? null : (
				<ImageObject source={image} style={styles.image} />
			)}
			{sidebarVisible ? (
				<SidebarCompatibleSharpTriangle fill={fill} />
			) : (
				<SharpTriangle fill={fill} />
			)}

			{logo === '' ? (
				<Image
					source={require('../../../assets/images/menuLogo.png')}
					style={global.kiosk ? styles.kioskLogo : styles.logo}
					defaultSource={require('../../../assets/images/loadImg.gif')}
				/>
			) : (
				<ImageObject
					source={logo}
					style={global.kiosk ? styles.kioskLogo : styles.logo}
					hideDefault={true}
				/>
			)}
		</View>
	)
}

const styles = StyleSheet.create({
	imageContainer: {
		width: '100%',
		height: Platform.OS === 'web' ? 230 : Dimensions.get('screen').height / 4,
	},
	image: {
		width: '100%',
		height: '100%',
	},
	triangleCorner: {
		position: 'relative',
		bottom: 50,
		left: 0,
		width: 0,
		height: 0,
		backgroundColor: 'transparent',
		borderStyle: 'solid',
		borderRightWidth: Dimensions.get('window').width,
		borderBottomWidth: 50,
		borderRightColor: 'transparent',
		borderBottomColor: Colors.custom.white,
	},
	logo: {
		borderRadius: 20,
		width: responsiveHeight(14),
		height: responsiveHeight(14),
		position: 'absolute',
		top: Platform.OS === 'web' ? 70 : Dimensions.get('screen').height / 8,
		left: '5%',
	},
	kioskLogo: {
		borderRadius: 20,
		width: Dimensions.get('window').width / 5,
		height: Dimensions.get('window').width / 5,
		position: 'absolute',
		top: Dimensions.get('screen').height / 8.5,
		left: '5%',
	},
})
