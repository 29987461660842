import { getPrepRange } from '../../../helpers/prepTimeFunctions'

export function getOrgHours(restaurants) {
	const orgHours = []

	restaurants.forEach(data => {
		orgHours.push(data.hours)
	})
	return orgHours
}

export function getOrgTimeInfo(restaurants, prepTime, defPrep) {
	const orgTimezone = restaurants.length > 0 ? restaurants[0].timezone : ''
	const orgJump = restaurants.length > 0 ? restaurants[0].scheduleJump : 0
	var preps = getPrepRange(restaurants)

	const prepRange = [preps.min, preps.max]
	const allPrepTimes = preps.allPrepTimes

	var prepTimeTitle =
		prepTime === 0
			? 'Pickup Now'
			: defPrep === 0
			? prepRange[0] === 9999
				? 'Pickup as soon as possible'
				: prepRange[0] === prepRange[1]
				? 'Pickup as soon as possible ( ' + prepRange[0] + ' minutes )'
				: 'Pickup as soon as possible ( ' + prepRange[0] + ' - ' + prepRange[1] + ' minutes )'
			: 'Pickup in ' + defPrep + ' minutes'

	return { orgTimezone, orgJump, prepRange, allPrepTimes, prepTimeTitle }
}
