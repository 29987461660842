import React, { useContext, useRef, useState, useEffect } from 'react'
import { Dimensions, View, Touchable, TouchableOpacity } from 'react-native'
import GlobalStyle, { largeTextSize, titleTextSize } from '../../constants/GlobalStyle'
import Button from '../Button'
import Modal from 'react-native-modal'
import { auth } from '../../firebase/config'
import { DataContext, IdleContext } from '../../state/context'
import { useDispatch } from 'react-redux'
import { setItems } from '../../state/actions'
import { Text } from '../Themed'

const Idle = ({ children }) => {
	const [isIdle, setIdle] = useState(false)
	const idleTimerRef = useRef(null)
	const idleTimeout = 60 * 1000 // 45 seconds

	const refreshTimerRef = useRef(null)
	const refreshTimeout = 15 * 1000 // 10 seconds

	const [idleModal, setIdleModal] = useState(false)

	const countdownTimerRef = useRef(null)
	const [timeUntilReset, setTimeUntilReset] = useState(refreshTimeout / 1000)

	const [hasTouched, setHasTouched] = useState(false)

	const { disableIdleTimer, navContext } = useContext(IdleContext)

	const { setShowKioskStartScreen } = useContext(DataContext)

	const dispatch = useDispatch()
	const clearApp = () => {
		console.log('Refreshing...')
		dispatch(setItems([]))
		setShowKioskStartScreen(true)
		setIdleModal(false)
		if (navContext !== null) {
			navContext.navigate('KioskHome')
			//navContext.popToTop()
		} else {
			console.log('Could not refresh.')
		}
	}

	//Disable idle timer when user is on checkout modals
	useEffect(() => {
		if (auth.currentUser && !global.pos) {
			if (disableIdleTimer) {
				console.log('Idle timer disabled...')
				clearTimeout(idleTimerRef.current)
				clearTimeout(refreshTimerRef.current)
				clearInterval(countdownTimerRef.current)
			} else {
				console.log('Idle timer enabled...')
				onTouch()
			}
		}
	}, [disableIdleTimer])

	// Modal component that appears when user is idle
	const areYouStillThere = () => {
		return (
			<Modal
				isVisible={idleModal}
				style={[
					GlobalStyle.modalStyle,
					{
						height: Dimensions.get('window').height * 0.3,
						marginTop: Dimensions.get('window').height * 0.35,
					},
				]}
				onBackButtonPress={() => {
					setIdleModal(false)
				}}
				onBackdropPress={() => {
					setIdleModal(false)
				}}
				avoidKeyboard={false}
			>
				<View
					style={[
						{
							backgroundColor: 'white',
							paddingTop: 30,
							borderRadius: 25,
							height: Dimensions.get('window').height * 0.3,
							flex: 1,
							overflow: 'hidden',
							position: 'absolute',
							top: '10%',
							left: 0,
							right: 0,
							bottom: 0,
						},
					]}
				>
					<Text
						style={{
							textAlign: 'center',
							fontSize: titleTextSize,
							paddingTop: 20,
							paddingBottom: 0,
							fontWeight: 'bold',
						}}
					>
						{'Are you still there?'}
					</Text>
					<Text
						style={{
							textAlign: 'center',
							fontSize: largeTextSize,
							paddingTop: 20,
							paddingBottom: 30,
						}}
					>
						{'This order will clear in ' + timeUntilReset + ' seconds.'}
					</Text>
					<Button
						onPress={() => {
							setIdleModal(false)
							onTouch()
						}}
						buttonStyle={[
							GlobalStyle.addItemBtn,
							{
								alignSelf: 'center',
								position: 'absolute',
								bottom: '40%',
								paddingHorizontal: 25,
								borderColor: 'white',
								backgroundColor: global.orgColor,
								marginHorizontal: '25%',
								elevation: 0,
								borderRadius: 15,
							},
						]}
						textStyle={[
							GlobalStyle.ctgTitle,
							{
								color: 'white',
								paddingVertical: 0,
								fontSize: largeTextSize,
								marginVertical: 5,
							},
						]}
						title={'CONTINUE'}
						isLoading={false}
						icon={undefined}
					/>
					<Button
						onPress={() => {
							clearApp()
						}}
						buttonStyle={[
							GlobalStyle.addItemBtn,
							{
								alignSelf: 'center',
								position: 'absolute',
								bottom: '20%',
								paddingHorizontal: 25,
								borderColor: 'grey',
								marginHorizontal: '25%',
								elevation: 0,
								borderRadius: 15,
							},
						]}
						textStyle={[
							GlobalStyle.ctgTitle,
							{
								paddingVertical: 0,
								fontSize: largeTextSize,
								marginVertical: 5,
							},
						]}
						title={'CLEAR NOW'}
						isLoading={false}
						icon={undefined}
					/>
				</View>
			</Modal>
		)
	}

	// Reset timer when user touches screen
	const onTouch = () => {
		if (!hasTouched) {
			setHasTouched(true)
		}
		//dont run on kiosk login screen or if timer disabled (placing order)
		if (auth.currentUser && !disableIdleTimer && !global.pos && hasTouched) {
			setIdle(false)
			clearTimeout(idleTimerRef.current)
			clearTimeout(refreshTimerRef.current)
			clearInterval(countdownTimerRef.current) // clear countdown timer

			idleTimerRef.current = setTimeout(() => {
				console.log('Screen idle')
				setIdle(true)
				setIdleModal(true)

				setTimeUntilReset(refreshTimeout / 1000) // reset time until reset
				countdownTimerRef.current = setInterval(() => {
					// start countdown timer
					setTimeUntilReset(timeUntilReset => timeUntilReset - 1)
				}, 1000)

				refreshTimerRef.current = setTimeout(() => {
					clearInterval(countdownTimerRef.current) // stop countdown timer
					clearApp()
				}, refreshTimeout)
			}, idleTimeout)
		}
	}
	// true if kiosk
	if (global.idleReset) {
		return (
			<View style={{ flex: 1 }}>
				{areYouStillThere()}
				<View style={{ flex: 1 }} onTouchMove={onTouch} onTouchStart={onTouch}>
					{children}
				</View>
			</View>
		)
	} else {
		return <>{children}</>
	}
}

export default Idle
