import { Platform, StyleSheet } from 'react-native'
import GlobalStyle, { largeTextSize, smallTextSize } from '../../../constants/GlobalStyle'

export const styles = StyleSheet.create({
	storeInfoIcon: {
		flex: 1,
		display: 'flex',
		justifyContent: 'flex-start',
	},
	storeInfoItem: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignSelf: 'flex-start',
		marginVertical: 2,
	},
	kioskStoreInfoItem: {
		alignSelf: 'flex-end',
		flexDirection: 'row',
		marginRight: 6,
		display: global.hideHours ? 'none' : null,
	},
	storeInfoText: {
		...GlobalStyle.text,
		marginLeft: 10,
		flex: Platform.OS === 'web' ? null : 10,
	},
	subHeaderText: {
		fontSize: largeTextSize + 2,
		letterSpacing: 1,
		fontWeight: '300',
		paddingBottom: '5%',
	},
	searchDropdown: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		backgroundColor: 'white',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		paddingHorizontal: 10,
		height: '10%',
		zIndex: 1000,
		minHeight: 80,
	},
	searchOverlay: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
		zIndex: 999,
	},
	searchInput: {
		flex: 1,
		borderColor: 'gray',
		borderWidth: 1,
		marginRight: 10,
		borderRadius: 5,
		paddingHorizontal: 10,
		minHeight: 40,
		fontSize: smallTextSize,
		paddingLeft: 10,
	},
	searchResultContainer: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: 'white',
		zIndex: 1,
	},
})
