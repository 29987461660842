import React from 'react'
import { View, TouchableOpacity, Image, Linking, StyleSheet } from 'react-native'
import '../../../global'

const ShareRow = ({ onPress, referralCode, url, orgName }) => {
	const shareLinks = [
		{
			id: 'twitter',
			name: 'Twitter',
			icon: 'https://cdn.iconscout.com/icon/free/png-512/twitter-circle-1865867-2745474.png',
			url: 'https://twitter.com/intent/tweet?url=',
		},
		{
			id: 'facebook',
			name: 'Facebook',
			icon: 'https://cdn.iconscout.com/icon/free/png-256/facebook-224-498412.png',
			url: 'https://www.facebook.com/sharer/sharer.php?u=',
		},
		{
			id: 'telegram',
			name: 'Telegram',
			icon: 'https://cdn.iconscout.com/icon/free/png-256/telegram-3-226554.png',
			url: 'https://telegram.me/share/url?url=',
		},
		{
			id: 'whatsapp',
			name: 'WhatsApp',
			icon: 'https://cdn.iconscout.com/icon/free/png-512/whatsapp-3691235-3073755.png',
			url: 'whatsapp://send?text=',
		},
		{
			id: 'messenger',
			name: 'Messenger',
			icon: 'https://cdn.iconscout.com/icon/free/png-256/facebook-messenger-1868983-1583147.png',
			url: 'fb-messenger://share/?link=',
		},
		{
			id: 'email',
			name: 'Email',
			icon: 'https://cdn.iconscout.com/icon/free/png-512/mail-email-message-letter-envelope-inbox-1-18040.png',
			url: null,
		},
		{
			id: 'link',
			name: 'Copy Link',
			icon: 'https://img.icons8.com/flat-round/64/link--v1.png', // replace with a suitable copy icon URL
			url: null,
		},
	]

	const handlePress = platform => {
		const shareUrl = `${url}/?referralCode=${referralCode}`
		const message = `Join me on ${orgName}! Use my referral code ${referralCode} for a discount on your first order: ${shareUrl}`
		const link = shareLinks.find(link => link.id === platform)
		Linking.openURL(`${link.url}${encodeURIComponent(message)}`)
		onPress && onPress(platform)
	}

	return (
		<View style={styles.container}>
			{shareLinks.map(link => (
				<TouchableOpacity key={link.id} onPress={() => handlePress(link.id)}>
					<Image style={styles.icon} source={{ uri: link.icon }} />
				</TouchableOpacity>
			))}
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
	},
	icon: {
		width: 30,
		height: 30,
		marginHorizontal: 10,
		borderRadius: 15,
	},
})

export default ShareRow
