import * as React from 'react'
import { getFunctions, httpsCallable } from 'firebase/functions'
import Spinner from '../LoadingSpinner'
import { useContext, useState } from 'react'
import { alertResponseSingle } from '../Alerts/Alerts'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import { TouchableOpacity, Image } from 'react-native'
import { AntDesign } from '@expo/vector-icons'
import GlobalStyle, { largeTextSize } from '../../constants/GlobalStyle'
import { DataContext } from '../../state/context'
import Colors from '../../constants/Colors'
import { Text } from '../Themed'

export default function StripeWebSaveCard(props) {
	const { stripeCustomerId, title, onPress } = props
	const functions = getFunctions()
	const [spinner, setSpinner] = useState(false)
	const stripe = useStripe()
	const elements = useElements()

	const { isCardValid } = useContext(DataContext)

	const handleSaveCardPress = async () => {
		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			return
		}

		const clientSecret = await getClientSecret()
		const secret = clientSecret.data.toString()

		const { setupIntent, error } = await stripe.confirmCardSetup(secret, {
			payment_method: {
				card: elements.getElement(CardElement),
			},
		})

		if (error) {
			// This point will only be reached if there is an immediate error when
			// confirming the payment. Show error to user
			alertResponseSingle(
				'Error adding payment method',
				'Please verify your payment method and try again',
				'OK',
				null,
				null
			)
		} else if (setupIntent) {
			// user will be redirected to your `return_url`.
			await onPress()
			elements.getElement(CardElement).clear()
		}
	}

	async function getClientSecret() {
		const createStripeSetupIntent = httpsCallable(functions, 'createStripeSetupIntent')
		return await createStripeSetupIntent({
			stripeCustomerId: stripeCustomerId,
			orgId: global.org,
		})
	}

	return (
		<>
			<Spinner
				visible={spinner}
				overlayColor="rgba(0, 0, 0, 0.65)"
				headerHeight={0}
				footerHeight={0}
				color="black"
				customIndicator={
					<Image
						style={{ width: 100, height: 100, alignSelf: 'center', marginTop: -20 }}
						source={require('../../assets/images/loadImg.gif')}
					/>
				}
			/>
			<TouchableOpacity
				style={[
					GlobalStyle.v2ButtonRightArrow,
					{ backgroundColor: isCardValid ? 'black' : Colors.greyscale[6] },
				]}
				activeOpacity={isCardValid ? 0.6 : 0.9}
				onPress={async () => {
					if (isCardValid) {
						setSpinner(true)
						await handleSaveCardPress()
						setSpinner(false)
					} else {
						null
					}
				}}
			>
				<Text style={GlobalStyle.v2ButtonText}>{title}</Text>
				<AntDesign name="right" size={largeTextSize} color="white" />
			</TouchableOpacity>
		</>
	)
}
