import { auth } from '../../../firebase/config'

export function addFreeItem(itemId, navigation) {
	navigation.navigate('Item', {
		token: itemId,
		editing: null,
		isOpen: true,
		menuId: global.menuId,
		menuItemCategory: 'Free Item',
		freeItem: true,
		bogo: false,
	})
}

//check if redemptionQty != -1 (limited # of user redemptions)
//if yes, loook through users redeemedCodes array.
//count all occurences of that coupon id.
//compare against redemptionQty
export function userCanRedeem(couponData, redeemedList) {
	var canRedeem = true
	if (couponData && redeemedList) {
		var qty = couponData.redemptionQty
		var count = 0
		if (qty != -1) {
			if (auth.currentUser) {
				//max redeems not unlimited
				redeemedList.forEach(redeemedCode => {
					if (redeemedCode === couponData.id) {
						++count
					}
				})
				if (count >= qty) {
					canRedeem = false
				}
			} else {
				canRedeem = false
			}
		}
	}
	return canRedeem
}

export function restaurantIsPresent(couponData, cartItems) {
	if (!couponData || !Object.prototype.hasOwnProperty.call(couponData, 'restaurantIds')) {
		return true
	}

	const { restaurantIds } = couponData

	// if restaurantIds is undefined or an empty array, return true
	if (!restaurantIds || restaurantIds.length === 0) {
		return true
	}

	return cartItems.some((item: any) => restaurantIds.includes(item.rId))
}

export function restaurantDiscount(couponData, cartItems) {
	if (couponData && couponData.restaurantIds && couponData.rate) {
		const { restaurantIds, rate } = couponData
		const { type, amount } = rate
		const applicableItems = cartItems.filter(item => restaurantIds.includes(item.rId))
		const totalApplicable = applicableItems.reduce((acc, curr) => acc + curr.total * curr.qty, 0)

		if (type === 'fixed') {
			return totalApplicable < amount * 100 ? totalApplicable : amount * 100
		} else if (type === 'percent') {
			const total = cartItems.reduce((acc, curr) => acc + curr.total * curr.qty, 0)
			const applicableDiscount = totalApplicable * amount
			return (applicableDiscount / total) * 100
		} else {
			return -1
		}
	} else {
		return -1
	}
}

export function restaurantDiscountPercentages(couponData, cartItems) {
	if (couponData && couponData.rate) {
		const restaurantIds = couponData.restaurantIds || cartItems.map(item => item.rId)
		const { type, amount } = couponData.rate

		// Initialize all rIds in the cart with a discount amount of 0
		const discountAmounts = cartItems.reduce((acc, curr) => {
			acc[curr.rId] = 0
			return acc
		}, {})

		const applicableItems = cartItems.filter(item => restaurantIds.includes(item.rId))

		applicableItems.forEach(item => {
			const itemTotal = item.total * item.qty
			let itemDiscount

			if (type === 'fixed') {
				itemDiscount = itemTotal < amount * 100 ? itemTotal : amount * 100
			} else if (type === 'percent') {
				itemDiscount = itemTotal * (amount / 100)
			}

			// Add the discount for this item to the existing discount for this restaurant
			discountAmounts[item.rId] += itemDiscount
		})

		// Round discount amounts for each restaurant
		Object.keys(discountAmounts).forEach(rId => {
			discountAmounts[rId] = Math.round(discountAmounts[rId])
		})
		console.log(discountAmounts)
		return discountAmounts
	} else {
		return null
	}
}

export function codeIsAvailable(couponData, availableList) {
	var isAvailable = true
	if (couponData && couponData.userSpecific) {
		if (availableList.indexOf(couponData['id']) == -1) {
			isAvailable = false
		}
	}
	return isAvailable
}

//codes that are not available based on other redeemed codes
//i.e. having 2 promo codes (storefront & online) but only letting 1 be redeemed
//return true if code is valid
//return false if invalid
export function sameCodeGroupNotRedeemed(couponData, redeemedList) {
	var isNotExcluded = true
	if (couponData && redeemedList && couponData.includeIds) {
		var toExclude = couponData.includeIds
		toExclude.forEach(id => {
			if (redeemedList.indexOf(id) !== -1) {
				isNotExcluded = false
			}
		})
	}
	return isNotExcluded
}
