import React, { useState } from 'react'
import { ItemScreenContext } from '../../../state/context'
import { ModifierList } from '../../../state/types'

const ItemScreenProvider = ({ children }) => {
	let objects: { [key: string]: boolean } = {}
	var setObjects: any
	;[objects, setObjects] = useState({})
	const [collapsibles, setCollapsibles] = useState<any>({})
	const [itemId, setItemId] = useState('')
	const [itemName, setItemName] = useState('')
	const [itemTaxes, setItemTaxes] = useState([])
	const [ageVerificationRequired, setAgeVerificationRequired] = useState('')
	const [itemNote, setItemNote] = useState('')
	const [idempotencyKey, setIdempotencyKey] = useState('')
	const [itemDesc, setItemDesc] = useState('')
	const [posId, setPosId] = useState('')
	const [itemPrice, setItemPrice] = useState(0)
	const [promoPrice, setPromoPrice] = useState(0)
	const [discountPrice, setDiscountPrice] = useState(0)
	const [quantity, setQuantity] = useState(1)
	const [imageUri, setImageUri] = useState('')
	const [modList, setModList] = useState<Array<ModifierList>>([])
	const [modListLoaded, setModListLoaded] = useState(false)
	const [isModList, setIsModList] = useState(false)
	const [restId, setRestId] = useState('')
	const [allowAdd, setAllowAdd] = useState(false)
	const [allowAddMulti, setAllowAddMulti] = useState(false)
	const [visibleModList, setVisibleModList] = useState('')
	const [visibleModListIndex, setVisibleModListIndex] = useState(0)
	const [totalModPrice, setTotalModPrice] = useState(0)
	const [showMinErrorText, setShowMinErrorText] = useState(false)
	const [variationIds, setVariationIds] = useState([])
	const [modListsQuantity, setModListsQuantity] = useState({})
	const [singleSelectionId, setSingleSelectionId] = useState({})
	return (
		<ItemScreenContext.Provider
			value={{
				objects,
				setObjects,
				collapsibles,
				setCollapsibles,
				itemId,
				setItemId,
				itemName,
				setItemName,
				itemTaxes,
				setItemTaxes,
				itemNote,
				setItemNote,
				idempotencyKey,
				setIdempotencyKey,
				itemDesc,
				setItemDesc,
				posId,
				setPosId,
				itemPrice,
				setItemPrice,
				promoPrice,
				setPromoPrice,
				discountPrice,
				setDiscountPrice,
				quantity,
				setQuantity,
				imageUri,
				setImageUri,
				modList,
				setModList,
				modListLoaded,
				setModListLoaded,
				isModList,
				setIsModList,
				restId,
				setRestId,
				allowAdd,
				setAllowAdd,
				allowAddMulti,
				setAllowAddMulti,
				visibleModList,
				setVisibleModList,
				visibleModListIndex,
				setVisibleModListIndex,
				totalModPrice,
				setTotalModPrice,
				showMinErrorText,
				setShowMinErrorText,
				variationIds,
				setVariationIds,
				modListsQuantity,
				setModListsQuantity,
				singleSelectionId,
				setSingleSelectionId,
				ageVerificationRequired,
				setAgeVerificationRequired,
			}}
		>
			{children}
		</ItemScreenContext.Provider>
	)
}

export default ItemScreenProvider
