import React from 'react'
import { View, StyleSheet } from 'react-native'
import { Text } from '../../../../components/Themed'
import { largeTextSize, smallTextSize } from '../../../../constants/GlobalStyle'

const CardSeparator = () => {
	return (
		<View style={styles.container}>
			<View style={styles.line} />
			<Text style={styles.text}>Or pay with card</Text>
			<View style={styles.line} />
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	line: {
		backgroundColor: 'gray',
		borderStyle: 'solid',
		borderWidth: 1,
		borderColor: 'gray',
		marginVertical: largeTextSize,
		width: smallTextSize,
	},
	text: {
		marginHorizontal: 10,
		color: 'gray',
		fontWeight: 'bold',
	},
})

export default CardSeparator
