import React, { useContext, useEffect, useState } from 'react'
import {
	useStripe,
	useElements,
	CardElement,
	PaymentRequestButtonElement,
} from '@stripe/react-stripe-js'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { alertResponseSingle } from '../Alerts/Alerts'
import { useSquareCheckout } from '../Square/Hooks/useSquareCheckout'
import CheckoutNavFooter from '../Footers/CheckoutNavFooter'
import { Dimensions } from 'react-native'
import { CheckoutScreenContext } from '../../state/context'
import crashlytics from '../Crashlytics/crashlyticsLog'

export const CARD_ELEMENT_OPTIONS = {
	style: {
		base: {
			color: '#32325d',
			fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
			fontSmoothing: 'antialiased',
			fontSize: '16px',
			'::placeholder': {
				color: '#aab7c4',
			},
		},
		invalid: {
			color: '#fa755a',
			iconColor: '#fa755a',
		},
	},
}

export default function StripeWebPayment(props) {
	const {
		onPress,
		saveCard,
		amount,
		orgFee,
		tip,
		discount,
		orgDiscountCoverage,
		userStripeId,
		cardId,
		userId,
		toggleModal,
		setSpinner,
		setOrderPlaced,
		prices,
		orgTip,
		setShowAddTip,
		setShowSquarePayment,
		paymentProvider,
		setCardElementVisible,
		paymentRequest,
		setPaymentRequest,
	} = props

	const stripe = useStripe()
	const elements = useElements()
	const functions = getFunctions()
	const { chargeCustomerCard } = useSquareCheckout()
	const { setProcessedOrder } = useContext(CheckoutScreenContext)

	useEffect(() => {
		if (paymentRequest && (cardId === 'googlePay' || cardId === 'applePay')) {
			const handlePaymentMethod = event => {
				if (event) {
					event.complete('success')
					handleSubmit({
						paymentMethod: event.paymentMethod,
					})
				}
			}

			paymentRequest.on('paymentmethod', handlePaymentMethod)

			return () => {
				// Cleanup the event listener on component unmount
				paymentRequest.off('paymentmethod', handlePaymentMethod)
			}
		}
	}, [paymentRequest, cardId])

	const handleSubmit = async event => {
		// We don't want to let default form submission happen here,
		// which would refresh the page.

		if (!event.paymentMethod) {
			event.preventDefault()
		}

		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return
		}

		if (paymentProvider === 'stripe' && amount >= 0.005 && amount < 0.5) {
			setOrderPlaced(false)
			setSpinner(false)
			toggleModal(true)
			alertResponseSingle('Minimum order of $0.50 required', '', 'OK', 'default', null)
			return
		}

		//submit order to square
		const processedOrder = await onPress()
		if (processedOrder && processedOrder.data) {
			console.log(processedOrder)
			setProcessedOrder(processedOrder)
			const fbOrderId = processedOrder.data[0].orderId

			if (amount < 0.005 && processedOrder && processedOrder !== 'PaymentError') {
				console.log('Order total is $0')
				successfulPayment(processedOrder, null)
			} else if (processedOrder && processedOrder !== 'PaymentError') {
				const payIntent: any = await createPaymentIntent(fbOrderId, event).catch(() =>
					alertResponseSingle('Error creating payment', 'Please try again', 'OK', 'default', null)
				)
				const secret = payIntent.data.secret ? payIntent.data.secret.toString() : ''
				const payIntentId = payIntent.data.id ? payIntent.data.id.toString() : ''
				if (payIntent.data === 'PaymentError' || secret === '' || payIntentId === '') {
					setOrderPlaced(false)
					setSpinner(false)
					toggleModal(true)
					alertResponseSingle('Error with payment', 'Please try again', 'OK', 'default', null)
				} else if (cardId === '') {
					console.log(elements.getElement(CardElement))
					try {
						const result = await stripe.confirmCardPayment(secret, {
							payment_method: {
								card: elements.getElement(CardElement),
							},
						})

						if (result.error) {
							setOrderPlaced(false)
							setSpinner(false)
							toggleModal(true)
							console.log('Payment confirmation error', result.error)
							alertResponseSingle(
								'Error adding payment method',
								'Please verify your payment method and try again',
								'OK',
								'default',
								null
							)
						} else if (result.paymentIntent) {
							// The payment has been processed!
							console.log('Success from promise', result.paymentIntent)
							//call paymentConfirmed by feeding in orderId
							successfulPayment(processedOrder, payIntentId)
						}
					} catch (error) {
						console.log(error)
						setOrderPlaced(false)
						setSpinner(false)
						toggleModal(true)
						console.log('Payment confirmation error', error)
						alertResponseSingle('Error adding payment method', '', 'OK', 'default', null)
					}
				} else {
					successfulPayment(processedOrder, payIntentId)
				}
			} else {
				toggleModal(true)
				setOrderPlaced(false)
				setSpinner(false)
				console.log('Order processing error')
				alertResponseSingle('Payment Error', 'Please try again', 'OK', 'default', null)
			}
		}
	}

	async function createPaymentIntent(orderId, event) {
		const createStripePaymentIntent = httpsCallable(functions, 'createStripePaymentIntentV2')
		return await createStripePaymentIntent({
			amount: amount.toFixed(2) * 100,
			firebaseOrganizationId: global.org,
			firebaseOrderId: orderId,
			orgFeePercent: orgFee,
			tipAmount: tip,
			discountAmount: discount,
			discountPercentOrgCovers: orgDiscountCoverage,
			stripeCustomerId: userStripeId,
			saveCard: saveCard,
			cardId: event.paymentMethod ? event.paymentMethod.id : cardId,
			terminalPayment: false,
			prices: prices,
			orgTip: orgTip,
		})
	}

	async function successfulPayment(processedOrder, payIntentId) {
		crashlytics().log('SETTING ORDER PLACED: TRUE')
		setOrderPlaced(true)

		const processPaymentForSquare = httpsCallable(functions, 'processPaymentForSquare')
		console.log(processedOrder)
		await processPaymentForSquare({
			orgId: global.org,
			orderData: processedOrder.data,
			uid: userId,
			paymentIntentId: payIntentId,
			isKiosk: false,
			isDineIn: false,
		})
	}

	const showSquareCard = () => {
		toggleModal()
		setCardElementVisible(true)
		setShowSquarePayment(true)
	}

	const handleSquareCustomerCardCharge = async () => {
		const paymentResponse = await chargeCustomerCard()
		const processedOrder = paymentResponse.processedOrder
		if (processedOrder && processedOrder.data && processedOrder !== 'PaymentError') {
			const fbOrderId = processedOrder.data[0].orderId
			if (paymentResponse.status === 'Failed') {
				setOrderPlaced(false)
				setSpinner(false)
				toggleModal(true)
				const updateOrderInDB = httpsCallable(functions, 'updateOrderInDB')
				const statusObj = { status: 'Failed' }
				updateOrderInDB({
					orgId: global.org,
					orderId: fbOrderId,
					orderObj: statusObj,
				}).then(() => {
					console.log('Status updated to ' + statusObj.status)
				})
			}
		}
		crashlytics().log('SETTING ORDER PLACED: TRUE')
		setOrderPlaced(true)
	}

	const handleDigitalWalletPay = () => {
		paymentRequest.show()
	}

	useEffect(() => {
		if (paymentProvider === 'stripe' && stripe) {
			const pr = stripe.paymentRequest({
				country: 'CA',
				currency: 'cad',
				total: {
					label: 'Total owed:',
					amount: Math.round(amount.toFixed(2) * 100),
				},
				requestPayerName: true,
				requestPayerEmail: true,
			})

			// Check the availability of the Payment Request API.
			pr.canMakePayment()
				.then(result => {
					console.log(result)
					if (result) {
						setPaymentRequest(pr)
					}
				})
				.catch(error => {
					console.log(error)
				})
		}
	}, [stripe])

	return (
		<>
			<CheckoutNavFooter
				style={{ marginBottom: Dimensions.get('window').height * 0.07, marginHorizontal: '5%' }}
				onBackPress={() => {
					setShowAddTip(true)
				}}
				onNextPress={
					paymentRequest && (cardId === 'googlePay' || cardId === 'applePay')
						? handleDigitalWalletPay
						: paymentProvider === 'square'
						? cardId === ''
							? showSquareCard
							: handleSquareCustomerCardCharge
						: handleSubmit
				}
				nextText="PAY NOW"
			/>
		</>
	)
}
