import * as React from 'react'
import { View, ScrollView, Platform } from 'react-native'
import { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../state/reducer'
import { RewardsDescription } from '../../components/Rewards/RewardsDescription'
import { RewardsTriangleHeader } from '../../components/Rewards/RewardsTriangleHeader'
import Colors from '../../constants/Colors'
import { ForwardButton } from '../../components/Headers/UIButtons'
import { auth } from '../../firebase/config'
import PointsBalance from '../../components/Rewards/PointsBalance'
import { styles } from '../RestaurantSelect/style'
import RewardsList from '../../components/Rewards/RewardsList'
import { DataContext } from '../../state/context'
import useUserListener from '../../helpers/fbDataRetrieval/ListenerHooks/useUserListener'
import { pointsTempRemove } from '../../components/Rewards/pointsTempRemove'
import { onAuthStateChanged } from 'firebase/auth'
import GlobalStyle, { titleTextSize } from '../../constants/GlobalStyle'
import AppStoreLinks from '../../components/AppStoreButtons'
import CopyrightFooter from '../../components/Footers/CopyrightFooter'
import { Text } from '../../components/Themed'

export default function RewardsScreen({ navigation }) {
	const { userData, setUserData, redeemedRewards } = useContext(DataContext)

	const loadedData = useSelector<ReduxStoreState, ReduxStoreState['loadedData']>(
		state => state.loadedData
	)

	const iosURL = loadedData[global.org].iosStoreURL || ''
	const androidURL = loadedData[global.org].googlePlayStoreURL || ''

	const orgName = loadedData[global.org].organizationName
		? loadedData[global.org].organizationName
		: ''

	const orgLogo = loadedData[global.org].organizationLogo
		? loadedData[global.org].organizationLogo
		: ''

	const [reset, setReset] = useState([])

	useUserListener({ setUserData })

	useEffect(() => {
		setReset([])
	}, [navigation])

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, user => {
			if (auth.currentUser === null) {
				setUserData(null)
			}
		})

		// Cleanup subscription on unmount
		return () => unsubscribe()
	}, [])
	if (auth.currentUser === null || userData === null) {
		return (
			<ScrollView>
				<RewardsTriangleHeader
					title={'REWARDS'}
					backFunction={null}
					logo={orgLogo}
					navigation={navigation}
				/>
				<View style={{ marginTop: Platform.OS === 'web' ? 50 : 20 }}>
					<View style={{ width: '90%', maxWidth: 1300, alignSelf: 'center' }}>
						<View style={{ marginHorizontal: '3.5%', marginBottom: -15 }}>
							<RewardsDescription orgName={orgName} />
						</View>
						<Text
							style={[
								styles.subHeaderText,
								{
									marginHorizontal: '3.5%',
									paddingBottom: 25,
									letterSpacing: 1.2,
								},
							]}
						>
							{'REWARDS YOU CAN EARN'}
						</Text>
					</View>
					<RewardsList showAll={false} navigation={navigation} />
					<View style={{ width: '90%', maxWidth: 1300, alignSelf: 'center' }}>
						{Platform.OS === 'web' && (
							<>
								<Text
									style={[
										styles.subHeaderText,
										{
											marginHorizontal: '3.5%',
											marginTop: 10,
											paddingBottom: 25,
											letterSpacing: 1.2,
										},
									]}
								>
									{'DOWNLOAD THE APP'}
								</Text>
								<View style={{ alignSelf: 'center', marginBottom: 15 }}>
									<AppStoreLinks iosURL={iosURL} androidURL={androidURL} />
								</View>
							</>
						)}
						<ForwardButton
							onPress={() => {
								navigation.navigate('AccountStack', {
									screen: 'Account',
									params: { signup: true },
								})
							}}
							color={global.orgColor}
							title={'SIGN UP TO START EARNING POINTS'}
							style={{ marginTop: 30, marginBottom: 50, marginHorizontal: '3.5%' }}
						/>
					</View>
				</View>
			</ScrollView>
		)
	} else {
		return (
			<ScrollView>
				<RewardsTriangleHeader
					title={'REWARDS'}
					backFunction={null}
					logo={orgLogo}
					navigation={navigation}
				/>
				<View style={{ marginHorizontal: '5%', width: '90%', maxWidth: 1200, alignSelf: 'center' }}>
					{Platform.OS === 'web' && (
						<Text
							style={[
								GlobalStyle.titleText,
								{ color: global.orgColor2, fontSize: titleTextSize, paddingVertical: '3%' },
							]}
						>
							{'REWARDS'}
						</Text>
					)}
					<PointsBalance
						userPoints={
							userData.user &&
							userData.user.loyaltyPoints !== undefined &&
							userData.user.loyaltyPoints > 0
								? userData.user.loyaltyPoints - pointsTempRemove(redeemedRewards)
								: 0
						}
					/>
				</View>
				<Text
					style={[
						styles.subHeaderText,
						{
							marginHorizontal: '5%',
							marginTop: 10,
							paddingBottom: 25,
							letterSpacing: 1.2,
							width: '90%',
							maxWidth: 1200,
							alignSelf: 'center',
						},
					]}
				>
					{'YOUR REWARDS'}
				</Text>
				<RewardsList showAll={false} onlyRedeemable={true} navigation={navigation} />
				<Text
					style={[
						styles.subHeaderText,
						{
							marginHorizontal: '5%',
							marginTop: 10,
							paddingBottom: 25,
							letterSpacing: 1.2,
							width: '90%',
							maxWidth: 1200,
							alignSelf: 'center',
						},
					]}
				>
					{'OTHER REWARDS YOU CAN EARN'}
				</Text>
				<RewardsList showAll={false} onlyNonRedeemable={true} navigation={navigation} />
				<View style={{ height: 30 }}></View>
				{Platform.OS === 'web' && <CopyrightFooter />}
			</ScrollView>
		)
	}
}
