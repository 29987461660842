import { getAnalytics, isSupported, logEvent } from 'firebase/analytics'
import { Platform } from 'react-native'

// Initialize Firebase Analytics
let webAnalytics = null

//initialize firebase analytics for web
isSupported().then(supported => {
	if (supported) {
		webAnalytics = getAnalytics()
	}
})

export const initializeRNFirebase = () => {
	return null
}

// User opens the app
export const logAppOpenAnalytics = () => {
	logEvent(webAnalytics, 'app_open')
}

// User searches for a menu item
export const logSearchMenuAnalytics = searchTerm => {
	logEvent(webAnalytics, 'search', {
		search_term: searchTerm || '',
	})
}

// User adds an item to their cart
export const logAddItemAnalytics = (total, itemObj) => {
	logEvent(webAnalytics, 'add_to_cart', {
		currency: 'CAD',
		value: total,
		items: [itemObj],
	})
}

// User selects a menu from the home screen (brand or category)
export const logSelectMenuAnalytics = (type, id) => {
	logEvent(webAnalytics, 'select_content', {
		content_type: type,
		item_id: id,
	})
}

// User selects an item from the menu
export const logSelectItemAnalytics = (id, name, price, itemObj) => {
	logEvent(webAnalytics, 'select_item', {
		content_type: 'menu_item',
		item_list_id: id,
		item_list_name: name,
		items: [itemObj],
	})
	logEvent(webAnalytics, 'view_item', {
		value: parseFloat(price.substring(1)) || 0, //price is in format $x.xx
		currency: 'CAD',
		items: [itemObj],
	})
}

// User goes to 'Cart' screen
export const logViewCartAnalytics = (items, total) => {
	logEvent(webAnalytics, 'view_cart', {
		items: items,
		currency: 'CAD',
		value: total,
	})
}

// User presses "Delete" button on an item in the cart
export const logRemoveFromCartAnalytics = (total, itemObj) => {
	logEvent(webAnalytics, 'remove_from_cart', {
		currency: 'CAD',
		value: total,
		items: [itemObj],
	})
}

// User presses "Checkout Now" button on the cart screen
export const logBeginCheckoutAnalytics = (total, items) => {
	logEvent(webAnalytics, 'begin_checkout', {
		currency: 'CAD',
		value: total,
		items: items,
	})
}

// User adds a payment method for checkout
export const logAddCheckoutPaymentAnalytics = (couponId = null, value, items) => {
	const type = global.kiosk ? 'kiosk' : Platform.OS === 'web' ? 'web' : 'mobile'

	logEvent(webAnalytics, 'add_shipping_info', {
		coupon: couponId,
		currency: 'CAD',
		value: value,
		items: items,
		shipping_tier: type + '_pickup',
	})
	logEvent(webAnalytics, 'add_payment_info', {
		coupon: couponId,
		currency: 'CAD',
		value: value,
		items: items,
	})
}

// User successfully completes checkout and pays for their order
export const logCompleteCheckoutAnalytics = (orderId, items, total, taxes) => {
	logEvent(webAnalytics, 'purchase', {
		currency: 'CAD',
		items: items,
		value: total * 100,
		transaction_id: orderId,
		tax: taxes,
	})
}

// User logs in to their account
export const logLoginAnalytics = () => {
	let type = ''
	if (Platform.OS === 'ios') {
		type = 'ios'
	} else if (Platform.OS === 'android') {
		type = 'android'
	} else if (Platform.OS === 'web') {
		type = 'web'
	}
	logEvent(webAnalytics, 'login', {
		method: type,
	})
}

// User successfully signs up for a new account
export const logSignUpAnalytics = () => {
	let type = ''
	if (Platform.OS === 'ios') {
		type = 'ios'
	} else if (Platform.OS === 'android') {
		type = 'android'
	} else if (Platform.OS === 'web') {
		type = 'web'
	}
	logEvent(webAnalytics, 'sign_up', {
		method: type,
	})
}

// User navigates to a screen in the app
export const logScreenViewAnalytics = screenName => {
	logEvent(webAnalytics, 'screen_view', {
		firebase_screen: screenName,
		firebase_screen_class: screenName,
	})
}
