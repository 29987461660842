import { DATABASE_NAME } from '@env'
import '../../global'
import { doc, collection, onSnapshot } from 'firebase/firestore'
import { db } from '../../firebase/config'

export function formatMenus(data: any) {
	return {
		id: data.id,
		menuContent: data.menuContent,
		activeDateRanges: data.activeDateRanges,
		name: data.name,
		restaurantId: data.restaurantId,
		activeTimesOfWeek: data.activeTimesOfWeek,
		featuredItems: [],
		default: data.default,
		priority: data.priority ? data.priority : 9999,
	}
}
export const getMenuData = () => {
	return new Promise((resolve, reject) => {
		const dataImport = {}
		const menuRef = collection(doc(db, DATABASE_NAME, global.org), 'Menus')

		onSnapshot(
			menuRef,
			snapshot => {
				snapshot.docChanges().forEach(change => {
					const data = change.doc.data()
					if (!data.isDeleted) {
						const menuReturn = formatMenus(data)
						const key = 'menus_for_' + data.restaurantId

						if (!dataImport[key]) {
							dataImport[key] = []
						}

						if (change.type === 'modified') {
							const index = dataImport[key].findIndex(i => i.id === data.id)
							if (index >= 0) {
								dataImport[key].splice(index, 1)
							}
						}

						dataImport[key].push(menuReturn)
					}
				})

				// Sort each menu array by priority.
				Object.keys(dataImport).forEach(key => {
					dataImport[key].sort((a, b) => {
						// Assuming lower numbers are higher priority.
						return b.priority - a.priority
					})
				})

				resolve(dataImport)
			},
			reject
		)
	})
}
