import * as React from 'react'
import { Image, Text, View, StyleSheet } from 'react-native'
import Formatter from '../../../helpers/Formatter'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../state/reducer'
import QRCode from 'react-native-qrcode-svg'

export function StarReceiptView({ orderData }) {
	const loadedData = useSelector<ReduxStoreState, ReduxStoreState['loadedData']>(
		state => state.loadedData
	)
	const orgName = loadedData[global.org].organizationName
		? loadedData[global.org].organizationName
		: ''
	const orgAddress = loadedData[global.org].organizationAddress
		? loadedData[global.org].organizationAddress
		: ''
	const orgURL = loadedData[global.org].webURL
		? loadedData[global.org].webURL.replace(/^(http:\/\/|https:\/\/)/, '')
		: ''
	let orgPhone = loadedData[global.org].organizationPhoneNumber
		? loadedData[global.org].organizationPhoneNumber
		: ''

	if (orgPhone !== '') {
		if (orgPhone.length === 11 && orgPhone[0] === '1') {
			orgPhone = orgPhone.slice(1)
		}
		const match = orgPhone.match(/^(\d{3})(\d{3})(\d{4})$/)
		if (match) {
			orgPhone = '(' + match[1] + ')-' + match[2] + '-' + match[3]
		}
	}

	const orgLogo = loadedData[global.org].organizationLogo
		? loadedData[global.org].organizationLogo
		: ''
	return (
		<View style={styles.container}>
			<Image source={{ uri: orgLogo }} style={{ alignSelf: 'center', width: 200, height: 200 }} />
			<Text style={styles.title}>{orgName}</Text>
			<Text style={styles.centerText}>{orgAddress}</Text>
			<Text style={styles.centerText}>{orgPhone}</Text>
			<Text style={[styles.centerText, { marginBottom: 30 }]}>{orgURL}</Text>
			<Text style={styles.centerText}>
				{`Order type: ${orderData.channelData.orderType.toLowerCase()} order via ${
					orderData.channelData.appType
				}`}
			</Text>
			<Text style={styles.centerText}>
				Order date: {new Date(orderData.orderDate._seconds * 1000).toLocaleString()}
			</Text>
			{orderData.pickupDate && (
				<Text style={styles.centerText}>
					Pickup time: {new Date(orderData.pickupDate._seconds * 1000).toLocaleString()}
				</Text>
			)}

			<Text style={[styles.centerText, { marginTop: 40, marginBottom: 20 }]}>
				{orderData.tableNumber === '' || orderData.tableNumber === null
					? 'Order #' + orderData.orderNumber
					: 'Order #' + orderData.orderNumber + ' for ' + orderData.tableNumber}
			</Text>
			<Text style={styles.centerText}>{'************************************************'}</Text>
			<View style={styles.objects}>
				{orderData.orderItems.map((item, index) => (
					<View key={item.name + index} style={{ marginBottom: 10 }}>
						<View key={index} style={styles.itemContainer}>
							<Text style={styles.itemName}>
								{item.quantity}x {item.name}
							</Text>
							{item.priceMoney && (
								<Text style={styles.itemPrice}>
									{Formatter.currencyFormat(item.priceMoney.amount / 100)}
								</Text>
							)}
						</View>

						{item.modifiers.length > 0 && (
							<View style={styles.modifiersContainer}>
								{item.modifiers.map((modifier, mIndex) => (
									<View key={mIndex + '_mod_' + modifier.name} style={styles.modifier}>
										<Text style={styles.modifierText}>+ {modifier.name}</Text>
										<Text style={styles.modifierText}>
											{'$' + modifier.priceMoney.amount / 100}
										</Text>
									</View>
								))}
							</View>
						)}
					</View>
				))}
				{orderData.priceData.subTotal !== 0 && (
					<View key={'star_subtotal'} style={styles.itemContainer}>
						<Text style={styles.amounts}>Subtotal</Text>
						<Text style={styles.amounts}>
							{Formatter.currencyFormat(orderData.priceData.subTotal)}
						</Text>
					</View>
				)}
				{orderData.priceData.tip !== 0 && (
					<View key={'star_tip'} style={styles.itemContainer}>
						<Text style={styles.amounts}>Tip</Text>
						<Text style={styles.amounts}>{Formatter.currencyFormat(orderData.priceData.tip)}</Text>
					</View>
				)}
				{orderData.priceData.serviceCharge !== 0 && (
					<View key={'star_sc'} style={styles.itemContainer}>
						<Text style={styles.amounts}>Service Charge</Text>
						<Text style={styles.amounts}>
							{Formatter.currencyFormat(orderData.priceData.serviceCharge / 100)}
						</Text>
					</View>
				)}
				{orderData.priceData.bagFee + orderData.priceData.bagFeeTax !== 0 && (
					<View key={'star_bagfee'} style={styles.itemContainer}>
						<Text style={styles.amounts}>Bag Fee</Text>
						<Text style={styles.amounts}>
							{Formatter.currencyFormat(
								(orderData.priceData.bagFee + orderData.priceData.bagFeeTax) / 100
							)}
						</Text>
					</View>
				)}
				{orderData.priceData.discount !== 0 && (
					<View key={'star_discount'} style={styles.itemContainer}>
						<Text style={styles.amounts}>Discount</Text>
						<Text style={styles.amounts}>
							{Formatter.currencyFormat(orderData.priceData.discount)}
						</Text>
					</View>
				)}
				<View key={'star_taxes'} style={styles.itemContainer}>
					<Text style={styles.amounts}>Taxes</Text>
					<Text style={styles.amounts}>{Formatter.currencyFormat(orderData.priceData.taxes)}</Text>
				</View>

				{orderData.priceData.total !== 0 && (
					<View key={'star_total'} style={styles.itemContainer}>
						<Text style={styles.amounts}>Total</Text>
						<Text style={styles.amounts}>
							{Formatter.currencyFormat(orderData.priceData.total)}
						</Text>
					</View>
				)}
				<Text style={styles.centerText}>{'************************************************'}</Text>
				<Text style={[styles.centerText, { marginBottom: 20 }]}>Order online:</Text>

				<View style={{ alignSelf: 'center' }}>
					<QRCode value={orgURL} size={120} color="black" />
				</View>
				<Text style={[styles.centerText, { marginVertical: 60 }]}>
					{'Thank you for your order!'}
				</Text>
			</View>
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		marginBottom: 100,
		width: 1000,
		marginLeft: -225,
	},
	objects: {
		marginHorizontal: 225,
		marginVertical: 10,
	},
	title: {
		fontSize: 36,
		textAlign: 'center',
		marginVertical: 40,
	},
	itemContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	itemName: {
		fontSize: 24,
		fontWeight: '300',
		paddingRight: 20,
	},
	itemPrice: {
		fontSize: 24,
		fontWeight: '300',
	},
	modifiersContainer: {
		marginTop: 5,
		marginLeft: 20,
	},
	modifier: {
		justifyContent: 'space-between',
		flexDirection: 'row',
	},
	modifierText: {
		fontSize: 22,
		color: '#555',
		fontWeight: '400',
	},
	amounts: {
		fontSize: 24,
		marginTop: 5,
		fontWeight: '300',
	},

	centerText: {
		fontSize: 24,
		textAlign: 'center',
		marginBottom: 10,
		fontWeight: '300',
	},
})
