import * as React from 'react'
import { Dimensions, TouchableOpacity } from 'react-native'
import Colors from '../../constants/Colors'
import { Text, View } from '../Themed'
import GlobalStyle, { largeTextSize, mediumTextSize, mlTextSize } from '../../constants/GlobalStyle'
import {
	getCheckoutImage,
	getCheckoutImageMobile,
} from '../../screens/Menu/Components/MenuItemCard'
import { getDiscountPrice } from '../../screens/Item/Helpers/functions'

// Component displayed for each individual item
export default function LineItem({
	item,
	isCheckout,
	isConfirmed,
	deletePopup,
	loadedData,
	navigation,
}) {
	const modData = modsList(item.mods, isCheckout, item.qty)
	let discAmount = 0
	if (item.appliedCoupon) {
		discAmount = getDiscountPrice(item.appliedCoupon, loadedData[item.id], item.qty)
	}

	const totalPrice = Number(isCheckout ? item.ppu / 100 : item.total / 100) * item.qty
	const titleText = item.name.toUpperCase()

	// kiosk view
	if (global.kiosk) {
		return (
			<OpenItem
				item={item}
				navigation={navigation}
				isCheckout={isCheckout}
				isConfirmed={isConfirmed}
			>
				<View
					key={item.id + item.idempotencyKey + '-item'}
					style={[
						{
							padding: 15,
							flexDirection: 'row',
							borderTopWidth: 2,
							borderColor: '#eaeaea',
						},
					]}
				>
					<View style={{ display: isCheckout ? 'flex' : 'none' }}>
						{getCheckoutImage(item.imageUri, item.qty)}
					</View>
					<View style={{ flex: 1 }}>
						<View style={{ flexDirection: 'row' }}>
							<Text
								style={[
									GlobalStyle.itemNameText,
									{ marginBottom: isCheckout ? '5%' : 5, flex: 5, fontSize: largeTextSize },
								]}
							>
								{titleText}
							</Text>
							<View style={{ flex: 1, alignItems: 'flex-end' }}>
								<ItemPrice
									totalPrice={totalPrice}
									discAmount={discAmount}
									isCheckout={isCheckout}
									realPrice={item.total}
									nonPromoPrice={item.noPromo}
									mods={item.mods}
								/>
							</View>
						</View>
						<View style={{ minHeight: isCheckout ? Dimensions.get('window').width * 0.02 : 0 }}>
							<View style={{ marginBottom: discAmount > 0 ? 15 : 0 }}></View>
							{modData}
							<ItemNote note={item.note} />
						</View>

						<View
							style={{
								display: isCheckout ? 'flex' : 'none',
								flexDirection: 'row',
								paddingBottom: '8%',
								paddingTop: '2%',
							}}
						>
							<Text
								style={[
									GlobalStyle.itemPriceText,
									{
										paddingHorizontal: 5,
										color: 'darkblue',
										fontSize: mlTextSize,
									},
								]}
							>
								{'Change'}
							</Text>
							<TouchableOpacity
								style={{ paddingHorizontal: 40 }}
								onPress={() => {
									deletePopup(item)
								}}
							>
								<Text
									style={[
										GlobalStyle.itemPriceText,
										{ color: Colors.custom.pinkRed, fontSize: mlTextSize },
									]}
								>
									Remove
								</Text>
							</TouchableOpacity>
						</View>
					</View>
				</View>
			</OpenItem>
		)
	} else {
		return (
			<OpenItem
				item={item}
				navigation={navigation}
				isCheckout={isCheckout}
				isConfirmed={isConfirmed}
			>
				<View
					style={[
						{
							padding: 15,
							flexDirection: 'row',
							borderTopWidth: 2,
							borderColor: '#eaeaea',
						},
					]}
				>
					{isConfirmed || isCheckout ? (
						<View>{getCheckoutImageMobile(item.imageUri, item.qty)}</View>
					) : null}

					<View style={{ flex: 1 }}>
						<View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
							<Text style={[GlobalStyle.itemNameText, { marginBottom: 5, width: '80%' }]}>
								{titleText}
							</Text>
							<View style={{ alignItems: 'flex-end', flex: 1 }}>
								<ItemPrice
									totalPrice={totalPrice}
									discAmount={discAmount}
									isCheckout={isCheckout}
									realPrice={item.total}
									nonPromoPrice={item.noPromo}
									mods={item.mods}
								/>
							</View>
						</View>
						<View>
							{modData}
							<ItemNote note={item.note} />
							<View style={{ flexDirection: 'row' }}>
								{isCheckout ? (
									<>
										<Text
											style={[
												GlobalStyle.itemPriceText,
												{
													paddingLeft: 0,
													paddingRight: 5,
													paddingVertical: 10,
													color: 'darkblue',
													display: isConfirmed ? 'none' : 'flex',
												},
											]}
										>
											{'Change'}
										</Text>
										<TouchableOpacity
											style={{ paddingVertical: 10, paddingHorizontal: 30 }}
											onPress={() => {
												deletePopup(item)
											}}
										>
											<Text
												style={[
													GlobalStyle.itemPriceText,
													{
														color: Colors.custom.pinkRed,
														display: isConfirmed ? 'none' : 'flex',
													},
												]}
											>
												Remove
											</Text>
										</TouchableOpacity>
									</>
								) : null}
							</View>
						</View>
					</View>
				</View>
			</OpenItem>
		)
	}
}

function ItemNote({ note }) {
	if (note !== '') {
		return (
			<View
				key={note}
				style={{
					flexDirection: 'row',
					paddingBottom: 8,
				}}
			>
				<View style={{ flex: 1 }}>
					<Text style={[GlobalStyle.text, { fontStyle: 'italic' }]}>{'note: ' + note}</Text>
				</View>
				<View style={{ flex: 0.2, alignItems: 'flex-end' }}></View>
			</View>
		)
	} else {
		return <></>
	}
}

function ItemPrice({ totalPrice, discAmount, isCheckout, realPrice, nonPromoPrice, mods }) {
	// Check if mods list has one where variation is true
	// Get that mod and add its price to total price
	const variationMod = mods.find(mod => mod.variation)
	if (variationMod) {
		totalPrice += variationMod.priceMoney?.amount / 100 ?? 0
	}

	if (discAmount > 0 || realPrice !== nonPromoPrice) {
		const originalPrice = discAmount > 0 ? totalPrice.toFixed(2) : (nonPromoPrice / 100).toFixed(2)
		return (
			<>
				<Text style={[GlobalStyle.itemPriceText, { textDecorationLine: 'line-through' }]}>
					${originalPrice}
				</Text>
				<Text style={[GlobalStyle.itemPriceText, { color: 'green' }]}>
					${(totalPrice - discAmount).toFixed(2)}
				</Text>
			</>
		)
	} else {
		return (
			<Text style={[GlobalStyle.itemPriceText, { marginBottom: isCheckout ? '20%' : 5 }]}>
				${totalPrice.toFixed(2)}
			</Text>
		)
	}
}

function modsList(mods, isCheckout, itemQty) {
	const modAggregate = mods.reduce((acc, mod) => {
		// If the mod already exists in the accumulator, increment the count
		if (acc[mod.id]) {
			acc[mod.id].count += 1
		} else {
			// If the mod doesn't exist, add it to the accumulator and initialize count
			acc[mod.id] = { ...mod, count: 1 }
		}
		return acc
	}, {})

	// Convert the aggregated object to an array and return the JSX
	return Object.values(modAggregate).map((mod: any) => (
		<View
			key={mod.id}
			style={{
				flexDirection: 'row',
				justifyContent: 'space-between',
				marginBottom: 10,
			}}
		>
			<Text style={{ fontSize: mediumTextSize, flex: 5 }}>
				{mod.count > 1 ? mod.count + 'x ' + mod.name : mod.name}
			</Text>
			<View style={{ alignItems: 'flex-end', flex: 1, display: mod.variation ? 'none' : 'flex' }}>
				<Text
					style={{
						fontSize: mediumTextSize,
					}}
				>
					{isCheckout
						? mod.priceMoney && mod.priceMoney.amount !== 0
							? '$' + ((mod.priceMoney.amount * itemQty * mod.count) / 100).toFixed(2)
							: ''
						: '$' + (mod.price * mod.count).toFixed(2)}
				</Text>
			</View>
		</View>
	))
}

function OpenItem({ item, navigation, isCheckout, isConfirmed, children }) {
	if (isCheckout && !isConfirmed) {
		return (
			<View key={item.id}>
				<TouchableOpacity
					key={item.idempotencyKey + item.id}
					onPress={() =>
						navigation.navigate('Item', {
							token: item.id,
							editing: JSON.stringify({
								modList: item.modList,
								qty: item.qty,
								note: item.note,
								isEditing: true,
								idempotencyKey: item.idempotencyKey,
							}),
							isOpen: true,
							menuId: item.menuId,
							menuItemCategory: item.menuItemCategory,
							appliedCoupon: item.appliedCoupon,
						})
					}
				>
					{children}
				</TouchableOpacity>
			</View>
		)
	} else {
		return <View key={item.id}>{children}</View>
	}
}
