import { DATABASE_NAME } from '@env'
import '../../global'
import { collection, doc, onSnapshot } from 'firebase/firestore'
import { db } from '../../firebase/config'

export function formatTaxes(data: any) {
	return {
		id: data.id,
		amount: data.amount,
		isCompound: data.isCompound,
		name: data.name,
	}
}

export const getTaxData = () => {
	return new Promise((resolve, reject) => {
		const dataImport = {}
		const taxRef = collection(doc(db, DATABASE_NAME, global.org), 'Taxes')

		onSnapshot(
			taxRef,
			snapshot => {
				snapshot.docChanges().forEach(change => {
					const data = change.doc.data()
					if (!data.isDeleted) {
						const taxReturn = formatTaxes(data)

						const key = data.id

						if (!dataImport[key]) {
							dataImport[key] = []
						}

						if (change.type === 'modified') {
							const index = dataImport[key].findIndex(i => i.id === data.id)
							if (index >= 0) {
								dataImport[key].splice(index, 1)
							}
						}

						dataImport[key].push(taxReturn)
					}
				})

				resolve(dataImport)
			},
			reject
		)
	})
}
