import { createStore } from 'redux'
import { cartReducer } from './reducer'
import { Platform } from 'react-native'
import { loadState, saveState } from './localStorage'
import { persistStore } from 'redux-persist'

var store

if (Platform.OS === 'web') {
	const persistedState = loadState()
	store = createStore(cartReducer, persistedState)

	store.subscribe(() => {
		saveState(store.getState())
	})
} else {
	store = createStore(cartReducer)
}

const persistor = persistStore(store)

export { store, persistor }
