import React, { useState } from 'react'
import { View, Text, ScrollView, TouchableOpacity, Animated, Image } from 'react-native'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../state/reducer'
import Colors from '../../../constants/Colors'
import { smallTextSize } from '../../../constants/GlobalStyle'
import { RestaurantList } from './RestaurantList'
import { checkSnooze } from '../../../helpers/checkSnooze'
import { cuisineImages } from '../../../assets/images/cuisines/content'

export default function CuisineSelector({ navigation }) {
	const [selectedCuisine, setSelectedCuisine] = useState(null)

	const loadedData = useSelector<ReduxStoreState, ReduxStoreState['loadedData']>(
		state => state.loadedData
	)

	const restaurants = useSelector<ReduxStoreState, ReduxStoreState['restaurants']>(
		state => state.restaurants
	)

	const filteredRestaurants = selectedCuisine
		? restaurants.filter(
				restaurant =>
					restaurant.cuisines.includes(selectedCuisine.toLowerCase()) &&
					!checkSnooze(restaurant.isSnoozed, restaurant.snoozeUntil)
		  )
		: restaurants.filter(restaurant => !checkSnooze(restaurant.isSnoozed, restaurant.snoozeUntil))

	const handleCuisinePress = cuisine => {
		setSelectedCuisine(selectedCuisine === cuisine ? null : cuisine)
	}

	// Sort cuisines by ordinal
	const sortedCuisines = [...loadedData.cuisines].sort((a, b) => a.ordinal - b.ordinal)

	// Filter out cuisines that aren't available in any restaurant
	const availableCuisines = sortedCuisines.filter(cuisine =>
		restaurants.some(restaurant => restaurant.cuisines.includes(cuisine.id.toLowerCase()))
	)

	console.log(cuisineImages)

	return (
		<View style={{ flex: 1, marginTop: -50 }}>
			{/* Cuisine Selector */}
			<ScrollView
				horizontal
				showsHorizontalScrollIndicator={false}
				style={{ paddingVertical: 10, paddingHorizontal: 15 }}
			>
				{availableCuisines.map((cuisine, index) => {
					const cuisineName = cuisine.title
					const isSelected = selectedCuisine === cuisineName
					const rotation = isSelected ? '15deg' : '0deg'
					return (
						<TouchableOpacity
							key={cuisine.id}
							onPress={() => handleCuisinePress(cuisineName)}
							style={{
								paddingVertical: 8,
								paddingHorizontal: 15,
								alignItems: 'center',
							}}
						>
							<View
								style={{
									width: 80,
									height: 80,
									borderRadius: 40,
									backgroundColor: isSelected ? Colors.greyscale[2] : Colors.custom.appBackground,
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<Animated.Image
									source={cuisine.imageUrl ? { uri: cuisine.imageUrl } : cuisineImages[cuisine.id]}
									style={{
										width: 50,
										height: 50,
										transform: [{ rotate: rotation }],
									}}
								/>
							</View>
							<Text style={{ fontSize: smallTextSize, fontWeight: '500' }}>{cuisineName}</Text>
						</TouchableOpacity>
					)
				})}
			</ScrollView>

			{/* Restaurant List */}
			<RestaurantList navigation={navigation} visibleRestaurants={filteredRestaurants} />
		</View>
	)
}
