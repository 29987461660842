import * as React from 'react'
import { TouchableOpacity, FlatList, RefreshControl, Image, Platform } from 'react-native'
import Colors from '../../constants/Colors'
import { Text, View } from '../../components/Themed'
import { RootStackParamList, RootTabScreenProps } from '../../navigation/types'
import { useCallback, useContext, useEffect, useState } from 'react'
import { auth } from '../../firebase/config'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { Spacer } from '../../components/Spacer'
import Collapsible from 'react-native-collapsible'
import GlobalStyle, { titleTextSize } from '../../constants/GlobalStyle'
import { OrderData } from './types'
import { RouteProp, useRoute } from '@react-navigation/native'
import { ReduxStoreState } from '../../state/reducer'
import { useSelector } from 'react-redux'
import { setPickupTime } from '../../helpers/prepTimeFunctions'
import { formatInTimeZone } from 'date-fns-tz'
import { DisplayOrder } from '../../components/OrderCart/DisplayOrder'
import { TriangleHeader } from '../../components/Headers/TriangleHeader'
import { DataContext } from '../../state/context'
import EpsonPrintReceipt from '../../components/Kiosk/Receipts/EpsonPrintReceipt.native'
import StarPrintReceipt from '../../components/Kiosk/Receipts/StarPrintReceipt.native'
import PrinterStatus from '../../components/Kiosk/Receipts/PrinterStatus.native'
import CopyrightFooter from '../../components/Footers/CopyrightFooter'
import POSNavBar from '../../navigation/POSNavBar'
import { convertDoordashStatus } from '../../components/Delivery/OrderBanner'

export default function OrdersScreen({ navigation }: RootTabScreenProps<'TabOrders'>) {
	const PAGE_LENGTH = 10

	const prepTimeDefault = useSelector<ReduxStoreState, ReduxStoreState['prepTime']>(
		state => state.prepTime
	)

	const loadedData = useSelector<ReduxStoreState, ReduxStoreState['loadedData']>(
		state => state.loadedData
	)

	const organizationLogo = useSelector<ReduxStoreState, ReduxStoreState['organizationLogo']>(
		state => state.organizationLogo
	)

	const { receiptPrinter, printerType } = useContext(DataContext)

	let collapsibles: { [key: string]: boolean } = {}
	var setCollapsibles: any
	;[collapsibles, setCollapsibles] = useState({})
	const [pages, setPages] = useState(1)
	const [reset, setReset] = useState([])
	const [ordersView, setOrdersView] = useState([])
	const [loading, setLoading] = useState(true)
	const [checkOrders, setCheckOrders] = useState(true)

	const functions = getFunctions()

	let page = 1
	if (useRoute<RouteProp<RootStackParamList, 'TabOrders'>>().params != undefined) {
		page = useRoute<RouteProp<RootStackParamList, 'TabOrders'>>().params.page
	}

	useEffect(() => {
		const unsubscribe = navigation.addListener('focus', () => {
			setLoading(true)
			setCollapsibles({}) //collapse all orders on navigation
			if (auth.currentUser) {
				setOrdersView([])
				setPages(1)
				orders(1)
				setReset([])
			} else {
				setOrdersView([])
				setReset([])
				setLoading(false)
			}
		})
		return () => {
			unsubscribe()
		}
	}, [navigation])

	function orderQuantity(singleOrder) {
		var count = 0
		singleOrder.orderItems.forEach(item => {
			count += parseInt(item.qty)
		})
		return count
	}

	function itemOrItems(len) {
		if (len === 1) {
			return 'item'
		} else {
			return 'items'
		}
	}

	function PrinterView({ singleOrder }) {
		if (global.pos) {
			if (printerType === 'star') {
				return <StarPrintReceipt item={singleOrder.rawObject} printer={receiptPrinter} />
			} else {
				return <EpsonPrintReceipt item={singleOrder.rawObject} printer={receiptPrinter} />
			}
		}
		return null
	}

	function setView(singleOrder: OrderData) {
		//expand details for first order if active
		if (
			singleOrder.pos === 0 &&
			singleOrder.pickupStatus !== 'Completed' &&
			collapsibles[singleOrder.orderID] === undefined
		) {
			collapsibles[singleOrder.orderID] = true
		}
		var defaultRestaurant = loadedData[singleOrder.restaurants[0]]
		var timezone = defaultRestaurant ? defaultRestaurant.timezone : 'America/Vancouver'
		var readyTime
		readyTime = setPickupTime(
			singleOrder.prepTime,
			singleOrder.orderDate,
			prepTimeDefault,
			timezone
		)
		var rList = [...new Set(singleOrder.restaurants)]
		var rReturn = ''
		var quantity = orderQuantity(singleOrder)

		rList.forEach((rId: string, i) => {
			if (i == rList.length - 1 && i !== 0) {
				rReturn = rReturn.slice(0, rReturn.length - 2)
				rReturn += ' & ' + loadedData[rId]?.name
			} else if (i == rList.length - 1) {
				rReturn += loadedData[rId]?.name
			} else {
				rReturn += loadedData[rId]?.name + ', '
			}
		})

		return (
			<View key={singleOrder.orderID + '_view'}>
				<TouchableOpacity
					onPress={() => {
						setCollapsibles((collapsibles: object) => ({
							...collapsibles,
							[singleOrder.orderID]: !collapsibles[singleOrder.orderID],
						}))
					}}
				>
					<View
						style={[
							GlobalStyle.collapsibleHeading,
							{ padding: 20, paddingBottom: 0, flexDirection: 'row' },
						]}
					>
						<View style={GlobalStyle.ordersHeading}>
							<Text style={GlobalStyle.responsiveTextSize}>{rReturn}</Text>
						</View>

						<View style={[GlobalStyle.ordersHeading, { flex: 1.25, alignItems: 'flex-end' }]}>
							<Text style={[GlobalStyle.responsiveTextSize, { textAlign: 'center' }]}>
								<Text style={{ fontWeight: '300' }}>
									{formatInTimeZone(Date.parse(singleOrder.orderDate), timezone, 'MMM d')}
								</Text>
							</Text>
						</View>
					</View>

					<View
						key={singleOrder.orderID + Math.random()}
						style={[
							GlobalStyle.collapsibleHeading,
							{ padding: 20, paddingTop: 7, flexDirection: 'row' },
						]}
					>
						<View style={[GlobalStyle.ordersHeading, { flex: 0.75, alignItems: 'flex-start' }]}>
							<Text style={[GlobalStyle.responsiveTextSize, { fontWeight: '300' }]}>
								{quantity} {itemOrItems(quantity)}
								{' ·'} ${(singleOrder.prices.total / 100).toFixed(2)}
							</Text>
						</View>

						<View style={[GlobalStyle.ordersHeading, { alignItems: 'flex-end' }]}>
							<Text style={[GlobalStyle.responsiveTextSize, { fontWeight: '300' }]}>
								{singleOrder.deliveryStatus !== ''
									? convertDoordashStatus(singleOrder.deliveryStatus)
									: singleOrder.pickupStatus}
							</Text>
						</View>
					</View>
				</TouchableOpacity>
				<PrinterView singleOrder={singleOrder} />
				<Collapsible collapsed={!collapsibles[singleOrder.orderID]}>
					<View
						key={singleOrder.orderID + Math.random()}
						style={{
							borderBottomWidth: 2,
							borderLeftWidth: 2,
							borderRightWidth: 2,
							borderColor: Colors.custom.lightGrey,
							padding: 15,
						}}
					>
						<View style={{ flexDirection: 'row', paddingHorizontal: 15 }}>
							<View style={[{ flex: 1.25 }]}>
								<Text style={[GlobalStyle.responsiveTextSize]}>{'Placed on  '}</Text>
								<Text style={[GlobalStyle.responsiveTextSize, { fontWeight: '300' }]}>
									{formatInTimeZone(
										Date.parse(singleOrder.orderDate),
										timezone,
										"EEE MMM d 'at' h:mm a"
									)}
								</Text>
							</View>

							<View style={[{ flex: 1.25, alignItems: 'flex-end' }]}>
								<Text style={[GlobalStyle.responsiveTextSize]}>{'Pickup on'}</Text>
								<Text style={[GlobalStyle.responsiveTextSize, { fontWeight: '300' }]}>
									{readyTime}
								</Text>
							</View>
						</View>
						<View style={{ paddingTop: 7, paddingBottom: 15, paddingHorizontal: 15 }}>
							<Text style={[GlobalStyle.responsiveTextSize]}>
								{'Order no. '}
								{singleOrder.orderNumber}
							</Text>
						</View>
						<DisplayOrder
							items={singleOrder.orderItems}
							prices={singleOrder.prices}
							isCheckout={false}
							isConfirmed={false}
							navigation={navigation}
							deletePopup={null}
							loadedData={loadedData}
							trackingURL={singleOrder.deliveryTrackingURL}
						/>
					</View>
				</Collapsible>
				<Spacer size={15} />
			</View>
		)
	}

	function orders(pageNum) {
		setPages(pageNum)
		setReset([])

		var startAt = 0
		var endAt = PAGE_LENGTH

		if (pageNum > 1) {
			startAt = (pageNum - 1) * PAGE_LENGTH
			endAt = (pageNum - 1) * PAGE_LENGTH + PAGE_LENGTH
		}

		if (auth.currentUser !== null && checkOrders) {
			console.log('Retrieving Orders.')

			const getUserOrdersFromDB = httpsCallable(functions, 'getUserOrdersFromDB')
			getUserOrdersFromDB({
				orgId: global.org,
				userId: auth.currentUser.uid,
				limit: endAt,
			})
				.then(snapshot => {
					console.log('Orders Retrieved.')
					const snapArr: any[] = snapshot.data as any[]
					const orders = snapArr.slice(startAt)
					let pos = 0
					const tempOrders = [...ordersView]
					if (orders.length <= 0) {
						setCheckOrders(false)
					}

					for (var x = 0; x < orders.length; x++) {
						const items = []
						const restaurants = []
						let itemData = {
							name: '',
							qty: 0,
							mods: [],
							note: '',
							total: '',
							id: '',
							rId: '',
							noPromo: 0,
						}
						var data = orders[x]
						if (data.status !== 'Pending' && data.status !== 'Failed') {
							data.orderItems.forEach(orderItem => {
								const mods = []
								orderItem.modifiers.forEach(itemMod => {
									mods.push({
										name: itemMod?.name,
										price: itemMod.priceMoney.amount / 100,
									})
								})
								itemData = {
									name: orderItem?.name,
									qty: orderItem.quantity,
									mods: mods,
									note: orderItem.note,
									total: orderItem.priceMoney.amount,
									id: orderItem.id,
									rId: orderItem.restaurantId,
									noPromo: orderItem.priceMoney.amount,
								}
								items.push(itemData)
								restaurants.push(orderItem.restaurantId)
							})

							var orderObj = {
								pos: pos + ordersView.length,
								orderDate: new Date(data.orderDate._seconds * 1000).toString(),
								orderID: data.id,
								orderNote: data.orderNote,
								orderItems: items,
								prepTime: data.prepTime,
								restaurants: restaurants,
								prices: data.priceData,
								pickupStatus: data.status,
								orderNumber: data.orderNumber,
								rawObject: data,
								deliveryStatus:
									data.channelData &&
									data.channelData.doordash &&
									data.channelData.doordash.deliveryStatus
										? data.channelData.doordash.deliveryStatus
										: '',
								deliveryTrackingURL:
									data.channelData &&
									data.channelData.doordash &&
									data.channelData.doordash.trackingUrl
										? data.channelData.doordash.trackingUrl
										: '',
							}
							tempOrders.push(orderObj)
							pos++
						}
					}
					setOrdersView(tempOrders)
					setLoading(false)
				})
				.catch(e => {
					console.log('Error fetching orders')
					console.log(e)
				})
		}
	}

	function getOrdersView() {
		var orderReturn = []
		ordersView.forEach(orderObj => {
			orderReturn.push(setView(orderObj))
		})
		return orderReturn
	}

	const [refreshing, setRefreshing] = useState(false)
	const onRefresh = useCallback(() => {
		setRefreshing(true)

		setTimeout(() => {
			setOrdersView([])
			setPages(1)
			orders(1)
			setRefreshing(false)
			setLoading(true)
			setReset([])
		}, 800)
	}, [])

	const noOrderText = global.pos
		? 'Past orders from this POS will appear here once an order is placed.'
		: 'Your past orders will appear here once you place your first order.'

	function loggedInView() {
		if (auth.currentUser) {
			if (ordersView.length === 0 && !loading) {
				return (
					<View
						key={'no_orders'}
						style={[
							GlobalStyle.container,
							{
								alignItems: 'center',
								paddingTop: 30,
								backgroundColor: Colors.custom.appBackground,
							},
						]}
					>
						<Text style={GlobalStyle.medBoldText}>{noOrderText}</Text>
					</View>
				)
			} else if (loading) {
				return (
					<View
						key={'loading'}
						style={[
							GlobalStyle.container,
							{
								alignItems: 'center',
								paddingTop: 30,
								backgroundColor: Colors.custom.appBackground,
							},
						]}
					>
						<Image
							style={{
								width: 100,
								height: 100,
								alignSelf: 'center',
								marginTop: -20,
							}}
							source={require('../../assets/images/loadImg.gif')}
						/>
					</View>
				)
			} else {
				return (
					<View
						style={[
							GlobalStyle.container,
							{
								marginTop: Platform.OS === 'web' ? 0 : -40,
								paddingHorizontal: 0,
								paddingTop: 0,
								paddingBottom: 0,
								backgroundColor: Colors.custom.white,
							},
						]}
					>
						<FlatList
							data={ordersView.map(orderObj => {
								return setView(orderObj)
							})}
							ListHeaderComponentStyle={{ paddingHorizontal: Platform.OS === 'web' ? '2%' : 0 }}
							ListFooterComponentStyle={{ paddingHorizontal: 0 }}
							refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
							onEndReachedThreshold={0.3}
							showsVerticalScrollIndicator={false}
							onEndReached={() => {
								orders(pages + 1)
							}}
							renderItem={({ item }) => <View style={{ paddingHorizontal: '2%' }}>{item}</View>}
							ListFooterComponent={Platform.OS === 'web' && <CopyrightFooter />}
							ListHeaderComponent={
								global.pos ? (
									<PrinterStatus receiptPrinter={receiptPrinter} printerType={printerType} />
								) : (
									Platform.OS === 'web' && (
										<Text
											style={[
												GlobalStyle.titleText,
												{ color: global.orgColor2, fontSize: titleTextSize, paddingVertical: '3%' },
											]}
										>
											{'PAST ORDERS'}
										</Text>
									)
								)
							}
						/>
					</View>
				)
			}
		} else {
			return (
				<View
					key={'signed_out'}
					style={[
						GlobalStyle.container,
						{
							justifyContent: 'center',
							alignItems: 'center',
							backgroundColor: Colors.custom.appBackground,
						},
					]}
				>
					<Text style={[GlobalStyle.medBoldText, { marginTop: -150 }]}>
						Please sign in to view orders
					</Text>
				</View>
			)
		}
	}

	return (
		<>
			<TriangleHeader
				logo={organizationLogo}
				navigation={navigation}
				title={'ORDERS'}
				backFunction={
					Platform.OS === 'web'
						? () => {
								if (navigation.canGoBack()) {
									navigation.goBack()
								} else {
									navigation.navigate('HomeStack', { screen: 'RestaurantSelect' })
								}
						  }
						: null
				}
			/>

			{loggedInView()}
			{global.pos ? <POSNavBar navigation={navigation} screenName={'Orders'} /> : null}
		</>
	)
}
