import { AnyAction } from 'redux'
import {
	ItemListActionTypes,
	KioskActionTypes,
	LocationActionTypes,
	RestaurantActionTypes,
} from './actions'
import {
	AddItemAction,
	Item,
	LocationManager,
	RemoveItemAction,
	SetCurrentLocationAction,
	SetRestaurantAction,
	SetItemAction,
	SetBoolAction,
	SetAnyAction,
} from './types'

export interface ReduxStoreState {
	items: Item[]
	LocationId: string
	AccessToken: string
	ApplicationId: string
	RestaurantId: string
	ImageLogo: string
	ImageHero: string
	locationManager: LocationManager
	enabledFeatures: any
	hours: any
	address: any
	prepTime: any
	name: string
	social: any //instagram, etc
	couponCodes: any
	schedulingJump: any
	DeveloperFee: any
	isSnoozed: any
	snoozeUntil: number
	pickupMsg: string
	prepTimeString: string
	prepTimeMs: string
	storeOpen: boolean
	userAddress: string
	localTimezone: string
	userId: string
	posUserId: string
	prepTimeList: any
	serviceFee: number
	platformFee: number
	orgFee: number
	serviceFeeTax: number
	platformFeeTax: number
	orgFeeTax: number
	stripeId: string
	paymentMethodId: string
	paymentMethods: any
	cardIndex: number
	restaurants: any[]
	slogan: string
	restaurantDataList: any
	schedCurrTime: string
	schedDuration: string
	defaultPrepTime: string
	loadedData: any
	organizationLogo: string
	referralCode: string
	orgTipPercent: any
	organizationHeader: string
	disableIdle: boolean
	hoursRef: any
	combinedHours: any
	locationSettings: any
	deliverySettings: any
	deliveryActive: boolean
}

const locationManager: LocationManager = {
	locations: [],
	currentLocation: null,
}

const initialState = {
	items: [],
	LocationId: '',
	RestaurantId: '',
	AccessToken: '',
	ApplicationId: '',
	ImageLogo: '',
	ImageHero: '',
	locationManager: locationManager,
	enabledFeatures: {},
	hours: {},
	address: {},
	prepTime: -1,
	name: '',
	social: [],
	couponCodes: {},
	schedulingJump: 0,
	DeveloperFee: {},
	snoozeUntil: 0,
	isSnoozed: false,
	pickupMsg: '',
	prepTimeString: '',
	prepTimeMs: '',
	storeOpen: true,
	userAddress: '',
	localTimezone: '',
	userId: '',
	posUserId: '',
	prepTimeList: [],
	serviceFee: 0,
	platformFee: 0,
	orgFee: 0,
	serviceFeeTax: 0,
	platformFeeTax: 0,
	orgFeeTax: 0,
	stripeId: '',
	paymentMethodId: '',
	paymentMethods: [],
	cardIndex: 0,
	restaurants: [],
	slogan: '',
	restaurantDataList: {},
	schedCurrTime: '',
	schedDuration: '',
	defaultPrepTime: '',
	loadedData: {},
	organizationLogo: '',
	referralCode: '',
	orgTipPercent: 0,
	organizationHeader: '',
	disableIdle: false,
	hoursRef: null,
	combinedHours: null,
	locationSettings: null,
	deliverySettings: null,
	deliveryActive: true,
}

export const cartReducer = (state: ReduxStoreState = initialState, action: AnyAction) => {
	function getIndex(id: string) {
		return state.items.findIndex(item => item.id == id)
	}

	switch (action.type) {
		case RestaurantActionTypes.SET_LOADEDDATA:
			const setLoadedData = <SetRestaurantAction>action
			state.loadedData = setLoadedData.id
			return state

		case RestaurantActionTypes.SET_LOCATIONSETTINGS:
			const setLocationSettings = <SetRestaurantAction>action
			state.locationSettings = setLocationSettings.id
			return state

		case RestaurantActionTypes.SET_DELIVERYSETTINGS:
			const setDeliverySettings = <SetRestaurantAction>action
			state.deliverySettings = setDeliverySettings.id
			return state

		case RestaurantActionTypes.SET_HOURSREF:
			const setHoursRef = <SetRestaurantAction>action
			state.hoursRef = setHoursRef.id
			return state

		case RestaurantActionTypes.SET_COMBINEDHOURS:
			const setCombinedHours = <SetRestaurantAction>action
			state.combinedHours = setCombinedHours.id
			return state

		case ItemListActionTypes.SET_RESTAURANTDATALIST:
			const setRestaurantDataList = <SetAnyAction>action
			state.restaurantDataList = setRestaurantDataList.var
			return state

		case RestaurantActionTypes.SET_USERADDRESS:
			const setUserAddress = <SetRestaurantAction>action
			state.pickupMsg = setUserAddress.id
			return state

		case RestaurantActionTypes.SET_DEFAULTPREPTIME:
			const setDefaultPrepTime = <SetRestaurantAction>action
			state.defaultPrepTime = setDefaultPrepTime.id
			return state

		case RestaurantActionTypes.SET_SCHEDCURRTIME:
			const setSchedCurrTime = <SetRestaurantAction>action
			state.schedCurrTime = setSchedCurrTime.id
			return state

		case RestaurantActionTypes.SET_SCHEDDURATION:
			const setSchedDuration = <SetRestaurantAction>action
			state.schedDuration = setSchedDuration.id
			return state

		case ItemListActionTypes.SET_RESTAURANTS:
			const setRestaurants = <SetItemAction>action
			state.restaurants = setRestaurants.payload
			return state

		case RestaurantActionTypes.SET_ORGANIZATIONLOGO:
			const setOrganizationLogo = <SetRestaurantAction>action
			state.organizationLogo = setOrganizationLogo.id
			return state

		case RestaurantActionTypes.SET_ORGANIZATIONHEADER:
			const setOrganizationHeader = <SetRestaurantAction>action
			state.organizationHeader = setOrganizationHeader.id
			return state

		case RestaurantActionTypes.SET_REFERRALCODE:
			const setReferralCode = <SetRestaurantAction>action
			state.referralCode = setReferralCode.id
			return state

		case RestaurantActionTypes.SET_SLOGAN:
			const setSlogan = <SetRestaurantAction>action
			state.slogan = setSlogan.id
			return state

		case RestaurantActionTypes.SET_STRIPEID:
			const setStripeId = <SetRestaurantAction>action
			state.stripeId = setStripeId.id
			return state

		case RestaurantActionTypes.SET_PAYMENTMETHODID:
			const setPaymentMethodId = <SetRestaurantAction>action
			state.paymentMethodId = setPaymentMethodId.id
			return state

		case RestaurantActionTypes.SET_CARDINDEX:
			const setCardIndex = <SetAnyAction>action
			state.cardIndex = setCardIndex.var
			return state

		case RestaurantActionTypes.SET_SERVICEFEETAX:
			const setServiceFeeTax = <SetAnyAction>action
			state.serviceFeeTax = setServiceFeeTax.var
			return state

		case RestaurantActionTypes.SET_PLATFORMFEETAX:
			const setPlatformFeeTax = <SetAnyAction>action
			state.platformFeeTax = setPlatformFeeTax.var
			return state

		case RestaurantActionTypes.SET_ORGFEETAX:
			const setOrgFeeTax = <SetAnyAction>action
			state.orgFeeTax = setOrgFeeTax.var
			return state

		case RestaurantActionTypes.SET_ORGTIPPERCENT:
			const setOrgTipPercent = <SetAnyAction>action
			state.orgTipPercent = setOrgTipPercent.var
			return state

		case RestaurantActionTypes.SET_SERVICEFEE:
			const setServiceFee = <SetAnyAction>action
			state.serviceFee = setServiceFee.var
			return state

		case RestaurantActionTypes.SET_PLATFORMFEE:
			const setPlatformFee = <SetAnyAction>action
			state.platformFee = setPlatformFee.var
			return state

		case RestaurantActionTypes.SET_ORGFEE:
			const setOrgFee = <SetAnyAction>action
			state.orgFee = setOrgFee.var
			return state

		case RestaurantActionTypes.SET_PREPTIMELIST:
			const setPrepTimeList = <SetAnyAction>action
			state.prepTimeList = setPrepTimeList.var
			return state

		case RestaurantActionTypes.SET_POSUSERID:
			const setPosUserId = <SetRestaurantAction>action
			state.posUserId = setPosUserId.id
			return state

		case RestaurantActionTypes.SET_PICKUPMSG:
			const setPickupMessage = <SetRestaurantAction>action
			state.pickupMsg = setPickupMessage.id
			return state

		case RestaurantActionTypes.SET_USERID:
			const setUserId = <SetRestaurantAction>action
			state.userId = setUserId.id
			return state

		case ItemListActionTypes.SET_ITEMS:
			const setItemAction = <SetItemAction>action
			state.items = setItemAction.payload
			return state

		case ItemListActionTypes.SET_PAYMENTMETHODS:
			const setPaymentMethods = <SetItemAction>action
			state.paymentMethods = setPaymentMethods.payload
			return state

		case RestaurantActionTypes.SET_LOCALTIMEZONE:
			const setLocalTimezone = <SetRestaurantAction>action
			state.localTimezone = setLocalTimezone.id
			return state

		case RestaurantActionTypes.SET_DEVELOPERFEE:
			const setDeveloperFeeAction = <SetRestaurantAction>action
			state.DeveloperFee = setDeveloperFeeAction.id
			return state

		case RestaurantActionTypes.SET_SCHEDULINGJUMP:
			const setSchedulingJumpAction = <SetRestaurantAction>action
			state.schedulingJump = setSchedulingJumpAction.id
			return state

		case RestaurantActionTypes.SET_ADDRESS:
			const setAddressAction = <SetRestaurantAction>action
			state.address = setAddressAction.id
			return state

		case RestaurantActionTypes.SET_PREPTIME:
			const setPrepTimeAction = <SetRestaurantAction>action
			state.prepTime = setPrepTimeAction.id
			return state

		case RestaurantActionTypes.SET_PREPTIMESTRING:
			const setPrepTimeStringAction = <SetRestaurantAction>action
			state.prepTimeString = setPrepTimeStringAction.id
			return state

		case RestaurantActionTypes.SET_PREPTIMEMS:
			const setPrepTimeMsAction = <SetRestaurantAction>action
			state.prepTimeMs = setPrepTimeMsAction.id
			return state

		case RestaurantActionTypes.SET_NAME:
			const setNameAction = <SetRestaurantAction>action
			state.name = setNameAction.id
			return state

		case RestaurantActionTypes.SET_SOCIAL:
			const setSocialAction = <SetRestaurantAction>action
			state.social = setSocialAction.id
			return state

		case RestaurantActionTypes.SET_STOREOPEN:
			const setStoreOpen = <SetBoolAction>action
			state.storeOpen = setStoreOpen.bool
			return state

		case RestaurantActionTypes.SET_DELIVERYACTIVE:
			const setDeliveryActive = <SetBoolAction>action
			state.deliveryActive = setDeliveryActive.bool
			return state

		case KioskActionTypes.DISABLE_IDLE:
			const setDisableIdle = <SetBoolAction>action
			state.disableIdle = setDisableIdle.bool
			return state

		case RestaurantActionTypes.SET_ISSNOOZED:
			const setIsSnoozed = <SetRestaurantAction>action
			state.isSnoozed = setIsSnoozed.id
			return state

		case RestaurantActionTypes.SET_SNOOZEUNTIL:
			const setSnoozeUntil = <SetRestaurantAction>action
			state.snoozeUntil = parseFloat(setSnoozeUntil.id)
			return state

		case RestaurantActionTypes.SET_COUPONCODES:
			const setCouponCodesAction = <SetRestaurantAction>action
			state.couponCodes = setCouponCodesAction.id
			return state

		case RestaurantActionTypes.SET_HOURS:
			const setHoursAction = <SetRestaurantAction>action
			state.hours = setHoursAction.id
			return state

		case RestaurantActionTypes.SET_IMAGELOGO:
			const setLogoAction = <SetRestaurantAction>action
			state.ImageLogo = setLogoAction.id
			return state

		case RestaurantActionTypes.SET_IMAGEHERO:
			const setHeroAction = <SetRestaurantAction>action
			state.ImageHero = setHeroAction.id
			return state

		case RestaurantActionTypes.SET_LOCATIONID:
			const setLocIDAction = <SetRestaurantAction>action
			state.LocationId = setLocIDAction.id
			return state

		case RestaurantActionTypes.SET_RESTAURANTID:
			const setRestaurantIDAction = <SetRestaurantAction>action
			state.RestaurantId = setRestaurantIDAction.id
			return state

		case RestaurantActionTypes.SET_ACCESSTOKEN:
			const setAccessTokenAction = <SetRestaurantAction>action
			state.AccessToken = setAccessTokenAction.id
			return state

		case RestaurantActionTypes.SET_APPLICATIONID:
			const setAppIDAction = <SetRestaurantAction>action
			state.ApplicationId = setAppIDAction.id
			return state

		case ItemListActionTypes.ADD_ITEM:
			const addItemAction = <AddItemAction>action
			state.items.push(addItemAction.payload)
			return state

		case ItemListActionTypes.REMOVE_ITEM:
			const removeItemAction = <RemoveItemAction>action
			const itemIndex = state.items.findIndex(
				item => item.id == removeItemAction.id && item.idempotencyKey == removeItemAction.key
			)
			if (getIndex(removeItemAction.id) >= 0) {
				state.items.splice(itemIndex, 1)
			}
			return state

		case ItemListActionTypes.REMOVE_ALL_ITEMS:
			return initialState

		case LocationActionTypes.SET_CURRENT:
			const setCurrentLocationAction = <SetCurrentLocationAction>action
			state.locationManager.currentLocation = setCurrentLocationAction.location
			return state

		default:
			return state
	}
}
