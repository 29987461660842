import React from 'react'
import { Dimensions, View, Text, ScrollView, TouchableOpacity, Platform } from 'react-native'
import Modal from 'react-native-modal/dist/modal'
import GlobalStyle, { MAX_CHECKOUT_WIDTH } from '../../../../constants/GlobalStyle'
import { useContext } from 'react'
import { CheckoutScreenContext } from '../../../../state/context'
import { styles } from '../../Helpers/style'
import ExitIcon from '../../../../components/Headers/UIButtons'

/**
 * Base modal for checkout process
 */
// export function PlaceOrderModal({
// 	toggleConfirmModal,
// 	height,
// 	allowClickAway,
// 	removePadding = false,
// 	children,
// 	showNumPad = false,
// 	style = {},
// }) {
// 	const { isConfirmModalVisible } = useContext(CheckoutScreenContext)

// 	return (
// 		<Modal
// 			style={[
// 				GlobalStyle.modalStyle,
// 				{
// 					width: '90%',
// 					marginTop: 0,
// 					maxWidth: MAX_CHECKOUT_WIDTH - 200,
// 					justifyContent: 'center',
// 					flex: 1,
// 				},
// 				style,
// 			]}
// 			isVisible={isConfirmModalVisible}
// 			onBackButtonPress={allowClickAway ? toggleConfirmModal : null}
// 			onBackdropPress={allowClickAway ? toggleConfirmModal : null}
// 			avoidKeyboard={false}
// 			animationIn={'fadeIn'}
// 			animationOut={'fadeOut'}
// 			useNativeDriver={true}
// 		>
// 			<View
// 				style={[
// 					styles.modal,
// 					{
// 						paddingTop: removePadding ? 0 : 30,
// 						height: height,
// 						justifyContent: 'center',
// 					},
// 				]}
// 			>
// 				<ExitIcon onPress={toggleConfirmModal} style={{ top: 20 }} />

// 				{children}
// 			</View>
// 		</Modal>
// 	)
// }

export function PlaceOrderModal({
	toggleConfirmModal,
	height,
	allowClickAway,
	removePadding = false,
	children,
	showNumPad = false,
	style = {},
	smallHeight = false,
}) {
	const { isConfirmModalVisible } = useContext(CheckoutScreenContext)

	return (
		<Modal
			isVisible={isConfirmModalVisible}
			onBackButtonPress={allowClickAway ? toggleConfirmModal : null}
			onBackdropPress={allowClickAway ? toggleConfirmModal : null}
			avoidKeyboard={false}
			animationIn={'fadeIn'}
			animationOut={'fadeOut'}
			useNativeDriver={true}
			statusBarTranslucent={true}
		>
			<View style={[styles.centeredView, smallHeight && { marginVertical: '50%' }]}>
				<View style={styles.modalView}>
					{allowClickAway && <ExitIcon onPress={toggleConfirmModal} style={{ top: 20 }} />}
					<View
						style={{
							marginTop: Platform.OS === 'web' ? 15 : 30,
							width: '90%',
							height: '100%',
							justifyContent: 'space-between',
							marginBottom: Platform.OS === 'web' ? 100 : 0,
							// width: '100%',
							// flex: 1,
							// justifyContent: 'space-between',
						}}
					>
						{children}
					</View>
				</View>
			</View>
		</Modal>
	)
}
