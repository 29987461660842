import React, { useContext } from 'react'
import styles from './styles'
import { Text, View } from '../Themed'
import PaymentMethodsRow from './PaymentMethodsRow'
import { Image, Platform, TouchableOpacity } from 'react-native'
import { DataContext } from '../../state/context'

type PaymentMethodsListProps = {
	paymentMethods: any[]
	setCardId?: (cardId: string) => void
	loading: boolean
	isCheckout: boolean
	onPress: any
	cardId: string
	isDefault: boolean
	setSelectedCard: any
	selectedCard: string
	isDigitalWalletEnabled?: boolean
	digitalWalletType: any
}

export default function PaymentMethodsList(props: PaymentMethodsListProps) {
	const {
		paymentMethods,
		setCardId,
		loading,
		onPress,
		isCheckout,
		cardId,
		isDefault,
		selectedCard,
		setSelectedCard,
		isDigitalWalletEnabled = false,
		digitalWalletType,
	} = props

	const { paymentProvider } = useContext(DataContext)

	function showPaymentMethods() {
		const paymentMethodsList = paymentMethods.map((paymentMethod, i) => {
			const { id } = paymentMethod

			var isSelected = false
			if (id === cardId) {
				isSelected = true
			}

			let { cardBrand, last4, expMonth, expYear } = paymentMethod

			if (paymentProvider === 'stripe') {
				const { card_brand, last_4, exp_month, exp_year } = paymentMethod
				cardBrand = card_brand
				last4 = last_4
				expMonth = exp_month
				expYear = exp_year
			}

			return (
				<View
					key={id + '_' + i}
					style={[styles.rowContainer, { marginBottom: !isDefault ? 15 : 0 }]}
				>
					<TouchableOpacity
						activeOpacity={0.8}
						onPress={() => {
							isDefault ? onPress() : setSelectedCard(id)
						}}
					>
						<PaymentMethodsRow
							id={id}
							isDefault={isDefault}
							card_brand={cardBrand}
							last_4={last4}
							exp_month={expMonth}
							exp_year={expYear}
							setCardId={setCardId}
							isCheckout={isCheckout}
							onPress={onPress}
							isChecked={selectedCard === null ? isSelected : selectedCard === id}
						/>
					</TouchableOpacity>
				</View>
			)
		})

		if (isDigitalWalletEnabled && (!isDefault || cardId === digitalWalletType)) {
			var isSelected = false
			if (digitalWalletType === cardId) {
				isSelected = true
			}

			paymentMethodsList.push(
				<View
					key={digitalWalletType}
					style={[styles.rowContainer, { marginBottom: !isDefault ? 15 : 0 }]}
				>
					<TouchableOpacity
						activeOpacity={0.8}
						onPress={() => {
							isDefault ? onPress() : setSelectedCard(digitalWalletType)
						}}
					>
						<PaymentMethodsRow
							id={digitalWalletType}
							isDefault={isDefault}
							name={digitalWalletType === 'applePay' ? 'Apple Pay' : 'Google Pay'}
							card_brand={digitalWalletType}
							setCardId={setCardId}
							isCheckout={isCheckout}
							onPress={onPress}
							isChecked={selectedCard === null ? isSelected : selectedCard === digitalWalletType}
						/>
					</TouchableOpacity>
				</View>
			)
		}
		return paymentMethodsList
	}

	if (paymentMethods?.length || isDefault) {
		return <View>{showPaymentMethods()}</View>
	} else if (loading) {
		return (
			<Image
				style={{ width: 100, height: 100, alignSelf: 'center', marginTop: -20 }}
				source={require('../../assets/images/loadImg.gif')}
			/>
		)
	}

	return <Text>No available payment methods</Text>
}
