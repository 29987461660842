import React from 'react'
import { View } from './Themed'
import Colors from '../constants/Colors'
import { styles } from '../screens/Checkout/Helpers/style'

export default function Separator() {
	return (
		<View
			style={styles.separator}
			lightColor={Colors.custom.lightGrey}
			darkColor={Colors.custom.lightGrey}
		/>
	)
}
