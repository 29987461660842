import React, { useContext, useState } from 'react'
import { CheckoutScreenContext, DataContext } from '../../../state/context'
import { CheckoutItem, Prices } from './types'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../state/reducer'

const CheckoutScreenProvider = ({ children }) => {
	const [items, setItems] = useState<CheckoutItem[]>([])
	const [orderNote, setOrderNote] = useState('')
	const [allowOrder, setAllowOrder] = useState(true)
	const [couponCode, setCouponCode] = useState('')
	const [couponCodeData, setCouponCodeData] = useState<Array<any>>([])
	const [couponCodeDisplay, setCouponCodeDisplay] = useState<any>()
	const [couponCodePricing, setCouponCodePricing] = useState<any>()
	const [userRedeemedCodes, setUserRedeemedCodes] = useState([])
	const [userAvailableCodes, setUserAvailableCodes] = useState([])
	const [saveCard, setSaveCard] = useState(true)
	const [useSavedPaymentMethod, setUseSavedPaymentMethod] = useState(true)
	const [useNewPaymentMethod, setUseNewPaymentMethod] = useState(false)
	const [phoneNumber, setPhoneNumber] = useState('')
	const [phoneNumberInput, setPhoneNumberInput] = useState(false)
	const [showPhoneNumber, setShowPhoneNumber] = useState(false)
	const [couponCodeBackup, setCouponCodeBackup] = useState('')
	const [modalTimestamps, setModalTimestamps] = useState({})

	const [restaurantDiscountAmount, setRestaurantDiscountAmount] = useState<any>()

	const [orderCreated, setOrderCreated] = useState(false)

	const [status, setStatus] = useState('')
	const [orderId, setOrderId] = useState('')
	const [orderNumber, setOrderNumber] = useState('')
	const [awaitingTerminalInput, setAwaitingTerminalInput] = useState(false)
	const [paymentError, setPaymentError] = useState(false)

	const [savedPhone, setSavedPhone] = useState('')

	const [isFreeItemDiscount, setIsFreeItemDiscount] = useState(false)

	const [showDineInOrTakeout, setShowDineInOrTakeout] = useState(true)

	const [showPOSMethods, setShowPOSMethods] = useState(global.pos)

	const [analyticsItems, setAnalyticsItems] = useState([])

	//options are '', 'manual', 'pinpad', 'cash', 'external'
	const [typePOS, setTypePOS] = useState('')
	//if pos type is external, record brand name, id, type, & extra note
	//For others, just record type
	const [posExtraData, setPosExtraData] = useState({
		name: '',
		firebaseId: '',
		note: '',
		type: '',
	})

	const [tableNumber, setTableNumber] = useState(null)

	const [takeOutName, setTakeOutName] = useState(null)

	const [couponRefresh, setCouponRefresh] = useState([])

	const [prices, setPrices] = useState<Prices>({
		discount: 0,
		subTotal: 0,
		deliveryFee: 0,
		serviceCharge: 0,
		taxes: 0,
		taxesList: [],
		total: 0,
		tip: 0,
		noCodeDiscount: 0,
		organizationDiscount: 0,
		bagFee: 0,
		bagFeeTax: 0,
		subTotalAfterDiscount: 0,
	})
	let collapsibles: { [key: string]: boolean } = {}
	var setCollapsibles: any
	;[collapsibles, setCollapsibles] = useState({})
	const [tipCount, setTipCount] = useState(0)
	const [spinner, setSpinner] = useState(false)
	const [orderPlaced, setOrderPlaced] = useState(false)
	const [expanded, setExpanded] = useState(true)
	const [userStoredAddresses, setUserStoredAddresses] = useState<any>([])
	const [isConfirmModalVisible, setConfirmModalVisible] = useState(false)
	const [isTipModalVisible, setTipModalVisible] = useState(false)
	const [isPaymentMethodModalVisible, setPaymentMethodModalVisible] = useState(false)
	const [isAddingCard, setIsAddingCard] = useState(false)
	const [isPromoModalVisible, setPromoModalVisible] = useState(false)
	const [isGuestCheckout, setIsGuestCheckout] = useState(false)
	const [isUser, setIsUser] = useState(false)
	const [showNotes, setShowNotes] = useState(false)

	const [validCouponCodes, setValidCouponCodes] = useState([]) //global.couponCodes;
	const [loading, setLoading] = useState(true)
	const [paymentMethods, setPaymentMethods] = useState([])
	const [allPrepTimes, setAllPrepTimes] = useState([])
	const [isTaxesExpanded, setIsTaxesExpanded] = useState(false)

	const [modalKey, setModalKey] = useState(0)
	const [reset, setReset] = useState([])
	const [timer, setTimer] = useState(null)
	const [cancelButton, setCancelButton] = useState('Cancel')

	const [selectedCard, setSelectedCard] = useState(null)
	const paymentMethodId = useSelector<ReduxStoreState, ReduxStoreState['paymentMethodId']>(
		state => state.paymentMethodId
	)

	const { paymentProvider } = useContext(DataContext)

	const [cardId, setCardId] = useState(paymentProvider === 'stripe' ? paymentMethodId : '') // unsure where the paymentMethodId originates from

	const [bagFeeEnabled, setBagFeeEnabled] = useState(false)
	const [bagFee, setBagFee] = useState(0)
	const [bagFeeSelected, setBagFeeSelected] = useState(true)

	const [couponName, setCouponName] = useState('')

	const [showAddTip, setShowAddTip] = useState(!global.kiosk)

	const [cardElementVisible, setCardElementVisible] = useState(false)

	const [visiblePromos, setVisiblePromos] = useState(0)

	const [selectedTip, setSelectedTip] = useState(null)

	const [isOpen, setIsOpen] = useState(true)

	const [analyticsPaymentMethod, setAnalyticsPaymentMethod] = useState(null)

	const [selectedMethods, setSelectedMethods] = useState({
		textMessage: false,
		email: false,
		print: global.kiosk,
		textUpdates: true,
		showNumPad: false,
		phoneString: '',
		emailString: '',
	})

	const [visibleCouponCount, setVisibleCouponCount] = useState(0)

	const [showSquarePayment, setShowSquarePayment] = useState(false) // used only for web

	const [checkoutId, setCheckoutId] = useState('') // only for square terminal

	const [isDigitalWalletEnabled, setIsDigitalWalletEnabled] = useState(false)

	const [isDigitalPaymentWindowOpen, setIsDigitalPaymentWindowOpen] = useState(false)

	const [paymentRequest, setPaymentRequest] = useState(null)

	const [digitalWalletType, setDigitalWalletType] = useState(null)

	const [terminalOrder, setTerminalOrder] = useState(null)

	const [processedOrder, setProcessedOrder] = useState({})

	const [posLoyaltyPoints, setPOSLoyaltyPoints] = useState(-1)

	const [posLoyaltyPointsAccrued, setPOSLoyaltyPointsAccrued] = useState(0)

	const [showKioskLoyaltyEntry, setShowKioskLoyaltyEntry] = useState(false)

	const [isRestaurantSnoozed, setIsRestaurantSnoozed] = useState(false)

	const [orderData, setOrderData] = useState(false)

	const [showGuestCheckoutName, setShowGuestCheckoutName] = useState(false)

	const [deliveryAmountToSpend, setDeliveryAmountToSpend] = useState(0)

	return (
		<CheckoutScreenContext.Provider
			value={{
				items,
				setItems,
				orderNote,
				setOrderNote,
				allowOrder,
				setAllowOrder,
				couponCode,
				setCouponCode,
				couponCodeData,
				setCouponCodeData,
				couponCodeDisplay,
				setCouponCodeDisplay,
				couponCodePricing,
				setCouponCodePricing,
				userRedeemedCodes,
				setUserRedeemedCodes,
				userAvailableCodes,
				setUserAvailableCodes,
				saveCard,
				setSaveCard,
				useSavedPaymentMethod,
				setUseSavedPaymentMethod,
				useNewPaymentMethod,
				setUseNewPaymentMethod,
				phoneNumber,
				setPhoneNumber,
				phoneNumberInput,
				setPhoneNumberInput,
				showPhoneNumber,
				setShowPhoneNumber,
				couponCodeBackup,
				setCouponCodeBackup,
				restaurantDiscountAmount,
				setRestaurantDiscountAmount,
				orderCreated,
				setOrderCreated,
				status,
				setStatus,
				orderId,
				setOrderId,
				orderNumber,
				setOrderNumber,
				awaitingTerminalInput,
				setAwaitingTerminalInput,
				paymentError,
				setPaymentError,
				savedPhone,
				setSavedPhone,
				isFreeItemDiscount,
				setIsFreeItemDiscount,
				showDineInOrTakeout,
				setShowDineInOrTakeout,
				tableNumber,
				setTableNumber,
				takeOutName,
				setTakeOutName,
				couponRefresh,
				setCouponRefresh,
				prices,
				setPrices,
				collapsibles,
				setCollapsibles,
				tipCount,
				setTipCount,
				spinner,
				setSpinner,
				orderPlaced,
				setOrderPlaced,
				expanded,
				setExpanded,
				userStoredAddresses,
				setUserStoredAddresses,
				isConfirmModalVisible,
				setConfirmModalVisible,
				isTipModalVisible,
				setTipModalVisible,
				isPromoModalVisible,
				setPromoModalVisible,
				isGuestCheckout,
				setIsGuestCheckout,
				cardId,
				setCardId,
				isUser,
				setIsUser,
				showNotes,
				setShowNotes,
				validCouponCodes,
				setValidCouponCodes,
				loading,
				setLoading,
				paymentMethods,
				setPaymentMethods,
				allPrepTimes,
				setAllPrepTimes,
				isTaxesExpanded,
				setIsTaxesExpanded,
				modalKey,
				setModalKey,
				reset,
				setReset,
				timer,
				setTimer,
				cancelButton,
				setCancelButton,
				showPOSMethods,
				setShowPOSMethods,
				typePOS,
				setTypePOS,
				posExtraData,
				setPosExtraData,
				bagFeeEnabled,
				setBagFeeEnabled,
				bagFee,
				setBagFee,
				setBagFeeSelected,
				bagFeeSelected,
				selectedMethods,
				setSelectedMethods,
				couponName,
				setCouponName,
				isPaymentMethodModalVisible,
				setPaymentMethodModalVisible,
				isAddingCard,
				setIsAddingCard,
				selectedCard,
				setSelectedCard,
				showAddTip,
				setShowAddTip,
				cardElementVisible,
				setCardElementVisible,
				visiblePromos,
				setVisiblePromos,
				selectedTip,
				setSelectedTip,
				isOpen,
				setIsOpen,
				visibleCouponCount,
				setVisibleCouponCount,
				analyticsItems,
				setAnalyticsItems,
				analyticsPaymentMethod,
				setAnalyticsPaymentMethod,
				showSquarePayment,
				setShowSquarePayment,
				checkoutId,
				setCheckoutId,
				isDigitalWalletEnabled,
				setIsDigitalWalletEnabled,
				setIsDigitalPaymentWindowOpen,
				isDigitalPaymentWindowOpen,
				paymentRequest,
				setPaymentRequest,
				digitalWalletType,
				setDigitalWalletType,
				terminalOrder,
				setTerminalOrder,
				processedOrder,
				setProcessedOrder,
				posLoyaltyPoints,
				setPOSLoyaltyPoints,
				showKioskLoyaltyEntry,
				setShowKioskLoyaltyEntry,
				posLoyaltyPointsAccrued,
				setPOSLoyaltyPointsAccrued,
				isRestaurantSnoozed,
				setIsRestaurantSnoozed,
				orderData,
				setOrderData,
				showGuestCheckoutName,
				setShowGuestCheckoutName,
				modalTimestamps,
				setModalTimestamps,
				deliveryAmountToSpend,
				setDeliveryAmountToSpend,
			}}
		>
			{children}
		</CheckoutScreenContext.Provider>
	)
}

export default CheckoutScreenProvider
