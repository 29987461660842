import * as React from 'react'
import GlobalStyle from '../../../constants/GlobalStyle'
import { styles } from '../Helpers/style'
import { Text, View } from '../../../components/Themed'
import { currencyFormat } from '../Helpers/priceFunctions'

type props = {
	name: string
	price: number
}

export default function Pricing(props: props) {
	const { name, price } = props

	return (
		<View
			style={[styles.pricingComponent, { flexDirection: 'row', justifyContent: 'space-between' }]}
		>
			<View>
				<Text style={[GlobalStyle.text, { flex: 1 }]}>{name}</Text>
			</View>
			<View>
				<Text style={[GlobalStyle.text, { flex: 1 }]}>{currencyFormat(price)}</Text>
			</View>
		</View>
	)
}
