import { StatusBar } from 'expo-status-bar'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
	View,
	TextInput,
	TouchableOpacity,
	ScrollView,
	Image,
	Modal,
	TouchableWithoutFeedback,
	Platform,
} from 'react-native'
import DropDownPicker from 'react-native-dropdown-picker'
import { Formik } from 'formik'
import * as yup from 'yup'
import { OrganizationLogoAddressPage } from '../../components/OrganizationLogoHeader'
import { Spacer } from '../../components/Spacer'
import { formStyle, smallTextSize } from '../../constants/GlobalStyle'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../state/reducer'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { provinceList } from './validProvinces'
import { DataContext } from '../../state/context'
import Collapsible from 'react-native-collapsible'
import { auth } from '../../firebase/config'
import { AddressCard } from './AddressCard'
import DeliveryPickupToggle from './DeliveryPickupToggle'
import { getDeliveryEstimate } from '../../helpers/fbDataRetrieval/RetrieveLocation'
import { add } from 'date-fns'
import { parsePrepTime } from '../../helpers/prepTimeFunctions'
import { Text } from '../Themed'
import { alertResponse } from '../Alerts/Alerts'
import Colors from '../../constants/Colors'
import Banner from '../Banner'
import { setDeliverySettings } from '../../state/actions'

// Address validation schema
const addressSchema = yup.object().shape({
	street: yup.string().required('Street is required'),
	street2: yup.string(),
	city: yup.string().required('City is required'),
	province: yup.string().required('Province is required'),
	postalCode: yup
		.string()
		.transform(value => value.replace(/\s+/g, '').toUpperCase())
		.required('A valid postal code is required')
		.matches(/^[0-9A-Z]+$/, 'Invalid postal code'),
	label: yup.string(),
	deliveryNote: yup.string(),
})

const functions = getFunctions()

const capitalizeFirstLetterOfEachWord = text => {
	return text.replace(/\b\w/g, char => char.toUpperCase())
}

export default function NewAddressModal(props) {
	const { modalVisible, setModalVisible } = props

	const organizationLogo = useSelector<ReduxStoreState, ReduxStoreState['organizationLogo']>(
		state => state.organizationLogo
	)
	const userId = auth.currentUser ? auth.currentUser.uid : ''
	const {
		setUserLocation,
		setUserDeliveryAddress,
		userData,
		activeRID,
		orgDeliveryCoverageType,
		orgDeliveryCoverage,
		setDeliveryEstimate,
		userLocation,
		contactlessDropoff,
		setContactlessDropoff,
		deliveryIsSandbox,
		isDelivery,
		deliveryEstimate,
	} = useContext(DataContext)

	const deliveryAddresses = userData?.user?.deliveryAddresses || []
	const [saveAddress, setSaveAddress] = useState(auth.currentUser ? true : false)
	const [isLoading, setIsLoading] = useState(false)
	const [isCollapsed, setIsCollapsed] = useState(true)
	const [errorWithAddress, setErrorWithAddress] = useState(false)
	const [showInvalidAddress, setShowInvalidAddress] = useState(false)
	const [isFocus, setIsFocus] = useState(false)
	const [provinces, setProvinces] = useState(provinceList)

	useEffect(() => {
		if (deliveryAddresses.length <= 0) {
			setIsCollapsed(false)
		}
	}, [])

	useEffect(() => {
		if (userLocation) {
			setIsLoading(true)
			if ((userLocation && userLocation.error) || !isDelivery || deliveryEstimate !== null) {
				setIsLoading(false)
			}
		}
	}, [deliveryEstimate])

	function splitFee(fee) {
		if (orgDeliveryCoverageType === 'percent') {
			return fee * (orgDeliveryCoverage / 100)
		} else if (fee - orgDeliveryCoverage >= 0) {
			return fee - orgDeliveryCoverage
		} else {
			return 0
		}
	}

	const DropoffToggle = () => {
		return (
			<TouchableOpacity
				onPress={() => setContactlessDropoff(!contactlessDropoff)}
				style={{
					flexDirection: 'row',
					alignItems: 'center',
					marginHorizontal: isCollapsed ? '3.5%' : '5%',
					marginVertical: 10,
					paddingBottom: 20,
				}}
			>
				<MaterialCommunityIcons
					name={contactlessDropoff ? 'checkbox-marked-circle' : 'checkbox-blank-circle-outline'}
					size={26}
					color={contactlessDropoff ? global.orgColor : '#818181'}
					style={{
						marginLeft: -10,
						marginRight: 10,
					}}
				/>
				<Text style={{ flex: 1, fontSize: smallTextSize, fontWeight: '700' }}>
					{'Contactless dropoff?'}
				</Text>
			</TouchableOpacity>
		)
	}

	const handleAddressSelect = async (
		longAddress,
		formattedAddress,
		label,
		instructions,
		updatedAt,
		id,
		addressObj
	) => {
		const locationObj: any = {
			coords: null,
			formattedAddress: formattedAddress,
			longAddress: longAddress,
			label: label,
			instructions: instructions,
			updatedAt: updatedAt,
			id: id,
			addressObject: addressObj,
		}
		setUserLocation(locationObj)
		locationObj.deliverySelected = isDelivery
		setDeliverySettings(locationObj)
		setIsLoading(true)
		setShowInvalidAddress(false)
		const estimate: any = await getDeliveryEstimate(
			longAddress,
			add(new Date(), parsePrepTime('P0DT0H' + 15 + 'M0S')),
			activeRID,
			deliveryIsSandbox
		)

		if (!estimate.fee && !estimate.timeToDeliver) {
			setErrorWithAddress(true)
		} else {
			setErrorWithAddress(false)
		}

		setDeliveryEstimate({
			restaurantId: activeRID,
			estimate: {
				fee: splitFee(estimate.fee) || -1,
				timeToDeliver: estimate.timeToDeliver || -1,
				totalFee: estimate.fee || -1,
			},
		})
		setIsLoading(false)

		setUserDeliveryAddress(longAddress)
		//setModalVisible(false)
	}

	return (
		<Modal
			visible={modalVisible}
			statusBarTranslucent={true}
			transparent={true}
			animationType="fade"
			onRequestClose={() => null}
		>
			<TouchableWithoutFeedback onPress={() => null}>
				<View
					style={{
						flex: 1,
						justifyContent: 'center',
						alignItems: 'center',
						paddingHorizontal: '2.5%',
						backgroundColor: 'rgba(0,0,0,0.5)',
					}}
				>
					<TouchableWithoutFeedback onPress={() => {}}>
						<View
							style={{
								backgroundColor: 'white',
								paddingHorizontal: 20,
								width: '95%',
								maxWidth: 600,
								borderRadius: 10,
								shadowOpacity: 0.25,
								shadowRadius: 3.84,
								elevation: 5,
								maxHeight: '90%',
							}}
						>
							<ScrollView
								//contentContainerStyle={{ alignItems: 'center' }}
								showsVerticalScrollIndicator={false}
								automaticallyAdjustKeyboardInsets={true}
							>
								{/* <ExitIcon
									// onPress={() => setModalVisible(false)}
									style={{ marginTop: -20, marginRight: -15 }}
									onPress={() => {
										const createBusiness = httpsCallable(functions, 'createDoorDashBusiness')
										createBusiness({
											isSandbox: true,
											orgId: global.org,
											orgName: 'DeliverectTesting',
										})
										const createStore = httpsCallable(functions, 'createDoorDashStore')
										createStore({
											isSandbox: true,
											orgId: global.org,
											restaurantId: '66347a0bb2696c371ffdf968',
											restaurantName: 'Deliverect Store',
											restaurantPhoneNumber: '+16046826220',
											restaurantAddress: '1515 W 2nd Ave, Vancouver, BC, V6J 5C5, Canada',
										})
										const listStores = httpsCallable(functions, 'listDoorDashStores')
										listStores({
											orgId: global.org,
										})
									}}
								/> */}
								{showInvalidAddress && (
									<Banner text="Invalid Address! Please use a different address to continue." />
								)}
								<OrganizationLogoAddressPage imageUri={organizationLogo} />
								<DeliveryPickupToggle
									showChange={false}
									onPressPickup={() => {
										setModalVisible(false)
									}}
								/>

								{deliveryAddresses.length > 0 && (
									<>
										<View style={formStyle.headerContainer}>
											<TouchableOpacity
												onPress={() => setIsCollapsed(!isCollapsed)}
												style={formStyle.touchable}
											>
												<Text style={formStyle.headerText}>Select a delivery address</Text>
											</TouchableOpacity>
										</View>

										<Collapsible collapsed={!isCollapsed}>
											{deliveryAddresses.map((address, index) => (
												<AddressCard
													key={index}
													longAddress={address?.longAddress}
													label={address?.label}
													instructions={address?.instructions}
													onPress={() =>
														handleAddressSelect(
															address?.longAddress,
															address?.formattedAddress,
															address?.label,
															address?.instructions,
															address?.updatedAt,
															address?.id,
															address?.addressObject
														)
													}
													onDelete={() => {
														alertResponse(
															'Delete saved address?',
															'',
															'Delete',
															'Cancel',
															'default',
															'cancel',
															() => {
																const setDeliveryAddressForUser = httpsCallable(
																	functions,
																	'setDeliveryAddressForUser'
																)

																setDeliveryAddressForUser({
																	userId: userId,
																	address: address,
																	orgId: global.org,
																	isEdit: false,
																	isDelete: true,
																})
															},
															null
														)
													}}
												/>
											))}
										</Collapsible>
									</>
								)}

								<View style={formStyle.headerContainer}>
									<TouchableOpacity
										onPress={() => {
											//if (deliveryAddresses.length > 0) {
											setIsCollapsed(!isCollapsed)
											//}
										}}
										style={formStyle.touchable}
									>
										<Text style={formStyle.headerText}>Enter a new delivery address</Text>
									</TouchableOpacity>
								</View>

								<Collapsible collapsed={isCollapsed}>
									<Formik
										initialValues={{
											label: '',
											street: '',
											street2: '',
											city: '',
											province: '',
											postalCode: '',
											country: '',
											deliveryNote: '',
										}}
										validationSchema={addressSchema}
										onSubmit={async (values, actions) => {
											const streetAddress = `${values.street}${
												values.street2 ? ', ' + values.street2 : ''
											}`
											const longAddress = `${values.street}${
												values.street2 ? ', ' + values.street2 : ''
											}, ${values.city}, ${values.province}, ${values.postalCode}`
											const id = Date.now()
											const locationObj: any = {
												coords: null,
												formattedAddress: streetAddress,
												longAddress: longAddress,
												label: values.label,
												instructions: values.deliveryNote,
												id: id,
												addressObject: {
													streetAddress: values.street,
													streetAddress2: values.street2,
													city: values.city,
													province: values.province,
													postalCode: values.postalCode,
												},
											}
											setUserLocation(locationObj)
											locationObj.deliverySelected = isDelivery
											setDeliverySettings(locationObj)

											const setDeliveryAddressForUser = httpsCallable(
												functions,
												'setDeliveryAddressForUser'
											)

											const addAddress: any = setDeliveryAddressForUser({
												userId: userId,
												address: {
													id: id,
													longAddress: longAddress,
													formattedAddress: streetAddress,
													label: values.label,
													instructions: values.deliveryNote,
													updatedAt: new Date(),
													addressObject: {
														streetAddress: values.street,
														streetAddress2: values.street2,
														city: values.city,
														province: values.province,
														postalCode: values.postalCode,
													},
												},
												orgId: global.org,
												isEdit: false,
												isDelete: false,
											})
											setIsLoading(true)
											setShowInvalidAddress(false)
											console.log('ADDED: ' + addAddress)
											setUserDeliveryAddress(longAddress)

											const estimate: any = await getDeliveryEstimate(
												longAddress,
												add(new Date(), parsePrepTime('P0DT0H' + 15 + 'M0S')),
												activeRID,
												deliveryIsSandbox
											)

											setDeliveryEstimate({
												restaurantId: activeRID,
												estimate: {
													fee: splitFee(estimate.fee) || -1,
													timeToDeliver: estimate.timeToDeliver || -1,
													totalFee: estimate.fee || -1,
												},
											})
											setIsLoading(false)

											if (
												(!estimate.fee && !estimate.timeToDeliver) ||
												(userLocation && userLocation.error) ||
												!userLocation
											) {
												setIsCollapsed(deliveryAddresses.length > 0)
												setShowInvalidAddress(true)
												// alertResponseSingle(
												// 	'Invalid Address',
												// 	'Please select a different address to continue.',
												// 	'Continue',
												// 	null,
												// 	null
												// )
											} else {
												setShowInvalidAddress(false)
												setModalVisible(false)
											}

											actions.resetForm()
										}}
									>
										{({
											handleChange,
											handleBlur,
											handleSubmit,
											setFieldValue,
											values,
											touched,
											errors,
										}) => (
											<View style={{ width: '95%', alignSelf: 'center' }}>
												<TextInput
													returnKeyType={'done'}
													style={formStyle.textInput}
													placeholder="Street address"
													placeholderTextColor="#000000"
													onChangeText={handleChange('street')}
													value={capitalizeFirstLetterOfEachWord(values.street)}
													onBlur={handleBlur('street')}
													autoComplete="address-line1"
												/>
												<Text style={formStyle.errorText}>{touched.street && errors.street}</Text>
												<Spacer size={10} />

												<TextInput
													returnKeyType={'done'}
													style={formStyle.textInput}
													placeholder="Apt / Suite"
													placeholderTextColor="#000000"
													onChangeText={handleChange('street2')}
													value={values.street2}
													onBlur={handleBlur('street2')}
													autoComplete="address-line2"
												/>
												<Text style={formStyle.errorText}>{touched.street2 && errors.street2}</Text>
												<Spacer size={10} />

												{Platform.OS === 'ios' ? (
													<TextInput
														returnKeyType={'done'}
														style={formStyle.textInput}
														placeholder="City"
														placeholderTextColor="#000000"
														onChangeText={handleChange('city')}
														value={capitalizeFirstLetterOfEachWord(values.city)}
														onBlur={handleBlur('city')}
														textContentType="addressCity"
													/>
												) : (
													<TextInput
														returnKeyType={'done'}
														style={formStyle.textInput}
														placeholder="City"
														placeholderTextColor="#000000"
														onChangeText={handleChange('city')}
														value={capitalizeFirstLetterOfEachWord(values.city)}
														onBlur={handleBlur('city')}
														autoComplete="postal-address-locality"
													/>
												)}
												<Text style={formStyle.errorText}>{touched.city && errors.city}</Text>
												<Spacer size={10} />

												<DropDownPicker
													listMode="SCROLLVIEW"
													maxHeight={186}
													open={isFocus}
													value={values.province}
													items={provinces}
													setItems={setProvinces}
													setOpen={setIsFocus}
													setValue={callback => {
														const selectedValue = callback(values.province)
														console.log(selectedValue)
														setFieldValue('province', selectedValue)
													}}
													placeholder={'Province'}
													placeholderStyle={{
														color: '#000000',
														fontFamily: Platform.OS === 'web' ? 'System' : 'DefaultFont',
													}}
													style={formStyle.textInput}
													textStyle={{
														marginLeft: 5,
														fontFamily: Platform.OS === 'web' ? 'System' : 'DefaultFont',
													}}
													dropDownContainerStyle={{ borderColor: Colors.custom.transparentGrey }}
												/>
												<Text style={formStyle.errorText}>
													{touched.province && errors.province}
												</Text>
												<Spacer size={10} />
												<TextInput
													returnKeyType={'done'}
													style={formStyle.textInput}
													placeholder="Postal code"
													placeholderTextColor="#000000"
													onChangeText={handleChange('postalCode')}
													value={values.postalCode.toUpperCase()}
													onBlur={handleBlur('postalCode')}
													autoComplete="postal-code"
												/>
												<Text style={formStyle.errorText}>
													{touched.postalCode && errors.postalCode}
												</Text>
												<Spacer size={10} />

												<TextInput
													returnKeyType={'done'}
													style={formStyle.textInput}
													placeholder="Delivery instructions (optional)"
													placeholderTextColor="#000000"
													onChangeText={handleChange('deliveryNote')}
													value={values.deliveryNote}
													onBlur={handleBlur('deliveryNote')}
												/>
												<Text style={formStyle.errorText}>
													{touched.deliveryNote && errors.deliveryNote}
												</Text>
												<Spacer size={10} />
												<TextInput
													returnKeyType={'done'}
													style={formStyle.textInput}
													placeholder="Label (optional)"
													placeholderTextColor="#000000"
													onChangeText={handleChange('label')}
													value={values.label}
													onBlur={handleBlur('label')}
												/>
												<Text style={formStyle.errorText}>{touched.label && errors.label}</Text>
												<Spacer size={10} />
												<TouchableOpacity
													onPress={() => setSaveAddress(!saveAddress)}
													style={{
														flexDirection: 'row',
														alignItems: 'center',
														display: auth.currentUser ? 'flex' : 'none',
													}}
												>
													<MaterialCommunityIcons
														name={
															saveAddress
																? 'checkbox-marked-circle'
																: 'checkbox-blank-circle-outline'
														}
														size={26}
														color={saveAddress ? global.orgColor : '#818181'}
													/>
													<Text
														style={{ marginLeft: 5, fontSize: smallTextSize, fontWeight: '700' }}
													>
														{'Save address for future orders.'}
													</Text>
												</TouchableOpacity>
												<Spacer size={10} />
												<View style={[formStyle.btnContainer]}>
													<TouchableOpacity
														style={[formStyle.button, { backgroundColor: global.orgColor }]}
														onPress={isLoading ? null : (handleSubmit as any)}
													>
														{isLoading ? (
															<Image
																style={{
																	width: 90,
																	height: 90,
																	marginTop: -34,
																	marginBottom: -34,
																}}
																source={require('../../assets/images/loadImg.gif')}
															/>
														) : (
															<Text style={formStyle.btnText}>
																{saveAddress ? 'SAVE ADDRESS' : 'SET ADDRESS'}
															</Text>
														)}
													</TouchableOpacity>
												</View>
											</View>
										)}
									</Formik>
								</Collapsible>
								<DropoffToggle />
							</ScrollView>

							{(isCollapsed ||
								(deliveryAddresses.length <= 0 && userLocation !== null && isCollapsed)) && (
								<TouchableOpacity
									style={[
										formStyle.button,
										{ backgroundColor: global.orgColor, width: '100%', marginBottom: 20 },
									]}
									onPress={() => {
										if (
											!isLoading &&
											((deliveryEstimate &&
												deliveryEstimate.estimate &&
												deliveryEstimate.estimate.totalFee === -1) ||
												errorWithAddress ||
												(userLocation && userLocation.error) ||
												!userLocation)
										) {
											setShowInvalidAddress(true)
											// alertResponseSingle(
											// 	'Invalid Address',
											// 	'Please select a different address to continue.',
											// 	'Continue',
											// 	null,
											// 	null
											// )
										} else if (!isLoading) {
											setShowInvalidAddress(false)
											setModalVisible(false)
										}
									}}
								>
									{isLoading ? (
										<Image
											style={{
												width: 90,
												height: 90,
												marginTop: -34,
												marginBottom: -34,
											}}
											source={require('../../assets/images/loadImg.gif')}
										/>
									) : (
										<Text style={formStyle.btnText}>{'CONTINUE'}</Text>
									)}
								</TouchableOpacity>
							)}
						</View>
					</TouchableWithoutFeedback>
				</View>
			</TouchableWithoutFeedback>
		</Modal>
	)
}
