import React from 'react'
import { Text } from '../../../components/Themed'
import { getCombinedModPrice } from '../Helpers/functions'
import { useContext } from 'react'
import { ItemScreenContext } from '../../../state/context'
import Colors from '../../../constants/Colors'
import Formatter from '../../../helpers/Formatter'
import { lTextSize } from '../../../constants/GlobalStyle'

export function AdjustedPrice() {
	const { itemPrice, promoPrice, discountPrice, quantity, modList, totalModPrice } =
		useContext(ItemScreenContext)
	if (discountPrice > 0) {
		var p1 = Formatter.currencyFormat(((itemPrice + totalModPrice) * quantity) / 100)
		var p2 = Formatter.currencyFormat(
			((itemPrice + totalModPrice) * quantity) / 100 - discountPrice
		)

		return (
			<>
				<Text style={{ textDecorationLine: 'line-through' }}>{p1}</Text>
				<Text style={{ color: Colors.custom.pinkRed }}> {p2}</Text>
			</>
		)
	} else if (promoPrice === -1) {
		return (
			<Text style={{ color: 'green' }}>
				{Formatter.currencyFormat(((itemPrice + totalModPrice) * quantity) / 100)}
			</Text>
		)
	} else if (promoPrice === 0 && itemPrice === 0) {
		//loading
		return <></>
	} else {
		var p1 = Formatter.currencyFormat(((itemPrice + totalModPrice) * quantity) / 100)
		var p2 = Formatter.currencyFormat(((promoPrice + totalModPrice) * quantity) / 100)
		return (
			<>
				<Text style={{ textDecorationLine: 'line-through', fontSize: lTextSize }}>{p1}</Text>
				<Text style={{ color: Colors.custom.pinkRed, fontSize: lTextSize }}> {p2}</Text>
			</>
		)
	}
}
