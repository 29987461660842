import React from 'react'
import { View, StyleSheet, TouchableOpacity, Platform, Dimensions } from 'react-native'
import { AntDesign } from '@expo/vector-icons'
import GlobalStyle, {
	MAX_CHECKOUT_WIDTH,
	SMALL_WIDTH,
	largeTextSize,
	titleTextSize,
} from '../../constants/GlobalStyle'
import Colors from '../../constants/Colors'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../state/reducer'
import { AdjustedPrice } from '../../screens/Item/Components/AdjustedPriceText'
import { responsiveWidth } from 'react-native-responsive-dimensions'
import { alertResponseText } from '../Alerts/Alerts'
import { Text } from '../Themed'

export type FooterProps = {
	navigation: any
	showAddToOrder?: boolean
	showNext?: boolean
	onNextPress?: any
	onAddToOrderPress?: any
	onBackPress?: any
	price?: any
	checkoutBar?: boolean
	blurNext?: boolean
	isMenu?: boolean
	rId?: string
	isSearchActive?: boolean
	onCancelSearch?: any
	cartBar?: boolean
	onCartPress?: any
	returnToCheckout?: boolean
	isEdit?: boolean
	loading?: boolean
	isRestaurantSnoozed?: boolean
}

/** Footer used in following cases:
 * 1. Cart page "checkout now" button (on mobile & web --> sticky on mobile)
 * 2. Item page bottom bar
 * 3. Menu page bottom bar
 * 4. "Checkout now" bar that shows up on restaurant select screen on kiosk/pos only
 */
export default function NavFooter(props: FooterProps) {
	const {
		navigation,
		showAddToOrder = false,
		showNext = false,
		price = null,
		onNextPress = null,
		onAddToOrderPress = null,
		onBackPress = null,
		checkoutBar = false,
		blurNext = false,
		isMenu = false,
		rId = null,
		isSearchActive = false,
		onCancelSearch = null,
		cartBar = false,
		onCartPress = null,
		returnToCheckout = false,
		isEdit = false,
		loading = false,
		isRestaurantSnoozed = null,
	} = props

	const cart = useSelector<ReduxStoreState, ReduxStoreState['items']>(state => state.items)
	let cartCount = 0
	if (checkoutBar) {
		for (let i = 0; i < cart.length; i++) {
			cartCount += cart[i].qty
		}
	}
	if (cartCount !== 0 || !checkoutBar) {
		return (
			<View
				style={[
					styles.barContainer,
					{ paddingBottom: Platform.OS === 'ios' ? 10 : 0 },
					cartBar ? styles.cartContainer : styles.container,
				]}
			>
				{checkoutBar ? (
					<>
						<View
							style={[
								GlobalStyle.v2ButtonRightArrow,
								{
									marginHorizontal: 0,
									paddingHorizontal: 0,
									alignItems: 'center',
								},
							]}
						>
							<Text style={styles.checkoutItemsText}>
								{'TOTAL: '}
								<Text style={{ color: 'green' }}>
									{cartCount + (cartCount > 1 ? ' ITEMS' : ' ITEM')}
								</Text>
							</Text>
						</View>
						<TouchableOpacity
							style={[GlobalStyle.v2ButtonRightArrow, { backgroundColor: global.orgColor }]}
							onPress={() => {
								//if (loadedData[global.org].singleBrandEnabled && global.kiosk) {
								navigation.reset({
									index: 0,
									routes: [{ name: 'TabCheckout' }],
								})
								// } else {
								// 	navigation.navigate('TabCheckout')
								// }
							}}
						>
							<Text style={[styles.buttonText, { marginRight: 10 }]}>CHECKOUT NOW</Text>
							<AntDesign name="right" size={largeTextSize - 3} color="white" />
						</TouchableOpacity>
					</>
				) : cartBar ? (
					<>
						<View style={styles.cartBar}>
							<View
								style={[
									GlobalStyle.v2ButtonRightArrow,
									{ marginHorizontal: 0, paddingHorizontal: 0, alignItems: 'center' },
								]}
							>
								{Platform.OS !== 'web' && (
									<Text style={[styles.checkoutItemsText, styles.totalText]}>
										{'TOTAL: '}
										<Text style={{ fontWeight: '700', color: 'white' }}>
											{'$' + price.toFixed(2)}
										</Text>
									</Text>
								)}
							</View>
							<TouchableOpacity
								style={[
									GlobalStyle.v2ButtonRightArrow,
									{
										backgroundColor: loading || isRestaurantSnoozed ? Colors.greyscale[6] : 'white',
									},
								]}
								onPress={
									isRestaurantSnoozed
										? () =>
												alertResponseText('Not taking orders right now!', 'Please try again later.')
										: onCartPress
								}
								disabled={loading && !isRestaurantSnoozed}
							>
								<Text style={[styles.buttonText, { color: 'black', marginRight: 10 }]}>
									CHECKOUT NOW
								</Text>
								<AntDesign name="right" size={largeTextSize - 3} color="black" />
							</TouchableOpacity>
						</View>
					</>
				) : (
					<TouchableOpacity
						style={[
							GlobalStyle.v2ButtonRightArrow,
							{
								backgroundColor: global.orgColor,
								paddingLeft: '5%',
							},
							SMALL_WIDTH && {
								borderBottomLeftRadius: 50,
								paddingLeft: '5%',
							},
						]}
						onPress={() => {
							if (onBackPress !== null) {
								onBackPress()
							} else if (isSearchActive && onCancelSearch !== null) {
								onCancelSearch()
							} else {
								if (returnToCheckout) {
									navigation.navigate('TabCheckout')
								} else if (isMenu) {
									if (Platform.OS === 'web') {
										navigation.replace('HomeStack', { screen: 'RestaurantSelect' })
									} else {
										navigation.navigate('HomeStack', { screen: 'RestaurantSelect' })
									}
								} else if (rId !== null) {
									navigation.navigate('HomeStack', { screen: 'Menu', params: { rId: rId } })
								}
							}
						}}
					>
						<AntDesign name="left" size={largeTextSize - 3} color="white" />
						<Text style={[styles.buttonText, { marginLeft: 10 }]}>BACK</Text>
					</TouchableOpacity>
				)}

				{price !== null && !cartBar && (
					<View
						style={[GlobalStyle.v2ButtonRightArrow, { marginHorizontal: 0, paddingHorizontal: 0 }]}
					>
						<Text style={styles.priceText}>
							<AdjustedPrice />
						</Text>
					</View>
				)}
				{showAddToOrder && (
					<TouchableOpacity
						style={[
							GlobalStyle.v2ButtonRightArrow,
							{
								backgroundColor:
									blurNext || isRestaurantSnoozed ? Colors.greyscale[5] : global.orgColor,
								paddingHorizontal: '3%',
								flexGrow: 0.1,
							},
							SMALL_WIDTH && {
								paddingRight: '5%',
								paddingLeft: '2%',
								borderBottomRightRadius: 50,
							},
						]}
						onPress={
							isRestaurantSnoozed
								? () => alertResponseText('Not taking orders right now!', 'Please try again later.')
								: onAddToOrderPress
						}
					>
						<Text style={[styles.buttonText]}>{isEdit ? 'UPDATE ITEM' : 'ADD TO ORDER'}</Text>
					</TouchableOpacity>
				)}
				{showNext && (
					<TouchableOpacity
						style={[
							GlobalStyle.v2ButtonRightArrow,
							{
								backgroundColor: blurNext ? Colors.greyscale[5] : global.orgColor,
								paddingRight: '5%',
							},
							SMALL_WIDTH && {
								borderBottomRightRadius: 50,
								paddingRight: '5%',
							},
						]}
						onPress={onNextPress}
					>
						<Text style={[styles.buttonText, { marginRight: 10 }]}>NEXT</Text>
						<AntDesign name="right" size={largeTextSize - 3} color="white" />
					</TouchableOpacity>
				)}
			</View>
		)
	} else {
		return null
	}
}

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		shadowOpacity: 1,
		shadowColor: 'grey',
		shadowRadius: 7,
		shadowOffset: { width: 0, height: 10 },
		elevation: 1,
		borderTopWidth: 1,
		borderColor: Colors.greyscale[2],
	},
	cartContainer: {
		backgroundColor: Colors.greyscale[9],
		borderWidth: 0,
		justifyContent: 'center',
		bottom: Platform.OS === 'web' ? 80 : 0,
		paddingBottom: Platform.OS === 'web' ? 20 : 0,
	},
	barContainer: {
		position: 'absolute',
		bottom: 0,
		width: '100%',
		height: Dimensions.get('window').height * 0.1,
		maxHeight: 180,
		minHeight: 60,
		backgroundColor: Colors.custom.white,
		justifyContent: 'space-between',
		paddingHorizontal: '3%',
		alignItems: 'center',
	},
	cartBar: {
		width: responsiveWidth(90),
		maxWidth: MAX_CHECKOUT_WIDTH,
		marginHorizontal: '5%',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	priceText: {
		paddingHorizontal: 5,
		color: 'green',
		fontWeight: '400',
		fontSize: titleTextSize,
		marginLeft: 5,
		marginRight: 5,
	},
	totalText: {
		color: 'white',
		fontSize: largeTextSize,
		paddingHorizontal: 0,
		marginHorizontal: 0,
		marginLeft: 0,
	},
	checkoutItemsText: {
		paddingHorizontal: 20,
		color: 'black',
		fontWeight: '400',
		fontSize: largeTextSize + 3,
		marginLeft: 5,
		marginRight: 5,
		letterSpacing: 1,
	},

	buttonText: {
		color: 'white',
		fontWeight: '400',
		fontSize: largeTextSize,
	},
})
