import React, { useEffect, useState } from 'react'
import { Text, TouchableOpacity, View, StyleSheet } from 'react-native'

interface NumberPadProps {
	numLength?: number
	allowDecimal?: boolean
	onValueChange?: (value: string) => void
	buttonTextByKey?: {
		one?: string
		two?: string
		three?: string
		four?: string
		five?: string
		six?: string
		seven?: string
		eight?: string
		nine?: string
		dot?: string
		zero?: string
	}
	accessible?: boolean
	style?: any
	activeOpacity?: number
	buttonSize?: number
	buttonItemStyle?: any
	buttonAreaStyle?: any
	buttonTextStyle?: any
	numericDisabled?: boolean
	rightBottomButton?: React.ReactNode
	rightBottomButtonDisabled?: boolean
	rightBottomButtonSize?: number
	rightBottomAccessibilityLabel?: string
	rightBottomButtonItemStyle?: any
}

const ButtonItem = ({
	activeOpacity,
	onButtonPress,
	buttonSize = 60,
	text,
	customComponent,
	customViewStyle,
	accessible,
	accessibilityLabel,
	disabled,
	customTextStyle,
}) => {
	return (
		<TouchableOpacity
			accessible={accessible}
			accessibilityRole="keyboardkey"
			accessibilityLabel={customComponent !== undefined ? accessibilityLabel : text}
			activeOpacity={activeOpacity}
			disabled={disabled}
			style={NumpadStyle.buttonContainer}
			onPress={onButtonPress}
		>
			<View
				style={[
					NumpadStyle.buttonView,
					{ width: buttonSize, height: buttonSize, borderRadius: buttonSize / 2 },
					customViewStyle,
				]}
			>
				{customComponent || <Text style={[NumpadStyle.buttonText, customTextStyle]}>{text}</Text>}
			</View>
		</TouchableOpacity>
	)
}
const ViewHolder = () => <View style={NumpadStyle.buttonContainer} />

// Number pad for phone number entry on kiosk
const NumberPad = ({
	numLength,
	allowDecimal,
	onValueChange,
	buttonTextByKey,
	accessible,
	style,
	activeOpacity,
	buttonSize,
	buttonItemStyle,
	buttonAreaStyle,
	buttonTextStyle,
	numericDisabled,
	rightBottomButton,
	rightBottomButtonDisabled,
	rightBottomButtonSize = 60,
	rightBottomAccessibilityLabel,
	rightBottomButtonItemStyle,
}: NumberPadProps) => {
	const [input, setInput] = useState('')

	const onButtonPressHandle = (key, value) => {
		// only 1 dot
		if ((key === 'dot' && input.length < 1) || (key === 'dot' && input.includes('.'))) return
		if (input.includes('.') && input.substring(input.indexOf('.')).length === 3) return
		// 2 digits after the dot
		if (input.length < numLength) {
			setInput(input + '' + value)
		}
	}

	useEffect(() => {
		if (onValueChange !== undefined) {
			onValueChange(input)
		}
	}, [input])

	return (
		<View style={[NumpadStyle.container, style]}>
			<View style={[NumpadStyle.buttonAreaContainer, buttonAreaStyle]}>
				<ButtonItem
					disabled={numericDisabled}
					accessible={accessible}
					activeOpacity={activeOpacity}
					onButtonPress={() => onButtonPressHandle('one', '1')}
					buttonSize={buttonSize}
					text={buttonTextByKey.one}
					customTextStyle={buttonTextStyle}
					customViewStyle={buttonItemStyle}
					customComponent={undefined}
					accessibilityLabel={undefined}
				/>
				<ButtonItem
					disabled={numericDisabled}
					accessible={accessible}
					activeOpacity={activeOpacity}
					onButtonPress={() => onButtonPressHandle('two', '2')}
					buttonSize={buttonSize}
					text={buttonTextByKey.two}
					customTextStyle={buttonTextStyle}
					customViewStyle={buttonItemStyle}
					customComponent={undefined}
					accessibilityLabel={undefined}
				/>
				<ButtonItem
					disabled={numericDisabled}
					accessible={accessible}
					activeOpacity={activeOpacity}
					onButtonPress={() => onButtonPressHandle('three', '3')}
					buttonSize={buttonSize}
					text={buttonTextByKey.three}
					customTextStyle={buttonTextStyle}
					customViewStyle={buttonItemStyle}
					customComponent={undefined}
					accessibilityLabel={undefined}
				/>
				<ButtonItem
					disabled={numericDisabled}
					accessible={accessible}
					activeOpacity={activeOpacity}
					onButtonPress={() => onButtonPressHandle('four', '4')}
					buttonSize={buttonSize}
					text={buttonTextByKey.four}
					customTextStyle={buttonTextStyle}
					customViewStyle={buttonItemStyle}
					customComponent={undefined}
					accessibilityLabel={undefined}
				/>
				<ButtonItem
					disabled={numericDisabled}
					accessible={accessible}
					activeOpacity={activeOpacity}
					onButtonPress={() => onButtonPressHandle('five', '5')}
					buttonSize={buttonSize}
					text={buttonTextByKey.five}
					customTextStyle={buttonTextStyle}
					customViewStyle={buttonItemStyle}
					customComponent={undefined}
					accessibilityLabel={undefined}
				/>
				<ButtonItem
					disabled={numericDisabled}
					accessible={accessible}
					activeOpacity={activeOpacity}
					onButtonPress={() => onButtonPressHandle('six', '6')}
					buttonSize={buttonSize}
					text={buttonTextByKey.six}
					customTextStyle={buttonTextStyle}
					customViewStyle={buttonItemStyle}
					customComponent={undefined}
					accessibilityLabel={undefined}
				/>
				<ButtonItem
					disabled={numericDisabled}
					accessible={accessible}
					activeOpacity={activeOpacity}
					onButtonPress={() => onButtonPressHandle('seven', '7')}
					buttonSize={buttonSize}
					text={buttonTextByKey.seven}
					customTextStyle={buttonTextStyle}
					customViewStyle={buttonItemStyle}
					customComponent={undefined}
					accessibilityLabel={undefined}
				/>
				<ButtonItem
					disabled={numericDisabled}
					accessible={accessible}
					activeOpacity={activeOpacity}
					onButtonPress={() => onButtonPressHandle('eight', '8')}
					buttonSize={buttonSize}
					text={buttonTextByKey.eight}
					customTextStyle={buttonTextStyle}
					customViewStyle={buttonItemStyle}
					customComponent={undefined}
					accessibilityLabel={undefined}
				/>
				<ButtonItem
					disabled={numericDisabled}
					accessible={accessible}
					activeOpacity={activeOpacity}
					onButtonPress={() => onButtonPressHandle('nine', '9')}
					buttonSize={buttonSize}
					text={buttonTextByKey.nine}
					customTextStyle={buttonTextStyle}
					customViewStyle={buttonItemStyle}
					customComponent={undefined}
					accessibilityLabel={undefined}
				/>
				{allowDecimal ? (
					<ButtonItem
						disabled={numericDisabled}
						accessible={accessible}
						activeOpacity={activeOpacity}
						onButtonPress={() => onButtonPressHandle('dot', '.')}
						buttonSize={buttonSize}
						text={buttonTextByKey.dot}
						customTextStyle={buttonTextStyle}
						customViewStyle={buttonItemStyle}
						customComponent={undefined}
						accessibilityLabel={undefined}
					/>
				) : (
					<ViewHolder />
				)}
				<ButtonItem
					disabled={numericDisabled}
					accessible={accessible}
					activeOpacity={activeOpacity}
					onButtonPress={() => onButtonPressHandle('zero', '0')}
					buttonSize={buttonSize}
					text={buttonTextByKey.zero}
					customTextStyle={buttonTextStyle}
					customViewStyle={buttonItemStyle}
					customComponent={undefined}
					accessibilityLabel={undefined}
				/>
				{rightBottomButton !== undefined ? (
					<ButtonItem
						disabled={rightBottomButtonDisabled}
						accessible={accessible}
						activeOpacity={activeOpacity}
						accessibilityLabel={rightBottomAccessibilityLabel}
						onButtonPress={() => {
							setInput(input.slice(0, -1))
						}}
						customViewStyle={rightBottomButtonItemStyle}
						customComponent={rightBottomButton}
						buttonSize={rightBottomButtonSize}
						text={undefined}
						customTextStyle={undefined}
					/>
				) : (
					<ViewHolder />
				)}
			</View>
		</View>
	)
}

NumberPad.defaultProps = {
	buttonTextByKey: {
		one: '1',
		two: '2',
		three: '3',
		four: '4',
		five: '5',
		six: '6',
		seven: '7',
		eight: '8',
		nine: '9',
		dot: '.',
		zero: '0',
	},
	accessible: false,
	onButtonPress: () => {
		null
	},
	onRightButtonPress: () => {
		null
	},
	style: { paddingVertical: 12 },
	activeOpacity: 0.9,
	buttonTextStyle: { color: '#000', fontSize: 30, fontWeight: '400' },
	rightBottomAccessibilityLabel: 'right_bottom',
	numericDisabled: false,
	rightBottomButtonDisabled: false,
	allowDecimal: true,
	sheetRef: null,
}

const NumpadStyle = StyleSheet.create({
	container: {
		alignItems: 'center',
	},
	buttonAreaContainer: {
		alignItems: 'center',
		alignContent: 'center',
		flexDirection: 'row',
		flexWrap: 'wrap',
	},
	buttonContainer: {
		marginBottom: 12,
		width: '33%',
		alignItems: 'center',
		justifyContent: 'center',
	},
	buttonView: {
		textAlign: 'center',
		alignItems: 'center',
		justifyContent: 'center',
	},
	buttonText: {
		color: '#000',
		fontSize: 22,
		fontWeight: 'bold',
	},
})

export default NumberPad
