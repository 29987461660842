import React from 'react'
import { useContext } from 'react'
import { CheckoutScreenContext, DataContext } from '../../../../state/context'
import CustomerName from './CustomerName.kiosk'
import CheckoutNavFooter from '../../../../components/Footers/CheckoutNavFooter'

/**
 * Guest checkout only: ask for name
 */
export function GuestCustomerName({ toggleConfirmModal }) {
	const { takeOutName, setTakeOutName, setShowGuestCheckoutName } =
		useContext(CheckoutScreenContext)

	const { dineInEnabled, usesTableNumbers, isTakeOut } = useContext(DataContext)

	const handleBackPress = () => {
		toggleConfirmModal()
	}

	const handleContinuePress = () => {
		setShowGuestCheckoutName(false)
	}

	return (
		<>
			<CustomerName
				value={takeOutName}
				setValue={setTakeOutName}
				isTakeOut={!dineInEnabled ? true : isTakeOut}
				showTableNumber={usesTableNumbers}
			>
				<></>
			</CustomerName>

			<CheckoutNavFooter
				onBackPress={handleBackPress}
				onNextPress={!takeOutName ? null : handleContinuePress}
				blurNext={!takeOutName}
				style={{ position: 'absolute', bottom: 40, left: 0, right: 0 }}
			/>
		</>
	)
}
