import * as React from 'react'
import { TouchableOpacity, View, FlatList, Dimensions } from 'react-native'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../state/reducer'
import ItemCard from './ItemCard'
import { DataContext } from '../../state/context'
import { useContext } from 'react'
import Colors from '../../constants/Colors'
import { LOYALTY_CARD_WIDTH, mediumTextSize } from '../../constants/GlobalStyle'
import { pointsTempRemove } from './pointsTempRemove'
import { Text } from '../Themed'

/**
 * @param {string} showAll - For home page, show both redeemable and non-redeemable rewards
 * @param {string} [onlyRedeemable] - True if the list should show only redeemable rewards
 * @param {string} [onlyNonRedeemable] - True if the list should show only non-redeemable rewards
 */
const RewardsList = ({
	showAll,
	onlyRedeemable = false,
	onlyNonRedeemable = false,
	isCheckout = false,
	navigation,
}) => {
	const { userData, redeemedRewards } = useContext(DataContext)

	const width = Dimensions.get('window').width
	const overMaxWidth = width * 0.9 > (isCheckout ? 800 : 1200)
	const screenWidth = overMaxWidth ? (isCheckout ? 800 : 1200) : width * 0.9
	const offset_percent = (width / 2 - screenWidth / 2) / width

	const USER_POINTS =
		userData &&
		userData.user &&
		userData.user !== null &&
		userData.user.loyaltyPoints !== undefined &&
		userData.user.loyaltyPoints > 0
			? userData.user.loyaltyPoints - pointsTempRemove(redeemedRewards)
			: 0

	const loadedData = useSelector<ReduxStoreState, ReduxStoreState['loadedData']>(
		state => state.loadedData
	)

	function assembleList() {
		const rewardArray = loadedData['rewards']

		const redeemableRewards = []
		const nonRedeemableRewards = []

		for (const reward in rewardArray) {
			const r = rewardArray[reward]
			if (r.type === 'fixedDiscount') {
				const rewardObject = {
					id: r.id,
					type: 'fixedDiscount',
					pointsCost: r.pointsCost,
					discountAmount: r.fixedDiscountAmount,
					canRedeem: r.pointsCost <= USER_POINTS,
				}
				if (r.pointsCost <= USER_POINTS) {
					redeemableRewards.push(rewardObject)
				} else {
					nonRedeemableRewards.push(rewardObject)
				}
			} else if (r.type === 'freeItem') {
				const menuItem = loadedData[r.menuItemId]
				if (menuItem) {
					const rewardObject = {
						type: 'freeItem',
						pointsCost: r.pointsCost,
						discountAmount: r.fixedDiscountAmount,
						id: r.id,
						menuItemId: r.menuItemId,
						menuItemName: menuItem.name,
						menuItemRestaurantId: menuItem.restaurantId,
						canRedeem: r.pointsCost <= USER_POINTS,
					}
					const isRedeemed = redeemedRewards.some(reward => reward.rewardId === r.id)
					if (r.pointsCost <= USER_POINTS || isRedeemed) {
						redeemableRewards.push(rewardObject)
					} else {
						nonRedeemableRewards.push(rewardObject)
					}
				}
			}
		}

		return {
			redeemList: redeemableRewards.sort((a, b) => a.pointsCost - b.pointsCost),
			nonRedeemList: nonRedeemableRewards.sort((a, b) => a.pointsCost - b.pointsCost),
		}
	}

	const { redeemList, nonRedeemList } = assembleList()

	const returnList = showAll
		? redeemList.concat(nonRedeemList)
		: onlyRedeemable
		? redeemList
		: nonRedeemList
	if (returnList.length > 0) {
		return (
			<FlatList
				data={returnList}
				renderItem={({ item }) => {
					return (
						<View style={{ marginRight: 25 }}>
							<ItemCard
								isRedeemable={item.canRedeem}
								isHomePage={showAll}
								rewardData={item}
								navigation={navigation}
								isCheckout={isCheckout}
							/>
						</View>
					)
				}}
				keyExtractor={item => item.id}
				horizontal={true}
				showsHorizontalScrollIndicator={false}
				snapToAlignment="start"
				snapToInterval={LOYALTY_CARD_WIDTH + 25}
				decelerationRate="fast"
				contentContainerStyle={{
					marginHorizontal: '5%',
					paddingRight: '3.5%',
					marginBottom: 20,
					marginLeft:
						isCheckout && overMaxWidth ? width * offset_percent - 65 : width * offset_percent,
				}}
			/>
		)
	}

	return (
		<Text
			style={{
				color: Colors.greyscale[5],
				fontSize: mediumTextSize,
				marginHorizontal: '5%',
				marginBottom: 20,
				alignSelf: 'center',
			}}
		>
			No rewards available. Order now to earn points!
		</Text>
	)
}

export default RewardsList
