import React from 'react'
import { View } from 'react-native'
import { Ionicons } from '@expo/vector-icons'
import { Text } from './Themed'

type SectionProp = {
	name: string
	style?: any
}

export function Section(props: SectionProp) {
	const { name, style = {} } = props
	return (
		<View
			style={[
				{
					flexDirection: 'row-reverse',
					alignItems: 'center',
					justifyContent: 'space-between',
					padding: 10,
					paddingVertical: 15,
					borderTopWidth: 0.4,
				},
				style,
			]}
		>
			<Ionicons name="chevron-forward-outline" size={24} color="gray" />
			<Text style={[{ marginLeft: 10, fontSize: 16 }]}>{name}</Text>
		</View>
	)
}
