import React from 'react'
import { View, StyleSheet } from 'react-native'
import EmailPasswordLogin from './emailPasswordLogin'

type LoginProps = {
	onCheckout: boolean
	navigation: any
	returnTo?: string
	loyaltyId?: string
}

export default function LoginForm(props: LoginProps) {
	const { onCheckout, navigation, returnTo = null, loyaltyId = null } = props

	return (
		<View>
			<View style={styles.loginForm}>
				<EmailPasswordLogin
					onCheckout={onCheckout}
					navigation={navigation}
					returnTo={returnTo}
					loyaltyId={loyaltyId}
				/>
			</View>
			<View style={styles.socialBtns}></View>
		</View>
	)
}

const styles = StyleSheet.create({
	loginForm: {
		borderBottomWidth: 1,
		paddingBottom: 20,
		marginBottom: 10,
	},
	socialBtns: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	forgotBtn: {
		textAlign: 'center',
		color: 'mediumblue',
		marginTop: 10,
	},
})
