import * as React from 'react'
import { Dimensions, Platform, TouchableOpacity, StyleSheet, FlatList } from 'react-native'
import { View, Text } from '../../../components/Themed'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../state/reducer'
import { checkSnooze } from '../../../helpers/checkSnooze'
import {
	setAppId,
	setImageHero,
	setImageLogo,
	setLocId,
	setHours,
	setAddress,
	setSocial,
	setName,
	setPrepTime,
	setCouponCodes,
	setSchedulingJump,
	setLocalTimezone,
	setRestaurantId,
	setDeveloperFee,
	setIsSnoozed,
	setSnoozeUntil,
	setSlogan,
} from '../../../state/actions'
import ImageObject from '../../../components/Image/ImageObject'
import Colors from '../../../constants/Colors'
import GlobalStyle, {
	sTextSize,
	smallTextSize,
	largeTextSize,
	SMALL_LIST_WIDTH,
	MED_LIST_WIDTH,
	LARGE_LIST_WIDTH,
} from '../../../constants/GlobalStyle'
import { logSelectMenuAnalytics } from '../../../firebase/analytics'
import CopyrightFooter from '../../../components/Footers/CopyrightFooter'

/**
 * Restaurant view on the home page of the app if appDisplayOptions.homeLayout == "restaurants"
 */
export function RestaurantList({ navigation, visibleRestaurants }) {
	const dispatch = useDispatch()

	const width = Dimensions.get('window').width
	const overMaxWidth = width * 0.9 > 1200
	const screenWidth = overMaxWidth ? 1200 : width * 0.9
	const numColumns = LARGE_LIST_WIDTH ? 3 : MED_LIST_WIDTH && !SMALL_LIST_WIDTH ? 2 : 1
	if (visibleRestaurants.length <= 0) {
		return (
			<Text
				style={{
					textAlign: 'center',
					fontStyle: 'italic',
					color: Colors.greyscale[7],
					fontSize: largeTextSize,
					paddingTop: Platform.OS === 'web' ? 20 : 0,
				}}
			>
				{'We are unable to accept new orders at this time.'}
			</Text>
		)
	}

	const renderItem = ({ restaurant, index }) => {
		return (
			<View
				key={index + restaurant.id}
				style={[
					{
						width: `${100 / numColumns}%`,
						marginVertical: smallTextSize,
					},
					index % numColumns === 0
						? {
								paddingRight: smallTextSize,
						  }
						: (index + 1) % numColumns === 0
						? {
								paddingLeft: smallTextSize,
						  }
						: {
								paddingHorizontal: smallTextSize,
						  },
				]}
			>
				<TouchableOpacity
					key={restaurant.id}
					onPress={() => {
						if (restaurant !== undefined) {
							logSelectMenuAnalytics('brand_menu', restaurant.id) //log analytics
							dispatch(setHours(restaurant.hours))
							dispatch(setLocId(restaurant.locationId))
							dispatch(setAppId(restaurant.applicationId))
							dispatch(setRestaurantId(restaurant.id))
							dispatch(setDeveloperFee(restaurant.developerFee))
							dispatch(setImageLogo(restaurant.logoImg))
							dispatch(setImageHero(restaurant.heroImg))
							dispatch(setAddress(restaurant.address))
							dispatch(setPrepTime(restaurant.prepTime))
							dispatch(setName(restaurant.name))
							dispatch(setCouponCodes(restaurant.couponCodes))
							dispatch(setSocial(restaurant.social))
							dispatch(setSchedulingJump(restaurant.schedulingJump))
							dispatch(setIsSnoozed(restaurant.isSnoozed))
							dispatch(setSnoozeUntil(restaurant.snoozeUntil))
							dispatch(setSlogan(restaurant.slogan))
							navigation.navigate('Menu', { rId: restaurant.id })
						} else {
							console.log('Could not load.')
						}
					}}
				>
					<View style={GlobalStyle.shadowContainer}>
						<View style={GlobalStyle.cardContainer}>
							{restaurant?.heroImg ? (
								<ImageObject
									source={restaurant.heroImg}
									style={{ height: Platform.OS === 'web' ? 200 : 170 }}
								/>
							) : (
								<View
									style={{
										width: screenWidth,
										height: Platform.OS === 'web' ? 200 : 170,
										backgroundColor: Colors.greyscale[2],
									}}
								></View>
							)}
							<View style={styles.textArea}>
								<Text numberOfLines={1} ellipsizeMode="tail" style={styles.name}>
									{restaurant.name}
								</Text>
								<Description address={restaurant.address} />
							</View>
						</View>
					</View>
				</TouchableOpacity>
			</View>
		)
	}

	return (
		<View
			style={{
				flex: 1,
			}}
		>
			<FlatList
				data={visibleRestaurants}
				numColumns={numColumns}
				keyExtractor={(restaurant, index) => index + restaurant.id}
				renderItem={({ item, index }) => renderItem({ restaurant: item, index })}
				contentContainerStyle={{
					marginLeft: Dimensions.get('window').width * 0.04,
					marginRight: Dimensions.get('window').width * 0.04 - 12.5,
					marginTop: Platform.OS === 'web' ? smallTextSize : -10,
					height: '100%',
				}}
				ListFooterComponent={Platform.OS === 'web' && <CopyrightFooter />}
				ListFooterComponentStyle={{
					marginLeft: -Dimensions.get('window').width * 0.04,
					marginRight: -Dimensions.get('window').width * 0.04 - 12.5,
					flex: 1,
					justifyContent: 'flex-end',
					marginTop: Dimensions.get('window').height * 0.12,
				}}
			/>
		</View>
	)

	function Description({ address }) {
		return (
			<View style={{ width: '100%' }}>
				<Text numberOfLines={1} ellipsizeMode="tail" style={[styles.description]}>
					{`${address.addressLine1} ${address.addressLine2 ? address.addressLine2 : ''}`}
				</Text>
			</View>
		)
	}
}

const styles = StyleSheet.create({
	name: {
		fontSize: smallTextSize,
		fontWeight: '600',
		minHeight: sTextSize * 1.75,
	},
	description: {
		fontSize: sTextSize,
		color: Colors.greyscale[5],
		minHeight: sTextSize * 1.25,
	},
	textArea: {
		padding: sTextSize,
	},
})
