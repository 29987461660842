import React from 'react'
import { TouchableOpacity, StyleSheet, StyleProp, ViewStyle } from 'react-native'
import GlobalStyle, { largeTextSize } from '../../../../constants/GlobalStyle'
import { AntDesign } from '@expo/vector-icons'
import { Text } from '../../../../components/Themed'

interface ButtonProps {
	onPress: () => void
	title: string
	style: StyleProp<ViewStyle>
}

const SquarePaymentButton = ({ onPress, title, style }: ButtonProps) => {
	return (
		<TouchableOpacity
			style={[
				GlobalStyle.v2ButtonRightArrow,
				{ backgroundColor: 'black', marginBottom: 20 },
				style,
			]}
			activeOpacity={0.6}
			onPress={onPress}
		>
			<Text style={GlobalStyle.v2ButtonText}>{title}</Text>
			<AntDesign name="right" size={largeTextSize - 3} color="white" />
		</TouchableOpacity>
	)
}

export default SquarePaymentButton
