import { DATABASE_NAME } from '@env'
import { doc, collection, onSnapshot } from 'firebase/firestore'
import { db } from '../../firebase/config'
import '../../global'

export const getThirdPartyPOSTypes = () => {
	return new Promise((resolve, reject) => {
		const dataImport = {}
		dataImport['thirdPartyTypes'] = []
		const thirdPartyRef = collection(doc(db, DATABASE_NAME, global.org), 'ThirdPartyTypes')

		onSnapshot(
			thirdPartyRef,
			snapshot => {
				snapshot.docChanges().forEach(change => {
					const data = change.doc.data()
					if (!data.isDeleted) {
						dataImport['thirdPartyTypes'].push({
							id: data.id,
							name: data.name,
						})
					}
				})

				resolve(dataImport)
			},
			reject
		)
	})
}
