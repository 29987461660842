import React, { useContext, useEffect, useRef, useState } from 'react'
import { View, StyleSheet, TouchableOpacity, Animated, Platform } from 'react-native'
import { SharpTriangle } from '../../../components/Headers/Triangle'
import GlobalStyle, { largeTextSize, titleTextSize } from '../../../constants/GlobalStyle'
import ImageObject from '../../../components/Image/ImageObject'
import Colors from '../../../constants/Colors'
import { startOfMinute } from 'date-fns'
import { DataContext } from '../../../state/context'
import { Text } from '../../../components/Themed'

/**
 * Landing screen for kiosk that shows a promotion image/video with "touch to start" button
 * Will show organization logo if no promo image/video is available
 * Overlays the restaurant select screen, disappears on press
 */
const KioskStartScreen = ({ defaultLogo, imageList, setVisible }) => {
	const [currentSlide, setCurrentSlide] = useState(0)
	const [activeSlide, setActiveSlide] = useState<any>({})
	const [nextSlide, setNextSlide] = useState<any>({})

	const [showDineInOptions, setShowDineInOptions] = useState(false)

	const { setIsDineIn, setIsTakeOut, dineInEnabled } = useContext(DataContext)

	const orgLogoVisible = imageList.length > 0 && !activeSlide ? false : true

	const handleContinuePress = () => {
		if (dineInEnabled) {
			setShowDineInOptions(true)
		} else {
			setVisible(false)
		}
	}

	const handleDineInPress = () => {
		setIsDineIn(true)
		setIsTakeOut(false)
		setVisible(false)
	}

	const handleTakeOutPress = () => {
		setIsDineIn(false)
		setIsTakeOut(true)
		setVisible(false)
	}

	const DineInOrTakeOut = () => {
		return (
			<>
				<View style={styles.dineInContainer}>
					<TouchableOpacity style={[styles.dineInButtons]} onPress={handleDineInPress}>
						<Text style={styles.dineInButtonText}>DINE IN</Text>
					</TouchableOpacity>
					<Text
						style={{
							fontSize: titleTextSize - 4,
							color: Colors.greyscale[6],
							marginHorizontal: 50,
							marginTop: -10,
						}}
					>
						{'OR'}
					</Text>
					<TouchableOpacity style={[styles.dineInButtons]} onPress={handleTakeOutPress}>
						<Text style={styles.dineInButtonText}>TAKE OUT</Text>
					</TouchableOpacity>
				</View>
			</>
		)
	}

	function minuteSplit() {
		if (imageList.length === 0) return 0
		const lastPromoImage = imageList.reduce((prev, current) =>
			prev.startSec > current.startSec ? prev : current
		)
		const lastPromoImageSec =
			parseInt(lastPromoImage.startSec) + parseInt(lastPromoImage.duration) + 15000
		const minuteSplit = Math.ceil(lastPromoImageSec / 60000)
		return minuteSplit
	}

	function changePromoFromTo(indexFrom, indexTo) {
		// show / hide the promo image and switch slide if needed
		if (indexFrom === -1) {
			// switch the promo image
			if (indexTo !== -1) {
				setActiveSlide({
					imageUrl: imageList[indexTo].imageUrl,
					isVideo: imageList[indexTo].isVideo,
					remove: false,
					hide: false,
				})
			}
		} else if (indexTo === -1) {
			setActiveSlide({
				imageUrl: null,
				isVideo: null,
				remove: false,
				hide: true,
			})
			setTimeout(() => {
				setActiveSlide({
					imageUrl: null,
					isVideo: null,
					remove: true,
					hide: true,
				})
			}, 1500)
		} else {
			// hide the current slide, then remove it
			setActiveSlide({
				imageUrl: imageList[indexTo].imageUrl,
				isVideo: imageList[indexTo].isVideo,
				remove: false,
				hide: true,
			})
			setTimeout(() => {
				setActiveSlide({
					imageUrl: imageList[indexTo].imageUrl,
					isVideo: imageList[indexTo].isVideo,
					remove: true,
					hide: true,
				})
			}, 1500)
			// show the next slide
			const nextSlide = currentSlide === 1 ? 0 : 1

			setNextSlide({
				imageUrl: imageList[indexTo].imageUrl,
				isVideo: imageList[indexTo].isVideo,
				hide: false,
				remove: false,
			})
			setActiveSlide(nextSlide)
		}

		// schedule the next promo image switch
		let timeToWaitFor
		const split = minuteSplit()
		if (indexTo === -1) {
			const now = new Date()
			const startOfCurrentMinute = startOfMinute(now)
			timeToWaitFor =
				startOfCurrentMinute.getTime() -
				now.getTime() +
				(split - (now.getMinutes() % split)) * 60000
		} else if (indexTo === imageList.length - 1) {
			timeToWaitFor = parseInt(imageList[indexTo].duration)
		} else {
			timeToWaitFor = parseInt(imageList[indexTo + 1].startSec)
		}
		setTimeout(() => {
			changePromoFromTo(indexTo, indexTo === imageList.length - 1 ? -1 : indexTo + 1)
		}, timeToWaitFor)
	}

	useEffect(() => {
		const init = async () => {
			const split = minuteSplit()

			if (imageList.length > 0) {
				// find the time at which the hour reaches a whole fraction of the minute to wait for (it must be at 0 seconds)
				const timeToWaitFor = new Date(
					startOfMinute(new Date()).getTime() + (split - (new Date().getMinutes() % split)) * 60000
				)
				// wait until that time to start the interval
				const time = timeToWaitFor.getTime() - new Date().getTime()
				setTimeout(() => {
					changePromoFromTo(-1, 0)
				}, time)
			}
		}

		init()
	}, [])

	const fadeAnim = useRef(new Animated.Value(0)).current

	const fadeIn = () => {
		fadeAnim.setValue(0)
		Animated.timing(fadeAnim, {
			toValue: 1,
			duration: 1000,
			useNativeDriver: true,
		}).start()
	}

	useEffect(() => {
		fadeIn()
	}, [activeSlide])

	const DisplayPromo = ({ isVideo }) => {
		if (isVideo === undefined || isVideo === null) {
			//show logo
			return (
				<Animated.View
					style={[
						{
							opacity: fadeAnim,
							width: '100%',
							height: '100%',
							alignItems: 'center',
							justifyContent: 'center',
						},
					]}
				>
					<ImageObject source={defaultLogo} style={GlobalStyle.orgLogo} hideDefault={true} />
				</Animated.View>
			)
		} else {
			//show static image
			return (
				<Animated.View
					style={[
						{
							opacity: fadeAnim,
							width: '100%',
							height: '100%',
						},
					]}
				>
					<ImageObject
						source={activeSlide.imageUrl}
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
							right: 0,
							bottom: 0,
						}}
						hideDefault={true}
					/>
				</Animated.View>
			)
		}
	}

	return (
		<View
			style={[
				styles.container,
				{
					backgroundColor: orgLogoVisible ? global.orgColor : Colors.greyscale[10],
				},
			]}
			onTouchEnd={handleContinuePress}
		>
			<>
				<View style={styles.imageContainer}>
					{imageList.length > 0 ? (
						<DisplayPromo isVideo={activeSlide.isVideo} />
					) : (
						<View style={[GlobalStyle.orgLogoContainer, { marginTop: 0 }]}>
							<ImageObject source={defaultLogo} style={GlobalStyle.orgLogo} hideDefault={true} />
						</View>
					)}
				</View>
				<SharpTriangle fill="white" />
				<View style={styles.bottomContainer}>
					{!showDineInOptions && (
						<>
							<TouchableOpacity
								style={[styles.button, { backgroundColor: global.orgColor }]}
								onPress={handleContinuePress}
							>
								<Text style={styles.buttonText}>TOUCH TO START</Text>
							</TouchableOpacity>
							<Text style={styles.footerText}>DEBIT & CREDIT ONLY</Text>
						</>
					)}

					{dineInEnabled && showDineInOptions && (
						<>
							<DineInOrTakeOut />
							<Text style={styles.footerText}>SELECT AN OPTION TO CONTINUE</Text>
						</>
					)}
				</View>
			</>
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
	},
	dineInContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginHorizontal: 10,
		alignItems: 'center',
	},
	dineInButtons: {
		paddingHorizontal: 50,
		paddingVertical: 20,
		borderRadius: 15,
		marginTop: -15,
		color: 'white',
		borderWidth: 3,
	},
	imageContainer: {
		flex: 4,
		justifyContent: 'center',
		alignItems: 'center',
	},
	image: {
		width: '100%',
		height: '100%',
	},
	bottomContainer: {
		flex: 1,
		backgroundColor: 'white',
		justifyContent: 'center',
		alignItems: 'center',
	},
	button: {
		paddingHorizontal: 50,
		paddingVertical: 20,
		borderRadius: 15,
		marginTop: -15,
	},
	dineInButtonText: {
		color: 'black',
		fontSize: titleTextSize - 2,
	},
	buttonText: {
		color: 'white',
		fontSize: titleTextSize - 2,
	},
	footerText: {
		marginTop: 20,
		color: Colors.greyscale[6],
		fontSize: largeTextSize - 2,
	},
})

export default KioskStartScreen
