import React from 'react'
import { KeyboardAvoidingView, View, TouchableOpacity, StyleSheet } from 'react-native'
import GlobalStyle, { largeTextSize } from '../../../../constants/GlobalStyle'
import { Text } from '../../../../components/Themed'

export function AddPromoView({ toggleModal, children, showSelections }) {
	return (
		<>
			<KeyboardAvoidingView behavior={'position'} keyboardVerticalOffset={60}>
				<View key={'promo'} style={{ height: showSelections ? '77%' : 180 }}>
					{children}
				</View>
			</KeyboardAvoidingView>
			<View
				style={{
					paddingHorizontal: global.kiosk ? 40 : 20,
					flex: 1,
					justifyContent: 'flex-end',
					marginBottom: 20,
				}}
			>
				<TouchableOpacity
					style={[
						GlobalStyle.v2ButtonRightArrow,
						{
							backgroundColor: global.orgColor,
							marginBottom: 20,
						},
					]}
					onPress={toggleModal}
				>
					<Text style={[styles.buttonText, { marginRight: 0 }]}>CONTINUE</Text>
				</TouchableOpacity>
			</View>
		</>
	)
}

const styles = StyleSheet.create({
	buttonText: {
		color: 'white',
		fontWeight: '400',
		fontSize: largeTextSize,
		marginRight: 15,
	},
})
