import { AnyAction } from 'redux'
import {
	AddItemAction,
	RemoveItemAction,
	UpdateItemQtyAction,
	IncItemQtyAction,
	DecItemQtyAction,
	Item,
	AddLocationAction,
	Location,
	RemoveLocationAction,
	SetCurrentLocationAction,
	RemoveAllItemsAction,
	SetRestaurantAction,
	SetItemAction,
	SetBoolAction,
	SetAnyAction,
} from './types'

export enum ItemListActionTypes {
	ADD_ITEM = 'ADD_ITEM',
	REMOVE_ITEM = 'REMOVE_ITEM',
	UPDATE_ITEM_QTY = 'UPDATE_ITEM_QTY',
	INC_ITEM_QTY = 'INC_ITEM_QTY',
	DEC_ITEM_QTY = 'DEC_ITEM_QTY',
	REMOVE_ALL_ITEMS = 'REMOVE_ALL_ITEMS',
	SET_ITEMS = 'SET_ITEMS',
	SET_PAYMENTMETHODS = 'SET_PAYMENTMETHODS',
	SET_RESTAURANTS = 'SET_RESTAURANTS',
	SET_RESTAURANTDATALIST = 'SET_RESTAURANTDATALIST',
}

export enum LocationActionTypes {
	ADD_LOCATION = 'ADD_LOCATION',
	REMOVE_LOCATION = 'REMOVE_LOCATION',
	SET_CURRENT = 'SET_CURRENT',
	UPDATE_LOCATION = 'UPDATE_LOCATION',
}

export enum KioskActionTypes {
	DISABLE_IDLE = 'DISABLE_IDLE',
}

export enum RestaurantActionTypes {
	SET_HOURS = 'SET_HOURS',
	SET_IMAGELOGO = 'SET_IMAGELOGO',
	SET_IMAGEHERO = 'SET_IMAGEHERO',
	SET_LOCATIONID = 'SET_LOCATIONID',
	SET_ACCESSTOKEN = 'SET_ACCESSTOKEN',
	SET_APPLICATIONID = 'SET_APPLICATIONID',
	SET_NAME = 'SET_NAME',
	SET_ADDRESS = 'SET_ADDRESS',
	SET_SOCIAL = 'SET_SOCIAL',
	SET_COUPONCODES = 'SET_COUPONCODES',
	SET_PREPTIME = 'SET_PREPTIME',
	SET_PREPTIMELIST = 'SET_PREPTIMELIST',
	SET_SCHEDULINGJUMP = 'SET_SCHEDULINGJUMP',
	SET_RESTAURANTID = 'SET_RESTAURANT_ID',
	SET_DEVELOPERFEE = 'SET_DEVELOPERFEE',
	SET_SNOOZEUNTIL = 'SET_SNOOZEUNTIL',
	SET_ISSNOOZED = 'SET_ISSNOOZED',
	SET_PICKUPMSG = 'SET_PICKUPMSG',
	SET_PREPTIMESTRING = 'SET_PREPTIMESTRING',
	SET_PREPTIMEMS = 'SET_PREPTIMEMS',
	SET_STOREOPEN = 'SET_STOREOPEN',
	SET_USERADDRESS = 'SET_USERADDRESS',
	SET_LOCALTIMEZONE = 'SET_LOCALTIMEZONE',
	SET_USERID = 'SET_USERID',
	SET_POSUSERID = 'SET_POSUSERID',
	SET_SERVICEFEE = 'SET_SERVICEFEE',
	SET_PLATFORMFEE = 'SET_PLATFORMFEE',
	SET_ORGFEE = 'SET_ORGFEE',
	SET_SERVICEFEETAX = 'SET_SERVICEFEETAX',
	SET_PLATFORMFEETAX = 'SET_PLATFORMFEETAX',
	SET_ORGFEETAX = 'SET_ORGFEETAX',
	SET_STRIPEID = 'SET_STRIPEID',
	SET_PAYMENTMETHODID = 'SET_PAYMENTMETHODID',
	SET_CARDINDEX = 'SET_CARDINDEX',
	SET_SLOGAN = 'SET_SLOGAN',
	SET_SCHEDCURRTIME = 'SET_SCHEDCURRTIME',
	SET_SCHEDDURATION = 'SET_SCHEDDURATION',
	SET_DEFAULTPREPTIME = 'SET_DEFAULTPREPTIME',
	SET_LOADEDDATA = 'SET_LOADEDDATA',
	SET_ORGANIZATIONLOGO = 'SET_ORGANIZATIONLOGO',
	SET_ORGANIZATIONLOGODARK = 'SET_ORGANIZATIONLOGODARK',
	SET_REFERRALCODE = 'SET_REFERRALCODE',
	SET_ORGTIPPERCENT = 'SET_ORGTIPPERCENT',
	SET_ORGANIZATIONHEADER = 'SET_ORGANIZATIONHEADER',
	SET_HOURSREF = 'SET_HOURSREF',
	SET_COMBINEDHOURS = 'SET_COMBINEDHOURS',
	SET_LOCATIONSETTINGS = 'SET_LOCATIONSETTINGS',
	SET_DELIVERYSETTINGS = 'SET_DELIVERYSETTINGS',
	SET_DELIVERYACTIVE = 'SET_DELIVERYACTIVE',
}

const ReduxStoreActionTypes = {
	...ItemListActionTypes,
	...LocationActionTypes,
	...RestaurantActionTypes,
}

export type ReduxStoreActionTypes = typeof ReduxStoreActionTypes

export const setLoadedData = (data: any): SetRestaurantAction => ({
	type: RestaurantActionTypes.SET_LOADEDDATA,
	id: data,
})

export const setHoursRef = (data: any): SetRestaurantAction => ({
	type: RestaurantActionTypes.SET_HOURSREF,
	id: data,
})
export const setCombinedHours = (data: any): SetRestaurantAction => ({
	type: RestaurantActionTypes.SET_COMBINEDHOURS,
	id: data,
})

export const setRestaurantDataList = (restaurants: any): AnyAction => ({
	type: ItemListActionTypes.SET_RESTAURANTDATALIST,
	payload: restaurants,
})

export const setRestaurants = (restaurants: any[]): SetItemAction => ({
	type: ItemListActionTypes.SET_RESTAURANTS,
	payload: restaurants,
})

export const setDefaultPrepTime = (id: string): SetRestaurantAction => ({
	type: RestaurantActionTypes.SET_DEFAULTPREPTIME,
	id: id,
})

export const setSchedCurrTime = (id: string): SetRestaurantAction => ({
	type: RestaurantActionTypes.SET_SCHEDCURRTIME,
	id: id,
})

export const setSchedDuration = (id: string): SetRestaurantAction => ({
	type: RestaurantActionTypes.SET_SCHEDDURATION,
	id: id,
})

export const setOrganizationLogo = (id: string): SetRestaurantAction => ({
	type: RestaurantActionTypes.SET_ORGANIZATIONLOGO,
	id: id,
})

export const setOrganizationLogoDark = (id: string): SetRestaurantAction => ({
	type: RestaurantActionTypes.SET_ORGANIZATIONLOGODARK,
	id: id,
})

export const setOrganizationHeader = (id: string): SetRestaurantAction => ({
	type: RestaurantActionTypes.SET_ORGANIZATIONHEADER,
	id: id,
})

export const setPickupMessage = (id: string): SetRestaurantAction => ({
	type: RestaurantActionTypes.SET_PICKUPMSG,
	id: id,
})

export const setReferralCode = (id: string): SetRestaurantAction => ({
	type: RestaurantActionTypes.SET_REFERRALCODE,
	id: id,
})

export const setSlogan = (id: string): SetRestaurantAction => ({
	type: RestaurantActionTypes.SET_SLOGAN,
	id: id,
})

export const setLocationSettings = (id: string): SetRestaurantAction => ({
	type: RestaurantActionTypes.SET_LOCATIONSETTINGS,
	id: id,
})

export const setDeliverySettings = (id: string): SetRestaurantAction => ({
	type: RestaurantActionTypes.SET_DELIVERYSETTINGS,
	id: id,
})

export const setDeliveryActive = (id: boolean): SetBoolAction => ({
	type: RestaurantActionTypes.SET_DELIVERYACTIVE,
	bool: id,
})

export const setPaymentMethodId = (id: string): SetRestaurantAction => ({
	type: RestaurantActionTypes.SET_PAYMENTMETHODID,
	id: id,
})

export const setCardIndex = (i: number): SetAnyAction => ({
	type: RestaurantActionTypes.SET_CARDINDEX,
	var: i,
})

export const setStripeId = (id: string): SetRestaurantAction => ({
	type: RestaurantActionTypes.SET_STRIPEID,
	id: id,
})

export const setServiceFeeTax = (fee: number): SetAnyAction => ({
	type: RestaurantActionTypes.SET_SERVICEFEETAX,
	var: fee,
})

export const setPlatformFeeTax = (fee: number): SetAnyAction => ({
	type: RestaurantActionTypes.SET_PLATFORMFEETAX,
	var: fee,
})

export const setOrgFeeTax = (fee: number): SetAnyAction => ({
	type: RestaurantActionTypes.SET_ORGFEETAX,
	var: fee,
})

export const setOrgTipPercent = (fee: number): SetAnyAction => ({
	type: RestaurantActionTypes.SET_ORGTIPPERCENT,
	var: fee,
})

export const setServiceFee = (fee: number): SetAnyAction => ({
	type: RestaurantActionTypes.SET_SERVICEFEE,
	var: fee,
})

export const setPlatformFee = (fee: number): SetAnyAction => ({
	type: RestaurantActionTypes.SET_PLATFORMFEE,
	var: fee,
})

export const setOrgFee = (fee: number): SetAnyAction => ({
	type: RestaurantActionTypes.SET_ORGFEE,
	var: fee,
})

export const setPosUserId = (id: string): SetRestaurantAction => ({
	type: RestaurantActionTypes.SET_POSUSERID,
	id: id,
})

export const setUserId = (id: string): SetRestaurantAction => ({
	type: RestaurantActionTypes.SET_USERID,
	id: id,
})

export const setIsSnoozed = (id: string): SetRestaurantAction => ({
	type: RestaurantActionTypes.SET_ISSNOOZED,
	id: id,
})

export const setLocalTimezone = (id: string): SetRestaurantAction => ({
	type: RestaurantActionTypes.SET_LOCALTIMEZONE,
	id: id,
})

export const setSnoozeUntil = (id: string): SetRestaurantAction => ({
	type: RestaurantActionTypes.SET_SNOOZEUNTIL,
	id: id,
})

export const setSchedulingJump = (id: string): SetRestaurantAction => ({
	type: RestaurantActionTypes.SET_SCHEDULINGJUMP,
	id: id,
})

export const setDeveloperFee = (id: string): SetRestaurantAction => ({
	type: RestaurantActionTypes.SET_DEVELOPERFEE,
	id: id,
})

export const setName = (id: string): SetRestaurantAction => ({
	type: RestaurantActionTypes.SET_NAME,
	id: id,
})

export const setAddress = (id: string): SetRestaurantAction => ({
	type: RestaurantActionTypes.SET_ADDRESS,
	id: id,
})

export const setStoreOpen = (isOpen: boolean): SetBoolAction => ({
	type: RestaurantActionTypes.SET_STOREOPEN,
	bool: isOpen,
})

export const setDisableIdle = (disable: boolean): SetBoolAction => ({
	type: KioskActionTypes.DISABLE_IDLE,
	bool: disable,
})

export const setPrepTimeList = (prepList: any): SetAnyAction => ({
	type: RestaurantActionTypes.SET_PREPTIMELIST,
	var: prepList,
})

export const setUserAddress = (id: string): SetRestaurantAction => ({
	type: RestaurantActionTypes.SET_USERADDRESS,
	id: id,
})

export const setSocial = (id: string): SetRestaurantAction => ({
	type: RestaurantActionTypes.SET_SOCIAL,
	id: id,
})

export const setCouponCodes = (id: string): SetRestaurantAction => ({
	type: RestaurantActionTypes.SET_COUPONCODES,
	id: id,
})

export const setPrepTime = (id: string): SetRestaurantAction => ({
	type: RestaurantActionTypes.SET_PREPTIME,
	id: id,
})

export const setPrepTimeString = (id: string): SetRestaurantAction => ({
	type: RestaurantActionTypes.SET_PREPTIMESTRING,
	id: id,
})

export const setPrepTimeMs = (id: string): SetRestaurantAction => ({
	type: RestaurantActionTypes.SET_PREPTIMEMS,
	id: id,
})

export const setHours = (id: string): SetRestaurantAction => ({
	type: RestaurantActionTypes.SET_HOURS,
	id: id,
})

export const setImageLogo = (id: string): SetRestaurantAction => ({
	type: RestaurantActionTypes.SET_IMAGELOGO,
	id: id,
})

export const setImageHero = (id: string): SetRestaurantAction => ({
	type: RestaurantActionTypes.SET_IMAGEHERO,
	id: id,
})

export const setLocId = (id: string): SetRestaurantAction => ({
	type: RestaurantActionTypes.SET_LOCATIONID,
	id: id,
})

export const setRestaurantId = (id: string): SetRestaurantAction => ({
	type: RestaurantActionTypes.SET_RESTAURANTID,
	id: id,
})

export const setAppId = (id: string): SetRestaurantAction => ({
	type: RestaurantActionTypes.SET_APPLICATIONID,
	id: id,
})

export const setAccessToken = (id: string): SetRestaurantAction => ({
	type: RestaurantActionTypes.SET_ACCESSTOKEN,
	id: id,
})

export const addItem = (item: Item): AddItemAction => ({
	type: ItemListActionTypes.ADD_ITEM,
	payload: item,
})

export const setPaymentMethods = (paymentMethods: any[]): SetItemAction => ({
	type: ItemListActionTypes.SET_PAYMENTMETHODS,
	payload: paymentMethods,
})

export const setItems = (items: any[]): SetItemAction => ({
	type: ItemListActionTypes.SET_ITEMS,
	payload: items,
})

export const removeItem = (id: string, key: string): RemoveItemAction => ({
	type: ItemListActionTypes.REMOVE_ITEM,
	id: id,
	key: key,
})

export const updateItemQty = (id: string, qty: number): UpdateItemQtyAction => ({
	type: ItemListActionTypes.UPDATE_ITEM_QTY,
	id: id,
	payload: qty,
})

export const removeAllItems = (): RemoveAllItemsAction => ({
	type: ItemListActionTypes.REMOVE_ALL_ITEMS,
})

export const incItemQty = (id: string): IncItemQtyAction => ({
	type: ItemListActionTypes.INC_ITEM_QTY,
	id: id,
})

export const decItemQty = (id: string): DecItemQtyAction => ({
	type: ItemListActionTypes.DEC_ITEM_QTY,
	id: id,
})

export const addLocation = (location: Location): AddLocationAction => ({
	type: LocationActionTypes.ADD_LOCATION,
	location: location,
})

export const removeLocation = (name: string): RemoveLocationAction => ({
	type: LocationActionTypes.REMOVE_LOCATION,
	name: name,
})

export const setCurrentLocation = (location: Location): SetCurrentLocationAction => ({
	type: LocationActionTypes.SET_CURRENT,
	location: location,
})
