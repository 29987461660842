import { ORGANIZATION_ID, TERMINAL_MODE } from '@env'
import { Dimensions } from 'react-native'

global.multi = true
const isKiosk = TERMINAL_MODE === 'true'

if (isKiosk) {
	global.kiosk = true
	global.tipLarge = false
	global.idleReset = true
	if (Dimensions.get('window').width > 900) {
		global.kioskV2 = false
	} else {
		global.kioskV2 = true
	}
}

if (!isKiosk) {
	global.org = 'CA-BC-Vancouver-FoodRepublic'
	//global.org = 'Demo'
	//global.org = '3GI3JNC8pZZTTAZ5tobO'
}
