import { collection, doc, getDocs } from 'firebase/firestore'
import { db } from '../../firebase/config'
import '../../global'
import { DATABASE_NAME } from '@env'

export const getMenuItemCategories = async () => {
	const categories = []
	const categoriesRef = collection(doc(db, DATABASE_NAME, global.org), 'MenuItemCategories')
	const snapshot = await getDocs(categoriesRef)
	snapshot.docs.forEach(doc => {
		const data = doc.data()
		if (!data.isDeleted) {
			categories.push({
				id: data.id,
				name: data.title,
				image: data.image ? data.image : null,
				items: [],
				restaurantIds: new Set(),
				ordinal: data.ordinal ? data.ordinal : Number.MAX_SAFE_INTEGER,
			})
		}
	})

	// Sort the categories based on the ordinal value
	categories.sort((a, b) => a.ordinal - b.ordinal)

	return {
		menuItemCategories: categories,
	}
}
