import React from 'react'
import { View, StyleSheet, TouchableOpacity } from 'react-native'
import Colors from '../../constants/Colors'
import { largeTextSize, mediumTextSize, smallTextSize } from '../../constants/GlobalStyle'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { Text } from '../Themed'

/**
 * For showing the loyalty rewards points balance available to the user
 * @param {boolean} isHomePage - If the header is being used on the home page
 * @param {boolean} navigation - To redirect to rewards page
 */
const PointsBalance = ({ isHomePage = false, navigation = null, userPoints }) => {
	const functions = getFunctions()
	return (
		<View style={styles.container}>
			<View style={styles.pointsContainer}>
				<Text style={[styles.pointsText, { color: global.orgColor2 }]}>POINTS BALANCE:</Text>
				<Text style={styles.pointsValue}>{`${userPoints} POINTS`}</Text>
			</View>
			{isHomePage && (
				<TouchableOpacity
					style={styles.button}
					onPress={() => {
						navigation.navigate('Rewards')
					}}
				>
					<Text style={styles.buttonText}>VIEW REWARDS</Text>
				</TouchableOpacity>
			)}
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	pointsContainer: {
		flexDirection: 'column',
	},
	pointsText: {
		color: Colors.greyscale[6],
		fontSize: smallTextSize,
		fontWeight: '400',
	},
	pointsValue: {
		color: Colors.greyscale[8],
		fontSize: largeTextSize + 2,
		fontWeight: '800',
		letterSpacing: 1.2,
		marginTop: 5,
	},
	button: {
		backgroundColor: '#FFF', // replace with your button background color
		paddingHorizontal: 20,
		paddingVertical: 10,
		borderRadius: 5,
		shadowColor: '#000',
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
	},
	buttonText: {
		fontSize: smallTextSize,
		fontWeight: '600',
	},
})

export default PointsBalance
