import * as React from 'react'
import Colors from '../../constants/Colors'
import { Text, View } from '../Themed'
import GlobalStyle from '../../constants/GlobalStyle'

export function PricesView({ prices }) {
	var pricingView = []

	pricingView.push(
		<View
			key={prices.total + 'separator'}
			style={{
				height: 3,
				marginTop: 10,
				backgroundColor: Colors.custom.white,
			}}
		></View>
	)

	if (prices.discount !== 0) {
		pricingView.push(
			<View
				key={prices.discount + 'discount'}
				style={[
					GlobalStyle.collapsibleHeading,
					{ padding: 15, paddingVertical: 5, flexDirection: 'row', backgroundColor: 'white' },
				]}
			>
				<View style={[{ flex: 1 }]}>
					<Text style={GlobalStyle.medText}>{'Discount'}</Text>
				</View>
				<View style={[{ flex: 1, alignItems: 'flex-end' }]}>
					<Text style={GlobalStyle.medText}>-${(prices.discount / 100).toFixed(2)}</Text>
				</View>
			</View>
		)
	}
	if (prices.tip !== 0) {
		pricingView.push(
			<View
				key={prices.tip + 'tip'}
				style={[
					GlobalStyle.collapsibleHeading,
					{ padding: 15, paddingVertical: 5, flexDirection: 'row', backgroundColor: 'white' },
				]}
			>
				<View style={[{ flex: 1 }]}>
					<Text style={GlobalStyle.medText}>{'Tips'}</Text>
				</View>
				<View style={[{ flex: 1, alignItems: 'flex-end' }]}>
					<Text style={GlobalStyle.medText}>${(prices.tip / 100).toFixed(2)}</Text>
				</View>
			</View>
		)
	}
	if (prices.deliveryFee !== 0) {
		pricingView.push(
			<View
				key={prices.deliveryFee + 'deliveryFee'}
				style={[
					GlobalStyle.collapsibleHeading,
					{ padding: 15, paddingVertical: 5, flexDirection: 'row', backgroundColor: 'white' },
				]}
			>
				<View style={[{ flex: 1 }]}>
					<Text style={GlobalStyle.medText}>{'Delivery Fee'}</Text>
				</View>
				<View style={[{ flex: 1, alignItems: 'flex-end' }]}>
					<Text style={GlobalStyle.medText}>${(prices.deliveryFee / 100).toFixed(2)}</Text>
				</View>
			</View>
		)
	}
	if (prices.taxes !== 0 || prices.serviceCharge !== 0) {
		pricingView.push(
			<View key={prices.taxes + 'tax'}>
				{prices.taxes !== 0 ? <Taxes prices={prices} /> : <View></View>}
				{prices.serviceCharge !== 0 ? (
					<View
						key={'0' + prices.serviceCharge + 'taxesandfees'}
						style={[
							GlobalStyle.collapsibleHeading,
							{ padding: 15, paddingVertical: 5, flexDirection: 'row', backgroundColor: 'white' },
						]}
					>
						<View style={[{ flex: 1, paddingLeft: 10 }]}>
							<Text style={GlobalStyle.medText}>
								{'Service Fee ('}
								{Math.round(prices.serviceCharge / (prices.subTotal + prices.discount))}
								{'%)'}
							</Text>
						</View>
						<View style={[{ flex: 1, alignItems: 'flex-end', paddingRight: 10 }]}>
							<Text style={GlobalStyle.medText}>${(prices.serviceCharge / 100).toFixed(2)}</Text>
						</View>
					</View>
				) : (
					<View></View>
				)}

				{prices.bagFee !== 0 ? (
					<View
						key={'0' + prices.bagFee + 'taxesandfees'}
						style={[
							GlobalStyle.collapsibleHeading,
							{ padding: 15, paddingVertical: 5, flexDirection: 'row', backgroundColor: 'white' },
						]}
					>
						<View style={[{ flex: 1, paddingLeft: 10 }]}>
							<Text style={GlobalStyle.medText}>{'Bag Fee'}</Text>
						</View>
						<View style={[{ flex: 1, alignItems: 'flex-end', paddingRight: 10 }]}>
							<Text style={GlobalStyle.medText}>
								${((prices.bagFee + prices.bagFeeTax) / 100).toFixed(2)}
							</Text>
						</View>
					</View>
				) : (
					<View></View>
				)}
			</View>
		)
	}

	pricingView.push(
		<View
			key={prices.total + 'total'}
			style={[
				GlobalStyle.collapsibleHeading,
				{
					marginTop: 10,
					padding: 20,
					flexDirection: 'row',
					borderTopWidth: 2,
					borderColor: '#d2d2d2',
					backgroundColor: global.kiosk ? 'white' : Colors.custom.lightGrey,
				},
			]}
		>
			<View
				style={[
					{
						flex: 1,
						backgroundColor: global.kiosk ? 'white' : Colors.custom.lightGrey,
					},
				]}
			>
				<Text style={GlobalStyle.medText}>{global.kiosk ? 'Total' : 'TOTAL'}</Text>
			</View>
			<View
				style={[
					GlobalStyle.ordersHeading,
					{
						flex: 1,
						alignItems: 'flex-end',
						backgroundColor: global.kiosk ? 'white' : Colors.custom.lightGrey,
					},
				]}
			>
				<Text style={GlobalStyle.medText}>${(prices.total / 100).toFixed(2)}</Text>
			</View>
		</View>
	)

	return <>{pricingView}</>
}

function Taxes({ prices }) {
	var taxes = []

	prices.taxesList.forEach(tax => {
		var index = taxes.findIndex(t => t.name === tax.name)
		if (index >= 0) {
			taxes[index] = {
				id: tax.id,
				name: tax.name,
				amount: taxes[index].amount + tax.amount,
			}
		} else {
			//doesnt exist
			taxes.push({
				id: tax.id,
				name: tax.name,
				amount: tax.amount,
			})
		}
	})
	var taxReturn = []
	taxes.forEach((tax, i) => {
		taxReturn.push(
			<View
				key={i + 'tax'}
				style={[
					GlobalStyle.collapsibleHeading,
					{
						padding: 15,
						paddingVertical: 5,
						flexDirection: 'row',
						backgroundColor: Colors.custom.white,
					},
				]}
			>
				<View style={[{ flex: 1 }]}>
					<Text style={GlobalStyle.medText}>{tax.name}</Text>
				</View>
				<View style={[{ flex: 1, alignItems: 'flex-end' }]}>
					<Text style={GlobalStyle.medText}>${(Math.round(tax.amount) / 100).toFixed(2)}</Text>
				</View>
			</View>
		)
	})
	return <>{taxReturn}</>
}
