import { Platform } from 'react-native'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { logSignUpAnalytics } from '../../firebase/analytics'

export type User = {
	displayName: string
	firstName: string
	lastName: string
	email: string
	generatedEmail?: string
	phone?: string
	uid: string
	role: string
}
/**
 * Creates in firebase auth, adds user to 'Users' table, and creates customer in Square & Stripe
 * Create referral coupon if applicable.
 */
export default async function registerUser(user, paymentProvider, orgName, orgLogo) {
	const functions = getFunctions()
	const createSquareUser = httpsCallable(functions, 'createSquareUser')
	const createStripeUser = httpsCallable(functions, 'createStripeUser')
	const createUserInDB = httpsCallable(functions, 'createUserInDB')
	const generateReferralCode = httpsCallable(functions, 'generateReferralCode')

	var platform = Platform.OS === 'web' ? 'web app' : 'mobile app'

	if (paymentProvider === 'square') {
		const [referralCode, _] = await Promise.all([
			generateReferralCode({
				firstName: user.firstName,
			}),
			createSquareUser({
				firstName: user.firstName,
				lastName: user.lastName,
				email: user.email,
				phone: user.phone,
				uid: user.uid,
				orgId: global.org,
			}),
		])

		user.role = 'customer'
		user.stripeId = ''
		user.referralCode = referralCode.data
		user.phoneVerified = true
	} else {
		const [stripeUserId, referralCode, _] = await Promise.all([
			createStripeUser({
				firstName: user.firstName,
				lastName: user.lastName,
				email: user.email,
				phone: user.phone,
				firebaseUserId: user.uid,
				role: user.role,
				platform: platform,
				organization: global.org,
			}),
			generateReferralCode({
				firstName: user.firstName,
			}),
			createSquareUser({
				firstName: user.firstName,
				lastName: user.lastName,
				email: user.email,
				phone: user.phone,
				uid: user.uid,
				orgId: global.org,
			}),
		])

		user.role = 'customer'
		user.stripeId = stripeUserId.data
		user.referralCode = referralCode.data
		user.phoneVerified = true
	}

	await createUserInDB({
		orgId: global.org,
		user: user,
		orgName: orgName,
		orgLogo: orgLogo,
	})

	logSignUpAnalytics()

	return true
}
