import { DATABASE_NAME } from '@env'
import '../../global'
import { doc, collection, getDoc } from 'firebase/firestore'
import { db } from '../../firebase/config'

export const getOrgData = () => {
	return new Promise((resolve, reject) => {
		const publicSettingsRef = doc(
			collection(doc(db, DATABASE_NAME, global.org), 'Settings'),
			'Public'
		)

		getDoc(publicSettingsRef)
			.then(docSnapshot => {
				if (docSnapshot.exists()) {
					const data = docSnapshot.data()
					resolve(data)
				} else {
					reject(new Error('Document does not exist.'))
				}
			})
			.catch(error => {
				reject(error)
			})
	})
}
