import { DATABASE_NAME } from '@env'
import { doc, collection, onSnapshot } from 'firebase/firestore'
import { db } from '../../firebase/config'
import '../../global'

export const getPickupPoints = () => {
	return new Promise((resolve, reject) => {
		const dataImport = {}
		dataImport['pickupPoints'] = []
		const pickupPointRef = collection(doc(db, DATABASE_NAME, global.org), 'PickupPoints')

		onSnapshot(
			pickupPointRef,
			snapshot => {
				snapshot.docChanges().forEach(change => {
					const data = change.doc.data()
					if (!data.isDeleted) {
						dataImport['pickupPoints'].push({
							id: data.id,
							name: data.name,
							address: data.address,
						})
					}
				})

				resolve(dataImport)
			},
			reject
		)
	})
}
