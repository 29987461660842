import { useContext, useEffect } from 'react'
import { ItemScreenContext } from '../../../state/context'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../state/reducer'
import { countQtySelected, countSelected, getDiscountPrice } from '../Helpers/functions'
import { checkSnooze } from '../../../helpers/checkSnooze'

const useItem = ({ appliedCoupon, token, editing, loyaltyPrice }) => {
	const loadedData = useSelector<ReduxStoreState, ReduxStoreState['loadedData']>(
		state => state.loadedData
	)

	const {
		setObjects,
		setItemId,
		setItemName,
		setItemNote,
		setIdempotencyKey,
		setItemDesc,
		setPosId,
		setItemPrice,
		setPromoPrice,
		setDiscountPrice,
		quantity,
		setQuantity,
		setImageUri,
		modList,
		setModList,
		setModListLoaded,
		setIsModList,
		setRestId,
		setAllowAdd,
		setAllowAddMulti,
		objects,
		modListLoaded,
		setItemTaxes,
		setVisibleModList,
		setVisibleModListIndex,
		setVariationIds,
		modListsQuantity,
		setTotalModPrice,
		setSingleSelectionId,
		singleSelectionId,
		setAgeVerificationRequired,
	} = useContext(ItemScreenContext)

	function setChecked(modList) {
		modList.forEach(list => {
			list.options.forEach(mod => {
				setObjects((objects: object) => ({
					...objects,
					[mod.id]: mod.isSelected,
				}))
			})
		})
	}

	useEffect(() => {
		if (appliedCoupon) {
			const data = loadedData[token]

			var disc = getDiscountPrice(appliedCoupon, data, quantity)
			setDiscountPrice(disc)
		}
	}, [quantity])

	useEffect(() => {
		const data = loadedData[token]

		if (editing && editing.modList) {
			var modReturn = editing.modList
			setVisibleModListIndex(modReturn.length - 1) //if editing jump to last step
			setChecked(modReturn)
			setQuantity(editing.qty)
			setItemNote(editing.note)
			setIdempotencyKey(editing.idempotencyKey)
		} else {
			setVisibleModListIndex(0)
		}
		setPosId(data.posId ? data.posId : '')
		setVariationIds(data.variationIds ? data.variationIds : [])
		setItemId(data.id)
		setItemName(data.name ? data.name : '')
		setAgeVerificationRequired(data.ageVerificationRequired ? data.ageVerificationRequired : false)
		setItemDesc(data.description ? data.description : '')
		setRestId(data.restaurantId ? data.restaurantId : '')
		setItemPrice(data.price)
		setPromoPrice(loyaltyPrice !== undefined ? loyaltyPrice : data.promo)

		setImageUri(data.imageUri)
		setItemTaxes(prevItemTaxes => [
			...prevItemTaxes,
			...data.taxIds.map(taxId => loadedData[taxId]),
		])

		const info = data.modifierLists
		// Initialize a set to store current modifier ids for O(1) existence checks
		const currentModIds = new Set(modList.map(mod => mod.id))
		if (info.length > 0) {
			setIsModList(true)

			const localModList = []

			let defaultIndex = 0

			const infoWithNestedModifiers = []

			info.forEach((modListForItem, index, array) => {
				const modListData = loadedData[modListForItem.modifierListId]
				infoWithNestedModifiers.push(modListForItem)
				if (data && !currentModIds.has(data.id) && modListData) {
					const modifiers = loadedData['items_for_' + modListForItem.modifierListId] ?? []
					modifiers.forEach(mod => {
						if (mod.modLists?.length > 0) {
							for (const nestedModList of mod.modLists) {
								infoWithNestedModifiers.push({
									...nestedModList,
									isNested: true,
									parentModifierId: mod.id,
									parentModifierName: mod.name,
								})
							}
						}
					})
				}
			})

			infoWithNestedModifiers.forEach((modListForItem, index) => {
				const modListData = loadedData[modListForItem.modifierListId]
				if (data && !currentModIds.has(data.id) && modListData) {
					const isSingle = modListData.selectionType !== 'MULTIPLE'
					const min =
						modListForItem.minSelectedModifiers === -1
							? modListData.min
							: modListForItem.minSelectedModifiers
					const max =
						modListForItem.maxSelectedModifiers === -1
							? modListData.max
							: modListForItem.maxSelectedModifiers

					const options = loadedData['items_for_' + modListForItem.modifierListId] ?? []
					const localOptions = options
						.filter(m => !checkSnooze(m.isSnoozed, m.snoozeUntil) && !m.isDeleted)
						.sort((a, b) => a.ordinal - b.ordinal)
						.map((m, index) => {
							const isSelected = (isSingle || (max === 1 && min === 1)) && index === 0
							// Update the total modification price if the current item is selected
							if (isSelected) {
								singleSelectionId[modListData.id] = m.id
								setTotalModPrice(prevPrice => prevPrice + (m.amount || 0))
							}
							return {
								id: m.id,
								name: m.name,
								price: m.amount || 0,
								currency: 'CAD',
								isSelected: isSelected,
								isSnoozed: m.isSnoozed,
								snoozeUntil: m.snoozeUntil,
								imageUrl: m.imageUrl,
								isRecommended: m.isRecommended,
								ordinal: m.ordinal,
								variation: m.variation || false,
								parentModifierId: modListForItem?.isNested ? modListForItem.parentModifierId : '',
							}
						})
					const localData = {
						id: modListForItem?.isNested
							? modListData.id + '-' + modListForItem.parentModifierId
							: modListData.id,
						name: modListForItem?.isNested
							? modListData.name + ' for ' + modListForItem.parentModifierName
							: modListData.name,
						selectionType: modListData.selectionType,
						ordinal: 1,
						options: localOptions,
						min: min,
						max: max,
						variation: modListData.variation,
						selectedId: '',
						isNested: modListForItem?.isNested ?? false,
					}
					if (localOptions.length > 0) {
						localModList.push(localData)
						if (!editing && index === defaultIndex) {
							setVisibleModList(modListData.id)
						}
					} else {
						defaultIndex++
					}
				}
			})

			if (editing && editing.isEditing) {
				setModList(modReturn)
				setVisibleModList('special_instructions') //if editing jump to last step
			} else {
				//Add special instructions input
				localModList.push({
					id: 'special_instructions',
					name: ' ',
					options: [],
				})

				setModList(localModList)
			}
			if (localModList.length === info.length) {
				setModListLoaded(true)
			}
		} else {
			setModList([
				{
					id: 'special_instructions',
					name: ' ',
					options: [],
				},
			])
			setVisibleModList('special_instructions')
			setModListLoaded(true)
		}
	}, [token])

	useEffect(() => {
		let count = 0
		let multiAdd = true
		let singleAdd = true
		modList.forEach(list => {
			count = countQtySelected(list, modListsQuantity[list.id])
			if (list.selectionType === 'MULTIPLE' && list.max !== 1) {
				if (multiAdd && count >= list.min && (count <= list.max || list.max === -1)) {
					multiAdd = true
				} else if (count < list.min) {
					multiAdd = false
				} else if (count > list.max && list.max !== -1) {
					multiAdd = false
				}
			} else {
				//single
				if (count >= 1 && singleAdd) {
					singleAdd = true
				} else {
					singleAdd = false
				}
			}
		})
		setAllowAddMulti(multiAdd)
		setAllowAdd(singleAdd)
	}, [objects, modListLoaded, token])
}

export default useItem
