import * as React from 'react'
import { TouchableOpacity, ScrollView, Dimensions, TextInput, Platform } from 'react-native'
import { Text, View } from '../../components/Themed'
import { RootTabScreenProps } from '../../navigation/types'
import GlobalStyle, { formStyle, titleTextSize } from '../../constants/GlobalStyle'
import { Section } from '../../components/ListSection'
import { deleteUser } from './deleteUser'
import { auth } from '../../firebase/config'
import Modal from 'react-native-modal'
import { useState } from 'react'
import Button from '../../components/Button'
import Colors from '../../constants/Colors'
import { useDispatch, useSelector } from 'react-redux'
import { setItems, setStripeId, setUserId } from '../../state/actions'
import { alertResponse } from '../../components/Alerts/Alerts'
import { ReduxStoreState } from '../../state/reducer'
import { EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth'
import { TriangleHeader } from '../../components/Headers/TriangleHeader'
import CopyrightFooter from '../../components/Footers/CopyrightFooter'

export default function SettingsScreen({ navigation }: RootTabScreenProps<any>) {
	const [isPasswordConfirmModalVisible, setPasswordConfirmModalVisible] = useState(false)
	const [password, setPassword] = useState('')
	const [errorText, setErrorText] = useState('')
	const [textColor, settextColor] = useState('crimson')

	const togglePasswordConfirmModal = () => {
		setPasswordConfirmModalVisible(!isPasswordConfirmModalVisible)
	}

	const organizationLogo = useSelector<ReduxStoreState, ReduxStoreState['organizationLogo']>(
		state => state.organizationLogo
	)

	const cart = useSelector<ReduxStoreState, ReduxStoreState['items']>(state => state.items)

	const dispatch = useDispatch()

	const user = auth.currentUser

	function deleteAccountPopup() {
		alertResponse(
			'Delete your account?',
			'Deleting your account will delete all of your stored data and cannot be recovered.',
			'No',
			'Yes',
			'cancel',
			'default',
			() => {
				null
			},
			() => {
				togglePasswordConfirmModal()
			}
		)
	}

	// Modal to re-enter password for account deletion
	function passwordPopup() {
		return (
			<>
				{/* modal code */}
				<View>
					<Modal
						style={[
							GlobalStyle.modalStyle,
							{
								height: 250,
								marginTop: Dimensions.get('window').height / 2 - 175,
							},
						]}
						isVisible={isPasswordConfirmModalVisible}
						onBackButtonPress={togglePasswordConfirmModal}
						onBackdropPress={togglePasswordConfirmModal}
						avoidKeyboard={false}
					>
						<View
							style={[
								GlobalStyle.modalView,
								{
									backgroundColor: 'white',
									paddingTop: 35,
									borderRadius: 25,
									height: 250,
								},
							]}
						>
							<Text
								style={[GlobalStyle.titleNoMargin, { alignSelf: 'center', marginHorizontal: 20 }]}
							>
								Please re-enter your password to delete your account
							</Text>
							<View style={{ flexDirection: 'row', paddingTop: 25, paddingLeft: 10 }}>
								<TextInput
									returnKeyType={'done'}
									style={[
										formStyle.textInput,
										{
											width: Dimensions.get('window').width - Dimensions.get('window').width / 2.1,
											marginLeft: 20,
										},
									]}
									placeholder="Password"
									placeholderTextColor={Colors.custom.black}
									onChangeText={text => {
										setPassword(text)
										setErrorText('')
									}}
									secureTextEntry={true}
									value={password}
								/>
								<Button
									onPress={() => {
										refreshAndDelete()
									}}
									title="Confirm"
									buttonStyle={[
										GlobalStyle.addItemBtn,
										{
											flex: 1,
											borderRadius: 15,
											marginLeft: 15,
											marginRight: 30,
											width: Dimensions.get('window').width * 0.05 + 100,
											borderColor: 'crimson',
											borderWidth: 3,
										},
									]}
									textStyle={GlobalStyle.addItemBtnText}
									isLoading={false}
									icon={undefined}
								/>
							</View>
							<Text style={{ color: textColor, paddingTop: 10, paddingBottom: 5 }}>
								{errorText}
							</Text>
						</View>
					</Modal>
				</View>
			</>
		)
	}

	function refreshAndDelete() {
		if (user && user.email) {
			const credential = EmailAuthProvider.credential(user.email, password)

			reauthenticateWithCredential(user, credential)
				.then(() => {
					dispatch(setItems([])) //clear cart
					while (cart.length > 0) {
						cart.pop()
					}
					setErrorText('Deleting...')
					setTimeout(() => {
						setErrorText('Account Deleted!')
						settextColor('green')
						setTimeout(() => {
							deleteUser(user)
							dispatch(setStripeId(''))
							dispatch(setUserId(''))
							togglePasswordConfirmModal()
							navigation.navigate('HomeStack', { screen: 'RestaurantSelect' })
						}, 850)
					}, 2500)
				})
				.catch(error => {
					console.log(error)
					setErrorText('Incorrect Password')
				})
		}
	}

	function displaySettings() {
		return (
			<View>
				{Platform.OS === 'web' && (
					<Text
						style={[
							GlobalStyle.titleText,
							{
								color: global.orgColor2,
								fontSize: titleTextSize,
								paddingVertical: '2%',
								paddingHorizontal: '2%',
							},
						]}
					>
						{'ACCOUNT SETTINGS'}
					</Text>
				)}
				<TouchableOpacity onPress={() => deleteAccountPopup()}>
					<Section name={'Delete Account'} style={{ borderTopWidth: 0 }} />
				</TouchableOpacity>
			</View>
		)
	}

	return (
		<View style={{ flex: 1 }}>
			<TriangleHeader
				title={'ACCOUNT SETTINGS'}
				backFunction={() => {
					navigation.navigate('AccountStack', { screen: 'Account' })
				}}
				navigation={navigation}
				logo={organizationLogo}
			/>
			<View
				style={[
					GlobalStyle.container,
					{
						backgroundColor: Colors.custom.appBackground,
						marginTop: Platform.OS === 'web' ? 0 : -50,
					},
				]}
			>
				<ScrollView>
					<View>{displaySettings()}</View>
				</ScrollView>
				{passwordPopup()}
			</View>
			{Platform.OS === 'web' && <CopyrightFooter />}
		</View>
	)
}
