import React from 'react'
import { useContext } from 'react'
import { View, ScrollView, TouchableOpacity, Dimensions, StyleSheet, Platform } from 'react-native'
import { Text } from '../../../../components/Themed'
import PaymentMethodsList from '../../../../components/Stripe/PaymentMethodsList'
import GlobalStyle, { largeTextSize, mediumTextSize } from '../../../../constants/GlobalStyle'
import { responsiveHeight } from 'react-native-responsive-dimensions'
import Colors from '../../../../constants/Colors'
import { CheckoutScreenContext, DataContext } from '../../../../state/context'
import { AntDesign } from '@expo/vector-icons'
import ExitIcon from '../../../../components/Headers/UIButtons'
import { useDispatch } from 'react-redux'
import { setPaymentMethodId } from '../../../../state/actions'
import { useAddCard } from '../../../../components/Square/Hooks/useAddCard'

export function SelectPaymentMethodView({ toggleModal }) {
	const {
		setCardId,
		cardId,
		loading,
		setIsAddingCard,
		selectedCard,
		setSelectedCard,
		isDigitalWalletEnabled,
		digitalWalletType,
	} = useContext(CheckoutScreenContext)

	const { paymentMethods, paymentProvider } = useContext(DataContext)

	const dispatch = useDispatch()

	const { startCustomerCardEntry } = useAddCard()

	return (
		<View style={{ flex: 1 }}>
			<ExitIcon onPress={toggleModal} style={{ top: -responsiveHeight(2) }} />
			<View style={styles.container}>
				<View style={styles.titleSection}>
					<Text style={GlobalStyle.headerText}>SELECT PAYMENT METHOD</Text>
				</View>
				<ScrollView style={{ width: '90%', flex: 1 }}>
					<PaymentMethodsList
						paymentMethods={paymentMethods}
						isDefault={false}
						setCardId={setCardId}
						cardId={cardId}
						loading={loading}
						onPress={() => {
							null
						}}
						isCheckout={true}
						selectedCard={selectedCard}
						setSelectedCard={setSelectedCard}
						isDigitalWalletEnabled={isDigitalWalletEnabled}
						digitalWalletType={digitalWalletType}
					/>
				</ScrollView>
				<View style={styles.buttonContainer}>
					<TouchableOpacity
						style={[styles.button, { backgroundColor: 'black' }]}
						onPress={() => {
							if (selectedCard !== null) {
								setCardId(selectedCard)
								dispatch(setPaymentMethodId(selectedCard))
							}
							toggleModal()
						}}
					>
						<Text style={styles.buttonText}>USE SELECTED CARD</Text>
						<AntDesign name="right" size={largeTextSize - 3} color="white" />
					</TouchableOpacity>
					<TouchableOpacity
						style={[styles.button, { backgroundColor: Colors.greyscale[6] }]}
						onPress={() => {
							paymentProvider === 'square' && Platform.OS !== 'web'
								? startCustomerCardEntry()
								: setIsAddingCard(true)
						}}
					>
						<Text style={styles.buttonText}>ADD A DIFFERENT CARD</Text>
						<AntDesign name="right" size={largeTextSize - 3} color="white" />
					</TouchableOpacity>
					<TouchableOpacity
						style={styles.cancel}
						onPress={() => {
							toggleModal()
						}}
					>
						<Text style={styles.cancelText}>CANCEL</Text>
					</TouchableOpacity>
				</View>
			</View>
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
	},
	titleSection: {
		padding: 20,
	},

	buttonContainer: {
		width: '90%',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexDirection: 'column',
		paddingBottom: 20,
	},
	button: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		padding: 15,
		borderRadius: 5,
		marginTop: 10,
		width: '100%',
	},
	buttonText: {
		color: 'white',
		fontSize: 16,
	},

	// buttonContainer: {
	// 	flexDirection: 'column',
	// 	width: '90%',
	// 	//position: 'absolute',
	// 	//bottom: 0,
	// },
	// button: {
	// 	flex: 1,
	// 	flexDirection: 'row',
	// 	borderRadius: (Dimensions.get('window').height * 0.1) / 8,
	// 	alignItems: 'center',
	// 	maxHeight: 140,
	// 	minHeight: 45,
	// 	height: Dimensions.get('window').height * 0.1,
	// 	justifyContent: 'center',
	// 	marginTop: 20,
	// 	width: '100%',
	// },
	// buttonText: {
	// 	color: 'white',
	// 	fontWeight: '400',
	// 	fontSize: largeTextSize,
	// 	marginRight: 10,
	// },
	cancel: {
		marginTop: 20,
		marginBottom: 0,
	},
	cancelText: {
		color: 'black',
		fontWeight: '300',
		fontSize: mediumTextSize,
	},
})
