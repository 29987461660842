import { Dimensions, Platform, StyleSheet } from 'react-native'
import Colors from '../../../constants/Colors'
import GlobalStyle, {
	MAX_CHECKOUT_WIDTH,
	titleTextSize,
	xxlTextSize,
} from '../../../constants/GlobalStyle'
import '../../../global.js'

const width = Dimensions.get('window').width
const height = Dimensions.get('window').height

export const styles = StyleSheet.create({
	borderLine: {
		marginHorizontal: -15,
		height: 0,
		marginVertical: 15,
		borderTopWidth: 2,
		borderColor: Colors.greyscale[2],
	},
	separator: {
		marginVertical: 10,
		height: 1,
		width: '100%',
	},
	pricingComponent: {
		...GlobalStyle.flexRow,
		justifyContent: 'space-between',
		marginBottom: 10,
		alignItems: 'center',
	},
	container: {
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: '#fff',
	},
	buttonText: {
		fontSize: xxlTextSize,
		fontWeight: '400',
	},
	dineInButtonContainer: {
		marginBottom: 10,
	},
	buttonContainer: {
		paddingTop: 150,
		flexDirection: 'row',
		marginBottom: 10,
	},
	inputContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		marginBottom: 10,
	},
	label: {
		marginRight: 10,
		fontSize: global.kioskV2 ? 22 : 32,
	},
	input: {
		borderWidth: 2,
		borderRadius: 20,
		padding: 15,
		width: global.kioskV2 ? 225 : 300,
		fontSize: global.kioskV2 ? 18 : 28,
		paddingLeft: 20,
	},
	skipButton: {
		marginRight: 15,
		backgroundColor: '#fff',
		borderWidth: 2,
		padding: 15,
		paddingHorizontal: 45,
		borderRadius: 20,
	},
	continueButton: {
		borderWidth: 2,
		padding: 15,
		paddingHorizontal: 45,
		borderRadius: 20,
	},
	fullScreen: {
		height: '100%',
		width: '100%',
	},
	loadImg: {
		alignSelf: 'center',
		marginTop: -20,
	},
	modal: {
		backgroundColor: 'white',
		borderRadius: 25,
	},
	// centeredView: {
	// 	flex: 1,
	// 	justifyContent: 'center',
	// 	alignItems: 'center',
	// 	marginHorizontal: '10%',
	// 	maxHeight: 550,
	// },
	// modalView: {
	// 	flex: 1,
	// 	position: 'relative',
	// 	backgroundColor: 'white',
	// 	borderRadius: 20,
	// 	alignItems: 'center',
	// 	shadowColor: '#000',
	// 	shadowOffset: {
	// 		width: 0,
	// 		height: 2,
	// 	},
	// 	shadowOpacity: 0.25,
	// 	shadowRadius: 4,
	// 	elevation: 5,
	// 	width: '100%',
	// 	maxWidth: MAX_CHECKOUT_WIDTH - 200,
	// 	overflow: 'hidden',
	// },
	centeredView: {
		justifyContent: 'center',
		marginVertical: '10%',
		maxHeight: Dimensions.get('window').height * 0.8,
		marginHorizontal: '10%',
		height: height * 0.65 > 800 ? 800 : height * 0.65,
		width: '100%',
		maxWidth: MAX_CHECKOUT_WIDTH - 200,
		alignSelf: 'center',
	},
	modalView: {
		maxHeight: Dimensions.get('window').height * 0.8,

		position: 'relative',
		backgroundColor: 'white',
		borderRadius: 20,
		alignItems: 'center',
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5,
		overflow: 'hidden',
	},
})
