import { DATABASE_NAME } from '@env'
import { doc, collection, onSnapshot } from 'firebase/firestore'
import { db } from '../../firebase/config'
import '../../global'

export const getLoyaltyRewardData = () => {
	return new Promise((resolve, reject) => {
		const dataImport = {}

		const loyaltyRewardRef = collection(doc(db, DATABASE_NAME, global.org), 'LoyaltyRewards')

		if (!dataImport['rewards']) {
			dataImport['rewards'] = []
		}

		onSnapshot(
			loyaltyRewardRef,
			snapshot => {
				snapshot.docChanges().forEach(change => {
					const data = change.doc.data()
					if (!data.isDeleted && data.isEnabled) {
						dataImport['reward_' + data.id] = data
						dataImport['rewards'].push(data)
					}
				})

				resolve(dataImport)
			},
			reject
		)
	})
}
