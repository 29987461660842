import { Dimensions } from 'react-native'
import { MED_WIDTH, SMALL_WIDTH } from '../constants/GlobalStyle'

// Find ideal card width based on screen size
// Try to fit as many cards as possible on a row at least of min width
// Extend card width to fill screen as much as possible
export function calculateOptimalCardWidth(
	screenWidth,
	MIN_WIDTH = 200,
	MARGIN = 30,
	cardCount = 0
) {
	// Adjust MIN_WIDTH for small screens
	if (SMALL_WIDTH && MIN_WIDTH > 140) {
		MIN_WIDTH = 140
	} else if (
		Dimensions.get('window').width > 1000 &&
		Dimensions.get('window').width < Dimensions.get('window').height
	) {
		MIN_WIDTH = 250
	}
	// Step 1: Calculate the potential number of cards per row
	const potentialCardCount = Math.floor((screenWidth + MARGIN) / (MIN_WIDTH + MARGIN))

	// Step 2: Determine actual number of cards per row
	const actualCardCount =
		cardCount > 0 ? Math.min(cardCount, potentialCardCount) : potentialCardCount

	// Step 3: Calculate total margin space and available space for cards
	const totalMargin = (actualCardCount - 1) * MARGIN // Margin between cards
	const availableSpace = screenWidth - totalMargin

	// Step 4: Calculate card width
	let cardWidth = availableSpace / actualCardCount

	// Step 5: Adjust for MIN_WIDTH and maximum width
	cardWidth = Math.max(cardWidth, MIN_WIDTH) // Maximum width 300

	return { width: cardWidth, cardsPerRow: actualCardCount }
}
