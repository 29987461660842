import React from 'react'
import { View, StyleSheet, Dimensions } from 'react-native'
import { Text } from './Themed'
import { mediumTextSize } from '../constants/GlobalStyle'
const { width } = Dimensions.get('window')

const Banner = ({ text, style = {}, textStyle = {} }) => {
	if (text !== '') {
		return (
			<View style={[styles.container, { backgroundColor: global.orgColor }, style]}>
				<Text style={[styles.bannerText, textStyle]}>{text}</Text>
			</View>
		)
	} else {
		return <></>
	}
}

const styles = StyleSheet.create({
	container: {
		width: '100%',
		alignItems: 'center',
		justifyContent: 'center',
		padding: 15,
		borderRadius: 5,
		alignSelf: 'center',
		marginTop: 20,
	},
	bannerText: {
		color: 'white',
		fontSize: mediumTextSize,
		fontWeight: 'bold',
	},
})

export default Banner
