import { createContext } from 'react'

export const RestaurantScreenContext = createContext(null)

export const CheckoutScreenContext = createContext(null)

export const ItemScreenContext = createContext(null)

export const RestaurantSelectScreenContext = createContext(null)

//allows for turing off idle timer on certain screens/modals
export const IdleContext = createContext(null)

//allows loading to take place over multiple screens
export const DataContext = createContext(null)
