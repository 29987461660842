import * as React from 'react'
import { TouchableOpacity, View } from 'react-native'
import GlobalStyle from '../../../constants/GlobalStyle'
import Colors from '../../../constants/Colors'
import { Text } from '../../../components/Themed'

export default function PinpadReaderList({ pinpads, setReaderError, setShowContinue }) {
	var readerListFormatted = []
	if (pinpads) {
		pinpads.data.forEach(reader => {
			if (reader.id) {
				const name = reader.label ? reader.label : reader.name
				readerListFormatted.push(
					<View key={reader.id + Math.random()} style={{ paddingBottom: 15 }}>
						<TouchableOpacity
							onPress={() => {
								if (reader.status === 'offline') {
									setReaderError('Cannot connect to pinpad.')
									global.readerId = null
									setShowContinue(false)
								} else {
									global.readerId = reader.id
									setReaderError('Connected to pinpad ' + name + '.')
									setShowContinue(true)
								}
							}}
						>
							<View
								style={[
									{
										borderColor: global.readerId === reader.id ? 'green' : 'grey',
										borderWidth: 2,
										paddingHorizontal: 15,
										paddingVertical: 15,
										borderRadius: 10,
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'space-between',
									},
								]}
							>
								<View style={{ flexDirection: 'row', alignItems: 'center' }}>
									<View
										style={{
											width: 22,
											height: 22,
											borderRadius: 11,
											backgroundColor:
												reader.status === 'online'
													? '#4BB543'
													: reader.name === 'Square Terminal'
													? Colors.greyscale[7]
													: '#cc0000',

											marginRight: 10,
										}}
									/>
									<Text style={[GlobalStyle.addItemBtnText, { fontSize: 18 }]}>{name}</Text>
								</View>
								<Text style={{ fontSize: 14, color: 'grey' }}>
									{reader.name === 'Square Terminal' ? reader.id.replace('tmr_', '') : reader.id}
								</Text>
							</View>
						</TouchableOpacity>
					</View>
				)
			} else {
				readerListFormatted.push(
					<View key={reader + Math.random()} style={{ paddingBottom: 15 }}>
						<TouchableOpacity
							onPress={() => {
								global.readerId = reader.id
								setReaderError('Connected to pinpad ' + reader + '.')
								setShowContinue(true)
							}}
						>
							<View
								style={[
									{
										borderColor: global.readerId === reader.id ? 'green' : 'grey',
										borderWidth: 2,
										paddingHorizontal: 15,
										paddingVertical: 15,
										borderRadius: 10,
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'space-between',
									},
								]}
							>
								<View style={{ flexDirection: 'row', alignItems: 'center' }}>
									<Text style={[GlobalStyle.addItemBtnText, { fontSize: 18 }]}>{reader}</Text>
								</View>
							</View>
						</TouchableOpacity>
					</View>
				)
			}
		})
	}
	return <View>{readerListFormatted}</View>
}
