import { Alert } from 'react-native'

export function getLogoutPrompt(navigation) {
	return Alert.alert(
		'Create an account',
		'You must sign in and add a payment method before submitting an order.',
		[
			{
				text: 'Cancel',
				onPress: () => {
					return
				},
			},
			{
				text: 'Continue',
				onPress: () =>
					navigation.navigate('AccountStack', { screen: 'Account', params: { signup: true } }),
			},
		]
	)
}

export function getAddPaymentPrompt(navigation) {
	return Alert.alert(
		'Add a payment method',
		'You must add a payment method before submitting an order.',
		[
			{
				text: 'Cancel',
				onPress: () => {
					return
				},
			},
			{
				text: 'Continue',
				onPress: () => navigation.navigate('Wallet'),
			},
		]
	)
}

export function getEmptyCartAlert() {
	return Alert.alert(
		'Cart is empty',
		'You must have at least one item in your cart before placing an order.',
		[
			{
				text: 'OK',
				onPress: () => {
					return
				},
			},
		]
	)
}

export function addressTooFar() {
	return Alert.alert(
		'This delivery address is too far from the restaurant',
		'Please update your selected delivery address.',
		[
			{
				text: 'OK',
				onPress: () => {
					return
				},
			},
		]
	)
}

export function storeClosed() {
	return Alert.alert(
		'This location is closed.',
		'Please check back later or schedule a pickup time for your order',
		[
			{
				text: 'OK',
				onPress: () => {
					return
				},
			},
		]
	)
}
