import * as React from 'react'
import { View, Button, Platform } from 'react-native'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../state/reducer'
import { useState } from 'react'
import { alertResponseText } from '../../Alerts/Alerts'
export default function EpsonPrintReceipt({ item, printer }) {
	let EscPosPrinter, getPrinterSeriesByName
	if (Platform.OS !== 'web' && global.pos) {
		const escPosModule = require('react-native-esc-pos-printer')
		EscPosPrinter = escPosModule.default
		getPrinterSeriesByName = escPosModule.getPrinterSeriesByName

		const [init, setInit] = useState(false)
		const [isPrinting, setIsPrinting] = useState(false)
		const [isError, setIsError] = useState(false)

		const loadedData = useSelector<ReduxStoreState, ReduxStoreState['loadedData']>(
			state => state.loadedData
		)

		const orgName = loadedData[global.org].organizationName
			? loadedData[global.org].organizationName
			: ''
		const orgAddress = loadedData[global.org].organizationAddress
			? loadedData[global.org].organizationAddress
			: ''
		const orgURL = loadedData[global.org].webURL
			? loadedData[global.org].webURL.replace(/^(http:\/\/|https:\/\/)/, '')
			: ''
		let orgPhone = loadedData[global.org].organizationPhoneNumber
			? loadedData[global.org].organizationPhoneNumber
			: ''

		if (orgPhone !== '') {
			if (orgPhone.length === 11 && orgPhone[0] === '1') {
				orgPhone = orgPhone.slice(1)
			}
			const match = orgPhone.match(/^(\d{3})(\d{3})(\d{4})$/)
			if (match) {
				orgPhone = '(' + match[1] + ')-' + match[2] + '-' + match[3]
			}
		}

		const orgLogo = loadedData[global.org].organizationLogo
			? loadedData[global.org].organizationLogo
			: ''

		return (
			<View>
				<Button
					title={isPrinting ? 'Printing...' : isError ? 'Error printing receipt' : 'Print Receipt'}
					disabled={!printer || isPrinting}
					color={!printer ? 'gray' : global.orgColor}
					onPress={async () => {
						setIsPrinting(true)
						try {
							if (printer) {
								if (!init) {
									await EscPosPrinter.init({
										target: printer.target,
										seriesName: getPrinterSeriesByName(printer.name),
										language: 'EPOS2_LANG_EN',
									})
									setInit(true)
								}
								const printing = new EscPosPrinter.printing()

								await printing
									.initialize()
									.align('center')
									.image(
										{
											uri: orgLogo,
										},
										{ width: 220 }
									)
									.newline()
									.newline()
									.size(2, 2)
									.smooth(true)
									.text(orgName)
									.newline()
									.newline()
									.size(1, 1)
									.text(orgAddress)
									.newline()
									.text(orgPhone)
									.newline()
									.text(orgURL)
									.newline()
									.newline()
									.text('Order type: ' + item.channelData.orderType.toLowerCase())
									.text(' order via ' + item.channelData.appType)
									.newline()
									.text('Order date: ' + new Date(item.orderDate._seconds * 1000).toLocaleString())
									.newline()
									.text(
										'Pickup time: ' + new Date(item.pickupDate._seconds * 1000).toLocaleString()
									)
									.newline()
									.newline()
									.newline()
									.text('Order #' + item.orderNumber)
									.text(
										item.tableNumber === null || item.tableNumber === ''
											? ''
											: ' for ' + item.tableNumber
									)
									.newline()
									.newline()
									.text('************************************************')
									.newline()
									.newline()
								await item.orderItems.forEach(orderItem => {
									printing
										.textLine(48, {
											left: orderItem.quantity + 'x ' + orderItem.name,
											right:
												'$' + ((orderItem.priceMoney.amount * orderItem.quantity) / 100).toFixed(2),
											gapSymbol: ' ',
										})
										.newline()
									if (orderItem.modifiers.length > 0) {
										orderItem.modifiers.forEach(modifier => {
											printing
												.text('    ')
												.textLine(44, {
													left: `${modifier.name}`,
													right:
														'$' +
														((modifier.priceMoney.amount * orderItem.quantity) / 100).toFixed(2),
													gapSymbol: ' ',
												})
												.newline()
										})
									}
								})
								const status = await printing
									.newline()
									//.text('************************************************')
									.newline()
									.align('left')
									.textLine(48, {
										left: 'Subtotal',
										right: '$' + item.priceData.subTotal.toFixed(2),
										gapSymbol: ' ',
									})
									.newline()
								if (item.priceData.discount !== 0) {
									printing
										.textLine(48, {
											left: 'Discount',
											right: '$' + item.priceData.discount.toFixed(2),
											gapSymbol: ' ',
										})
										.newline()
								}
								printing
									.textLine(48, {
										left: 'Tip',
										right: '$' + item.priceData.tip.toFixed(2),
										gapSymbol: ' ',
									})
									.newline()
								if (item.priceData.serviceCharge !== 0) {
									printing
										.textLine(48, {
											left: 'Service Charge',
											right: '$' + (item.priceData.serviceCharge / 100).toFixed(2),
											gapSymbol: ' ',
										})
										.newline()
								}
								printing
									.textLine(48, {
										left: 'Taxes',
										right: '$' + item.priceData.taxes.toFixed(2),
										gapSymbol: ' ',
									})
									.newline()
									.textLine(48, {
										left: 'Total',
										right: '$' + item.priceData.total.toFixed(2),
										gapSymbol: ' ',
									})
									.newline()
									.text(item.orderNote !== '' ? '\n\nOrder Note: ' + item.orderNote : '')
									.newline()
									.text('************************************************')
									.newline()
									.newline()
									.align('center')
									.text('Order online:')
									.newline()
									.qrcode({
										value: orgURL,
										width: 5,
										type: 'EPOS2_SYMBOL_QRCODE_MODEL_2',
										level: 'EPOS2_LEVEL_M',
									})
									.newline()
									.newline()
									.text('Thank you for your order!')
									.newline()
									.newline()
									.newline()
									.newline()
									.cut()
									.send()
								console.log('printing', status)
							}
						} catch (error) {
							console.log('error', error)
							alertResponseText('Error printing receipt', error)
						}
						setTimeout(() => {
							setIsPrinting(false)
						}, 3500)
					}}
				/>
			</View>
		)
	} else {
		return <></>
	}
}
