import * as React from 'react'
import {
	NavigationContainer,
	DefaultTheme,
	DarkTheme,
	useNavigationState,
} from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { Platform, View, Dimensions, PixelRatio } from 'react-native'
import { Text } from '../components/Themed'
import { useSelector } from 'react-redux'
import { FontAwesome5, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons'
import LoginScreen from '../screens/Login'
import SettingsScreen from '../screens/Settings'
import OrdersScreen from '../screens/Orders'
import SignupScreen from '../screens/Signup'
import AccountScreen from '../screens/Account'
import { ReferScreen } from '../screens/Referral'
import WalletScreen from '../screens/Wallet'
import { ReduxStoreState } from '../state/reducer'
import GlobalStyle, { sTextSize } from '../constants/GlobalStyle'
import {
	CheckoutScreenWithState,
	ItemScreenWithState,
	RestaurantSelectScreenWithState,
	RestaurantScreenWithState,
} from './state'
import { APP_PREFIX, WEB_PREFIX } from '@env'
import ReceiptScreen from '../screens/Receipt'
import KioskHomeScreen from '../screens/KioskHome'
import Colors from '../constants/Colors'
import { logScreenViewAnalytics } from '../firebase/analytics'
import { PaymentForm } from 'react-square-web-payments-sdk'
import QRLoyaltyScreen from '../screens/QRLoyalty'
import ChefHatIcon from '../components/Rewards/ChefHatIcon'
import RewardsScreen from '../screens/Rewards'
import { useEffect } from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage'
import ResetPasswordScreen from '../screens/ResetPassword'
import DownloadScreen from '../screens/Download'

const linking = {
	prefixes: [WEB_PREFIX],
	config: {
		screens: {
			HomeStack: {
				path: '',
				screens: {
					RestaurantSelect: {
						path: '/home',
					},
					Menu: '/menu/:rId',
					Item: '/item/:token',
				},
			},
			AccountStack: {
				path: 'account',
				screens: {
					Account: {
						path: '',
					},
					Settings: '/settings',
					Wallet: '/wallet',
					Refer: '/refer',
					Login: '/login',
					Signup: '/signup',
				},
			},
			TabCheckout: '/cart',
			TabOrders: '/orders',
			receipt: '/receipt',
			download: '/download',
			QRLoyalty: '/rewards',
			Rewards: '/redeem',
			ResetPassword: '/reset-password/:token',
			Root: {
				parse: {
					referralCode: referralCode => referralCode,
				},
			},
		},
	},
}

// modifying DarkTheme/DefaultTheme in file wont change actual theme on web
const theme = {
	...DefaultTheme,
	colors: {
		...DefaultTheme.colors,
		background: Colors.custom.appBackground,
	},
}

function Navigation({ colorScheme }) {
	const loadedData = useSelector<ReduxStoreState, ReduxStoreState['loadedData']>(
		state => state.loadedData
	)

	function buildAnalyticName(route) {
		const routeName = route.name
		const routeParams = route.params
		let analyticName = routeName
		if (routeParams) {
			if (routeName === 'Item') {
				analyticName = routeName + '_' + routeParams.token
			} else if (routeName === 'Menu') {
				analyticName = routeName + '_' + routeParams.rId
			}
		} else if (routeName === 'RestaurantSelect') {
			analyticName = 'Home'
		} else if (routeName === 'TabCheckout') {
			analyticName = 'Cart'
		} else if (routeName === 'TabOrders') {
			analyticName = 'Orders'
		}
		return analyticName
	}

	const navigationRef: any = React.useRef()
	const routeNameRef: any = React.useRef()
	return (
		<NavigationContainer
			linking={linking}
			theme={theme}
			documentTitle={{
				formatter: (options, route) =>
					`${loadedData[global.org].organizationName} - ${options?.title ?? route?.name}`,
			}}
			ref={navigationRef}
			onReady={() => {
				routeNameRef.current = navigationRef.current.getCurrentRoute().name
			}}
			onStateChange={async () => {
				const previousRouteName = routeNameRef.current
				const currentRouteName = navigationRef.current.getCurrentRoute().name

				if (previousRouteName !== currentRouteName) {
					const screenName = buildAnalyticName(navigationRef.current.getCurrentRoute())
					logScreenViewAnalytics(screenName)
				}
				routeNameRef.current = currentRouteName
			}}
		>
			<RootNavigator />
		</NavigationContainer>
	)
}

const Stack = createNativeStackNavigator()

function RootNavigator() {
	const isKiosk = global.kiosk
	const initialRoute = isKiosk ? 'KioskHome' : Platform.OS === 'web' ? 'HomeStack' : 'tab'

	return (
		<Stack.Navigator
			initialRouteName={initialRoute}
			screenOptions={{
				animation: 'none',
			}}
		>
			{!isKiosk && Platform.OS !== 'web' ? (
				<Stack.Screen name="tab" component={BottomTabNavigator} options={{ headerShown: false }} />
			) : (
				<>
					<Stack.Screen
						name="AccountStack"
						component={AccountStackScreen}
						options={{ headerShown: false }}
					/>

					<Stack.Screen name="receipt" component={ReceiptScreen} options={{ headerShown: false }} />
					<Stack.Screen
						name="download"
						component={DownloadScreen}
						options={{ headerShown: false }}
					/>

					<Stack.Screen
						name="ResetPassword"
						component={ResetPasswordScreen}
						options={{ headerShown: false }}
					/>

					<Stack.Screen
						name="TabOrders"
						component={OrdersScreen}
						options={{
							headerShown: false,
							title: 'Orders',
						}}
					/>

					<Stack.Screen
						name="TabCheckout"
						component={CheckoutScreenWithState}
						options={{
							headerShown: false,
							title: 'Cart',
						}}
					/>
					<Stack.Screen
						name="HomeStack"
						component={HomeStackScreen}
						options={{ headerShown: false }}
					/>
				</>
			)}

			<Stack.Screen
				name="QRLoyalty"
				component={QRLoyaltyScreen}
				options={{ headerShown: false, title: 'Rewards' }}
			/>

			<Stack.Screen
				name="Rewards"
				component={RewardsScreen}
				options={{ headerShown: false, title: 'Your Rewards' }}
			/>

			<Stack.Screen name="KioskHome" component={KioskHomeScreen} options={{ headerShown: false }} />

			<Stack.Screen name="Item" component={ItemScreenWithState} options={{ headerShown: false }} />
		</Stack.Navigator>
	)
}

const CheckoutIcon = ({ color }) => {
	const cart = useSelector<ReduxStoreState, ReduxStoreState['items']>(state => state.items)

	const cartCount = cart.reduce((count, item) => count + item.qty, 0)

	return (
		<View>
			{cartCount > 0 ? (
				<View style={[GlobalStyle.fabTO3, { backgroundColor: global.orgColor }]}>
					<Text style={{ fontSize: 11, color: 'white' }}>{cartCount}</Text>
				</View>
			) : null}
			<MaterialCommunityIcons name="shopping-outline" size={26} color={color} />
		</View>
	)
}

const BottomTab = createBottomTabNavigator()

function BottomTabNavigator() {
	const loadedData = useSelector<ReduxStoreState, ReduxStoreState['loadedData']>(
		state => state.loadedData
	)
	const loyaltyEnabled = loadedData[global.org].loyaltyEnabled || false
	const singleBrandEnabled = loadedData[global.org].singleBrandEnabled || false

	return (
		<BottomTab.Navigator
			initialRouteName="tab"
			screenOptions={{
				tabBarActiveTintColor: global.orgColor,
				tabBarInactiveTintColor: global.orgColor2,
				tabBarLabelStyle: { fontSize: sTextSize, fontWeight: '600', fontFamily: 'DefaultFont' },
				tabBarStyle: {
					paddingTop: 10,
					paddingBottom: Platform.OS === 'ios' ? 20 : 10,
					shadowColor: '#000',
					shadowOffset: {
						width: 0,
						height: 1,
					},
					shadowOpacity: 0.12,
					shadowRadius: 4,
					elevation: 3,
					height:
						Platform.OS === 'ios'
							? Dimensions.get('window').height * 0.075 + 10
							: Dimensions.get('window').height * 0.075,
				},
			}}
			backBehavior="history"
		>
			<BottomTab.Screen
				name="HomeStack"
				component={HomeStackScreen}
				options={{
					title: singleBrandEnabled ? 'MENU' : 'HOME',
					tabBarIcon: ({ color }) => (
						<MaterialCommunityIcons
							name="silverware-fork-knife"
							size={24}
							color={color}
							style={{ marginLeft: -2 }}
						/>
					),
					headerShown: false,
				}}
			/>
			{loyaltyEnabled && (
				<BottomTab.Screen
					name="Rewards"
					component={RewardsScreen}
					options={{
						title: 'REWARDS',
						tabBarIcon: ({ color }) => <ChefHatIcon color={color} />,
						headerShown: false,
					}}
				/>
			)}

			<BottomTab.Screen
				name="TabOrders"
				component={OrdersScreen}
				options={{
					headerShown: false,
					title: 'ORDERS',
					tabBarIcon: ({ color }) => <FontAwesome5 name="history" size={22} color={color} />,
				}}
			/>

			<BottomTab.Screen
				name="TabCheckout"
				component={CheckoutScreenWithState}
				options={{
					headerShown: false,
					title: 'CART',
					tabBarIcon: ({ color }) => <CheckoutIcon color={color} />,
				}}
			/>

			<BottomTab.Screen
				name="AccountStack"
				component={AccountStackScreen}
				options={{
					headerShown: false,
					title: 'ACCOUNT',
					tabBarIcon: ({ color }) => (
						<MaterialIcons name="account-circle" size={26} color={color} />
					),
				}}
			/>
		</BottomTab.Navigator>
	)
}

const HomeStack = createNativeStackNavigator()

function HomeStackScreen() {
	const loadedData = useSelector<ReduxStoreState, ReduxStoreState['loadedData']>(
		state => state.loadedData
	)

	return (
		<HomeStack.Navigator
			screenOptions={{
				presentation: 'transparentModal',
				animation: 'slide_from_bottom',
			}}
		>
			{!global.singleBrandEnabled && (
				<HomeStack.Screen
					name="RestaurantSelect"
					component={RestaurantSelectScreenWithState}
					options={{ headerShown: false, title: 'Home' }}
				/>
			)}

			<HomeStack.Screen
				name="Menu"
				component={RestaurantScreenWithState}
				options={{ headerShown: false }}
				initialParams={
					Platform.OS === 'web' && global.singleBrandEnabled
						? { rId: loadedData['defaultRestaurant'].id }
						: null
				}
			/>
			<Stack.Screen name="Item" component={ItemScreenWithState} options={{ headerShown: false }} />
		</HomeStack.Navigator>
	)
}

const AccountStack = createNativeStackNavigator()

function AccountStackScreen() {
	return (
		<AccountStack.Navigator
			screenOptions={{
				presentation: 'transparentModal',
				animation: 'slide_from_bottom',
			}}
		>
			<AccountStack.Screen
				name="Account"
				component={AccountScreen}
				options={{ headerShown: false }}
			/>
			<Stack.Screen name="Login" component={LoginScreen} options={{ headerShown: false }} />
			<Stack.Screen name="Signup" component={SignupScreen} options={{ headerShown: false }} />
			<AccountStack.Screen
				name="Settings"
				component={SettingsScreen}
				options={{ headerShown: false }}
			/>
			<AccountStack.Screen
				name="Wallet"
				component={WalletScreen}
				options={{ headerShown: false, presentation: 'card' }}
			/>
			<AccountStack.Screen name="Refer" component={ReferScreen} options={{ headerShown: false }} />
		</AccountStack.Navigator>
	)
}

export default Navigation
