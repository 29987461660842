import { StyleSheet } from 'react-native'
import GlobalStyle, { formStyle } from '../../constants/GlobalStyle'

export const styles = StyleSheet.create({
	container: {
		...formStyle.container,
		paddingHorizontal: 0,
	},
	signupBtn: {
		color: 'mediumblue',
	},
	modal: {
		justifyContent: 'center',
		margin: 0,
	},
	modalView: {
		backgroundColor: 'white',
		borderRadius: 25,
		padding: 20,
		marginHorizontal: 20,
		width: '80%',
		maxWidth: 720,
		alignSelf: 'center',
	},
	closeIcon: {
		marginTop: 10,
		alignSelf: 'flex-start',
	},
	title: {
		...GlobalStyle.titleNoMargin,
		alignSelf: 'center',
	},
	inputContainer: {
		flexDirection: 'row',
		paddingTop: 10,
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	textInput: {
		...formStyle.textInput,
		flex: 1,
		width: '60%',
	},
	confirmButton: {
		borderRadius: 15,
		borderWidth: 2,
		paddingHorizontal: 20,
		minWidth: 100,
	},
	buttonText: {
		...GlobalStyle.addItemBtnText,
	},
	errorText: {
		paddingTop: 10,
		paddingBottom: 5,
		paddingHorizontal: 25,
		textAlign: 'center',
	},
})
