import React, { useState } from 'react'
import { IdleContext } from '../../state/context'

const IdleContextProvider = ({ children }) => {
	const [disableIdleTimer, setDisableIdleTimer] = useState(false)
	const [navContext, setNavContext] = useState(null)
	return (
		<IdleContext.Provider
			value={{
				disableIdleTimer,
				setDisableIdleTimer,
				navContext,
				setNavContext,
			}}
		>
			{children}
		</IdleContext.Provider>
	)
}

export default IdleContextProvider
