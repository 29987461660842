import React from 'react'
import { StyleSheet, View } from 'react-native'
import { Card, Title } from 'react-native-paper'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import Colors from '../../../constants/Colors'

/**
 * Two cards to choose between Kiosk or POS
 * global.kiosk will always be true, but global.pos will be true only if POS is selected
 */
const KioskPOSOption = ({ selected, setSelected, clearState }) => {
	const OptionCard = ({ type, iconName, description }) => (
		<Card
			style={styles.card}
			onPress={() => {
				setSelected(type)
				clearState()
				if (type === 'KIOSK') {
					global.pos = false
				} else if (type === 'POS') {
					global.pos = true
				}
			}}
		>
			<Card.Content style={styles.cardContent}>
				<MaterialCommunityIcons
					name={iconName}
					size={60}
					color={selected === type ? Colors.custom.ngkMain : '#000'}
				/>
				<Title style={{ color: selected === type ? Colors.custom.ngkMain : '#000' }}>
					{description}
				</Title>
			</Card.Content>
		</Card>
	)

	return (
		<>
			<View style={styles.cardContainer}>
				<OptionCard type="KIOSK" iconName="tablet-dashboard" description="KIOSK" />
				<OptionCard type="POS" iconName="cash-register" description="POS" />
			</View>
		</>
	)
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: '#f5f5f5',
		alignItems: 'center',
	},
	title: {
		fontSize: 28,
		marginVertical: 20,
		fontWeight: 'bold',
	},
	cardContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		//width: '90%',
		marginHorizontal: 10,
	},
	card: {
		flex: 1,
		margin: 10,
		elevation: 5,
		backgroundColor: '#fff',
		borderRadius: 10,
	},
	cardContent: {
		alignItems: 'center',
	},
	continueButton: {
		position: 'absolute',
		bottom: 30,
		backgroundColor: '#6200ea',
		borderRadius: 30,
		elevation: 5,
	},
})

export default KioskPOSOption
