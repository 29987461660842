import * as React from 'react'
import { Text } from '../Themed'
import { titleTextSize, mediumTextSize } from '../../constants/GlobalStyle'
import { auth } from '../../firebase/config'
import Colors from '../../constants/Colors'

export const RewardsDescription = ({ orgName }) => {
	return (
		<>
			<Text
				style={{
					fontSize: titleTextSize,
					marginBottom: 20,
					textAlign: 'center',
				}}
			>
				{`${
					auth.currentUser === null ? 'JOIN' : ''
				} OUR REWARDS PROGRAM AT ${orgName.toUpperCase()}!`}
			</Text>
			<Text
				style={{
					fontSize: mediumTextSize,
					color: Colors.custom.mediumGrey,
					marginBottom: 15,
					lineHeight: 25,
					textAlign: 'justify',
				}}
			>
				{`Our rewards program is all about giving back to you every time you shop with us. It's simple: when you buy something, you earn points. These points can be used for exciting offers like discounts and special deals. To start collecting points, just download our app and sign up. Once you're in, every purchase adds points to your account. It's our way of saying thank you for shopping with us, making each buy a bit more special. So, download the app, sign up, and start turning your purchases into rewards!`}
			</Text>
		</>
	)
}
