import { DATABASE_NAME } from '@env'
import { collection, onSnapshot } from 'firebase/firestore'
import { db } from '../../firebase/config'
import '../../global'

export const getCuisines = () => {
	return new Promise((resolve, reject) => {
		const dataImport = {}
		dataImport['cuisines'] = []
		const cuisinesRef = collection(db, 'Cuisines')

		onSnapshot(
			cuisinesRef,
			snapshot => {
				snapshot.docChanges().forEach(change => {
					const data = change.doc.data()
					if (!data.isDeleted) {
						dataImport['cuisines'].push({
							id: change.doc.id,
							ordinal: data.ordinal,
							imageUrl: data.imageUrl,
							title: data.title || change.doc.id,
						})
					}
				})

				resolve(dataImport)
			},
			reject
		)
	})
}
