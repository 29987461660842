export const provinceList = [
	{ label: 'Alberta', value: 'AB' },
	{ label: 'British Columbia', value: 'BC' },
	{ label: 'Manitoba', value: 'MB' },
	{ label: 'New Brunswick', value: 'NB' },
	{ label: 'Newfoundland and Labrador', value: 'NL' },
	{ label: 'Northwest Territories', value: 'NT' },
	{ label: 'Nova Scotia', value: 'NS' },
	{ label: 'Nunavut', value: 'NU' },
	{ label: 'Ontario', value: 'ON' },
	{ label: 'Prince Edward Island', value: 'PE' },
	{ label: 'Quebec', value: 'QC' },
	{ label: 'Saskatchewan', value: 'SK' },
	{ label: 'Yukon', value: 'YT' },
]

// export const provinceList = [
// 	'ALABAMA',
// 	'ALASKA',
// 	'AMERICAN SAMOA',
// 	'ARIZONA',
// 	'ARKANSAS',
// 	'CALIFORNIA',
// 	'COLORADO',
// 	'CONNECTICUT',
// 	'DELAWARE',
// 	'DISTRICT OF COLUMBIA',
// 	'FLORIDA',
// 	'GEORGIA',
// 	'GUAM',
// 	'HAWAII',
// 	'IDAHO',
// 	'ILLINOIS',
// 	'INDIANA',
// 	'IOWA',
// 	'KANSAS',
// 	'KENTUCKY',
// 	'LOUISIANA',
// 	'MAINE',
// 	'MARYLAND',
// 	'MASSACHUSETTS',
// 	'MICHIGAN',
// 	'MINNESOTA',
// 	'MISSISSIPPI',
// 	'MISSOURI',
// 	'MONTANA',
// 	'NEBRASKA',
// 	'NEVADA',
// 	'NEW HAMPSHIRE',
// 	'NEW JERSEY',
// 	'NEW MEXICO',
// 	'NEW YORK',
// 	'NORTH CAROLINA',
// 	'NORTH DAKOTA',
// 	'NORTHERN MARIANA ISLANDS',
// 	'OHIO',
// 	'OKLAHOMA',
// 	'OREGON',
// 	'PENNSYLVANIA',
// 	'PUERTO RICO',
// 	'RHODE ISLAND',
// 	'SOUTH CAROLINA',
// 	'SOUTH DAKOTA',
// 	'TENNESSEE',
// 	'TEXAS',
// 	'U.S. VIRGIN ISLANDS',
// 	'UTAH',
// 	'VERMONT',
// 	'VIRGINIA',
// 	'WASHINGTON',
// 	'WEST VIRGINIA',
// 	'WISCONSIN',
// 	'WYOMING',
// 	'ALBERTA',
// 	'BRITISH COLUMBIA',
// 	'MANITOBA',
// 	'NEW BRUNSWICK',
// 	'NEWFOUNDLAND AND LABRADOR',
// 	'NORTHWEST TERRITORIES',
// 	'NOVA SCOTIA',
// 	'NUNAVUT',
// 	'ONTARIO',
// 	'PRINCE EDWARD ISLAND',
// 	'QUEBEC',
// 	'SASKATCHEWAN',
// 	'YUKON',
// 	'AB',
// 	'BC',
// 	'MB',
// 	'NB',
// 	'NL',
// 	'NT',
// 	'NS',
// 	'NU',
// 	'ON',
// 	'PE',
// 	'QC',
// 	'SK',
// 	'YT',
// 	'AL',
// 	'AK',
// 	'AS',
// 	'AZ',
// 	'AR',
// 	'CA',
// 	'CO',
// 	'CT',
// 	'DE',
// 	'DC',
// 	'FL',
// 	'GA',
// 	'GU',
// 	'HI',
// 	'ID',
// 	'IL',
// 	'IN',
// 	'IA',
// 	'KS',
// 	'KY',
// 	'LA',
// 	'ME',
// 	'MD',
// 	'MA',
// 	'MI',
// 	'MN',
// 	'MO',
// 	'MS',
// 	'MT',
// 	'NE',
// 	'NV',
// 	'NH',
// 	'NJ',
// 	'NM',
// 	'NY',
// 	'NC',
// 	'ND',
// 	'MP',
// 	'OH',
// 	'OK',
// 	'OR',
// 	'PA',
// 	'PR',
// 	'RI',
// 	'SC',
// 	'SD',
// 	'TN',
// 	'TX',
// 	'VI',
// 	'UT',
// 	'VT',
// 	'VA',
// 	'WA',
// 	'WV',
// 	'WI',
// 	'WY',
// ]
