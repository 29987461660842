import { useContext, useEffect } from 'react'
import { CheckoutScreenContext } from '../../../state/context'

const useIdleReset = ({ goHome }) => {
	const { orderPlaced, setTimer } = useContext(CheckoutScreenContext)

	useEffect(() => {
		if (orderPlaced && global.kiosk && global.idleReset && !global.pos) {
			const timerId = setTimeout(() => {
				goHome(true)
			}, 12000)

			setTimer(timerId)

			return () => {
				clearTimeout(timerId) // Clear the timer when the component unmounts or the timer is reset
			}
		}
	}, [orderPlaced])
}

export default useIdleReset
