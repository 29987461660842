import { DATABASE_NAME } from '@env'
import { doc, collection, onSnapshot } from 'firebase/firestore'
import { db } from '../../firebase/config'
import '../../global'

export function formatModifierLists(data: any) {
	return {
		id: data.id,
		max: data.max,
		min: data.min,
		name: data.name,
		restaurantId: data.restaurantId,
		selectionType: data.selectionType,
		variation: data.variation || false,
	}
}

export const getModifierListData = () => {
	return new Promise((resolve, reject) => {
		const dataImport = {}

		const modListRef = collection(doc(db, DATABASE_NAME, global.org), 'ModifierLists')

		onSnapshot(
			modListRef,
			snapshot => {
				snapshot.docChanges().forEach(change => {
					const data = change.doc.data()
					if (!data.isDeleted) {
						const modListReturn = formatModifierLists(data)
						dataImport[data.id] = modListReturn
					}
				})

				resolve(dataImport)
			},
			reject
		)
	})
}
