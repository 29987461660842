import { AntDesign, FontAwesome } from '@expo/vector-icons'
import MaterialIcons from '@expo/vector-icons/build/MaterialIcons'
import React from 'react'
import {
	View,
	StyleSheet,
	Pressable,
	PressableProps,
	TouchableOpacity,
	Dimensions,
} from 'react-native'
import '../global'
import { largeTextSize, xlTextSize } from '../constants/GlobalStyle'
import { Text } from './Themed'

const width = Dimensions.get('window').width

export type CustomButtonProps = {
	buttonStyle: object
	textStyle: object
	title: string
	isLoading: boolean
	icon: any
}

export type ButtonProps = CustomButtonProps & PressableProps

export default function Button(props: ButtonProps) {
	const { onPress, title, buttonStyle, textStyle, isLoading, icon } = props
	return (
		<Pressable style={buttonStyle} onPress={onPress}>
			<Text style={textStyle}>{title}</Text>
		</Pressable>
	)
}

const styles = StyleSheet.create({
	button: {
		alignItems: 'center',
		justifyContent: 'center',
		paddingVertical: 12,
		paddingHorizontal: 32,
		borderRadius: 4,
		elevation: 3,
		backgroundColor: 'black',
	},
	text: {
		fontSize: 16,
		lineHeight: 21,
		fontWeight: 'bold',
		letterSpacing: 0.25,
		color: 'white',
	},
	exitButton: {
		margin: 5,
		zIndex: 100,
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: '#ffffff',
		borderRadius: 50,
		width: 45,
		height: 45,
		shadowColor: '#000', // Shadow settings for iOS
		shadowOffset: {
			width: 0,
			height: 1,
		},
		shadowOpacity: 0.22,
		shadowRadius: 2.22,
		elevation: 3, // Shadow settings for Android
	},
	nextButtonContainer: {
		backgroundColor: 'black',
		flex: width > 800 ? 0.5 : 1,
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		paddingVertical: 20,
		borderRadius: 20,
		marginBottom: 30,
	},
	nextButtonText: {
		color: '#FFF',
		fontSize: largeTextSize,
		fontWeight: '500',
		marginRight: 40,
		textAlign: 'center',
	},
	nextIcon: {
		position: 'absolute',
		right: 30,
		marginLeft: 5,
	},
})
