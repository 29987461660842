import { Alert, Platform } from 'react-native'
import WebAlert from './alert'

export function alertResponse(title, body, button1, button2, style1, style2, func1, func2) {
	if (Platform.OS === 'web') {
		WebAlert.alert(title, body, [
			{
				text: button1,
				onPress: func1,
				style: style1,
			},
			{
				text: button2,
				onPress: func2,
				style: style2,
			},
		])
	} else {
		Alert.alert(title, body, [
			{
				text: button1,
				onPress: func1,
				style: style1,
			},
			{
				text: button2,
				onPress: func2,
				style: style2,
			},
		])
	}
}

export function alertResponseSingle(title, body, button1, style1, func1) {
	if (Platform.OS === 'web') {
		WebAlert.alert(title, body, [
			{
				text: button1,
				onPress: func1,
				style: style1,
			},
		])
	} else {
		Alert.alert(title, body, [
			{
				text: button1,
				onPress: func1,
				style: style1,
			},
		])
	}
}

export function alertResponseText(title, body) {
	if (Platform.OS === 'web') {
		WebAlert.alert(title, body)
	} else {
		Alert.alert(title, body)
	}
}
