export function formatRestaurantId(rId: string, loadedData: any) {
	if (rId) {
		if (rId.startsWith('category')) {
			const split = rId.split('_')
			return {
				id: rId,
				name: split[1] || '',
			}
		} else {
			return {
				id: rId,
				name: loadedData[rId] && loadedData[rId].name ? loadedData[rId].name : rId,
			}
		}
	}
	return {
		id: '',
		name: '',
	}
}
