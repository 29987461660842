import { Dimensions, StyleSheet } from 'react-native'
import Colors from '../../constants/Colors'
import GlobalStyle, {
	bigTextSize,
	largeTextSize,
	xlTextSize,
	IS_LANDSCAPE,
} from '../../constants/GlobalStyle'

const width = Dimensions.get('window').width
const height = Dimensions.get('window').height

export const styles = StyleSheet.create({
	container: {
		...GlobalStyle.whiteBg,
		paddingHorizontal: '5%',
	},
	homeContainer: {
		flex: 1,
		...GlobalStyle.whiteBg,
	},
	footer: {
		...GlobalStyle.flexRow,
		width: '100%',
		justifyContent: 'space-evenly',
		height: 60,
		alignItems: 'center',
		backgroundColor: Colors.custom.lightGreen,
	},
	smallImage: {
		width: 250,
		height: 250,
		resizeMode: 'contain',
	},
	socialImage: {
		width: 45,
		height: 45,
		resizeMode: 'contain',
	},
	welcomeText: {
		fontSize: bigTextSize,
		fontWeight: '900',
		color: Colors.greyscale[5],
		letterSpacing: 7,
	},
	subHeaderText: {
		fontSize: largeTextSize + 2,
		color: Colors.greyscale[6],
		letterSpacing: 3,
		fontWeight: '300',
		paddingVertical: IS_LANDSCAPE ? '5%' : '10%',
	},
	banner: {
		position: 'absolute',
		bottom: 3,
		width: '96%',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		zIndex: 100000,
		paddingVertical: 10,
		paddingHorizontal: 10,
		marginTop: 10,
		marginHorizontal: '2%',
		borderRadius: 5,
	},
	bannerText: {
		color: 'white',
		fontWeight: 'bold',
		textAlign: 'center',
		fontSize: 18,
		marginLeft: 5,
	},
	button: {
		backgroundColor: '#FFFFFF',
		paddingVertical: 10,
		paddingHorizontal: 20,
		borderRadius: 10,
		marginRight: 5,
	},
	buttonText: {
		fontSize: 18,
		fontWeight: 'bold',
	},
})
