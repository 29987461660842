import React, { useEffect, useRef, useState, forwardRef } from 'react'
import {
	View,
	StyleSheet,
	TouchableOpacity,
	Dimensions,
	Animated,
	FlatList,
	Platform,
} from 'react-native'
import { Text } from '../../../components/Themed'
import ImageObject from '../../../components/Image/ImageObject'
import Colors from '../../../constants/Colors'
import { SIDEBAR_WIDTH, mediumTextSize } from '../../../constants/GlobalStyle'
import { responsiveHeight, responsiveWidth } from 'react-native-responsive-dimensions'

const CategorySidebarRef = (props, ref) => {
	const {
		categories,
		onSelectCategory,
		selectedCategory,
		logo,
		style = {},
		horizontalScroll = false,
		onReturnToTop = null,
	} = props

	const translateY = useRef(new Animated.Value(0)).current
	const [movedUp, setMovedUp] = useState(false)

	useEffect(() => {
		if (!horizontalScroll) {
			if (
				categories.length > 0 &&
				(selectedCategory === categories[0] || selectedCategory === null)
			) {
				// Reset the sidebar position
				setMovedUp(false)
				Animated.timing(translateY, {
					toValue: 0,
					duration: 100,
					useNativeDriver: true,
				}).start()
			} else if (categories.length > 1 && !movedUp) {
				setMovedUp(true)
				Animated.timing(translateY, {
					toValue: -responsiveHeight(5),
					duration: 300,
					useNativeDriver: true,
				}).start()
			}
		}
	}, [selectedCategory])

	function shouldHighlight(category) {
		if (selectedCategory === category) {
			return true
		} else {
			return false
		}
	}

	function renderCategory(category, index) {
		const isSelected = shouldHighlight(category)
		const categoryStyle = horizontalScroll ? styles.horizontalCategory : styles.category
		const textStyles =
			isSelected && horizontalScroll ? styles.selectedCategoryText : styles.categoryText

		return (
			<View
				style={[
					horizontalScroll ? styles.horizontalItem : styles.sidebarItem,
					horizontalScroll && index === 0 && { paddingLeft: responsiveWidth(4.5) },
				]}
			>
				<View style={horizontalScroll ? {} : styles.separator} />
				<TouchableOpacity
					style={[categoryStyle, isSelected && !horizontalScroll ? styles.selectedCategory : {}]}
					onPress={() => {
						onSelectCategory(category)
					}}
				>
					<Text style={textStyles}>{category.toUpperCase()}</Text>
				</TouchableOpacity>
				<View
					style={
						horizontalScroll && isSelected
							? styles.underline
							: !horizontalScroll
							? styles.separator
							: {}
					}
				/>
			</View>
		)
	}

	// Render the category bar to move horizontally along the top
	// Not for large width screens
	if (horizontalScroll) {
		return (
			<>
				<Animated.View style={[styles.headerBar]}>
					<FlatList
						data={categories}
						horizontal={horizontalScroll}
						showsHorizontalScrollIndicator={false}
						renderItem={({ item, index }) => renderCategory(item, index)}
						onLayout={() => {
							if (ref) {
								ref.current?.scrollToIndex({
									index:
										categories.findIndex(item => item === selectedCategory) === -1
											? 0
											: categories.findIndex(item => item === selectedCategory),
									animated: true,
									viewOffset: 100,
								})
							}
						}}
						onScrollToIndexFailed={info => {
							const wait = new Promise(resolve => setTimeout(resolve, 500))
							wait.then(() => {
								ref.current?.scrollToIndex({ index: info.index, animated: true })
							})
						}}
						ref={ref}
					/>
				</Animated.View>
			</>
		)
	}

	const addedHeight = movedUp ? 0 : responsiveHeight(5)

	// Render the category bar to move vertically along the left-hand side
	// Not for small or medium width screens
	return (
		<>
			<Animated.View
				style={[
					styles.sidebarContainer,
					style,
					{
						paddingBottom: movedUp && Platform.OS !== 'web' ? 90 : 0,
						transform: [{ translateY }],
					}, //40 : 150
				]}
			>
				<TouchableOpacity onPress={onReturnToTop}>
					<ImageObject source={logo} style={styles.logo} />
				</TouchableOpacity>
				<FlatList
					style={[
						{
							height:
								Dimensions.get('window').height -
								(Dimensions.get('window').height * 0.2 + 190 + addedHeight),
						},
					]}
					showsVerticalScrollIndicator={false}
					data={categories}
					renderItem={({ item, index }) => renderCategory(item, index)}
					onLayout={() => {
						if (ref) {
							ref.current?.scrollToIndex({
								index:
									categories.findIndex(item => item === selectedCategory) === -1
										? 0
										: categories.findIndex(item => item === selectedCategory),
								animated: true,
								viewOffset: 100,
							})
						}
					}}
					onScrollToIndexFailed={info => {
						const wait = new Promise(resolve => setTimeout(resolve, 500))
						wait.then(() => {
							ref.current?.scrollToIndex({ index: info.index, animated: true })
						})
					}}
					ref={ref}
				/>
			</Animated.View>
			<View
				pointerEvents="none"
				style={{
					width: SIDEBAR_WIDTH,
					height: '100%',
					backgroundColor: 'white',
					position: 'absolute',
					left: 0,
					top: 0,
					bottom: 0,
					zIndex: -1,
					shadowColor: '#000',
					shadowOffset: { width: 1, height: 0 },
					shadowOpacity: 0.1,
					shadowRadius: 5,
					elevation: 10,
				}}
			/>
		</>
	)
}

const CategorySidebar = forwardRef(CategorySidebarRef)

export default CategorySidebar

const styles = StyleSheet.create({
	sidebarContainer: {
		width: 250,
		position: 'absolute',
		left: 0,
		top: Dimensions.get('window').height * 0.25 - Dimensions.get('window').height * 0.075 - 85, //header height - triangle height - half logo height
		zIndex: 0,
		bottom: 0,
	},
	sidebarItem: {
		width: SIDEBAR_WIDTH,
	},
	headerBar: {
		width: '100%',
		backgroundColor: 'white',
		shadowColor: '#000',
		shadowOffset: { width: 0, height: 3 },
		shadowOpacity: 0.05,
		shadowRadius: 2,
		elevation: 2,
		zIndex: 0,
	},
	headerBarShadowMask: {
		width: '100%',
		height: 2,
		backgroundColor: 'white',
		position: 'absolute',
		top: -2,
		zIndex: 1,
	},
	topCategory: {
		padding: 10,
		textTransform: 'uppercase',
	},
	category: {
		padding: 10,
		paddingVertical: 30,
		paddingLeft: 30,
		textTransform: 'uppercase',
	},
	selectedCategory: {
		backgroundColor: Colors.custom.appBackground,
		zIndex: 100000,
		width: 250,
		paddingRight: 20,
	},
	categoryText: {
		fontSize: mediumTextSize - 1,
		fontWeight: '400',
	},
	separator: {
		height: 1,
		backgroundColor: Colors.greyscale[4],
	},
	underline: {
		height: 1,
		backgroundColor: 'black',
		marginHorizontal: 3,
	},
	logo: {
		borderRadius: 20,
		width: 170,
		height: 170,
		alignSelf: 'center',
		marginVertical: 10,
		marginLeft: -20,
	},

	horizontalItem: {
		paddingHorizontal: 10,
		paddingVertical: 5,
	},
	horizontalCategory: {
		padding: 5,
		textTransform: 'uppercase',
	},
	selectedCategoryText: {
		fontWeight: 'bold',
		fontSize: mediumTextSize - 1,
	},
})
