import * as React from 'react'
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk'
import { SQUARE_LOCATION_ID, SQUARE_APPLICATION_ID } from '@env'
import { alertResponseSingle } from '../Alerts/Alerts'

export const SquarePaymentForm = ({ title, onPress }) => {
	return (
		<PaymentForm
			/**
			 * Identifies the calling form with a verified application ID generated from
			 * the Square Application Dashboard.
			 */
			applicationId={SQUARE_APPLICATION_ID}
			/**
			 * Invoked when payment form receives the result of a tokenize generation
			 * request. The result will be a valid credit card or wallet token, or an error.
			 */
			cardTokenizeResponseReceived={async (token, buyer) => {
				console.info({ token, buyer })
				try {
					await onPress(token.token)
				} catch (e) {
					alertResponseSingle('Error adding card', e.message, 'OK', null, null)
				}
			}}
			/**
			 * Identifies the location of the merchant that is taking the payment.
			 * Obtained from the Square Application Dashboard - Locations tab.
			 */
			locationId={SQUARE_LOCATION_ID}
		>
			<CreditCard>{title}</CreditCard>
		</PaymentForm>
	)
}
