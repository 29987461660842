import { doc, collection, onSnapshot } from 'firebase/firestore'
import { db } from '../../../firebase/config'
import '../../../global'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setLoadedData } from '../../../state/actions'
import { ReduxStoreState } from '../../../state/reducer'
import _ from 'lodash'
import { formatMenus } from '../getMenuData'
import { DATABASE_NAME } from '@env'

const useMenuListener = ({ setUpdateData }) => {
	const loadedData = useSelector<ReduxStoreState, ReduxStoreState['loadedData']>(
		state => state.loadedData
	)

	const [menuData] = useState({})

	const dispatch = useDispatch()

	useEffect(() => {
		const menuRef = collection(doc(db, DATABASE_NAME, global.org), 'Menus')

		const unsubscribe = onSnapshot(menuRef, snapshot => {
			snapshot.docChanges().forEach(change => {
				const data = change.doc.data()
				if (change.type === 'modified' && data !== undefined && !data.isDeleted) {
					const menuReturn = formatMenus(data)
					const key = 'menus_for_' + data.restaurantId
					if (!loadedData[key]) {
						menuData[key] = []
					} else {
						menuData[key] = loadedData[key]
					}

					const index = menuData[key].findIndex(i => i.id === data.id)
					if (index >= 0) {
						menuData[key].splice(index, 1)
					}

					menuData[key].push(menuReturn)
				}
			})

			const updatedData = _.merge({}, loadedData, menuData)
			dispatch(setLoadedData(updatedData))
			if (Object.keys(menuData).length > 0) {
				setUpdateData([])
			}
		})

		return () => {
			unsubscribe()
		}
	}, [])
}

export default useMenuListener
