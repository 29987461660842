import * as React from 'react'
import { Dimensions, Image, TouchableOpacity } from 'react-native'
import { Text } from '../../../components/Themed'
import GlobalStyle, { formStyle, largeTextSize } from '../../../constants/GlobalStyle'
import KioskPOSOption from './KioskPOSOption'

/**
 * Header with Logo & KIOSK + POS option on kiosk login screen
 */
export function Header({ selected, setSelected, isSignup, setIsSignup, clearState, isPOS }) {
	const useType = isPOS ? 'POS' : 'kiosk'
	return (
		<>
			<Image
				style={{
					alignSelf: 'center',
					width: Dimensions.get('window').width - 150,
					height: Dimensions.get('window').height * 0.1,
					marginTop: 60,
					marginBottom: 30,
				}}
				resizeMode="contain"
				source={require('../../../assets/images/ngkLogo.webp')}
			/>
			<KioskPOSOption selected={selected} setSelected={setSelected} clearState={clearState} />

			<Text
				style={[
					GlobalStyle.title,
					{
						fontSize: largeTextSize,
						alignSelf: 'center',
						marginTop: 30,
						marginBottom: 10,
						fontWeight: '700',
					},
				]}
			>
				{isSignup
					? `REGISTER A NEW ${useType.toUpperCase()} ACCOUNT`
					: `SIGN IN TO YOUR ${useType.toUpperCase()} ACCOUNT`}
			</Text>
			<TouchableOpacity
				onPress={() => {
					clearState()
					setIsSignup(!isSignup)
				}}
			>
				<Text
					style={[
						formStyle.link,
						{ alignSelf: 'center', marginBottom: useType !== 'POS' ? 25 : 0, fontSize: 18 },
					]}
				>
					{isSignup
						? `BACK TO SIGN IN`
						: useType !== 'POS'
						? `REGISTER A NEW ${useType.toUpperCase()}`
						: ''}
				</Text>
			</TouchableOpacity>
		</>
	)
}
