import { doc, collection, onSnapshot } from 'firebase/firestore'
import { db } from '../../../firebase/config'
import '../../../global'
import { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setLoadedData, setRestaurants } from '../../../state/actions'
import { ReduxStoreState } from '../../../state/reducer'
import { formatRestaurantData } from '../getRestaurantData'
import { DATABASE_NAME } from '@env'
import { DataContext } from '../../../state/context'
import _ from 'lodash'

const useRestaurantListener = ({ setReset }) => {
	const loadedData = useSelector<ReduxStoreState, ReduxStoreState['loadedData']>(
		state => state.loadedData
	)
	const restaurants = useSelector<ReduxStoreState, ReduxStoreState['restaurants']>(
		state => state.restaurants
	)

	const [restaurantData] = useState({})
	const dispatch = useDispatch()
	const { setUpdatedData } = useContext(DataContext)

	useEffect(() => {
		const restaurantRef = collection(doc(db, DATABASE_NAME, global.org), 'Restaurants')

		const unsubscribe = onSnapshot(restaurantRef, snapshot => {
			snapshot.docChanges().forEach(change => {
				const data = change.doc.data()
				if (change.type === 'modified' && data !== undefined && !data.isDeleted && data.isEnabled) {
					const restoData = formatRestaurantData(data)
					// console.log('inside restaurant listener', restoData.name)

					// Update restaurantData state
					restaurantData[data.id] = restoData
					const _ = require('lodash')

					const index = restaurants.findIndex(item => item.id === restoData.id)

					if (index !== -1) {
						restaurants[index] = restoData
					}

					const updatedData = _.merge({}, loadedData, restaurantData, {
						restaurants: restaurants,
					})

					dispatch(setRestaurants(restaurants))
					dispatch(setLoadedData(updatedData))
					setUpdatedData(updatedData)

					//rerender screen
					if (Object.keys(restaurantData).length > 0 && !_.isEqual(updatedData, loadedData)) {
						setReset([])
					}
				}
			})
		})

		// Clean up function
		return () => {
			unsubscribe()
		}
	}, [])
}

export default useRestaurantListener
