/**
 * Learn more about Light and Dark modes:
 * https://docs.expo.io/guides/color-schemes/
 */

import * as React from 'react'
import { Platform, Text as DefaultText, View as DefaultView } from 'react-native'
import Colors from '../constants/Colors'
import useColorScheme from '../hooks/useColorScheme'

export function useThemeColor(
	props: { light?: string; dark?: string },
	colorName: keyof typeof Colors.light & keyof typeof Colors.dark
) {
	const theme = useColorScheme()
	const colorFromProps = props[theme]

	if (colorFromProps) {
		return colorFromProps
	} else {
		return colorName === 'background' ? 'transparent' : 'black'
		// return Colors[theme][colorName]
	}
}

type ThemeProps = {
	lightColor?: string
	darkColor?: string
}

export type TextProps = ThemeProps & DefaultText['props']
export type ViewProps = ThemeProps & DefaultView['props']

export function Text(props: TextProps) {
	const { style, lightColor, darkColor, ...otherProps } = props
	const color = useThemeColor({ light: lightColor, dark: darkColor }, 'text')

	return (
		<DefaultText
			style={[
				{
					color,
					fontFamily: Platform.OS === 'web' ? 'System' : 'DefaultFont',
				},
				style,
			]}
			{...otherProps}
		/>
	)
}

export function View(props: ViewProps) {
	const { style, lightColor, darkColor, ...otherProps } = props
	return <DefaultView style={style} {...otherProps} />
}
