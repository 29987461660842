import React, { useState } from 'react'
import { RestaurantSelectScreenContext } from '../../../state/context'

const RestaurantSelectScreenProvider = ({ children }) => {
	const [restaurantsList, setRestaurantsList] = useState<any[]>([])
	const [dataLoading, setDataLoading] = useState(false)
	const [iDataLoading, setIDataLoading] = useState(true)
	const [mDataLoading, setMDataLoading] = useState(true)
	const [menuDataLoading, setMenuDataLoading] = useState(false)
	const [lDataLoading, setLDataLoading] = useState(true)
	const [rDataLoading, setRDataLoading] = useState(true)
	const [promoDataLoading, setPromoDataLoading] = useState(true)
	const [dataImport, setDataImport] = useState<any>({})
	const [allRestaurants, setAllRestaurants] = useState([])
	const [loading, setLoading] = useState(true)
	const [reset, setReset] = useState([])
	const [hoursMsg, setHoursMsg] = useState('')
	const [prepRange, setPrepRange] = useState([])
	const [conflictingCart, setConflictingCart] = useState(false)
	const [open, setOpen] = useState(false) //whether 'order now' 'order later' buttons are displayed
	const [restaurantData, setRestaurantData] = useState<any[]>([])
	const [allPrepTimes, setAllPrepTimes] = useState<any[]>([])
	const [orgTimezone, setOrgTimezone] = useState('')
	const [orgJump, setOrgJump] = useState(0)
	const [orgHours, setOrgHours] = useState([])
	const [hoursList, setHoursList] = useState([])
	const [orgHeader, setOrgHeader] = useState('')
	const [orgColor, setOrgColor] = useState('')
	const [rHours, setRHours] = useState<any>({
		0: { endTimes: [0], startTimes: [0] },
		1: { endTimes: [0], startTimes: [0] },
		2: { endTimes: [0], startTimes: [0] },
		3: { endTimes: [0], startTimes: [0] },
		4: { endTimes: [0], startTimes: [0] },
		5: { endTimes: [0], startTimes: [0] },
		6: { endTimes: [0], startTimes: [0] },
	})

	return (
		<RestaurantSelectScreenContext.Provider
			value={{
				restaurantsList,
				setRestaurantsList,
				dataLoading,
				setDataLoading,
				iDataLoading,
				setIDataLoading,
				mDataLoading,
				setMDataLoading,
				menuDataLoading,
				setMenuDataLoading,
				lDataLoading,
				setLDataLoading,
				rDataLoading,
				setRDataLoading,
				promoDataLoading,
				setPromoDataLoading,
				dataImport,
				setDataImport,
				allRestaurants,
				setAllRestaurants,
				loading,
				setLoading,
				reset,
				setReset,
				hoursMsg,
				setHoursMsg,
				prepRange,
				setPrepRange,
				conflictingCart,
				setConflictingCart,
				open,
				setOpen,
				restaurantData,
				setRestaurantData,
				allPrepTimes,
				setAllPrepTimes,
				orgTimezone,
				setOrgTimezone,
				orgJump,
				setOrgJump,
				orgHours,
				setOrgHours,
				hoursList,
				setHoursList,
				orgHeader,
				setOrgHeader,
				orgColor,
				setOrgColor,
				rHours,
				setRHours,
			}}
		>
			{children}
		</RestaurantSelectScreenContext.Provider>
	)
}

export default RestaurantSelectScreenProvider
