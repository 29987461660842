import { parsePrepTime } from '../../../helpers/prepTimeFunctions'
import { add, intervalToDuration } from 'date-fns'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../state/reducer'
import { utcToZonedTime } from 'date-fns-tz'
import { CheckoutScreenContext, DataContext } from '../../../state/context'
import { useContext } from 'react'

export const useSchedInteval = () => {
	const schedDuration = useSelector<ReduxStoreState, ReduxStoreState['schedDuration']>(
		state => state.schedDuration
	)
	const schedCurrTime = useSelector<ReduxStoreState, ReduxStoreState['schedCurrTime']>(
		state => state.schedCurrTime
	)
	const localTimezone = useSelector<ReduxStoreState, ReduxStoreState['localTimezone']>(
		state => state.localTimezone
	)
	const { defPrep } = useContext(DataContext)

	function getSchedInterval(isCheck) {
		var prepReturn = ''
		var valid = true
		if (schedDuration !== '') {
			var parsed = parsePrepTime(schedDuration)
			var pickupTime = add(new Date(schedCurrTime), parsed)

			if (utcToZonedTime(new Date(), localTimezone) >= pickupTime) {
				valid = false
			} else {
				var adjustedPickupDuration = intervalToDuration({
					start: utcToZonedTime(new Date(), localTimezone),
					end: pickupTime,
				})

				prepReturn =
					'P' +
					adjustedPickupDuration.days +
					'DT' +
					adjustedPickupDuration.hours +
					'H' +
					adjustedPickupDuration.minutes +
					'M' +
					adjustedPickupDuration.seconds +
					'S'
			}
		} else {
			prepReturn = 'P0DT0H' + defPrep + 'M0S'
		}

		return isCheck ? valid : prepReturn
	}

	return { getSchedInterval }
}
