import React from 'react'
import { View, Dimensions, SafeAreaView } from 'react-native'
import { SharpTriangle } from './Triangle'
import Colors from '../../constants/Colors'
import ImageObject from '../Image/ImageObject'
import DeliveryPickupToggle from '../Delivery/DeliveryPickupToggle'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
export const OrgLogoHeader = ({ logo, padding = 50 }) => {
	const height = Dimensions.get('window').height
	let add = 0
	if (height < 1200) {
		add = 20
	}

	const insets = useSafeAreaInsets()

	if (global.kiosk) {
		return (
			<>
				<View
					style={{
						height: height * 0.25 + 500,
						marginTop: -500, //handles overscrolling
						backgroundColor: global.orgColor ? global.orgColor : Colors.custom.restaurantMain,
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'flex-start',
						marginLeft: 0,
					}}
				>
					<ImageObject
						hideDefault={true}
						source={logo}
						style={{
							//width: height * 0.18,
							width: '100%',
							maxWidth: 300,
							height: height * 0.18,
							marginTop: height * -0.05 + 500,
							marginLeft: '5%',
						}}
					/>
				</View>
				<View style={{ backgroundColor: Colors.custom.appBackground, paddingBottom: 50 }}>
					<SharpTriangle />
				</View>
			</>
		)
	} else {
		return (
			<>
				<View
					style={{
						height: height * 0.22 + 500,
						marginTop: -500, //handles overscrolling
						backgroundColor: global.orgColor ? global.orgColor : Colors.custom.restaurantMain,
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'flex-start',
						marginLeft: 0,
					}}
				>
					<ImageObject
						hideDefault={true}
						source={logo}
						style={{
							height: Dimensions.get('window').width * 0.2,
							width: '100%',
							maxWidth: 240,
							maxHeight: 120,
							marginTop: height * -0.09 + 500 + insets.top + 2,
							marginLeft: '5%',
						}}
					/>
					<View
						style={{
							marginTop: height * -0.09 + 500 + insets.top + 2,
							position: 'absolute',
							top: '15%',
							right: '3.5%',
						}}
					>
						<DeliveryPickupToggle textColor={'white'} homePage={true} />
					</View>
				</View>
				<View style={{ paddingBottom: padding, backgroundColor: Colors.custom.appBackground }}>
					<SharpTriangle />
				</View>
			</>
		)
	}
}
