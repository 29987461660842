import React from 'react'
import { StyleSheet, TouchableOpacity, Platform, Dimensions } from 'react-native'
import { AntDesign, Ionicons } from '@expo/vector-icons'
import GlobalStyle, {
	SMALL_WIDTH,
	headerTextSize,
	largeTextSize,
} from '../../constants/GlobalStyle'
import Constants from 'expo-constants'
import { Text } from '../Themed'

const ExitIcon = ({ navigation = null, style = {}, onPress = null }) => {
	if (navigation) {
		return (
			<TouchableOpacity
				style={[styles.exitContainer, style]}
				activeOpacity={0.96}
				onPress={() => {
					if (navigation.canGoBack()) {
						navigation.goBack()
					}
				}}
			>
				<AntDesign
					name="close"
					size={headerTextSize}
					color={'white'}
					style={{
						textShadowColor: 'rgba(0, 0, 0, 0.75)',
						textShadowRadius: 6,
					}}
				/>
			</TouchableOpacity>
		)
	} else if (onPress) {
		return (
			<TouchableOpacity
				style={[styles.exitContainer, style]}
				activeOpacity={0.96}
				onPress={onPress}
			>
				<AntDesign
					name="close"
					size={headerTextSize}
					color={'white'}
					style={{
						textShadowColor: 'rgba(0, 0, 0, 0.75)',
						textShadowRadius: 6,
					}}
				/>
			</TouchableOpacity>
		)
	} else {
		return <></>
	}
}

export const SearchIcon = ({ toggle }) => {
	return (
		<TouchableOpacity
			style={[
				styles.exitContainer,
				!global.singleBrandEnabled && { right: 20 + headerTextSize * 1.35 },
			]}
			activeOpacity={0.96}
			onPress={toggle}
		>
			<Ionicons
				name="ios-search-outline"
				size={headerTextSize * 0.9}
				color={'white'}
				style={{
					textShadowColor: 'rgba(0, 0, 0, 0.75)',
					textShadowRadius: 6,
				}}
			/>
		</TouchableOpacity>
		// <TouchableOpacity
		// 	style={[styles.container, { right: 20 + 10 + headerTextSize * 1.35 }]}
		// 	activeOpacity={0.96}
		// 	onPress={toggle}
		// >
		// 	<Ionicons name="ios-search-outline" size={headerTextSize / 1.45} color={'white'} />
		// </TouchableOpacity>
	)
}

export const NavButton = ({ onPress, color, title, style = {} }) => {
	return (
		<TouchableOpacity
			style={[
				style,
				GlobalStyle.v2ButtonRightArrow,
				{
					backgroundColor: color,
				},
			]}
			onPress={onPress}
		>
			<Text style={[styles.buttonText, { marginRight: 0 }]}>{title}</Text>
		</TouchableOpacity>
	)
}

export const ForwardButton = ({ onPress, color, title, style = {}, opacity = 0.6 }) => {
	return (
		<TouchableOpacity
			activeOpacity={opacity}
			style={[
				style,
				GlobalStyle.v2ButtonRightArrow,
				{
					backgroundColor: color,
					paddingHorizontal: 10,
				},
			]}
			onPress={onPress}
		>
			<Text
				style={[
					styles.buttonText,
					{ marginLeft: 10, color: color === 'white' ? 'black' : 'white' },
				]}
			>
				{title}
			</Text>
			<AntDesign name="right" size={largeTextSize - 3} color="white" />
		</TouchableOpacity>
	)
}

const styles = StyleSheet.create({
	container: {
		position: 'absolute',
		top: Platform.OS === 'ios' ? 45 : Platform.OS === 'android' ? Constants.statusBarHeight : 20,
		right: 20,
		backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black
		borderRadius: (headerTextSize * 1.35) / 2,
		width: headerTextSize * 1.35,
		height: headerTextSize * 1.35,
		alignItems: 'center',
		justifyContent: 'center',
		zIndex: 1000,
		shadowColor: 'black',
		shadowOpacity: 0.5,
		shadowRadius: 2,
	},
	exitContainer: {
		position: 'absolute',
		top:
			Platform.OS === 'ios' && SMALL_WIDTH
				? 45
				: Platform.OS === 'android'
				? Constants.statusBarHeight
				: 20,
		right: 20,
		width: headerTextSize * 1.35,
		height: headerTextSize * 1.35,
		alignItems: 'center',
		justifyContent: 'center',
		zIndex: 1000,
	},
	buttonText: {
		color: 'white',
		fontWeight: '400',
		fontSize: largeTextSize,
		marginRight: 15,
	},
})

export default ExitIcon
