import * as React from 'react'
import { View } from 'react-native'

type SpacerProps = {
	size: number
}

export function Spacer(props: SpacerProps) {
	const { size } = props
	return <View style={{ height: size }} />
}
