import * as React from 'react'
import { View, Image } from 'react-native'
import GlobalStyle from '../constants/GlobalStyle'
import ImageObject from './Image/ImageObject'
import { ViewProps } from './Themed'
import { DataContext } from '../state/context'

export function OrganizationLogoHeader(props) {
	const { style, imageUri } = props
	if (imageUri !== '') {
		return (
			<View style={[GlobalStyle.orgLogoContainer, style]}>
				<ImageObject source={imageUri} style={GlobalStyle.orgLogo} hideDefault={true} />
			</View>
		)
	} else {
		return (
			<View style={[GlobalStyle.orgLogoContainer, style]}>
				<View style={GlobalStyle.orgLogo} />
			</View>
		)
	}
}

export function OrganizationLogoAddressPage(props) {
	const { imageUri } = props
	const { transparentLogo } = React.useContext(DataContext)
	if (imageUri !== '') {
		return (
			<View style={[GlobalStyle.orgLogoAcctContainer, { height: 150, marginTop: 20 }]}>
				<View
					style={[
						{ borderRadius: 150, flex: 1 },
						transparentLogo && { backgroundColor: global.orgColor },
					]}
				>
					<ImageObject source={imageUri} style={{ width: 150, flex: 1 }} />
				</View>
			</View>
		)
	} else {
		return (
			<View style={[GlobalStyle.orgLogoAcctContainer, { height: 150 }]}>
				<Image source={require('../assets/images/menuLogo.png')} style={{ width: 150, flex: 1 }} />
			</View>
		)
	}
}

export function OrganizationLogoAccountPage(props) {
	const { style, imageUri } = props
	const { transparentLogo } = React.useContext(DataContext)

	if (imageUri !== '') {
		return (
			<View style={[GlobalStyle.orgLogoAcctContainer, style]}>
				<View
					style={[
						{ borderRadius: 150, flex: 1 },
						transparentLogo && { backgroundColor: global.orgColor },
					]}
				>
					<ImageObject source={imageUri} style={{ width: 220, flex: 1 }} />
				</View>
			</View>
		)
	} else {
		return (
			<View style={[GlobalStyle.orgLogoAcctContainer, style]}>
				<Image source={require('../assets/images/menuLogo.png')} style={{ width: 220, flex: 1 }} />
			</View>
		)
	}
}

export function OrganizationLogoHeaderNoCaption(props: ViewProps) {
	const { style } = props
	return (
		<View style={[GlobalStyle.orgLogoContainer, style]}>
			<Image source={require('../assets/images/menuLogo.png')} style={GlobalStyle.orgLogo} />
		</View>
	)
}
