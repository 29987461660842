const tintColorLight = '#2f95dc'
const tintColorDark = '#fff'

const restaurantMain = '#1C2B54' //ngk color
const restaurantMainText = 'white'
const black = '#000'
const white = '#fff'
const shadeWhite = '#f8f8f8'
const darkGrey = '#3b3b3b'
const mediumGrey = '#555555'
const darkGreen = '#455b46'
const mediumGreen = '#8DC63F' //signup/login buttons
const lightGreen = '#b8c792'
const paleYellow = '#ffffbb'
const lightOrange = '#fdd988'
const mediumOrange = '#ffaf62'
const darkOrange = '#603B1B'
const cream = '#faf4f4'
const superLightCream = '#fffcfc'
const lightGrey = '#f7f7f7'
const pinkRed = '#d4022c'
const transparentGrey = 'rgba(72, 72, 72, 0.3)'
const superLightGrey = '#f5f5f5'
const ngkMain = '#1A408C'

export default {
	greyscale: {
		10: '#1c1c1c',
		9: '#262626',
		8: '#4D4D4D',
		7: '#818181',
		6: '#848484',
		5: '#a6a6a6',
		4: '#D7D7D7',
		3: '#DEDEDE',
		2: '#EBEBEB',
		1: '#FAFAFA',
	},
	light: {
		text: '#000',
		background: '#fff',
		tint: white,
		tabIconDefault: '#ccc',
		tabIconSelected: tintColorLight,
	},
	dark: {
		text: '#fff',
		background: '#000',
		tint: tintColorDark,
		tabIconDefault: '#ccc',
		tabIconSelected: tintColorDark,
	},
	custom: {
		restaurantMain: restaurantMain,
		black: black,
		white: white,
		appBackground: shadeWhite,
		darkGreen: darkGreen,
		mediumGreen: mediumGreen,
		lightGreen: lightGreen,
		paleYellow: paleYellow,
		lightOrange: lightOrange,
		mediumOrange: mediumOrange,
		darkOrange: darkOrange,
		cream: cream,
		darkGrey: darkGrey,
		lightGrey: lightGrey,
		pinkRed: pinkRed,
		transparentGrey: transparentGrey,
		superLightCream: superLightCream,
		superLightGrey: superLightGrey,
		mediumGrey: mediumGrey,
		restaurantMainText: restaurantMainText,
		ngkMain: ngkMain,
	},
}
