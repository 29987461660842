import '../../../global'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setLoadedData } from '../../../state/actions'
import { ReduxStoreState } from '../../../state/reducer'
import { formatMenuItems } from '../getMenuItemData'
import { DATABASE_NAME } from '@env'
import { doc, collection, onSnapshot } from 'firebase/firestore'
import { db } from '../../../firebase/config'
import _ from 'lodash'

const useMenuItemListener = ({ setUpdateData }) => {
	const loadedData = useSelector<ReduxStoreState, ReduxStoreState['loadedData']>(
		state => state.loadedData
	)
	const [itemData] = useState({})
	const dispatch = useDispatch()

	useEffect(() => {
		const menuItemRef = collection(doc(db, DATABASE_NAME, global.org), 'MenuItems')
		const unsubscribe = onSnapshot(menuItemRef, snapshot => {
			snapshot.docChanges().forEach(change => {
				const data = change.doc.data()
				if (!data || data.isDeleted) return

				if (change.type === 'modified') {
					const itemReturn = formatMenuItems(data)
					console.log(itemReturn.name)
					if (itemReturn.thumbnailUri === '' && itemReturn.imageUri !== '') {
						itemReturn.thumbnailUri = itemReturn.imageUri
					}

					itemData[data.id] = itemReturn
					const updatedData = _.merge({}, loadedData, itemData)
					dispatch(setLoadedData(updatedData))
					//rerender screen
					if (Object.keys(itemData).length > 0 && !_.isEqual(updatedData, loadedData)) {
						setUpdateData([])
					}
				}
			})
		})

		// Clean up function
		return () => {
			unsubscribe()
		}
	}, [])
}

export default useMenuItemListener
