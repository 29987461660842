import React from 'react'
import { TouchableOpacity, StyleSheet } from 'react-native'
import Colors from '../../../constants/Colors'
import { smallTextSize } from '../../../constants/GlobalStyle'
import { Text } from '../../../components/Themed'

export default function SignInButton({ onPress, text }) {
	return (
		<TouchableOpacity style={styles.button} onPress={onPress}>
			<Text style={styles.buttonText}>{text}</Text>
		</TouchableOpacity>
	)
}

const styles = StyleSheet.create({
	button: {
		backgroundColor: Colors.custom.ngkMain, // Green color for the button
		paddingVertical: 12,
		borderRadius: 25, // Rounded corners
		alignItems: 'center',
		justifyContent: 'center',
		marginHorizontal: 20,
	},
	buttonText: {
		color: '#FFFFFF',
		fontSize: smallTextSize,
		fontWeight: 'bold',
	},
})
