import * as React from 'react'
import { TouchableOpacity, ScrollView, Linking, Image, Platform } from 'react-native'
import { Text, View } from '../../components/Themed'
import { RootStackParamList } from '../../navigation/types'
import { auth } from '../../firebase/config'
import { useContext, useEffect, useState } from 'react'
import LoginScreen from '../Login'
import GlobalStyle, {
	MAX_CHECKOUT_WIDTH,
	SMALL_WIDTH,
	smallTextSize,
} from '../../constants/GlobalStyle'
import SignupScreen from '../Signup'
import { Section } from '../../components/ListSection'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxStoreState } from '../../state/reducer'
import { MenuProvider } from 'react-native-popup-menu'
import { useRoute, RouteProp } from '@react-navigation/native'
import { alertResponse } from '../../components/Alerts/Alerts'
import { setPaymentMethodId, setStripeId, setUserId } from '../../state/actions'
import Colors from '../../constants/Colors'
import { TriangleHeader } from '../../components/Headers/TriangleHeader'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { onAuthStateChanged } from 'firebase/auth'
import ExitIcon from '../../components/Headers/UIButtons'
import RNRestart from 'react-native-restart'
import { DataContext } from '../../state/context'
import OrderBanner from '../../components/Delivery/OrderBanner'
import { getUserData } from '../../helpers/fbDataRetrieval/getUserData'
import { getOrgData } from '../../helpers/fbDataRetrieval/getOrgData'

export default function AccountScreen({ navigation }) {
	const onCheckout = useRoute<RouteProp<RootStackParamList, 'TabAccount'>>().params
		? useRoute<RouteProp<RootStackParamList, 'TabAccount'>>().params.onCheckout
		: false

	const returnTo = useRoute<RouteProp<RootStackParamList, 'TabAccount'>>().params
		? useRoute<RouteProp<RootStackParamList, 'TabAccount'>>().params.returnTo
		: null

	const loyaltyId = useRoute<RouteProp<RootStackParamList, 'TabAccount'>>().params
		? useRoute<RouteProp<RootStackParamList, 'TabAccount'>>().params.loyaltyId
		: null

	const route = useRoute<RouteProp<any>>() // Replace with the actual screen name
	const signUp = route.params?.signup || false
	const [user, setUser] = useState<any>(null)
	//const [displayName, setDisplayName] = useState('')
	const [isUser, setIsUser] = useState(false)
	const [isSigningUp, setIsSigningUp] = useState(signUp)
	const [spinner, setSpinner] = useState(false)
	const [referralCode, setReferralCode] = useState('')
	const [referralText, setReferralText] = useState(false)
	const [isModalVisible, setModalVisible] = useState(false)

	const referredCode = useSelector<ReduxStoreState, ReduxStoreState['referralCode']>(
		state => state.referralCode
	)

	const loadedData = useSelector<ReduxStoreState, ReduxStoreState['loadedData']>(
		state => state.loadedData
	)

	const organizationLogo = useSelector<ReduxStoreState, ReduxStoreState['organizationLogo']>(
		state => state.organizationLogo
	)

	const {
		setUserDisplayName,
		userDisplayName,
		setUserData,
		setUserLocation,
		setPaymentMethods,
		setIsCardValid,
	} = useContext(DataContext)

	const toggleModal = () => {
		setModalVisible(!isModalVisible)
	}

	const dispatch = useDispatch()

	const functions = getFunctions()

	useEffect(() => {
		if (referredCode !== '' && referredCode !== undefined) {
			setIsSigningUp(true)
			setReferralText(true)
		}
		const unsubscribe = navigation.addListener('focus', async () => {
			onAuthStateChanged(auth, async user => {
				setIsCardValid(false)
				if (user) {
					console.log('user signed in', user.uid)
					const userInterval = setInterval(async () => {
						const data = await getUserData(user.uid)
						// console.log('new user data', data)
						if (Object.keys(data).length) {
							setUserData(data)
							clearInterval(userInterval)
						}
					}, 1000)
					setUser(user)
				} else {
					setUserData(null)
					setSpinner(false)
					setIsUser(false)
					setUserDisplayName('')
					setReferralCode('')
					loadedData['user'] = undefined
				}
			})
		})
		return () => {
			unsubscribe()
		}
	}, [])

	async function getDBUser() {
		const getUserInDB = httpsCallable(functions, 'getUserInDB')
		console.log(auth.currentUser.uid)
		const user = await getUserInDB({
			orgId: global.org,
			userId: auth.currentUser.uid,
		})

		if (user.data !== null && auth.currentUser && auth.currentUser.uid && user.data !== undefined) {
			console.log('User found.')
			setSpinner(false)
			setIsUser(true)

			const data = user.data as any
			if (data.referralCode) {
				setReferralCode(data.referralCode)
			}
			dispatch(setStripeId(data.stripeId))
			dispatch(setUserId(data.uid))
			if (data.displayName) {
				setUserDisplayName(data.displayName)
			} else {
				setUserDisplayName(`${data.firstName} ${data.lastName}`)
			}
			return user.data
		} else {
			console.log('User was not found.')
			return null
		}
	}

	useEffect(() => {
		let userData
		const fetchDBUser = async () => {
			if (auth.currentUser) {
				setSpinner(true)

				if (!isSigningUp) {
					console.log('Getting user.')
					userData = await getDBUser()
					if (userData === null) {
						auth.signOut()
					}
				} else {
					let i = 25
					const interval = setInterval(async () => {
						if (i > 0 && auth.currentUser) {
							userData = await getDBUser()
						}
						if (userData !== null || i <= 0) {
							clearInterval(interval)
							setSpinner(false)
						}
						i--
					}, 1600)
				}
			}
		}
		if (Platform.OS !== 'web') {
			fetchDBUser()
		} else if (auth.currentUser && isSigningUp) {
			navFunction()
		}
	}, [user])

	function paymentMethodButton() {
		return (
			<TouchableOpacity onPress={() => navigation.navigate('Wallet')}>
				<Section name={'Payment Methods'} />
			</TouchableOpacity>
		)
	}

	function backFunction() {
		navigation.navigate('HomeStack', { screen: 'RestaurantSelect' })
	}

	function navFunction() {
		if (returnTo) {
			if (loyaltyId) {
				console.log(loyaltyId)

				navigation.navigate(returnTo, { id: loyaltyId })
			} else {
				navigation.navigate(returnTo)
			}
		} else if (onCheckout) {
			navigation.navigate('TabCheckout')
		} else {
			navigation.navigate('HomeStack', { screen: 'RestaurantSelect' })
		}
	}

	function displayUserDataOrLogin() {
		var earnAmount = 10
		var earnType = '$'
		var earnString = '$10'

		var makeAmount = 10
		var makeType = '$'
		var makeString = '$10'
		if (loadedData[global.org] && loadedData[global.org].referralAmount) {
			earnAmount = loadedData[global.org].referralAmount.amount / 100
			earnType = loadedData[global.org].referralAmount.type === 'percent' ? '%' : '$'
		}
		if (earnType === '$') {
			earnString = earnType + earnAmount
		} else {
			earnString = earnAmount + earnType
		}

		if (loadedData[global.org] && loadedData[global.org].referrerAmount) {
			makeAmount = loadedData[global.org].referrerAmount.amount
			makeType = loadedData[global.org].referrerAmount.type === 'percent' ? '%' : '$'
		}
		if (makeType === '$') {
			makeString = makeType + makeAmount
		} else {
			makeString = makeAmount + makeType
		}
		const referralEnabled: boolean =
			loadedData[global.org] && loadedData[global.org].referralEnabled !== undefined
				? loadedData[global.org].referralEnabled
				: false

		if (isUser) {
			return (
				<>
					<OrderBanner navigation={navigation} />

					<TriangleHeader
						title={global.pos ? 'POS ACCOUNT' : 'MY ACCOUNT'}
						backFunction={Platform.OS === 'web' ? backFunction : null}
						navigation={navigation}
						logo={organizationLogo}
					/>
					<View
						style={[
							GlobalStyle.container,
							{
								marginTop: Platform.OS === 'web' ? 0 : -50,
								display: spinner ? 'none' : 'flex',
								backgroundColor: Colors.custom.appBackground,
							},
						]}
					>
						<Text
							style={[
								GlobalStyle.title,
								{ marginLeft: 15, color: Colors.custom.mediumGrey, marginBottom: 15 },
							]}
						>
							{userDisplayName}
						</Text>

						<ScrollView>
							<MenuProvider>
								<View>
									<View>
										<TouchableOpacity onPress={toggleModal}></TouchableOpacity>
										{referralEnabled && !global.pos ? (
											<TouchableOpacity
												onPress={() =>
													navigation.navigate('Refer', {
														referralCode: referralCode,
														referral: earnString,
														referrer: makeString,
													})
												}
											>
												<Section name={'Refer a Friend, earn ' + makeString} />
											</TouchableOpacity>
										) : (
											<></>
										)}

										{!global.pos && paymentMethodButton()}

										<TouchableOpacity onPress={() => navigation.navigate('Settings')}>
											<Section name={'Account Settings'} />
										</TouchableOpacity>
										<TouchableOpacity
											onPress={() =>
												Linking.openURL('https://nextgenkitchens.com/app-privacy-policy/')
											}
										>
											<Section name={'Privacy Policy'} />
										</TouchableOpacity>
										<TouchableOpacity
											style={{ borderBottomWidth: 0.6 }}
											onPress={() =>
												alertResponse(
													'Confirm Sign Out',
													'Are you sure you want to sign out?',
													'Cancel',
													'Sign Out',
													'cancel',
													'default',
													() => {
														return
													},
													() => {
														auth.signOut()
														dispatch(setUserId(''))
														dispatch(setStripeId(''))
														dispatch(setPaymentMethodId(''))
														setUser(null)
														setUserLocation(null)
														setUserData(null)
														setPaymentMethods([])
														if (global.pos) {
															RNRestart.Restart()
														}
													}
												)
											}
										>
											<Section name={'Sign Out'} />
										</TouchableOpacity>
									</View>
								</View>
							</MenuProvider>
						</ScrollView>
					</View>
					<View style={{ display: spinner ? 'flex' : 'none' }}>
						<Image
							style={{
								width: 100,
								height: 100,
								alignSelf: 'center',
								marginTop: -50,
							}}
							source={require('../../assets/images/loadImg.gif')}
						/>
					</View>
				</>
			)
		} else if (!isUser && !isSigningUp) {
			return (
				<View style={[GlobalStyle.whiteBg, { flex: 1 }]}>
					{!spinner ? (
						<LoginScreen
							onStateChange={setIsSigningUp}
							onCheckout={onCheckout}
							navigation={navigation}
							returnTo={returnTo}
							loyaltyId={loyaltyId}
						/>
					) : null}
					<View
						style={{
							display: spinner ? 'flex' : 'none',
							flex: 1,
							justifyContent: 'center',
							alignSelf: 'center',
						}}
					>
						{/* <TriangleHeader title={'MY ACCOUNT'} backFunction={backFunction} /> */}
						<Image
							style={{
								width: 100,
								height: 100,
							}}
							source={require('../../assets/images/loadImg.gif')}
						/>
						<Text
							style={{
								fontSize: smallTextSize,
								color: Colors.greyscale[7],
								marginTop: -15,
								textAlign: 'center',
							}}
						>
							Loading user...
						</Text>
					</View>
				</View>
			)
		} else if (!isUser && isSigningUp) {
			return (
				<View style={[GlobalStyle.whiteBg, { flex: 1 }]}>
					{!spinner ? (
						<SignupScreen
							onStateChange={setIsSigningUp}
							onCheckout={onCheckout}
							navigation={navigation}
							kioskNum={undefined}
							kioskPass={undefined}
							kioskFunction={undefined}
							isPOS={false}
							returnTo={returnTo}
							loyaltyId={loyaltyId}
						/>
					) : null}

					<View style={{ display: spinner ? 'flex' : 'none' }}>
						<Image
							style={{
								width: 100,
								marginTop: '100%',
								height: 100,
								alignSelf: 'center',
								justifyContent: 'center',
							}}
							source={require('../../assets/images/loadImg.gif')}
						/>
					</View>
				</View>
			)
		}
	}

	return (
		<>
			<View
				style={{
					flex: 1,
					justifyContent: 'center',
					alignItems: 'center',
					backgroundColor: 'rgba(0,0,0,0.5)', // semi-transparent background
				}}
			>
				<View
					style={{
						overflow: 'hidden',
						maxWidth: MAX_CHECKOUT_WIDTH - 200,
						width: !SMALL_WIDTH ? '90%' : '100%',
						height: !SMALL_WIDTH ? '90%' : '100%',
						backgroundColor: 'white',
						borderRadius: !SMALL_WIDTH ? 10 : 0,
					}}
				>
					{!SMALL_WIDTH && (
						<ExitIcon
							onPress={async () => {
								navFunction()
							}}
						/>
					)}
					{displayUserDataOrLogin()}
				</View>
			</View>
		</>
	)
}
