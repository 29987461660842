import { parsePrepTime } from '../../helpers/prepTimeFunctions'
import { add, format } from 'date-fns'

export function pickupText(
	schedTime,
	schedDuration,
	storeOpen,
	pickupMessage,
	prepTime,
	showPickupOn = true,
	deliveryTime = null
) {
	if (!storeOpen) {
		return 'This store is closed and not accepting new orders.'
	} else {
		if (pickupMessage == 'Pickup in -1 minutes') {
			if (prepTime === 0) {
				return 'Pickup Now'
			} else {
				//return 'Pickup in ' + prepTime + ' minutes'
				return `${
					deliveryTime !== null
						? 'Delivery in ' + deliveryTime + ' minutes'
						: 'Pickup in ' + prepTime + ' minutes'
				}`
			}
		} else if (schedDuration !== '' && schedDuration !== undefined) {
			const parsed = parsePrepTime(schedDuration)
			const pickupTime = add(new Date(schedTime), {
				days: parsed.days,
				minutes: parsed.minutes,
				hours: parsed.hours,
				seconds: parsed.seconds,
			})
			const time = format(pickupTime, "EEE, LLL d 'at' h:mm aaa")
			return showPickupOn ? `${deliveryTime !== null ? 'Delivery at' : 'Pickup on'} ${time}` : time
		} else {
			return pickupMessage
		}
	}
}
