import React from 'react'
import Svg, { Circle, Path } from 'react-native-svg'

const ChefHatIcon = props => {
	return (
		<Svg width="26" height="25" viewBox="0 0 26 25" fill="none">
			<Circle cx="13.5" cy="12.5" r="11" stroke={props.color} strokeWidth="3" />
			<Path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.91528 3.01056C8.97774 3.17784 8.25644 3.46345 7.38986 4.01057C5.7745 5.03039 4.45954 6.88046 3.98417 8.80215C3.72996 9.82992 3.71774 10.0152 3.71802 12.8499C3.71823 15.1483 3.72651 15.484 3.79448 15.9436C3.87712 16.5027 4.07859 17.2826 4.27235 17.7936C4.82897 19.2615 5.87569 20.5948 7.2041 21.528C7.82545 21.9645 8.88164 22.4249 9.68555 22.6096C10.0101 22.6842 10.2682 22.6897 13.418 22.6897C16.7794 22.6897 16.8041 22.6891 17.1522 22.5945C18.6483 22.1877 19.7575 21.5316 20.8015 20.4355C22.0308 19.145 22.6958 17.7246 22.9633 15.8181C23.0263 15.3694 23.036 14.979 23.0353 12.9358C23.0348 11.3047 23.0182 10.4194 22.982 10.0784C22.8305 8.65549 22.4227 7.4968 21.6585 6.31864C20.7388 4.90071 19.3378 3.78529 17.824 3.2658C16.9581 2.96862 17.0657 2.9761 13.4795 2.96385C10.9138 2.95509 10.1718 2.9648 9.91528 3.01056ZM13.0987 6.13533C12.4825 6.1951 12.0269 6.40913 11.5582 6.85901C11.1475 7.25334 10.874 7.73042 10.7539 8.26216L10.7031 8.48693L10.0794 8.48603C9.73633 8.48555 9.31821 8.50738 9.15021 8.53454C8.07154 8.7089 7.16748 9.48436 6.82483 10.529C6.72238 10.8414 6.71224 10.9272 6.71532 11.4534C6.71836 11.9695 6.73091 12.0691 6.82914 12.3557C6.96683 12.7575 7.15796 13.0621 7.50692 13.4355C7.86979 13.8237 8.11892 13.9777 8.83386 14.2556L8.946 14.2992L8.95691 15.5082L8.96778 16.7171L13.3661 16.728L17.7644 16.7389L17.7753 15.5122L17.7861 14.2855L18.0628 14.1842C19.0231 13.8327 19.7269 13.0749 19.961 12.1402C20.0634 11.7316 20.026 10.9033 19.886 10.4766C19.649 9.75425 19.178 9.20034 18.4954 8.84112C18.0104 8.58584 17.5185 8.47722 16.8223 8.47159C16.5177 8.4691 16.2207 8.48121 16.1622 8.49844C16.0583 8.52904 16.0526 8.51976 15.9247 8.11057C15.7664 7.60448 15.5679 7.26412 15.2302 6.9202C14.6388 6.31782 13.9255 6.05511 13.0987 6.13533ZM8.94727 18.1995V19.0159H13.3564H17.7656V18.1995V17.3831H13.3564H8.94727V18.1995Z"
				fill={props.color}
			/>
		</Svg>
	)
}

export default ChefHatIcon
