import * as React from 'react'
import { Dimensions, Platform, TouchableOpacity, Image } from 'react-native'
import { View, Text } from '../../../components/Themed'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../state/reducer'
import { styles } from '../style'
import { SMALL_WIDTH } from '../../../constants/GlobalStyle'
import { auth } from '../../../firebase/config'
import PointsBalance from '../../../components/Rewards/PointsBalance'
import { DataContext } from '../../../state/context'
import { useContext, useEffect } from 'react'
import RewardsList from '../../../components/Rewards/RewardsList'
import { pointsTempRemove } from '../../../components/Rewards/pointsTempRemove'
import { onAuthStateChanged } from 'firebase/auth'

/**
 * Loyalty section of the home page of the app
 */
export function LoyaltyHeader({ navigation }) {
	const { userData, redeemedRewards, setUserData } = useContext(DataContext)
	const loadedData = useSelector<ReduxStoreState, ReduxStoreState['loadedData']>(
		state => state.loadedData
	)

	const width = Dimensions.get('window').width
	const overMaxWidth = width * 0.9 > 1200
	const screenWidth = overMaxWidth ? 1200 : width * 0.9
	const offset_percent = (width / 2 - screenWidth / 2) / width

	let rName = ''
	if (loadedData && loadedData[global.org] && loadedData[global.org].organizationName) {
		rName = loadedData[global.org].organizationName.toUpperCase()
	}

	const MAX_SIGNEDOUT_WIDTH = 425

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, user => {
			if (auth.currentUser === null) {
				setUserData(null)
			}
		})

		// Cleanup subscription on unmount
		return () => unsubscribe()
	}, [])

	const SignedOutComponent = () => {
		return (
			<TouchableOpacity
				onPress={() => {
					navigation.navigate('Rewards')
				}}
				style={{
					width: screenWidth + screenWidth * 0.02,
					height: screenWidth * 0.6,
					maxHeight: MAX_SIGNEDOUT_WIDTH * 0.6,
					alignSelf: 'center',
					justifyContent: 'center',
				}}
			>
				{/* <View
					style={{
						flexDirection: 'row', // Arrange children in a row
						alignItems: 'center', // Center content vertically within the row
						height: '100%',
						backgroundColor: 'red',
					}}
				> */}
				<Image
					style={{
						width: Dimensions.get('window').width * 0.8, // Set the desired width of the image
						maxWidth: MAX_SIGNEDOUT_WIDTH,
						height: '100%',
					}}
					resizeMethod="resize"
					resizeMode="contain"
					source={require('../../../assets/images/learnMoreCard.png')}
				/>
				{/* </View> */}
			</TouchableOpacity>
		)
	}

	return (
		<View
			style={{
				marginBottom: 20,
				marginTop: 10,
			}}
		>
			<Text
				style={[
					styles.subHeaderText,
					{
						marginHorizontal: '5%',
						paddingTop: 0,
						paddingBottom: 30,
						display: global.kiosk ? 'none' : 'flex',
						marginTop: Platform.OS === 'web' ? 40 : -60,
						width: '90%',
						maxWidth: 1200,
						alignSelf: 'center',
					},
				]}
			>
				{rName + ' REWARDS'}
			</Text>
			{auth.currentUser === null || userData === null ? (
				<SignedOutComponent />
			) : (
				<>
					<View style={{ marginBottom: 30, width: '90%', maxWidth: 1200, alignSelf: 'center' }}>
						<PointsBalance
							isHomePage={true}
							navigation={navigation}
							userPoints={
								userData.user &&
								userData.user.loyaltyPoints !== undefined &&
								userData.user.loyaltyPoints > 0
									? userData.user.loyaltyPoints - pointsTempRemove(redeemedRewards)
									: 0
							}
						/>
					</View>
					<RewardsList showAll={true} navigation={navigation} />
				</>
			)}
		</View>
	)
}
