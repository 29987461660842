import React, { useEffect } from 'react'
import { Platform, View } from 'react-native'
import { CheckoutScreenContext, DataContext } from '../../../../state/context'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../state/reducer'
import { placeOrder } from '../../Helpers/placeOrder'
import StripeWebPayment from '../../../../components/Stripe/StripePayment.web'
import { auth } from '../../../../firebase/config'
import StripePayment from '../../../../components/Stripe/StripePayment'
import { parsePrepTime } from '../../../../helpers/prepTimeFunctions'
import { add } from 'date-fns'
import { getFunctions, httpsCallable } from 'firebase/functions'

/**
 * Returns "PAY NOW" button to place order
 */
export function ConfirmOrder({ navigation, toggleConfirmModal, getSchedInterval }) {
	const orgFee = useSelector<ReduxStoreState, ReduxStoreState['orgFee']>(state => state.orgFee)
	const stripeId = useSelector<ReduxStoreState, ReduxStoreState['stripeId']>(
		state => state.stripeId
	)

	const prepTime = useSelector<ReduxStoreState, ReduxStoreState['prepTime']>(
		state => state.prepTime
	)

	const orgTipPercent = useSelector<ReduxStoreState, ReduxStoreState['orgTipPercent']>(
		state => state.orgTipPercent
	)

	const serviceFee = useSelector<ReduxStoreState, ReduxStoreState['serviceFee']>(
		state => state.serviceFee
	)

	const prepTimeString = useSelector<ReduxStoreState, ReduxStoreState['prepTimeString']>(
		state => state.prepTimeString
	)

	const prepTimeList = useSelector<ReduxStoreState, ReduxStoreState['prepTimeList']>(
		state => state.prepTimeList
	)

	const loadedData = useSelector<ReduxStoreState, ReduxStoreState['loadedData']>(
		state => state.loadedData
	)

	const userId = auth.currentUser ? auth.currentUser.uid : ''

	const {
		couponCodeData,
		saveCard,
		useSavedPaymentMethod,
		setAwaitingTerminalInput,
		setOrderPlaced,
		items,
		orderNote,
		couponCode,
		useNewPaymentMethod,
		phoneNumber,
		showPhoneNumber,
		couponCodeBackup,
		restaurantDiscountAmount,
		setOrderCreated,
		setOrderId,
		setOrderNumber,
		tableNumber,
		takeOutName,
		prices,
		setSpinner,
		isGuestCheckout,
		cardId,
		validCouponCodes,
		loading,
		tipCount,
		typePOS,
		selectedMethods,
		posExtraData,
		setShowSquarePayment,
		setCardElementVisible,
		setCheckoutId,
		setShowDineInOrTakeout,
		setShowAddTip,
		setIsDigitalPaymentWindowOpen,
		paymentRequest,
		setPaymentRequest,
		setIsWaitingOrderStatus,
		setOrderData,
		modalTimestamps,
	} = useContext(CheckoutScreenContext)

	const {
		redeemedRewards,
		pointsEarned,
		paymentProvider,
		userData,
		isDineIn,
		selectedPickupPoint,
	} = useContext(DataContext)

	//for placeOrder function
	const data = {
		prepTime: prepTime,
		serviceFee: serviceFee,
		userId: userId,
		prepTimeString: prepTimeString,
		prepTimeList: prepTimeList,
		loadedData: loadedData,
		items: items,
		orderNote: orderNote,
		couponCode: couponCode,
		useNewPaymentMethod: useNewPaymentMethod,
		phoneNumber: phoneNumber,
		showPhoneNumber: showPhoneNumber,
		couponCodeBackup: couponCodeBackup,
		restaurantDiscountAmount: restaurantDiscountAmount,
		setOrderCreated: setOrderCreated,
		setOrderId: setOrderId,
		setOrderNumber: setOrderNumber,
		isDineIn: isDineIn,
		tableNumber: tableNumber,
		takeOutName: takeOutName,
		prices: prices,
		setSpinner: setSpinner,
		isGuestCheckout: isGuestCheckout,
		cardId: cardId,
		validCouponCodes: validCouponCodes,
		loading: loading,
		tipCount: tipCount,
		navigation: navigation,
		toggleConfirmModal: toggleConfirmModal,
		getSchedInterval: getSchedInterval,
		selectedMethods: selectedMethods,
		posExtraData: posExtraData,
		redeemedRewards: redeemedRewards,
		pointsEarned: pointsEarned,
		timestampData: modalTimestamps,
		ageVerificationRequired: false,
		pickupPointObject: selectedPickupPoint,
	}
	useEffect(() => {
		console.log(prices.total + ' TOTAL')
		const dataCopy = { ...data }
		delete dataCopy.loadedData
		const hasAgeVerificationRequired = dataCopy.items.some(obj => obj.ageVerificationRequired)
		dataCopy.ageVerificationRequired = hasAgeVerificationRequired
		setOrderData(dataCopy)
	}, [])

	return (
		<View>
			{Platform.OS === 'web' ? (
				<StripeWebPayment
					onPress={placeOrder(data, paymentProvider)}
					amount={prices.total}
					saveCard={saveCard}
					orgFee={orgFee}
					userStripeId={
						stripeId && stripeId !== ''
							? stripeId
							: userData && userData.user && userData.user.stripeId
							? userData.user.stripeId
							: ''
					}
					userId={userId}
					cardId={useSavedPaymentMethod ? cardId : ''}
					tip={prices.tip}
					discount={prices.discount}
					orgDiscountCoverage={couponCodeData[4]}
					toggleModal={toggleConfirmModal}
					setOrderPlaced={setOrderPlaced}
					setSpinner={setSpinner}
					prices={prices}
					orgTip={orgTipPercent}
					paymentProvider={paymentProvider}
					setShowSquarePayment={setShowSquarePayment}
					setCardElementVisible={setCardElementVisible}
					getSchedInterval={getSchedInterval}
					setShowAddTip={setShowAddTip}
					paymentRequest={paymentRequest}
					setPaymentRequest={setPaymentRequest}
					data={data}
				/>
			) : (
				<View style={{ bottom: 0, position: 'absolute', width: '100%' }}>
					<StripePayment
						onPress={placeOrder(data, paymentProvider)}
						amount={prices.total}
						saveCard={saveCard}
						orgFee={orgFee}
						userStripeId={
							stripeId && stripeId !== ''
								? stripeId
								: userData && userData.user && userData.user.stripeId
								? userData.user.stripeId
								: ''
						}
						userId={userId}
						cardId={useSavedPaymentMethod ? cardId : ''}
						tip={prices.tip}
						discount={prices.discount}
						orgDiscountCoverage={couponCodeData[4]}
						toggleModal={toggleConfirmModal}
						setOrderPlaced={setOrderPlaced}
						setSpinner={setSpinner}
						setAwaitingTerminalInput={setAwaitingTerminalInput}
						prices={prices}
						orgTip={orgTipPercent}
						isDineIn={isDineIn}
						posType={typePOS}
						getSchedInterval={getSchedInterval}
						paymentProvider={paymentProvider}
						setCheckoutId={setCheckoutId}
						setShowDineInOrTakeout={setShowDineInOrTakeout}
						setShowAddTip={setShowAddTip}
						setShowSquarePayment={setShowSquarePayment}
						toggleConfirmModal={toggleConfirmModal}
						setCardElementVisible={setCardElementVisible}
						setIsDigitalPaymentWindowOpen={setIsDigitalPaymentWindowOpen}
						setIsWaitingOrderStatus={setIsWaitingOrderStatus}
						data={data}
					/>
				</View>
			)}
		</View>
	)
}
