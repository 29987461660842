import { DATABASE_NAME } from '@env'
import { doc, collection, onSnapshot } from 'firebase/firestore'
import { db } from '../../firebase/config'
import '../../global'

export function formatModItems(data: any) {
	return {
		id: data.id,
		name: data.name,
		amount: data.priceMoney.amount,
		restaurantId: data.restaurantId,
		modListId: data.modifierListId,
		isSnoozed: data.isSnoozed,
		snoozeUntil: data.snoozeUntil,
		imageUrl: data.imageUrl,
		isRecommended: data.isRecommended,
		isDeleted: data.isDeleted,
		ordinal: data.ordinal ? data.ordinal : 9999,
		variation: data.variation || false,
		modLists: data.modifierLists,
	}
}

export const getModifierItemData = () => {
	return new Promise((resolve, reject) => {
		const dataImport = {}
		const modifierItemRef = collection(doc(db, DATABASE_NAME, global.org), 'ModifierItems')

		onSnapshot(
			modifierItemRef,
			snapshot => {
				snapshot.docChanges().forEach(change => {
					const data = change.doc.data()
					if (!data.isDeleted) {
						const modItemReturn = formatModItems(data)
						const key = 'items_for_' + data.modifierListId
						const key2 = 'mod_item_' + data.id

						if (!dataImport[key]) {
							dataImport[key] = []
						}

						if (change.type === 'modified') {
							const index = dataImport[key].findIndex(i => i.id === data.id)
							if (index >= 0) {
								dataImport[key].splice(index, 1)
							}
						}

						dataImport[key].push(modItemReturn)
						dataImport[key2] = modItemReturn
					}
				})

				resolve(dataImport)
			},
			reject
		)
	})
}
