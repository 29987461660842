import { doc, collection, onSnapshot } from 'firebase/firestore'
import { db } from '../../../firebase/config'
import '../../../global'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setLoadedData } from '../../../state/actions'
import { ReduxStoreState } from '../../../state/reducer'
import _ from 'lodash'
import { formatModifierLists } from '../getModifierListData'
import { DATABASE_NAME } from '@env'

const useModListListener = ({ setUpdateData }) => {
	const loadedData = useSelector<ReduxStoreState, ReduxStoreState['loadedData']>(
		state => state.loadedData
	)
	const [modListData, setModListData] = useState({})
	const dispatch = useDispatch()

	useEffect(() => {
		const modListRef = collection(doc(db, DATABASE_NAME, global.org), 'ModifierLists')
		const unsubscribe = onSnapshot(modListRef, snapshot => {
			const newModListData = { ...modListData }
			let updated = false

			snapshot.docChanges().forEach(change => {
				const data = change.doc.data()
				if (change.type === 'modified' && data !== undefined && !data.isDeleted) {
					const modListReturn = formatModifierLists(data)
					newModListData[data.id] = modListReturn
					updated = true
				}
			})

			if (updated) {
				const updatedData = _.merge({}, loadedData, newModListData)
				setModListData(newModListData)
				dispatch(setLoadedData(updatedData))
				if (Object.keys(newModListData).length > 0) {
					setUpdateData([])
				}
			}
		})

		return () => {
			unsubscribe()
		}
	}, [loadedData, modListData, dispatch, setUpdateData])

	return null
}

export default useModListListener

// const useModListListener = ({ setUpdateData }) => {
// 	const loadedData = useSelector<ReduxStoreState, ReduxStoreState['loadedData']>(
// 		state => state.loadedData
// 	)

// 	const [modListData] = useState({})

// 	const dispatch = useDispatch()

// 	useEffect(() => {
// 		const modListRef = collection(doc(db, DATABASE_NAME, global.org), 'ModifierLists')

// 		const unsubscribe = onSnapshot(modListRef, snapshot => {
// 			snapshot.docChanges().forEach(change => {
// 				const data = change.doc.data()
// 				if (change.type === 'modified' && data !== undefined && !data.isDeleted) {
// 					const modListReturn = formatModifierLists(data)
// 					modListData[data.id] = modListReturn
// 					const updatedData = _.merge({}, loadedData, modListData)

// 					dispatch(setLoadedData(updatedData))

// 					//rerender screen
// 					if (Object.keys(modListData).length > 0) {
// 						setUpdateData([])
// 					}
// 				}
// 			})
// 		})

// 		// Clean up function
// 		return () => {
// 			unsubscribe()
// 		}
// 	}, [])
// }

// export default useModListListener
