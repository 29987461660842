import * as React from 'react'
import { Linking, TouchableOpacity, Text, Platform, View } from 'react-native'
import { RootStackScreenProps } from '../../navigation/types'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../state/reducer'
import { OrganizationLogoAccountPage } from '../../components/OrganizationLogoHeader'
import { mediumTextSize } from '../../constants/GlobalStyle'
/**
 * Screen on web that shows a user's receipt for a previous order
 * User is redirected here from the link in their texted / emailed receipt at checkout.
 */
export default function DownloadScreen({ navigation, route }: RootStackScreenProps<'download'>) {
	const sourceRef = route.params?.ref.trim()

	const loadedData = useSelector<ReduxStoreState, ReduxStoreState['loadedData']>(
		state => state.loadedData
	)

	const organizationLogo = useSelector<ReduxStoreState, ReduxStoreState['organizationLogo']>(
		state => state.organizationLogo
	)

	const orgLogoDark = loadedData[global.org].organizationLogoDark
		? loadedData[global.org].organizationLogoDark
		: ''

	const iosURL = loadedData[global.org].iosStoreURL || ''
	const androidURL = loadedData[global.org].googlePlayStoreURL || ''
	console.log(sourceRef)

	const handlePress = () => {
		if (Platform.OS === 'web') {
			const userAgent = navigator.userAgent
			if (/android/i.test(userAgent) && androidURL) {
				console.log('User is on an Android device')
				Linking.openURL(androidURL).catch(err => {
					console.error('An error occurred', err)
					navigation.navigate('HomeStack', { screen: 'RestaurantSelect' })
				})
			} else if (/iPad|iPhone|iPod/.test(userAgent) && iosURL) {
				console.log('User is on an iOS device')
				Linking.openURL(iosURL).catch(err => {
					console.error('An error occurred', err)
					navigation.navigate('HomeStack', { screen: 'RestaurantSelect' })
				})
			} else {
				console.log('User is on desktop')
				navigation.navigate('HomeStack', { screen: 'RestaurantSelect' })
			}
		}
	}

	useEffect(() => {
		if (Platform.OS === 'web') {
			const userAgent = navigator.userAgent
			if (/android/i.test(userAgent) && androidURL) {
				console.log('User is on an Android device')
				try {
					window.location.href = androidURL
				} catch (error) {
					console.error('An error occurred', error)
					navigation.navigate('HomeStack', { screen: 'RestaurantSelect' })
				}
			} else if (/iPad|iPhone|iPod/.test(userAgent) && iosURL) {
				console.log('User is on an iOS device')
				try {
					window.location.href = iosURL
				} catch (error) {
					console.error('An error occurred', error)
					navigation.navigate('HomeStack', { screen: 'RestaurantSelect' })
				}
			} else {
				console.log('User is on desktop')
				navigation.navigate('HomeStack', { screen: 'RestaurantSelect' })
			}
		}
	}, [])

	return (
		<View style={{ marginTop: '10%' }}>
			<OrganizationLogoAccountPage imageUri={orgLogoDark ? orgLogoDark : organizationLogo} />
			<TouchableOpacity onPress={handlePress}>
				<Text style={{ textAlign: 'center', fontSize: mediumTextSize }}>
					{'Redirecting to app store...'}
				</Text>
			</TouchableOpacity>
		</View>
	)
}
