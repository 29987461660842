import { getFunctions, httpsCallable } from 'firebase/functions'

/**
 * deleteUser does 4 things:
 * 1. delete user from firebase auth store
 * 2. delete user from 'Users' database
 * 3. delete user from Square 'customers' page
 * 4. delete user from Stripe 'customers' page
 */
export async function deleteUser(user) {
	const functions = getFunctions()
	const deleteSquareUser = httpsCallable(functions, 'deleteSquareUser')
	const deleteStripeUser = httpsCallable(functions, 'deleteStripeUser')
	const deleteUserInDB = httpsCallable(functions, 'deleteUserInDB')

	// delete from square by calling Square API from cloud function
	deleteSquareUser({
		uid: user.uid,
		orgId: global.org,
	})

	// delete from stripe by calling Stripe API from cloud function
	deleteStripeUser({
		orgId: global.org,
		userId: user.uid,
	})

	// delete from firestore
	deleteUserInDB({
		orgId: global.org,
		userId: user.uid,
	})

	// delete from firebase auth
	user
		.delete()
		.then(function () {
			console.log('DELETED')
		})
		.catch(function (error) {
			console.log(error)
			if (error == 'auth/requires-recent-login') {
				console.log('Must sign in again.')
			}
		})
}
