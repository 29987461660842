import React from 'react'
import { View } from 'react-native'
import Colors from '../../../../constants/Colors'
import { useContext } from 'react'
import { CheckoutScreenContext, DataContext } from '../../../../state/context'
import { NavButton } from '../../../../components/Headers/UIButtons'
import { auth } from '../../../../firebase/config'

/**
 * On web only, allow guest checkout
 * Show option to log in or continue as guest
 */
export function GuestCheckout({ navigation, toggleConfirmModal }) {
	const {
		setCardElementVisible,
		setUseNewPaymentMethod,
		setIsGuestCheckout,
		setShowGuestCheckoutName,
	} = useContext(CheckoutScreenContext)
	const { paymentProvider } = useContext(DataContext)

	return (
		<View
			style={{
				flex: 1,
				width: '80%',
				alignSelf: 'center',
				justifyContent: 'space-evenly',
				marginBottom: '5%',
			}}
		>
			<NavButton
				onPress={() => {
					toggleConfirmModal()
					navigation.navigate('AccountStack', {
						screen: 'Account',
						params: { onCheckout: true },
					})
				}}
				color={'black'}
				title={'LOG IN'}
				style={{ marginBottom: 20 }}
			/>
			<NavButton
				onPress={() => {
					if (paymentProvider === 'stripe') {
						toggleConfirmModal()
						setCardElementVisible(true)
					} else {
						setUseNewPaymentMethod(true)
						if (auth.currentUser === null) {
							setIsGuestCheckout(true)
						}
					}
					setShowGuestCheckoutName(true)
				}}
				color={Colors.greyscale[5]}
				title={'CONTINUE AS GUEST'}
			/>
		</View>
	)
}
