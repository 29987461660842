import { DATABASE_NAME } from '@env'
import { doc, collection, onSnapshot } from 'firebase/firestore'
import { db } from '../../firebase/config'
import '../../global'

export const getPromoImageData = () => {
	if (global.kiosk) {
		return new Promise((resolve, reject) => {
			const dataImport = { promoImages: [] }

			const promoImageRef = collection(doc(db, DATABASE_NAME, global.org), 'PromoImages')
			onSnapshot(
				promoImageRef,
				snapshot => {
					snapshot.docChanges().forEach(change => {
						const data = change.doc.data()
						if (!data.isDeleted) {
							dataImport['promoImages'].push(data)
						}
					})

					resolve(dataImport)
				},
				reject
			)
		})
	} else {
		return null
	}
}
