//show item if false (not snoozed)
//hide item if true (snoozed)
export function checkSnooze(isSnoozed, snoozeUntil) {
	//check if isSnoozed is active
	if (isSnoozed) {
		/*
      if active, check if there's a 'snooze until' set.
      when the item is snoozed in the restaurant manager,
      isSnoozed is set to true and snoozeUntil is either a timestamp
      or undefined. When disabled manually, 
      isSnooze is set to false (snoozeUntil is irrelevant)
      If snoozeUntil was set and 'time to reactivate' has passed,
      allow item to be shown.
      Therefore item will pass 1st conditional and still return false 
      */
		if (snoozeUntil > 0 && snoozeUntil !== '') {
			const currentTime = new Date().getTime()
			if (currentTime >= snoozeUntil) {
				//snoozeUntil was set and 'time to reactivate' has passed -> show item
				return false
			} else {
				//snoozeUntil was set and 'time to reactivate' has not passed -> don't show
				return true
			}
		} else {
			//isSnoozed is true but snoozeUntil is not set (permanent disable) -> don't show
			return true
		}
	} else {
		//item isn't snoozed -> show item
		return false
	}
}
