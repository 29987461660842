import { StyleSheet } from 'react-native'
import Colors from '../../constants/Colors'
import GlobalStyle from '../../constants/GlobalStyle'

export const styles = StyleSheet.create({
	homeContainer: {
		flex: 1,
		...GlobalStyle.whiteBg,
	},
})
