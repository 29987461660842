export function roundTaxAmount(amount, isBankersRounding) {
	if (isBankersRounding) {
		// Banker's rounding (Round half to even)
		const rounded = Math.round(amount)
		if (amount - Math.floor(amount) === 0.5) {
			if (rounded % 2 !== 0) {
				// If the rounded number is odd, adjust to the nearest even number
				return rounded - 1
			}
		}
		return rounded
	} else {
		// Standard rounding
		return Math.round(amount)
	}
}
