import * as React from 'react'
import { Dimensions, Platform, ScrollView, TouchableOpacity } from 'react-native'
import { View, Text } from '../../../components/Themed'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../state/reducer'
import { checkSnooze } from '../../../helpers/checkSnooze'
import {
	setAppId,
	setImageHero,
	setImageLogo,
	setLocId,
	setHours,
	setAddress,
	setSocial,
	setName,
	setPrepTime,
	setCouponCodes,
	setSchedulingJump,
	setLocalTimezone,
	setRestaurantId,
	setDeveloperFee,
	setIsSnoozed,
	setSnoozeUntil,
	setSlogan,
} from '../../../state/actions'
import ImageObject from '../../../components/Image/ImageObject'
import Colors from '../../../constants/Colors'
import { largeTextSize, mediumTextSize } from '../../../constants/GlobalStyle'
import { styles } from '../style'
import { logSelectMenuAnalytics } from '../../../firebase/analytics'

/**
 * Brand section of the home page of the app
 */
export function BrandList({ navigation }) {
	const dispatch = useDispatch()

	const restaurants = useSelector<ReduxStoreState, ReduxStoreState['restaurants']>(
		state => state.restaurants
	)

	const visibleRestaurants = restaurants.filter(
		restaurant => !checkSnooze(restaurant.isSnoozed, restaurant.snoozeUntil)
	)

	const centerContent = restaurants.length < 4.5

	const width = Dimensions.get('window').width
	const overMaxWidth = width * 0.9 > 1200
	const screenWidth = overMaxWidth ? 1200 : width * 0.9

	const LOGO_SIZE = visibleRestaurants.length < 3.5 ? mediumTextSize * 12.5 : mediumTextSize * 10
	const offset_percent = (width / 2 - screenWidth / 2) / width

	if (visibleRestaurants.length <= 0) {
		return (
			<Text
				style={{
					textAlign: 'center',
					fontStyle: 'italic',
					color: Colors.greyscale[7],
					fontSize: largeTextSize,
					paddingTop: Platform.OS === 'web' ? 20 : 0,
				}}
			>
				{'We are unable to accept new orders at this time.'}
			</Text>
		)
	}
	return (
		<View style={{ width: width }}>
			<View
				style={{
					width: screenWidth,
					alignSelf: 'center',
				}}
			>
				<Text style={[styles.subHeaderText, { paddingTop: '5%', paddingBottom: '5%' }]}>
					{'SHOP BY BRAND'}
				</Text>
			</View>
			<ScrollView
				horizontal
				showsHorizontalScrollIndicator={false}
				contentContainerStyle={[
					{
						marginLeft: width * offset_percent,
						alignItems: 'flex-start',
						justifyContent: 'flex-start',
						paddingRight: '10%',
						//maxWidth: screenWidth,
					},
					{
						...(centerContent && {
							justifyContent: 'center',
							flexGrow: 1,
						}),
					},
					{ justifyContent: 'flex-start' },
				]}
			>
				{visibleRestaurants.map((restaurant, index) => (
					<View
						key={index + restaurant.id}
						style={{
							width: LOGO_SIZE,
							height: LOGO_SIZE,
							borderRadius: LOGO_SIZE / 2,
							overflow: 'hidden',
							marginLeft: index === 0 ? 0 : 15,
							marginRight: index === visibleRestaurants.length - 1 ? 0 : 30,
						}}
					>
						<TouchableOpacity
							key={restaurant.id}
							onPress={() => {
								if (restaurant !== undefined) {
									logSelectMenuAnalytics('brand_menu', restaurant.id) //log analytics

									dispatch(setHours(restaurant.hours))
									dispatch(setLocId(restaurant.locationId))
									dispatch(setAppId(restaurant.applicationId))
									dispatch(setRestaurantId(restaurant.id))
									dispatch(setDeveloperFee(restaurant.developerFee))
									dispatch(setImageLogo(restaurant.logoImg))
									dispatch(setImageHero(restaurant.heroImg))
									dispatch(setAddress(restaurant.address))
									dispatch(setPrepTime(restaurant.prepTime))
									dispatch(setName(restaurant.name))
									dispatch(setCouponCodes(restaurant.couponCodes))
									dispatch(setSocial(restaurant.social))
									dispatch(setSchedulingJump(restaurant.schedulingJump))
									dispatch(setIsSnoozed(restaurant.isSnoozed))
									dispatch(setSnoozeUntil(restaurant.snoozeUntil))
									dispatch(setSlogan(restaurant.slogan))
									navigation.navigate('Menu', { rId: restaurant.id })
								} else {
									console.log('Could not load.')
								}
							}}
						>
							{restaurant.logoImg !== undefined && restaurant.logoImg !== '' ? (
								<ImageObject
									source={restaurant.logoImg}
									style={{ width: LOGO_SIZE, height: LOGO_SIZE }}
								/>
							) : (
								<View
									style={{
										width: LOGO_SIZE,
										height: LOGO_SIZE,
										backgroundColor: Colors.greyscale[2],
									}}
								></View>
							)}
						</TouchableOpacity>
					</View>
				))}
			</ScrollView>
		</View>
	)
}
