import React, { useState } from 'react'
import { View, TouchableOpacity, StyleSheet, TextInput, Image } from 'react-native'
import { Text } from '../../components/Themed'
import { getFunctions, httpsCallable } from 'firebase/functions'
import Formatter from '../../helpers/Formatter'

/**
 * Appears after sign up, when a code is sent to the user's phone number to verify it is real
 * Show a verification code input field and a button to verify the entered code or resend it if not received
 */
const VerificationInput = ({ phoneNumber, sendCode, values, createAccount, setDisplay }) => {
	const [code, setCode] = useState('')
	const [timeToResend, setTimeToResend] = useState(0)
	const [isLoading, setIsLoading] = useState(false)
	const [errorText, setErrorText] = useState('')
	const functions = getFunctions()

	async function checkVerificationCode(phoneNumber: string) {
		console.log('CHECKING CODE ' + code)
		const verifySignUpCode = httpsCallable(functions, 'verifySignUpCode')
		const verificationStatus: any = await verifySignUpCode({
			phoneNumber: phoneNumber,
			code: code,
		})
		console.log('VERIFICATION STATUS: ' + verificationStatus.data.status)
		return verificationStatus.data.status
	}

	const handleVerify = async () => {
		//Verify code
		if (code.length === 6) {
			setIsLoading(true)
			setErrorText('')
			const status = await checkVerificationCode(phoneNumber)
			if (status === 'approved') {
				console.log('VERIFIED')
				createAccount(
					values.email,
					values.password,
					values.firstName,
					values.lastName,
					values.phone,
					values.referralCode,
					setDisplay,
					setIsLoading
				)
				console.log(isLoading)
			} else if (status === undefined) {
				setIsLoading(false)
				console.log('Not verified: ' + status)
				setErrorText('Your verification code has expired.')
			} else {
				setIsLoading(false)
				console.log('Not verified: ' + status)
				setErrorText('Your code is incorrect! Please try again.')
			}
		} else {
			setErrorText('Please enter a 6-digit code.')
		}
	}

	const handleResendCode = () => {
		if (timeToResend > 0) {
			return
		}

		setIsLoading(false)
		setCode('')
		setErrorText('')

		// Logic to send a new verification code
		console.log('Resending code...')
		sendCode(phoneNumber)

		// Start the timer to allow resending code again
		let timeLeft = 15
		setTimeToResend(timeLeft)
		const timer = setInterval(() => {
			timeLeft -= 1
			setTimeToResend(timeLeft)
			if (timeLeft <= 0) clearInterval(timer)
		}, 1000)
	}

	return (
		<View style={styles.container}>
			<Text style={styles.title}>Enter Your Verification Code</Text>
			<Text style={styles.subtitle}>
				A verification code has been sent to {Formatter.phoneNumber(phoneNumber)}
			</Text>
			<TextInput
				value={code}
				onChangeText={setCode}
				placeholder="Enter code"
				style={styles.input}
				keyboardType="numeric"
			/>
			<TouchableOpacity
				onPress={handleVerify}
				style={[styles.verifyButton, { backgroundColor: global.orgColor }]}
			>
				{isLoading ? (
					<Image
						style={{
							width: 90,
							height: 90,
							marginTop: -34,
							marginBottom: -34,
						}}
						source={require('../../assets/images/loadImg.gif')}
					/>
				) : (
					<Text style={styles.verifyButtonText}>Verify</Text>
				)}
			</TouchableOpacity>
			<Text style={[{ display: errorText !== '' ? 'flex' : 'none' }, styles.errorText]}>
				{errorText}
			</Text>
			<TouchableOpacity onPress={handleResendCode} style={styles.resendButton}>
				<Text
					style={{
						color: timeToResend > 0 ? 'gray' : global.orgColor,
						textDecorationLine: timeToResend > 0 ? 'none' : 'underline',
					}}
				>
					{timeToResend > 0 ? 'Code Resent' : 'Resend Code'}
				</Text>
			</TouchableOpacity>
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignItems: 'center',
	},
	errorText: {
		color: 'red',
		marginTop: 10,
		textAlign: 'center',
	},
	title: {
		fontSize: 24,
		color: 'black',
		textAlign: 'center',
		marginBottom: 10,
	},
	subtitle: {
		fontSize: 16,
		color: 'gray',
		textAlign: 'center',
		marginBottom: 20,
		marginHorizontal: 15,
	},
	input: {
		padding: 15,
		width: '70%',
		borderWidth: 1,
		borderColor: '#e0e0e0',
		borderRadius: 10,
		fontSize: 16,
		marginBottom: 20,
	},
	verifyButton: {
		padding: 15,
		width: '70%',
		borderRadius: 10,
		alignItems: 'center',
	},
	verifyButtonText: {
		color: 'white',
		fontSize: 18,
	},
	resendButton: {
		marginTop: 20,
		alignItems: 'center',
	},
})

export default VerificationInput
