/**
 * Converts a number into a currency-formatted string: $#.##
 * @param num the number to convert into a currency-formatted string
 * @returns currency-formatted string
 */

export default class Formatter {
	static currencyFormat(num: number): string {
		return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
	}

	static phoneNumber(phoneNumber: string): string {
		let cleaned = phoneNumber.replace(/\D/g, '')

		if (cleaned.length === 11 && cleaned.startsWith('1')) {
			cleaned = cleaned.substring(1)
		}

		if (cleaned.length !== 10) {
			return 'Invalid phone number'
		}

		const areaCode = cleaned.substring(0, 3)
		const middlePart = cleaned.substring(3, 6)
		const lastPart = cleaned.substring(6)

		return `(${areaCode})-${middlePart}-${lastPart}`
	}

	static encodedEmail(email: string): string {
		return (
			'relay+' + email.toLowerCase().replace(/@/g, '%') + '%' + global.org + '@nextgenkitchens.com'
		)
	}

	static decodedEmail(relayEmail: string): string {
		if (relayEmail.startsWith('relay+')) {
			// Extract between 'relay+' and second '%'
			const regex = /relay\+([^%]+%[^%]+)%/
			const match = relayEmail.match(regex)

			if (match && match[1]) {
				return match[1].replace('%', '@')
			} else {
				return relayEmail
			}
		} else {
			return relayEmail
		}
	}
}
