import React, { useContext, useEffect, useState } from 'react'
import { View, TouchableOpacity, StyleSheet } from 'react-native'
import { mediumTextSize, smallTextSize, xlTextSize } from '../../constants/GlobalStyle'
import Colors from '../../constants/Colors'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { DataContext } from '../../state/context'
import { Text } from '../Themed'

export const AddressCard = ({ longAddress, label, instructions, onPress, onDelete }) => {
	const { userLocation } = useContext(DataContext)
	return (
		<View style={styles.container}>
			<TouchableOpacity onPress={onPress} style={styles.row} activeOpacity={0.7}>
				<MaterialCommunityIcons
					name={
						userLocation && userLocation.longAddress === longAddress
							? 'checkbox-marked-circle'
							: 'checkbox-blank-circle-outline'
					}
					size={26}
					color={global.orgColor}
					style={styles.icon}
				/>

				<View style={styles.textContainer}>
					<Text style={styles.boldText}>{label}</Text>
					<Text style={styles.boldText}>{longAddress}</Text>
					<Text style={styles.normalText}>{instructions}</Text>
				</View>
				{/* <Text style={[styles.openUntilText, { color: global.orgColor }]}>
					{restaurantHours.todayHours}
				</Text> */}
				<MaterialCommunityIcons
					name="trash-can-outline"
					size={20}
					style={{ paddingLeft: 0 }}
					onPress={onDelete}
					color="red"
				/>
			</TouchableOpacity>
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		backgroundColor: 'white',
	},
	row: {
		flexDirection: 'row',
		alignItems: 'center',
		padding: 15,
	},
	icon: {
		width: '10%',
		marginLeft: -15,
		marginRight: 10,
	},
	textContainer: {
		width: '85%',
	},
	boldText: {
		fontSize: mediumTextSize,
		fontWeight: 'bold',
		color: 'black',
	},
	normalText: {
		fontSize: mediumTextSize,
		color: 'grey',
		marginTop: 5,
	},
	openUntilText: {
		marginLeft: 5,
		paddingRight: 5,
		alignSelf: 'flex-start',
		fontSize: smallTextSize,
		width: '40%',
		textAlign: 'right',
	},
	separator: {
		height: 1,
		backgroundColor: Colors.greyscale[4],
		width: '100%',
	},

	centeredView: {
		flex: 1,
		justifyContent: 'center',
		paddingTop: 22,
		paddingHorizontal: '5%',
		backgroundColor: 'rgba(0,0,0,0.5)',
	},
	modalView: {
		backgroundColor: 'white',
		borderRadius: 20,
		alignItems: 'center',
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5,
	},
	button: {
		borderRadius: 10,
		padding: 10,
		paddingHorizontal: 25,
	},
	buttonText: {
		fontSize: xlTextSize,
		color: 'white',
		fontWeight: '500',
		textAlign: 'center',
	},
	buttonRow: {
		paddingHorizontal: 20,
		paddingVertical: 15,
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
	},
})
