import React from 'react'
import { View, StyleSheet, Animated } from 'react-native'
import GlobalStyle, { largeTextSize } from '../../../constants/GlobalStyle'
import { AntDesign, FontAwesome5 } from '@expo/vector-icons'
import { RFValue } from 'react-native-responsive-fontsize'
import Colors from '../../../constants/Colors'
import Formatter from '../../../helpers/Formatter'
import { Text } from '../../../components/Themed'

/**
 * Animation popup that appears after item is added to cart
 */
export const ItemAdded = ({
	price,
	fadeAnim,
	slideAnim,
	scaleAnim,
	bagAnim,
	checkmarkAnim,
	checkmarkTranslateY,
	isLoyalty,
}) => {
	const bagSize = RFValue(60)
	const checkSize = RFValue(30)
	return (
		<Animated.View style={[styles.overlay, { opacity: fadeAnim }]}>
			<Animated.View style={[styles.displayContainer, { transform: [{ translateY: slideAnim }] }]}>
				<Animated.View
					style={{
						transform: [{ scale: bagAnim }],
					}}
				>
					<FontAwesome5 name="shopping-bag" size={bagSize} color={Colors.greyscale[6]} />
				</Animated.View>
				<Animated.View
					style={{
						position: 'absolute',
						transform: [{ translateY: checkmarkTranslateY }, { scale: checkmarkAnim }],
						opacity: checkmarkAnim,
					}}
				>
					<AntDesign
						name="checkcircle"
						size={checkSize}
						color="green"
						style={{
							//backgroundColor: 'white',
							//borderRadius: RFValue(15),
							borderColor: 'green',
						}}
					/>
				</Animated.View>

				<View style={{ width: '80%', alignItems: 'center', paddingTop: '5%' }}>
					<Text
						style={[
							GlobalStyle.titleText,
							{ fontWeight: '700', textAlign: 'center', paddingBottom: '4%' },
						]}
					>
						{isLoyalty ? 'Reward redeemed!' : global.pos ? 'Added to cart' : 'Added to your order!'}
					</Text>
					<Text style={styles.subHeaderText}>{Formatter.currencyFormat(price / 100)}</Text>
				</View>
			</Animated.View>
		</Animated.View>
	)
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		position: 'absolute',
		backgroundColor: 'white',
		top: 0,
		bottom: 0,
		right: 0,
		left: 0,
	},
	overlay: {
		...StyleSheet.absoluteFillObject,
		backgroundColor: 'white',
		justifyContent: 'center',
		alignItems: 'center',
		flex: 1,
		position: 'absolute',
		zIndex: 1000000,
		top: 0,
		bottom: 0,
		right: 0,
		left: 0,
	},
	displayContainer: {
		justifyContent: 'center',
		alignItems: 'center',
		padding: 20,
	},
	addedText: {
		fontWeight: 'bold',
		marginTop: 10,
	},
	price: {
		marginTop: 4,
		fontSize: 12,
	},
	subHeaderText: {
		fontSize: largeTextSize,
		color: Colors.greyscale[8],
		letterSpacing: 3,
		fontWeight: '400',
	},
})
