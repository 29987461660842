import React from 'react'
import RestaurantScreenProvider from '../screens/Menu/Helpers/variables'
import RestaurantScreen from '../screens/Menu'
import { RootTabScreenProps } from './types'
import CheckoutScreen from '../screens/Checkout'
import CheckoutScreenProvider from '../screens/Checkout/Helpers/variables'
import ItemScreenProvider from '../screens/Item/Helpers/variables'
import ItemScreen from '../screens/Item'
import RestaurantSelectScreen from '../screens/RestaurantSelect'
import RestaurantSelectScreenProvider from '../screens/RestaurantSelect/Helpers/variables'

export function RestaurantScreenWithState(props: RootTabScreenProps<'restaurant'>) {
	return (
		<RestaurantScreenProvider>
			<RestaurantScreen {...props} />
		</RestaurantScreenProvider>
	)
}

export function CheckoutScreenWithState(props: RootTabScreenProps<'TabCheckout'>) {
	return (
		<CheckoutScreenProvider>
			<CheckoutScreen {...props} />
		</CheckoutScreenProvider>
	)
}

export function ItemScreenWithState(props: RootTabScreenProps<'TabItem'>) {
	return (
		<ItemScreenProvider>
			<ItemScreen {...props} />
		</ItemScreenProvider>
	)
}

export function RestaurantSelectScreenWithState(props: RootTabScreenProps<'TabHome'>) {
	return (
		<RestaurantSelectScreenProvider>
			<RestaurantSelectScreen {...props} />
		</RestaurantSelectScreenProvider>
	)
}
