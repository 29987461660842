import { CheckoutItem, Prices } from './types'
import { auth, db } from '../../../firebase/config'
import { parsePrepTime, prepTimeDur } from '../../../helpers/prepTimeFunctions'
import {
	formatDiscountForSquare,
	formatItemsForFirebase,
	formatItemsForSquare,
	formatServiceChargesForSquare,
	getRestaurantSpecificDiscounts,
} from './itemFormatFunctions'
import { getLogoutPrompt, storeClosed } from './prompts'
import { getTaxes } from './priceFunctions'
import { Platform } from 'react-native'
import { add } from 'date-fns'
import { DATABASE_NAME } from '@env'
import { doc, collection } from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions'
/**
 * Place order functions
 */

const functions = getFunctions()

export const findUserID = (userId, isGuestCheckout) => {
	// userId needs to be refactored out of ConfirmOrder.tsxy
	var uId = ''
	if (global.kiosk) {
		uId = userId
	} else if (!isGuestCheckout) {
		uId = auth.currentUser.uid
	} else {
		if (userId !== '') {
			uId = userId
		} else {
			uId = 'Guest'
		}
	}

	return uId
}

async function ProcessOrder(
	prepReturn: string,
	paymentProvider: string,
	taxArray: any[],
	prices: Prices,
	items: CheckoutItem[],
	orderNote: string,
	discount: any,
	tip: number,
	prepTime: string,
	userId: string,
	prepTimeList: any, //list of prep times for mixnmatch
	serviceFee: any,
	bogoList: any,
	freeItemList: any,
	phone: any,
	isGuestCheckout: boolean,
	textUpdates: boolean,
	restaurants: any,
	tableNumber: any,
	isDineIn: boolean,
	pickupName: any,
	restaurantDiscountAmount: any,
	selectedMethods: any,
	posExtraData: { name: string; firebaseId: string; note: string; type: string },
	redeemedRewards: any,
	pointsEarned: number,
	timestampData: any,
	pickupPointObject: any
) {
	const orderLineItems = formatItemsForSquare(items)

	//item data for db orders collection
	const dbOrderItems = formatItemsForFirebase(items)

	const discountObj = getRestaurantSpecificDiscounts(items, prices, bogoList, freeItemList)

	const discountView = formatDiscountForSquare(
		items,
		restaurants,
		discount ? discount.orgCoverage : 0,
		discount,
		prices.subTotal,
		prices.noCodeDiscount,
		prices.discount
	)

	const serviceChargeView = formatServiceChargesForSquare(tip, prices.serviceCharge)

	let displayName = auth.currentUser ? auth.currentUser.displayName : 'Guest'
	if (displayName == null) {
		displayName = 'APP ORDER'
	}

	const note = orderNote
	let orderSource = 'App Pickup'
	if (Platform.OS === 'web') {
		orderSource = 'Web Pickup'
	} else if (global.kiosk) {
		if (isDineIn) {
			orderSource = 'Dine In'
		} else {
			orderSource = 'Kiosk Pickup'
		}
	}

	const functions = getFunctions()

	const firestoreFulfillmentData = {
		note: note,
		display_name: displayName,
		prep_time_duration: prepTime,
	}

	const id = doc(collection(db, DATABASE_NAME, global.org, 'Orders')).id
	var orderType = 'pickup'
	var priceReturn: any = { ...prices }
	delete priceReturn.noCodeDiscount
	delete priceReturn.deliveryFee
	for (const key in priceReturn) {
		if (key !== 'taxesList') {
			if (key == 'discount') {
				//if discount, get rid of negative
				priceReturn[key] = Math.abs(Math.round(priceReturn[key] * 100))
			} else {
				priceReturn[key] = Math.round(priceReturn[key] * 100)
			}
		} else {
			//if taxes list, go through each entry
			if (priceReturn[key].length > 0) {
				priceReturn[key].forEach(tax => {
					tax.amount = Math.round(tax.amount)
				})
			}
		}
	}
	priceReturn.currency = 'CAD'

	const uId = findUserID(userId, isGuestCheckout)

	let channelData = null
	if (global.pos && posExtraData.type === 'thirdParty') {
		channelData = {
			orderType: isDineIn ? 'dineIn' : 'takeOut',
			appType: 'pos',
			posPaymentType: posExtraData.type,
			thirdPartyPaymentInfo: {
				name: posExtraData.name,
				id: posExtraData.firebaseId,
				paymentNote: posExtraData.note,
			},
		}
	} else if (global.pos) {
		channelData = {
			orderType: isDineIn ? 'dineIn' : 'takeOut',
			appType: 'pos',
			posPaymentType: posExtraData.type,
		}
	} else {
		channelData = {
			orderType: isDineIn ? 'dineIn' : prices.deliveryFee > 0 ? 'delivery' : 'takeOut',
			appType: Platform.OS === 'web' ? 'web' : global.kiosk ? 'kiosk' : 'mobile',
		}
	}

	if (pickupPointObject && pickupPointObject.id) {
		channelData.pickupPointId = pickupPointObject.id
	}

	let loyaltyData = {}
	if (pointsEarned > 0) {
		loyaltyData = {
			userId: uId,
			pointsEarned: pointsEarned,
		}
	}

	const orderObj = {
		id: id,
		userId: uId,
		orderNote: firestoreFulfillmentData.note,
		prepTime: prepReturn,
		orderItems: dbOrderItems,
		orderDate: new Date(),
		pickupDate: add(new Date(), parsePrepTime(prepReturn)),
		priceData: priceReturn,
		channelData: channelData,
		manualInput: false,
		status: 'Pending',
		tableNumber: tableNumber !== '' && tableNumber !== null ? 'table ' + tableNumber : pickupName,
		textOrderUpdates:
			selectedMethods.phoneString !== '' &&
			selectedMethods.textUpdates &&
			selectedMethods.textMessage &&
			prices.deliveryFee <= 0,
		//orderNumber: orderProcessed.orderNumber,
		appliedDiscount: discount ? discount.id : null,
		receiptData: {
			emailAddress: selectedMethods.email ? selectedMethods.emailString : '',
			phoneNumber: selectedMethods.textMessage ? selectedMethods.phoneString : '',
			printed: selectedMethods.print,
		},
		loyaltyData: loyaltyData,
		timestampData: timestampData,
	}
	const phoneNumber = selectedMethods.phoneString !== '' ? '+1' + selectedMethods.phoneString : ''

	const processOrderForSquare = httpsCallable(functions, 'processOrderForSquareV6')
	const orderProcessed: any = await processOrderForSquare({
		prepTime: prepReturn,
		paymentProvider,
		orderNote: firestoreFulfillmentData.note,
		prepTimes: prepTimeList, //list has ALL restaurant ids, compare with line items to find relevant ones first
		orderItems: orderLineItems,
		discounts: discountView,
		serviceCharges: serviceChargeView,
		source: orderSource,
		uid: userId,
		orgId: global.org,
		taxes: taxArray,
		orderId: id,
		serviceFee: global.kiosk && paymentProvider === 'square' ? 0 : serviceFee,
		orgDiscountPercentCoverage: discount ? discount.orgCoverage : 0,
		restaurantSpecificDiscountObj: discountObj,
		phoneNumber: phoneNumber,
		kioskName: global.kioskName ? global.kioskName : 'Guest',
		restaurants: restaurants,
		tableNumber: tableNumber,
		isDineIn: isDineIn,
		tipAmount: prices.tip,
		priceData:
			global.kiosk && paymentProvider === 'square' ? { ...prices, serviceCharge: 0 } : prices,
		pickupName: pickupName,
		restaurantDiscountAmounts: restaurantDiscountAmount,
		discountId: discount ? discount.id : '',
		orderObj: orderObj,
		isGuestCheckout: isGuestCheckout,
		redeemedRewards: redeemedRewards,
		isKiosk: global.kiosk,
	}).catch(e => {
		console.log(e)
		return 'PaymentError'
	})

	orderProcessed.orderNumDisplay = orderProcessed.orderNumber
	orderProcessed.created = true

	return orderProcessed
}

export async function addCouponForReferrer(couponCode, userId) {
	if (couponCode.startsWith('refer-')) {
		const addReferrerCoupon = httpsCallable(functions, 'addCouponForReferrer')

		addReferrerCoupon({
			orgId: global.org,
			userId: userId,
			couponCode: couponCode,
		})
			.then(() => {
				console.log('Referrer coupon added')
			})
			.catch(e => {
				console.log('Error adding referrer coupon ' + e)
			})
	}
}

export function handleNextButtonClick(
	prepReturn: string,
	paymentProvider: string,
	navigation,
	prices: Prices,
	items: CheckoutItem[],
	orderNote: string,
	couponCodeData: any,
	tip: number,
	prepTimeNum: number, //original prep time
	prepTime: string,
	currentLocation: any,
	userId: string,
	prepTimeList: any,
	serviceFee: any,
	bogoList: any,
	freeItemList: any,
	phoneNumber: any,
	isGuestCheckout: boolean,
	textUpdates: boolean,
	restaurants: any,
	tableNumber: any,
	isDineIn: boolean,
	pickupName: any,
	restaurantDiscountAmount: any,
	selectedMethods: any,
	posExtraData: { name: string; firebaseId: string; note: string; type: string },
	redeemedRewards: any,
	pointsEarned: number,
	timestampData: any,
	pickupPointObject: any
) {
	const isOpen = true
	const originalPrepTime = prepTimeDur(prepTimeNum) //if prepTime != originalPrepTime (default), then its a scheduled order and can proceed.
	if (
		(isOpen || prepTime !== originalPrepTime) &&
		currentLocation.address != 'Delivery Unavailable'
	) {
		if (auth.currentUser || isGuestCheckout) {
			//get restaurant details for order
			//get tax details
			const taxArray = getTaxes(items)

			return ProcessOrder(
				prepReturn,
				paymentProvider,
				taxArray,
				prices,
				items,
				orderNote,
				couponCodeData,
				tip,
				prepTime,
				userId,
				prepTimeList,
				serviceFee,
				bogoList,
				freeItemList,
				phoneNumber,
				isGuestCheckout,
				textUpdates,
				restaurants,
				tableNumber,
				isDineIn,
				pickupName,
				restaurantDiscountAmount,
				selectedMethods,
				posExtraData,
				redeemedRewards,
				pointsEarned,
				timestampData,
				pickupPointObject
			)
		} else {
			return getLogoutPrompt(navigation)
		}
	} else {
		//restaurant not open
		storeClosed()
	}
}
