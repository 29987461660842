import * as React from 'react'
import { TextInput } from 'react-native'
import Colors from '../../constants/Colors'
import { smallTextSize } from '../../constants/GlobalStyle'

export function PriceMask({ prices, setCustomTip }) {
	return (
		<TextInput
			style={{
				borderWidth: 1,
				borderRadius: 10,
				borderColor: Colors.greyscale[5],
				width: global.kiosk ? 200 : 100,
				height: global.kiosk ? 80 : 30,
				paddingHorizontal: 20,
				marginVertical: 20,
				fontSize: smallTextSize,
				fontWeight: 'bold',
				color: global.orgColor ? global.orgColor : Colors.custom.restaurantMain,
				backgroundColor: Colors.custom.lightGrey,
			}}
			placeholder={'$' + prices.tip.toFixed(2)}
			keyboardType={'numeric'}
			onChangeText={text => {
				setCustomTip(Number(text))
			}}
		/>
	)
}

export function PhoneMask({ setPhoneNumber }) {
	return (
		<TextInput
			style={{
				borderWidth: 1,
				borderRadius: 15,
				borderColor: Colors.custom.darkGrey,
				width: 200,
				height: 50,
				marginRight: 50,
				paddingHorizontal: 20,
				marginVertical: 20,
				fontSize: 18,
				fontWeight: 'bold',
				color: global.orgColor ? global.orgColor : Colors.custom.restaurantMain,
				backgroundColor: Colors.custom.lightGrey,
			}}
			placeholder={'Phone Number'}
			keyboardType={'numeric'}
			onChangeText={text => {
				setPhoneNumber(text)
			}}
		/>
	)
}
