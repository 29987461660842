import { useContext, useEffect } from 'react'
import { addMods } from '../Helpers/itemFormatFunctions'
import { getTaxOnItem } from '../Helpers/priceFunctions'
import { CheckoutItem } from '../Helpers/types'
import { CheckoutScreenContext } from '../../../state/context'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../state/reducer'
import { auth } from '../../../firebase/config'
import { Platform } from 'react-native'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { userCanRedeem } from '../Helpers/checkCouponCode'
import { logViewCartAnalytics } from '../../../firebase/analytics'
import { formatRestaurantId } from '../../../helpers/formatRestaurantId'
import { checkSnooze } from '../../../helpers/checkSnooze'

const useCheckout = ({ navigation }) => {
	const functions = getFunctions()

	const cart = useSelector<ReduxStoreState, ReduxStoreState['items']>(state => state.items)
	const loadedData = useSelector<ReduxStoreState, ReduxStoreState['loadedData']>(
		state => state.loadedData
	)

	const {
		setItems,
		setOrderCreated,
		setAwaitingTerminalInput,
		setPaymentError,
		couponRefresh,
		setSpinner,
		setOrderPlaced,
		setCardId,
		setIsUser,
		validCouponCodes,
		setValidCouponCodes,
		modalKey,
		setModalKey,
		userAvailableCodes,
		userRedeemedCodes,
		setVisibleCouponCount,
		setAnalyticsItems,
		setIsRestaurantSnoozed,
	} = useContext(CheckoutScreenContext)

	const platform = global.pos
		? 'pos'
		: global.kiosk
		? 'kiosk'
		: Platform.OS === 'web'
		? 'web'
		: 'mobile'

	function getCouponCount(list: any) {
		let count = 0
		if (list && list.length > 0) {
			list.forEach(promo => {
				if (!promo.codeOnly && promo.type !== 'staffMeal') {
					if (!promo.userSpecific) {
						count++
					} else if (auth.currentUser && userCanRedeem(promo, userRedeemedCodes)) {
						if (userAvailableCodes.length > 0) {
							userAvailableCodes.forEach(code => {
								if (promo.id == code) {
									count++
								}
							})
						}
					}
				}
			})
		}
		return count
	}

	useEffect(() => {
		const unsubscribe = navigation.addListener('focus', () => {
			auth.onAuthStateChanged(user => {
				if (user) {
					// User is signed in.
					setIsUser(true)
				} else {
					//reset credit cards on sign out
					setCardId('')
					setIsUser(false)
				}
			})
			setSpinner(false)
			setOrderPlaced(false)
			setAwaitingTerminalInput(false)
			setPaymentError(false)
		})

		return () => {
			unsubscribe()
		}
	}, [navigation])

	useEffect(() => {
		setOrderCreated(false)
		const unsubscribe = navigation.addListener('focus', async () => {
			const localItems = []
			const localAnalyticsItems = []
			let analyticsTotal = 0
			if (loadedData['BOGO']) {
				loadedData['BOGO'].forEach(bogo => {
					if (bogo.codeOnly) {
						validCouponCodes.push(bogo)
					}
				})
			}
			if (loadedData['freeItem']) {
				loadedData['freeItem'].forEach(free => {
					if (free.codeOnly) {
						validCouponCodes.push(free)
					}
				})
			}

			const getCouponList = httpsCallable(functions, 'getCouponListTest')
			const fetchData = async () => {
				const couponList = await getCouponList({
					orgId: global.org,
					platform: platform,
				})
				setValidCouponCodes(couponList.data as any)
				setVisibleCouponCount(getCouponCount(couponList.data))
			}

			setModalKey(modalKey + 1)

			fetchData()
			if (cart.length > 0 && cart[0].rId) {
				const rId = cart[0].rId
				setIsRestaurantSnoozed(checkSnooze(loadedData[rId].isSnoozed, loadedData[rId].snoozeUntil))
			}

			cart.forEach((item, index) => {
				const modifierArray = addMods(item)
				const taxObj = getTaxOnItem(
					item.taxes,
					item.promoPrice === -1 || isNaN(item.promoPrice)
						? item.amount + item.modPriceSum
						: item.promoPrice + item.modPriceSum,
					item.qty
				)
				const localItem: CheckoutItem = {
					id: item.id,
					posId: item.posId,
					qty: item.qty,
					name: item.name,
					ppu: item.promoPrice === -1 || isNaN(item.promoPrice) ? item.amount : item.promoPrice, //not including modifiers
					total:
						item.promoPrice === -1 || isNaN(item.promoPrice)
							? item.amount + item.modPriceSum
							: item.promoPrice + item.modPriceSum, //including modifiers
					rId: item.rId,
					mods: modifierArray,
					modList: item.modList,
					note: item.note,
					taxes: item.taxes,
					noPromo: item.amount + item.modPriceSum,
					menuId: item.menuId,
					menuItemCategory: item.menuItemCategory,
					idempotencyKey: item.idempotencyKey,
					imageUri: item.imageUri,
					appliedCoupon: item.appliedCoupon,
					taxAmt: taxObj.total,
					taxList: taxObj.taxes,
					ageVerificationRequired: item.ageVerificationRequired,
				}
				const listInfo = formatRestaurantId(item.menuId, loadedData)
				localAnalyticsItems.push({
					item_id: item.id,
					item_name: item.name,
					item_location_id: item.rId,
					quantity: item.qty,
					price: (item.amount * item.qty) / 100,
					item_category: item.menuItemCategory,
					item_list_id: listInfo.id,
					item_list_name: listInfo.name,
				})
				analyticsTotal += (item.amount * item.qty) / 100

				localItems.push(localItem)
				if (index === cart.length - 1) {
					setAnalyticsItems(localAnalyticsItems)
					setItems(localItems)
				}
			})
			if (cart.length === 0) {
				setItems([])
			}
			logViewCartAnalytics(analyticsTotal, localAnalyticsItems)
		})
		return () => {
			unsubscribe()
		}
	}, [navigation, couponRefresh, cart])
}

export default useCheckout
