import { useContext, useEffect } from 'react'
import { calculateBagFee } from '../Helpers/priceFunctions'
import { CheckoutScreenContext, DataContext } from '../../../state/context'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../state/reducer'
import { auth, db } from '../../../firebase/config'
import stripeGetCards from '../../../components/Stripe/StripeGetCards'
import { setStripeId, setPaymentMethodId } from '../../../state/actions'
import { DATABASE_NAME } from '@env'
import { doc, onSnapshot } from 'firebase/firestore'
import { useSquare } from '../../../components/Square/Hooks/useSquare'

const useFetchCards = () => {
	const dispatch = useDispatch()
	const userId = auth.currentUser ? auth.currentUser.uid : ''
	const { getCards } = useSquare()
	const loadedData = useSelector<ReduxStoreState, ReduxStoreState['loadedData']>(
		state => state.loadedData
	)
	const paymentMethodId = useSelector<ReduxStoreState, ReduxStoreState['paymentMethodId']>(
		state => state.paymentMethodId
	)

	const { setSavedPhone, setCardId, isUser, setLoading, setBagFee, setBagFeeEnabled, cardId } =
		useContext(CheckoutScreenContext)

	const { setPaymentMethods, paymentProvider, paymentMethods, userData } = useContext(DataContext)

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true)
			console.log(userId)
			setBagFeeEnabled(
				loadedData[global.org].bagFee && loadedData[global.org].bagFee.enabled ? true : false
			)

			if (loadedData[global.org].bagFee && loadedData[global.org].bagFee.enabled) {
				setBagFee(calculateBagFee(loadedData[global.org].bagFee))
			}

			if (userId !== '' && userData.user && userData.user.phone && auth.currentUser) {
				setSavedPhone(userData.user && userData.user.phone ? userData.user.phone : '')
				if (paymentProvider === 'stripe') {
					try {
						if (userData && userData.user && userData.user.stripeId) {
							const stripeCards = await stripeGetCards(userData.user.stripeId)
							dispatch(setStripeId(userData.user.stripeId))
							setPaymentMethods(stripeCards)
							if (stripeCards.length > 0 && paymentMethodId === '') {
								dispatch(setPaymentMethodId(stripeCards[0].id))
								setCardId(stripeCards[0].id)
							}
						}

						setLoading(false)
					} catch (error) {
						setLoading(false)
						console.error('Error fetching Stripe cards:', error)
						// Handle error state if needed
					}
				}

				if (!global.kiosk && paymentProvider === 'square') {
					loadSquareCards()
				}
			} else {
				setLoading(false) // no cards to fetch since guest or not signed in
			}
		}
		fetchData()
	}, [userId, userData, isUser])

	useEffect(() => {
		if (paymentMethods.length >= 1) {
			setCardId(paymentMethods[0].id)
		} else {
			setCardId('')
		}
	}, [paymentMethods])

	const loadSquareCards = async () => {
		setLoading(true)
		const squareCards = await getCards()
		setLoading(false)
		setPaymentMethods(squareCards)
		if (squareCards.length > 0 && paymentMethodId === '') {
			dispatch(setPaymentMethodId(squareCards[0].id))
			setCardId(squareCards[0].id)
		}
	}
}

export default useFetchCards
