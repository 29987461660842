import { DATABASE_NAME } from '@env'
import { doc, getDoc } from 'firebase/firestore'
import { auth, db } from '../../firebase/config'
import '../../global'

export const getUserData = async uid => {
	const dataImport: any = {}
	const userDocRef = doc(db, DATABASE_NAME, global.org, 'Users', uid)

	await getDoc(userDocRef)
		.then(documentSnapshot => {
			if (documentSnapshot.exists()) {
				const data = documentSnapshot.data() // Get the document data
				if (data) {
					dataImport['user'] = data
					console.log('Setting user data')
				}
			} else {
				console.log('Not signed in.')
			}
		})
		.catch(error => {
			console.error('Error getting document:', error)
		})

	return dataImport
}
