import React, { useContext, useState } from 'react'
import {
	View,
	TextInput,
	StyleSheet,
	Keyboard,
	TouchableWithoutFeedback,
	Platform,
} from 'react-native'
import { Text } from '../../../../components/Themed'
import { FontAwesome, Ionicons, MaterialCommunityIcons } from '@expo/vector-icons'
import { CheckBox } from 'react-native-elements'
import { CheckoutScreenContext, DataContext } from '../../../../state/context'
import NumberPad from '../../../../components/NumberPad'
import { largeTextSize, titleTextSize } from '../../../../constants/GlobalStyle'
import { auth } from '../../../../firebase/config'
import Formatter from '../../../../helpers/Formatter'
import { ForwardButton } from '../../../../components/Headers/UIButtons'
import Colors from '../../../../constants/Colors'

/**
 * Screen to choose the desired receipt options & text updates
 * Email (through sendgrid), text (through twilio), or print (alerts R.M. to print receipt with order)
 */
const Receipt = ({ children }) => {
	const { selectedMethods, setSelectedMethods, savedPhone } = useContext(CheckoutScreenContext)

	const { isDelivery } = useContext(DataContext)

	const [keyboardActive, setKeyboardActive] = useState(false)

	const descriptor = global.pos ? 'THEIR' : 'YOUR'
	const pronoun = global.pos ? 'THEY' : 'YOU'

	const formatPhoneNumber = inputString => {
		const numbers = inputString.replace(/\D/g, '')
		const char = { 0: '(', 3: ')-', 6: '-' }
		let phone = ''
		for (let i = 0; i < numbers.length; i++) {
			phone += (char[i] || '') + numbers[i]
		}
		return phone
	}

	if (global.kiosk) {
		return (
			<>
				<TouchableWithoutFeedback onPress={Keyboard.dismiss}>
					<View style={styles.container}>
						<Text
							style={styles.subHeader}
						>{`HOW WOULD ${pronoun} LIKE ${descriptor} RECEIPT?`}</Text>
						<Text style={[styles.subHeader, { color: '#818181', marginBottom: 30, marginTop: 15 }]}>
							{'(SELECT ONE OR MORE)'}
						</Text>
						<CheckBox
							title="Text Message"
							checked={selectedMethods.textMessage}
							checkedColor={global.orgColor}
							checkedIcon={
								<MaterialCommunityIcons
									name="checkbox-marked"
									size={titleTextSize}
									color={global.orgColor}
								/>
							}
							uncheckedIcon={
								<MaterialCommunityIcons
									name="checkbox-blank-outline"
									size={titleTextSize}
									color="black"
								/>
							}
							onPress={() =>
								setSelectedMethods(prev => ({
									...prev,
									textMessage: !prev.textMessage,
									showNumPad: !prev.textMessage,
								}))
							}
							containerStyle={styles.checkboxContainer}
							textStyle={styles.checkboxText}
						/>
						<View style={{ display: keyboardActive ? 'none' : 'flex', alignItems: 'center' }}>
							{selectedMethods.textMessage && (
								<>
									<View style={styles.inputContainer}>
										<FontAwesome
											name="mobile-phone"
											size={titleTextSize * 1.15}
											color={Colors.greyscale[3]}
											style={styles.inputIcon}
										/>
										<TextInput
											style={styles.input}
											placeholder="Mobile number"
											placeholderTextColor="#aaa"
											value={selectedMethods.phoneStringFormatted}
											showSoftInputOnFocus={false}
											maxLength={14}
											autoFocus={true}
											editable={true}
											selectTextOnFocus={false}
											onFocus={() => setSelectedMethods(prev => ({ ...prev, showNumPad: true }))}
											onBlur={() => {
												setSelectedMethods(prev => ({
													...prev,
													showNumPad: false,
													textMessage: selectedMethods.phoneString === '' ? false : true,
												}))
											}}
										/>
									</View>
									<CheckBox
										title="Also receive order updates"
										checked={selectedMethods.textUpdates}
										checkedColor={global.orgColor}
										checkedIcon={
											<MaterialCommunityIcons
												name="checkbox-marked"
												size={titleTextSize}
												color={global.orgColor}
											/>
										}
										uncheckedIcon={
											<MaterialCommunityIcons
												name="checkbox-blank-outline"
												size={titleTextSize}
												color="black"
											/>
										}
										onPress={() =>
											setSelectedMethods(prev => ({
												...prev,
												textUpdates: !prev.textUpdates,
											}))
										}
										containerStyle={[styles.checkboxContainer, { marginTop: 5, marginLeft: '14%' }]}
										textStyle={styles.checkboxText}
									/>
								</>
							)}
							<NumberPad
								style={{
									display: selectedMethods.showNumPad ? 'flex' : 'none',
									marginBottom: 0,
									width: '80%',
								}}
								numLength={12}
								buttonSize={60}
								buttonItemStyle={{ backgroundColor: '#d6d6d6', borderRadius: 12, width: '80%' }}
								activeOpacity={0.1}
								onValueChange={value =>
									setSelectedMethods(prev => ({
										...prev,
										phoneString: value,
										phoneStringFormatted: formatPhoneNumber(value),
									}))
								}
								allowDecimal={false}
								rightBottomButton={
									<Ionicons name={'ios-backspace-outline'} size={28} color={'#000'} />
								}
							/>
						</View>

						{!selectedMethods.showNumPad ? (
							<>
								<CheckBox
									title="Email"
									checked={selectedMethods.email}
									checkedColor={global.orgColor}
									checkedIcon={
										<MaterialCommunityIcons
											name="checkbox-marked"
											size={titleTextSize}
											color={global.orgColor}
										/>
									}
									uncheckedIcon={
										<MaterialCommunityIcons
											name="checkbox-blank-outline"
											size={titleTextSize}
											color="black"
										/>
									}
									onPress={() => {
										setKeyboardActive(false)
										setSelectedMethods(prev => ({ ...prev, email: !prev.email }))
									}}
									containerStyle={styles.checkboxContainer}
									textStyle={styles.checkboxText}
								/>
								{selectedMethods.email && (
									<View style={styles.inputContainer}>
										<FontAwesome
											name="envelope"
											size={largeTextSize * 1.05}
											color={Colors.greyscale[3]}
											style={styles.inputIcon}
										/>
										<TextInput
											style={styles.input}
											autoFocus={true}
											onFocus={() => setKeyboardActive(true)}
											onBlur={() => {
												setKeyboardActive(false)
												if (selectedMethods.emailString === '') {
													setSelectedMethods(prev => ({ ...prev, email: !prev.email }))
												}
											}}
											placeholder="Email address"
											autoComplete="email"
											placeholderTextColor="#aaa"
											value={selectedMethods.emailString}
											onChangeText={value =>
												setSelectedMethods(prev => ({
													...prev,
													emailString: value,
												}))
											}
											returnKeyType={'done'}
											keyboardType="email-address"
										/>
									</View>
								)}
								<CheckBox
									title="Print"
									checked={selectedMethods.print}
									checkedColor={global.orgColor}
									checkedIcon={
										<MaterialCommunityIcons
											name="checkbox-marked"
											size={titleTextSize}
											color={global.orgColor}
										/>
									}
									uncheckedIcon={
										<MaterialCommunityIcons
											name="checkbox-blank-outline"
											size={titleTextSize}
											color="black"
										/>
									}
									onPress={() => setSelectedMethods(prev => ({ ...prev, print: !prev.print }))}
									containerStyle={styles.checkboxContainer}
									textStyle={styles.checkboxText}
								/>
								{selectedMethods.print && !keyboardActive && (
									<View style={{ width: '75%' }}>
										<Text style={[styles.printNote, { fontWeight: 'bold' }]}>
											{'NOTE: '}
											<Text style={[styles.printNote]}>
												{`${pronoun.toLowerCase()} will receive ${descriptor.toLowerCase()} printed receipt along with ${descriptor.toLowerCase()} order.`}
											</Text>
										</Text>
									</View>
								)}
							</>
						) : (
							<></>
							// <ForwardButton
							// 	onPress={() => {
							// 		Keyboard.dismiss()
							// 		setSelectedMethods(prev => ({ ...prev, showNumPad: false }))
							// 	}}
							// 	color={global.orgColor}
							// 	title={'CONTINUE'}
							// 	style={{ width: '75%', marginTop: 10 }}
							// />
						)}
					</View>
				</TouchableWithoutFeedback>

				{children}
			</>
		)
	} else if (Platform.OS === 'web' && auth.currentUser === null) {
		return (
			<>
				<TouchableWithoutFeedback onPress={global.kiosk ? Keyboard.dismiss : null}>
					<View style={styles.container}>
						<Text
							style={styles.subHeader}
						>{`HOW WOULD ${pronoun} LIKE ${descriptor} RECEIPT?`}</Text>
						<Text style={[styles.subHeader, { color: '#818181', marginBottom: 30, marginTop: 15 }]}>
							{'(SELECT ONE OR MORE)'}
						</Text>
						<CheckBox
							title="Text Message"
							checked={selectedMethods.textMessage}
							checkedColor={global.orgColor}
							checkedIcon={
								<MaterialCommunityIcons
									name="checkbox-marked"
									size={titleTextSize}
									color={global.orgColor}
								/>
							}
							uncheckedIcon={
								<MaterialCommunityIcons
									name="checkbox-blank-outline"
									size={titleTextSize}
									color="black"
								/>
							}
							onPress={() =>
								setSelectedMethods(prev => ({
									...prev,
									textMessage: !prev.textMessage,
									showNumPad: !prev.textMessage,
								}))
							}
							containerStyle={[styles.checkboxContainer, { marginTop: 0 }]}
							textStyle={styles.checkboxText}
						/>
						{selectedMethods.textMessage && (
							<>
								<View style={styles.inputContainer}>
									<FontAwesome
										name="mobile-phone"
										size={titleTextSize * 1.15}
										color={Colors.greyscale[3]}
										style={styles.inputIcon}
									/>
									<TextInput
										style={styles.input}
										placeholder="Mobile number"
										placeholderTextColor="#aaa"
										value={selectedMethods.phoneStringFormatted}
										maxLength={14}
										autoFocus={true}
										onChangeText={value =>
											setSelectedMethods(prev => ({
												...prev,
												phoneString: value,
												phoneStringFormatted: formatPhoneNumber(value),
											}))
										}
										returnKeyType={'done'}
										keyboardType="numeric"
									/>
								</View>
								<CheckBox
									title="Also receive order updates"
									checked={selectedMethods.textUpdates}
									checkedColor={global.orgColor}
									checkedIcon={
										<MaterialCommunityIcons
											name="checkbox-marked"
											size={titleTextSize}
											color={global.orgColor}
										/>
									}
									uncheckedIcon={
										<MaterialCommunityIcons
											name="checkbox-blank-outline"
											size={titleTextSize}
											color="black"
										/>
									}
									onPress={() =>
										setSelectedMethods(prev => ({
											...prev,
											textUpdates: !prev.textUpdates,
										}))
									}
									containerStyle={[styles.checkboxContainer, { marginTop: 5, marginLeft: '14%' }]}
									textStyle={styles.checkboxText}
								/>
							</>
						)}

						<CheckBox
							title="Email"
							checked={selectedMethods.email}
							checkedColor={global.orgColor}
							checkedIcon={
								<MaterialCommunityIcons
									name="checkbox-marked"
									size={titleTextSize}
									color={global.orgColor}
								/>
							}
							uncheckedIcon={
								<MaterialCommunityIcons
									name="checkbox-blank-outline"
									size={titleTextSize}
									color="black"
								/>
							}
							onPress={() => setSelectedMethods(prev => ({ ...prev, email: !prev.email }))}
							containerStyle={[
								styles.checkboxContainer,
								!selectedMethods.email && { marginBottom: 30 },
							]}
							textStyle={styles.checkboxText}
						/>
						{selectedMethods.email && (
							<View style={[styles.inputContainer, { marginBottom: 30 }]}>
								<FontAwesome
									name="envelope"
									size={largeTextSize * 1.05}
									color={Colors.greyscale[3]}
									style={styles.inputIcon}
								/>
								<TextInput
									style={[styles.input]}
									autoFocus={true}
									placeholder="Email address"
									placeholderTextColor="#aaa"
									value={selectedMethods.emailString}
									onChangeText={value =>
										setSelectedMethods(prev => ({
											...prev,
											emailString: value,
										}))
									}
									returnKeyType={'done'}
									keyboardType="email-address"
								/>
							</View>
						)}
					</View>
				</TouchableWithoutFeedback>

				{children}
			</>
		)
	} else if (auth.currentUser !== null) {
		return (
			<>
				<View style={styles.container}>
					<Text style={styles.subHeader}>{`HOW WOULD ${pronoun} LIKE ${descriptor} RECEIPT?`}</Text>
					<Text style={[styles.subHeader, { color: '#818181', marginBottom: 0, marginTop: 15 }]}>
						{'(SELECT ONE OR MORE)'}
					</Text>
					<CheckBox
						title={`Text message to ${Formatter.phoneNumber(savedPhone)}`}
						checked={selectedMethods.textMessage}
						checkedColor={global.orgColor}
						checkedIcon={
							<MaterialCommunityIcons
								name="checkbox-marked"
								size={titleTextSize}
								color={global.orgColor}
							/>
						}
						uncheckedIcon={
							<MaterialCommunityIcons
								name="checkbox-blank-outline"
								size={titleTextSize}
								color="black"
							/>
						}
						onPress={() =>
							setSelectedMethods(prev => ({
								...prev,
								textMessage: !prev.textMessage,
								showNumPad: !prev.textMessage,
							}))
						}
						containerStyle={styles.checkboxContainer}
						textStyle={styles.checkboxText}
					/>
					{/* {selectedMethods.textMessage ||
						(isDelivery && (
							<>
								<CheckBox
									title={isDelivery ? 'Receive delivery updates' : 'Also receive order updates'}
									checked={selectedMethods.textUpdates}
									checkedColor={global.orgColor}
									checkedIcon={
										<MaterialCommunityIcons
											name="checkbox-marked"
											size={titleTextSize}
											color={global.orgColor}
										/>
									}
									uncheckedIcon={
										<MaterialCommunityIcons
											name="checkbox-blank-outline"
											size={titleTextSize}
											color="black"
										/>
									}
									onPress={() =>
										setSelectedMethods(prev => ({
											...prev,
											textUpdates: !prev.textUpdates,
										}))
									}
									containerStyle={[
										styles.checkboxContainer,
										!isDelivery && { marginTop: 5, marginLeft: '14%' },
									]}
									textStyle={styles.checkboxText}
								/>
							</>
						))} */}

					{selectedMethods.textMessage && !isDelivery ? (
						<>
							<CheckBox
								title={
									isDelivery ? 'Receive delivery updates by text' : 'Also receive order updates'
								}
								checked={selectedMethods.textUpdates}
								checkedColor={global.orgColor}
								checkedIcon={
									<MaterialCommunityIcons
										name="checkbox-marked"
										size={titleTextSize}
										color={global.orgColor}
									/>
								}
								uncheckedIcon={
									<MaterialCommunityIcons
										name="checkbox-blank-outline"
										size={titleTextSize}
										color="black"
									/>
								}
								onPress={() =>
									setSelectedMethods(prev => ({
										...prev,
										textUpdates: !prev.textUpdates,
									}))
								}
								containerStyle={[
									styles.checkboxContainer,
									!isDelivery && { marginTop: 5, marginLeft: '14%' },
								]}
								textStyle={styles.checkboxText}
							/>
						</>
					) : (
						<></>
					)}

					<CheckBox
						title={`Email ${Formatter.decodedEmail(auth.currentUser.email)}`}
						checked={selectedMethods.email}
						checkedColor={global.orgColor}
						checkedIcon={
							<MaterialCommunityIcons
								name="checkbox-marked"
								size={titleTextSize}
								color={global.orgColor}
							/>
						}
						uncheckedIcon={
							<MaterialCommunityIcons
								name="checkbox-blank-outline"
								size={titleTextSize}
								color="black"
							/>
						}
						onPress={() => setSelectedMethods(prev => ({ ...prev, email: !prev.email }))}
						containerStyle={[styles.checkboxContainer, { marginBottom: isDelivery ? 5 : 30 }]}
						textStyle={styles.checkboxText}
					/>
					{isDelivery ? (
						<>
							<CheckBox
								title={
									isDelivery ? 'Receive delivery updates by text' : 'Also receive order updates'
								}
								checked={selectedMethods.textUpdates}
								checkedColor={global.orgColor}
								checkedIcon={
									<MaterialCommunityIcons
										name="checkbox-marked"
										size={titleTextSize}
										color={global.orgColor}
									/>
								}
								uncheckedIcon={
									<MaterialCommunityIcons
										name="checkbox-blank-outline"
										size={titleTextSize}
										color="black"
									/>
								}
								onPress={() =>
									setSelectedMethods(prev => ({
										...prev,
										textUpdates: !prev.textUpdates,
									}))
								}
								containerStyle={[
									styles.checkboxContainer,
									!isDelivery && { marginTop: 5, marginLeft: '14%' },
								]}
								textStyle={styles.checkboxText}
							/>
						</>
					) : (
						<></>
					)}
				</View>

				{children}
			</>
		)
	} else {
		return <></>
	}
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignItems: 'center',
		paddingTop: 20,
		marginTop: '5%',
		backgroundColor: '#fff',
		borderRadius: 25,
	},

	mainHeader: {
		fontSize: titleTextSize,
		textAlign: 'center',
		marginBottom: 50,
		fontWeight: '600',
	},
	subHeader: {
		fontSize: largeTextSize,
		color: Colors.greyscale[8],
		textAlign: 'center',
		fontWeight: '600',
	},
	inputContainer: {
		flexDirection: 'row',
		borderColor: 'grey',
		borderWidth: 1,
		borderRadius: 8,
		paddingHorizontal: 10,
		paddingVertical: 8,
		alignItems: 'center',
		width: '75%',
	},
	inputIcon: {
		marginRight: 10,
	},
	input: {
		flex: 1,
		paddingVertical: 10,
		fontSize: largeTextSize,
	},
	checkboxContainer: {
		backgroundColor: 'transparent',
		borderWidth: 0,
		paddingVertical: 5,
		marginLeft: '11%',
		marginRight: '11%',
		alignSelf: 'flex-start',
		marginTop: 30,
	},
	checkboxText: {
		marginHorizontal: 5,
		fontWeight: '500',
		fontSize: largeTextSize,
	},
	printNote: {
		paddingVertical: 5,
		alignSelf: 'flex-start',
		fontWeight: '300',
		fontSize: largeTextSize,
		color: '#4E4E4E',
	},
})

export default Receipt
