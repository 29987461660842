import React, { useContext } from 'react'
import { View, Image, StyleSheet, TouchableOpacity, Dimensions } from 'react-native'
import {
	LOYALTY_CARD_WIDTH,
	largeTextSize,
	mediumTextSize,
	mlTextSize,
	sTextSize,
	smallTextSize,
} from '../../constants/GlobalStyle'
import Colors from '../../constants/Colors'
import { DataContext } from '../../state/context'
import { Ionicons } from '@expo/vector-icons'
import { pointsTempRemove } from './pointsTempRemove'
import { Text } from '../Themed'

const ItemCard = ({
	isRedeemable = false,
	isHomePage = false,
	rewardData,
	navigation,
	isCheckout = false,
}) => {
	const { userData, redeemedRewards } = useContext(DataContext)
	const USER_POINTS =
		userData &&
		userData.user &&
		userData.user.loyaltyPoints !== undefined &&
		userData.user.loyaltyPoints > 0
			? userData.user.loyaltyPoints - pointsTempRemove(redeemedRewards)
			: 0

	let title = ''
	if (rewardData.type === 'fixedDiscount') {
		title = `GET $${rewardData.discountAmount} OFF YOUR ORDER`
	} else {
		title = `FREE ${rewardData.menuItemName.toUpperCase()}`
	}
	isRedeemable = isRedeemable || redeemedRewards.some(reward => reward.rewardId === rewardData.id)

	return (
		<View>
			<HeaderText
				isRedeemable={isRedeemable}
				isHomePage={isHomePage}
				isFreeItem={rewardData.type === 'freeItem'}
				pointsCost={rewardData.pointsCost}
				userPoints={USER_POINTS}
				isCheckout={isCheckout}
			/>
			<View
				style={[styles.card, { backgroundColor: isRedeemable ? '#F9D97C' : Colors.greyscale[6] }]}
			>
				<View style={styles.content}>
					<Text style={styles.title}>
						{title}
						{isRedeemable && (
							<Text style={styles.pointAmount}>{'\n' + rewardData.pointsCost + ' POINTS'}</Text>
						)}
					</Text>
					<Image source={require('../../assets/images/chefCoin.png')} style={styles.image} />
				</View>
				<RedeemButton
					redeemedList={redeemedRewards}
					rewardData={rewardData}
					isRedeemable={isRedeemable}
					onPress={() => {
						if (isRedeemable) {
							navigation.navigate('Item', {
								token: rewardData.menuItemId,
								editing: null,
								isOpen: true,
								menuItemCategory: 'loyaltyReward',
								menuId: rewardData.menuItemRestaurantId,
								loyaltyPrice: 0,
								loyaltyPoints: rewardData.pointsCost,
								loyaltyId: rewardData.id,
							})
						} else {
							null
						}
					}}
				/>
			</View>
		</View>
	)
}

const RedeemButton = ({ isRedeemable, onPress, redeemedList, rewardData }) => {
	//check if the reward has already been redeemed
	const isRedeemed = redeemedList.some(reward => reward.rewardId === rewardData.id)
	if (isRedeemed) {
		return (
			<View style={{ flexDirection: 'row', alignSelf: 'flex-end', marginTop: 8 }}>
				<Text
					style={{
						fontSize: largeTextSize,
						color: Colors.greyscale[8],
						fontStyle: 'italic',
						marginRight: 10,
						marginTop: 3,
					}}
				>
					ADDED TO ORDER!
				</Text>
				<View
					style={[
						styles.selectionCircle,
						{
							width: Dimensions.get('window').height * 0.03,
							height: Dimensions.get('window').height * 0.03,
							borderRadius: Dimensions.get('window').height * 0.015,
							borderColor: 'black',
							borderWidth: 1.5,
						},
					]}
				>
					<Ionicons name="checkmark" size={Dimensions.get('window').height * 0.025} color="green" />
				</View>
			</View>
		)
	} else {
		return (
			<TouchableOpacity
				style={isRedeemable ? styles.redeemButton : styles.earnButton}
				onPress={onPress}
			>
				<Text style={isRedeemable ? styles.redeemButtonText : styles.earnButtonText}>
					{isRedeemable ? 'REDEEM NOW' : 'EARN MORE POINTS TO REDEEM'}
				</Text>
			</TouchableOpacity>
		)
	}
}

const HeaderText = ({
	isRedeemable,
	isHomePage,
	isFreeItem,
	pointsCost,
	userPoints,
	isCheckout,
}) => {
	if (isCheckout) {
		return null
	}
	if (isRedeemable) {
		if (isFreeItem) {
			return <Text style={styles.headerText}>YOU HAVE EARNED A FREE ITEM!</Text>
		} else {
			return <Text style={styles.headerText}>YOU HAVE EARNED A COUPON!</Text>
		}
	} else if (!isRedeemable && isHomePage) {
		return (
			<Text style={styles.headerText}>{`YOU ARE ${
				pointsCost - userPoints
			} POINTS FROM EARNING`}</Text>
		)
	} else {
		return <Text style={styles.headerText}>{`${pointsCost} POINTS`}</Text>
	}
}

const styles = StyleSheet.create({
	card: {
		borderRadius: 10,
		padding: 15,
		paddingTop: 5,
		justifyContent: 'center',
		alignItems: 'center',
		shadowColor: '#000',
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
		width: LOYALTY_CARD_WIDTH,
		maxWidth: 380,
		borderWidth: 0.5,
		borderColor: Colors.greyscale[5],
	},
	content: {
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'space-around',
		marginBottom: 10,
	},
	title: {
		fontSize: mlTextSize,
		fontWeight: '800',
		flex: 2,
		marginRight: 5,
	},
	pointAmount: {
		fontSize: mediumTextSize,
		color: Colors.greyscale[8],
		fontWeight: '500',
		flex: 1,
		marginRight: 5,
		alignSelf: 'flex-start',
	},
	image: {
		width: Dimensions.get('window').width * 0.25,
		height: Dimensions.get('window').width * 0.25 + 5,
		maxWidth: 150,
		maxHeight: 155,
	},
	redeemButton: {
		backgroundColor: Colors.greyscale[8],
		width: '100%',
		paddingVertical: 8,
		borderRadius: 5,
	},
	earnButton: {
		backgroundColor: Colors.greyscale[5],
		width: '100%',
		paddingVertical: 10, //extra padding to keep component the same with smaller text size
		borderRadius: 5,
	},
	redeemButtonText: {
		color: '#fff',
		fontSize: mlTextSize,
		fontWeight: 'bold',
		alignSelf: 'center',
	},
	earnButtonText: {
		color: Colors.greyscale[6],
		fontSize: sTextSize,
		fontWeight: '800',
		alignSelf: 'center',
	},
	headerText: {
		fontSize: smallTextSize,
		fontWeight: '500',
		marginBottom: 10,
	},
	selectionCircle: {
		width: Dimensions.get('window').width * 0.05,
		height: Dimensions.get('window').width * 0.05,
		borderRadius: (Dimensions.get('window').width * 0.05) / 2,
		backgroundColor: 'white',
		//alignItems: 'center',
		//justifyContent: 'center',
		alignSelf: 'flex-end',
		borderColor: Colors.greyscale[7],
		borderWidth: 1,
		zIndex: 1,
	},
})

export default ItemCard
