import React from 'react'
import { Dimensions, Image } from 'react-native'
import { View } from '../../../components/Themed'
import ImageObject from '../../../components/Image/ImageObject'
import { ExtraSharpTriangle } from '../../../components/Headers/Triangle'
import ExitIcon from '../../../components/Headers/UIButtons'

export function ItemImage({ imageUri, navigation, menuId, isEdit, isLoyalty }) {
	return (
		<View style={{ marginBottom: 30 }}>
			<View
				style={[
					{
						width: '100%',
						height: Dimensions.get('window').height * 0.3,
					},
				]}
			>
				{imageUri === '' ? (
					<Image
						source={require('../../../assets/images/menuHeader.png')}
						style={{
							width: '100%',
							height: '100%',
						}}
					/>
				) : (
					<ImageObject
						source={imageUri}
						style={{
							width: '100%',
							height: '100%',
						}}
					/>
				)}
				<ExtraSharpTriangle />
			</View>
		</View>
	)
}
