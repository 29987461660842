import { StyleSheet } from 'react-native'
import Colors from '../../constants/Colors'
import { largeTextSize } from '../../constants/GlobalStyle'

const WalletStyles = StyleSheet.create({
	rowContainer: {
		borderRadius: 5,
		backgroundColor: Colors.custom.lightGrey,
	},
})

export default WalletStyles
