export type CheckDeliveryEligibilityParams = {
	totalOrderPrice: number
	subtotalOrderPrice: number
	totalDeliveryFee: number
	minimumDeliveryOrderAmount: number
}

export const calculateAdditionalAmountForDelivery = ({
	totalOrderPrice,
	subtotalOrderPrice,
	totalDeliveryFee,
	minimumDeliveryOrderAmount,
}: CheckDeliveryEligibilityParams): number => {
	// Calculate how much more is needed to meet the minimum delivery order amount
	const additionalForSubtotal = Math.max(0, minimumDeliveryOrderAmount - subtotalOrderPrice)

	// Calculate how much more is needed to meet the total delivery fee requirement
	const additionalForTotalPrice = Math.max(0, totalDeliveryFee - totalOrderPrice)

	// Return the greater of the two amounts
	return Math.max(additionalForSubtotal, additionalForTotalPrice)
}
