import { FontAwesome } from '@expo/vector-icons'
import * as Font from 'expo-font'
import * as React from 'react'
export default function useCachedResources() {
	const [isLoadingComplete, setLoadingComplete] = React.useState(false)

	// Load any resources or data that we need prior to rendering the app
	React.useEffect(() => {
		async function loadResourcesAndDataAsync() {
			try {
				// Load fonts
				await Font.loadAsync({
					...FontAwesome.font,
					'space-mono': require('../assets/fonts/SpaceMono-Regular.ttf'),
				})
			} catch (e) {
				console.warn(e)
			} finally {
				setLoadingComplete(true)
			}
		}

		loadResourcesAndDataAsync()
	}, [])

	return isLoadingComplete
}
