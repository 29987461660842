import React from 'react'
import { Image, StyleSheet, TouchableOpacity, Platform } from 'react-native'
import { xlTextSize } from '../../../../constants/GlobalStyle'

const googlePayLogo = require('../../../../assets/images/wallet/googlepay.png')

const applePayLogo = require('../../../../assets/images/wallet/applepayWhite.png')

export function DigitalWalletButton({ onPress, type }: { onPress: () => void; type: any }) {
	const imageSource = type === 'applePay' ? applePayLogo : googlePayLogo
	return (
		<TouchableOpacity onPress={() => onPress()} style={styles.button}>
			<Image source={imageSource} resizeMode={'center'} style={styles.image} />
		</TouchableOpacity>
	)
}

const styles = StyleSheet.create({
	button: {
		alignItems: 'center',
		backgroundColor: '#000000',
		borderRadius: 10,
		justifyContent: 'center',
		minHeight: 50,
		height: '5%',
		width: '90%',
		marginBottom: 15,
	},
	image: {
		height: '80%',
		width: '100%',
	},
})
