import React from 'react'
import { Image } from 'react-native'

export type ImgProps = {
	source: string
	style: any
	hideDefault?: boolean
}
/**
 * Web-only compoenent to display images
 * Use standard React Native Image component as FastImage isn't compatible with web
 */
export default function ImageObject(props: ImgProps) {
	const { source, style, hideDefault } = props
	if (hideDefault) {
		return <Image resizeMethod={'resize'} source={{ uri: source }} style={style} />
	} else {
		return <Image resizeMethod={'auto'} resizeMode="cover" source={{ uri: source }} style={style} />
	}
}

export function preLoadImage(source = '') {
	return null
}
