import { SQIPCardEntry, SQIPCore } from 'react-native-square-in-app-payments'
import { Alert, Platform } from 'react-native'
import { useContext, useEffect } from 'react'
import { DataContext } from '../../../state/context'
import { auth } from '../../../firebase/config'
import { useSquare } from './useSquare'
import { SQUARE_APPLICATION_ID } from '@env'

interface CardDetails {
	nonce: string
	card: {
		prepaidType: string
		expirationYear: number
		brand: string
		postalCode: string
		expirationMonth: number
		type: string
		lastFourDigits: string
	}
}

const uid = auth.currentUser ? auth.currentUser.uid : ''

/**
 * Checkout process for adding a Square card (only on mobile)
 */
export const useAddCard = () => {
	const { paymentProvider } = useContext(DataContext)
	const { createCustomerCard } = useSquare()

	useEffect(() => {
		if (paymentProvider === 'square') {
			initState()
		}
	}, [])

	const initState = async () => {
		try {
			await SQIPCore.setSquareApplicationId(SQUARE_APPLICATION_ID)
		} catch (e) {
			console.warn(e)
		}
	}

	const onCustomerCardEntryCancel = () => {}

	const onCustomerCardNonceRequestSuccess = async (cardDetails: CardDetails) => {
		try {
			// create the customer card record and add it to the state
			await createCustomerCard(cardDetails.nonce, null, false)
			SQIPCardEntry.completeCardEntry(() => {
				Alert.alert('Your card was saved and is ready to use.')
			})
		} catch (error: any) {
			SQIPCardEntry.showCardNonceProcessingError(error.message)
		}
	}

	const startCustomerCardEntry = async () => {
		const cardEntryConfig = {
			collectPostalCode: true,
		}

		await SQIPCardEntry.startCardEntryFlow(
			cardEntryConfig,
			onCustomerCardNonceRequestSuccess,
			onCustomerCardEntryCancel
		).catch((error: any) => {
			console.warn(error)
		})
	}

	return { startCustomerCardEntry }
}
