import * as React from 'react'
import { Text, View } from '../../../components/Themed'
import { Item } from '../../../state/types'
import { CheckoutItem } from './types'
import { findValidModListIds } from '../../Item/Helpers/functions'

export function formatItemsForFirebase(items) {
	const dbOrderItems = items.map(item => {
		const { modReturn, variationAdd } = formatItemModifiers(item, 'firebase')
		const taxReturn = formatItemTaxes(item, 'firebase')

		const dbOrderItem = {
			quantity: item.qty,
			name: item.name,
			priceMoney: {
				amount: item.ppu,
				currency: 'CAD',
			},
			modifiers: modReturn,
			note: item.note,
			menuItemId: item.id,
			id: item.idempotencyKey,
			taxIds: taxReturn, //ENABLE THIS
			menuId: item.menuId, //i.e. defaultmenu
			menuItemCategory: item.menuItemCategory, //i.e. pizzas
			restaurantId: item.rId,
			parentModifierId: item.parentModifierId, //for nested modifiers
		}

		return dbOrderItem
	})
	return dbOrderItems
}

function formatItemTaxes(item, destination) {
	const taxReturn = []
	item.taxes.forEach(tax => {
		if (destination === 'firebase') {
			taxReturn.push(tax.id)
		} else if (destination === 'square') {
			taxReturn.push({ taxUid: tax.id })
		}
	})
	return taxReturn
}

function formatItemModifiers(item, destination) {
	const modReturn = []
	let variationAdd = 0
	for (let i = 0; i < item.mods.length; i++) {
		const modifier = item.mods[i]
		if (destination === 'firebase') {
			modReturn.push({
				id: modifier.id,
				name: modifier.name,
				priceMoney: {
					amount: modifier.priceMoney.amount,
					currency: modifier.priceMoney.currency,
				},
				costMoney: {
					amount: modifier.costMoney.amount,
					currency: modifier.costMoney.currency,
				},
				parentModifierId: modifier.parentModifierId,
			})
		} else if (destination === 'square') {
			if (!modifier.variation) {
				modReturn.push({
					id: modifier.id,
					name: modifier.name,
					basePriceMoney: {
						amount: modifier.priceMoney.amount,
						currency: modifier.priceMoney.currency,
					},
				})
			} else {
				variationAdd += modifier.priceMoney.amount
			}
		}
	}
	return { modReturn, variationAdd }
}

export function formatItemsForSquare(items) {
	const orderLineItems = items.map(item => {
		const { modReturn, variationAdd } = formatItemModifiers(item, 'square')

		const taxReturn = formatItemTaxes(item, 'square')
		let orderLineItem
		if (item.posId && item.posId !== '') {
			orderLineItem = {
				restaurantId: item.rId, //delete before passing to square api in cloud func
				totalMoney: item.total * item.qty, //delete
				taxAmt: item.taxAmt, //delete
				taxList: item.taxList, //delete
				quantity: item.qty.toString(),
				basePriceMoney: {
					amount: item.ppu + variationAdd,
					currency: 'CAD',
				},
				modifiers: modReturn,
				note: item.note,
				catalogObjectId: item.posId,
				appliedTaxes: taxReturn,
			}
		} else {
			orderLineItem = {
				restaurantId: item.rId, //delete
				totalMoney: item.total * item.qty, //delete
				taxAmt: item.taxAmt, //delete
				taxList: item.taxList, //delete
				quantity: item.qty.toString(),
				name: item.name,
				basePriceMoney: {
					amount: item.ppu,
					currency: 'CAD',
				},
				modifiers: modReturn,
				note: item.note,
				appliedTaxes: taxReturn,
			}
		}

		return orderLineItem
	})

	return orderLineItems
}

//an object with each restaurant id for its key, find amounts that are to be debited from the total amount payed
//including, org fees, platform fees, and discounts
//since service fees are credited, not included here.
//each includes a key "square" that contains the discount object needed for the square order object.
export function getRestaurantSpecificDiscounts(
	items: CheckoutItem[],
	prices,
	bogoList,
	freeItemList
) {
	var discounts = {}

	var subtotals = {}

	//get item from cart
	//if item.isFree or item.bogoDiscount.amount > 0
	items.forEach(item => {
		subtotals[item.rId] += item.total
		var appliedCoupon = item.appliedCoupon
		if (item.appliedCoupon) {
			if (appliedCoupon.type === 'freeItem') {
				var index = -1
				var couponId = ''
				freeItemList.forEach((coupon, i) => {
					coupon.freeItemIds.forEach(id => {
						if (id === item.id) {
							index = i
							couponId = coupon.id
						}
					})
				})
				if (index !== -1) {
					if (!discounts[item.rId]) {
						discounts[item.rId] = []
					}
					var orgCoverage = freeItemList[index].orgCoverage
					var orgAmount = (((orgCoverage / 100) * item.ppu) / 100).toFixed(2)
					discounts[item.rId].push({
						amountMoney: {
							amount: item.ppu,
							currency: 'CAD',
						},
						scope: 'ORDER',
						type: 'FIXED_AMOUNT',
						name: 'Free ' + item.name,
						metadata: {
							to_be_paid_by_org: '$' + orgAmount + ' (' + orgCoverage + '%)',
							org_coverage_percent: orgCoverage.toString(),
							org_coverage_amount: orgAmount.toString(),
							type: 'freeItem',
							promoId: couponId,
						},
					})
				}
			} else if (appliedCoupon.type === 'BOGO') {
				bogoList.forEach(bogo => {
					if (bogo.itemIds) {
						bogo.itemIds.forEach(itemId => {
							if (itemId === item.id) {
								if (!discounts[item.rId]) {
									discounts[item.rId] = []
								}

								var orgCoverage = bogo.orgCoverage
								var orgAmount = ((orgCoverage / 100) * item.ppu).toFixed(2)

								discounts[item.rId].push({
									amountMoney: {
										amount: item.ppu * 100,
										currency: 'CAD',
									},
									scope: 'ORDER',
									type: 'FIXED_AMOUNT',
									name: 'BOGO ' + item.name,
									metadata: {
										to_be_paid_by_org: '$' + orgAmount + ' (' + orgCoverage + '%)',
										org_coverage_percent: orgCoverage.toString(),
										org_coverage_amount: orgAmount.toString(),
										type: 'BOGO',
										promoId: bogo.id,
									},
								})
							}
						})
					}
				})
			}
		}
	})

	return discounts
}

interface Restaurant {
	id: string
	organizationFee?: number
}

interface Prices {
	total: number
	subtotal: number
	taxes: number
	discounts: number
}

export function calculateFees(
	items: any[],
	restaurants: Restaurant[],
	organizationFeePercent: number,
	platformFeePercent: number,
	organizationFeeTaxPercent: number,
	platformFeeTaxPercent: number,
	discountAmount: number
) {
	let totalOrganizationFee = 0
	let totalOrganizationFeeTax = 0
	let totalPlatformFee = 0
	let totalPlatformFeeTax = 0

	platformFeePercent /= 100
	organizationFeeTaxPercent /= 100
	platformFeeTaxPercent /= 100

	let totalAmount = 0

	const restaurantsFees: { [restaurantId: string]: number } = {}

	for (const item of items) {
		const restaurant = restaurants.find(r => r.id === item.rId)

		if (restaurant) {
			var amount = item.total * item.qty

			totalAmount += amount

			const organizationFeePercentForRestaurant =
				restaurant.organizationFee ?? organizationFeePercent
			const restaurantEarned =
				amount * (1 - platformFeePercent) * (1 - organizationFeePercentForRestaurant / 100)
			const restaurantFee = amount * platformFeePercent
			const restaurantFeeTax = restaurantFee * platformFeeTaxPercent
			const organizationFee = (amount * organizationFeePercentForRestaurant) / 100
			const organizationFeeTax = organizationFee * organizationFeeTaxPercent

			totalOrganizationFee += organizationFee / 100
			totalOrganizationFeeTax += organizationFeeTax / 100
			totalPlatformFee += restaurantFee / 100
			totalPlatformFeeTax += restaurantFeeTax / 100

			if (restaurantsFees[item.rId]) {
				restaurantsFees[item.rId] += restaurantEarned
			} else {
				restaurantsFees[item.rId] = restaurantEarned
			}
		}
	}

	var discPercent = Math.abs(discountAmount * 100) / totalAmount

	totalOrganizationFee = (1 - discPercent) * totalOrganizationFee

	totalOrganizationFeeTax = (1 - discPercent) * totalOrganizationFeeTax

	totalPlatformFee = (1 - discPercent) * totalPlatformFee
	totalPlatformFeeTax = (1 - discPercent) * totalPlatformFeeTax

	totalOrganizationFee = isNaN(totalOrganizationFee) ? 0 : totalOrganizationFee
	totalOrganizationFeeTax = isNaN(totalOrganizationFeeTax) ? 0 : totalOrganizationFeeTax

	return {
		totalOrganizationFee,
		totalOrganizationFeeTax,
		totalPlatformFee,
		totalPlatformFeeTax,
	}
}

export function formatDiscountForSquare(
	items,
	restaurants,
	orgDiscountCover,
	discount,
	subtotal,
	noCodeDiscount,
	discountAmount
) {
	const discountView = []

	if (discount) {
		if (discount.rate.type === 'fixed') {
			var orgAmount = (
				((orgDiscountCover / 100) * Math.round(discount.rate.amount * 100)) /
				100
			).toFixed(2)

			discountView.push({
				amountMoney: {
					amount: Math.round(discount.rate.amount * 100),
					currency: 'CAD',
				},
				scope: 'ORDER',
				type: 'FIXED_AMOUNT',
				name: discount.name,
				metadata: {
					type: 'couponCode',
					promoId: discount.id,
					orgReimbursePercent: orgDiscountCover.toString(),
				},
			})
		} else {
			var totalDisc = (Math.round(discount.rate.amount) / 100) * (subtotal * 100)
			var orgAmount = (((orgDiscountCover / 100) * Math.round(totalDisc)) / 100).toFixed(2)

			discountView.push({
				amountMoney: {
					amount: Math.round(totalDisc),
					currency: 'CAD',
				},
				scope: 'ORDER',
				type: 'FIXED_AMOUNT',
				name: discount.name,
				metadata: {
					type: 'couponCode',
					promoId: discount.id,
					orgReimbursePercent: orgDiscountCover.toString(),
				},
			})
		}
	}

	return discountView
}

//tip & service fee
export function formatServiceChargesForSquare(tip, serviceFee) {
	const serviceChargeView = []
	if (tip > 0) {
		serviceChargeView.push({
			amountMoney: {
				amount: Math.round(tip * 100),
				currency: 'CAD',
			},
			calculationPhase: 'TOTAL_PHASE',
			name: 'Tip',
			taxable: false,
		})
	}

	if (serviceFee > 0) {
		serviceChargeView.push({
			amountMoney: {
				amount: Math.round(serviceFee * 100),
				currency: 'CAD',
			},
			calculationPhase: 'TOTAL_PHASE',
			name: 'Service Fee',
			taxable: false,
		})
	}

	return serviceChargeView
}

export function groupBy(arr: Array<Item>, property: string) {
	return arr.reduce(function (memo, x) {
		if (!memo[x[property]]) {
			memo[x[property]] = []
		}
		memo[x[property]].push(x)
		return memo
	}, {})
}

export function addMods(item) {
	const modifierArray = []
	const validModListIds = findValidModListIds(item.modList)

	item.modList.forEach(curr_list => {
		if (!validModListIds.includes(curr_list.id)) {
			return
		}

		curr_list.options.forEach(option => {
			const quantity = option.quantity || 1
			if (option.isSelected) {
				const repeatedOptions = Array(quantity).fill({
					name: option.name,
					id: option.id,
					priceMoney: {
						amount: option.price,
						currency: option.currency,
					},
					costMoney: {
						amount: 0,
						currency: option.currency,
					},
					variation: option.variation,
					parentModifierId: option.parentModifierId,
				})

				modifierArray.push(...repeatedOptions)

				if (curr_list.selectionType === 'SINGLE' || curr_list.max === 1) {
					return false
				}
			}
		})
	})

	return modifierArray
}

export function addNote(item) {
	if (item.note) {
		return item.note
	}
}

export function itemExtras(note, modArray) {
	if (typeof note == 'undefined' && modArray.length <= 0) {
		return <View style={{ marginBottom: 7 }}></View>
	} else if (typeof note == 'undefined') {
		return (
			<View style={{ marginLeft: '10%', marginBottom: 7 }}>
				<Text>{modArray.join('\n')}</Text>
			</View>
		)
	} else if (modArray.length <= 0) {
		return (
			<View style={{ marginLeft: '10%', marginBottom: 7 }}>
				<Text>{`Special Instructions: "${note}"`}</Text>
			</View>
		)
	} else {
		return (
			<View style={{ marginLeft: '10%', marginBottom: 7 }}>
				<Text>
					{modArray.join('\n')}
					{'\n\n'}
					{`Special Instructions: "${note}"`}
				</Text>
			</View>
		)
	}
}
