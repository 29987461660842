import React, { useContext, useEffect } from 'react'
import { DataContext } from '../../state/context'
import * as Location from 'expo-location'
import { Platform } from 'react-native'
import { calculateDistanceFromAddress } from '../getLocationDistance'
import Geocoder from 'react-native-geocoding'
import { GOOGLE_API_KEY } from '@env'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { add } from 'date-fns'
import { parsePrepTime } from '../prepTimeFunctions'

export const getDeliveryEstimate = async (
	customerAddress,
	pickupTime,
	activeRID,
	deliveryIsSandbox
) => {
	const functions = getFunctions()
	const getDeliveryEstimate = httpsCallable(functions, 'getDoorDashDeliveryQuote')
	const deliveryEstimate = await getDeliveryEstimate({
		customerAddress: customerAddress,
		pickupTime: pickupTime,
		orgId: global.org,
		restaurantId: activeRID,
		isSandbox: deliveryIsSandbox,
	})
	return deliveryEstimate.data
}

export default function RetrieveLocation() {
	const {
		setUserLocation,
		restaurantList,
		isSingleBrand,
		isDelivery,
		deliveryEnabled,
		userLocation,
		setDeliveryEstimate,
		orgDeliveryCoverageType,
		orgDeliveryCoverage,
		activeRID,
		deliveryIsSandbox,
	} = useContext(DataContext)

	const getAddressFromCoordinates = async (latitude, longitude) => {
		try {
			const response = await Geocoder.from(latitude, longitude)
			const addressComponents = response.results[0].address_components

			let streetAddress = ''
			let city = ''
			let province = ''
			let postalCode = ''

			addressComponents.forEach(component => {
				if (component.types.includes('route')) {
					streetAddress += component.long_name
				}
				if (component.types.includes('street_number')) {
					streetAddress = component.long_name + ' ' + streetAddress
				}
				if (component.types.includes('locality')) {
					city = component.long_name
				}
				if (component.types.includes('administrative_area_level_1')) {
					province = component.long_name
				}
				if (component.types.includes('postal_code')) {
					postalCode = component.long_name
				}
			})

			return {
				shortAddress: streetAddress === '' ? response.results[0].formatted_address : streetAddress,
				longAddress: `${streetAddress}, ${city}, ${province}, ${postalCode}`,
				addressObject: {
					streetAddress: streetAddress,
					streetAddress2: '',
					city: city,
					province: province,
					postalCode: postalCode,
				},
			}
		} catch (error) {
			console.error(error)
			return null
		}
	}

	useEffect(() => {
		Geocoder.init(GOOGLE_API_KEY, { language: 'en' })
	})

	function splitFee(fee) {
		if (orgDeliveryCoverageType === 'percent') {
			return fee * (orgDeliveryCoverage / 100)
		} else if (fee - orgDeliveryCoverage >= 0) {
			return fee - orgDeliveryCoverage
		} else {
			return 0
		}
	}

	useEffect(() => {
		;(async () => {
			if (
				(isSingleBrand && restaurantList.length > 1) ||
				(isDelivery && userLocation === null && deliveryEnabled)
			) {
				if (Platform.OS !== 'web') {
					const { status } = await Location.requestForegroundPermissionsAsync()
					if (status !== 'granted') {
						setUserLocation({
							error: 'LOCATION_PERMISSION_DENIED',
						})
						return
					}
				}
				const location = await Location.getCurrentPositionAsync({})
				const userAddress = await getAddressFromCoordinates(
					location.coords.latitude,
					location.coords.longitude
				)
				const locationObj: any = {
					coords: location.coords,
					formattedAddress: userAddress.shortAddress,
					longAddress: userAddress.longAddress,
					addressObject: userAddress.addressObject,
					retrievedFromCurrLocation: true,
				}
				setUserLocation(locationObj)

				// restaurantList.forEach(async restaurant => {
				// 	// Get delivery estimate
				// 	if (isDelivery) {
				// 		const estimate: any = await getDeliveryEstimate(
				// 			userAddress.longAddress,
				// 			add(new Date(), parsePrepTime('P0DT0H' + (restaurant.prepTime || 15) + 'M0S')),
				// 			activeRID,
				// 			deliveryIsSandbox
				// 		)

				// 		restaurant.deliveryFee = splitFee(estimate.fee) || -1
				// 		restaurant.deliveryTime = estimate.timeToDeliver || -1

				// 		setDeliveryEstimate({
				// 			restaurantId: restaurant.id || '',
				// 			estimate: {
				// 				fee: splitFee(estimate.fee) || -1,
				// 				timeToDeliver: estimate.timeToDeliver || -1,
				// 				totalFee: estimate.fee || -1,
				// 			},
				// 		})
				// 	}

				// 	const distanceFromUserInKM = calculateDistanceFromAddress(location.coords, {
				// 		latitude: restaurant.address?.latitude,
				// 		longitude: restaurant.address?.longitude,
				// 	})
				// 	restaurant.distance = distanceFromUserInKM || -1
				// })
				// restaurantList.sort((a, b) => a.distance - b.distance)
			}
		})()
	}, [isDelivery])

	return null
}
