import { getFunctions, httpsCallable } from 'firebase/functions'

export default function stripeGetCards(stripeCustomerId: string) {
	const functions = getFunctions()

	async function handleGetCards() {
		const customerCards = await getCards()
		var data: any = customerCards.data
		var cardList: any[] = []
		data.data.forEach(cardData => {
			var cardInfo = {
				last_4: cardData.card.last4,
				id: cardData.id,
				exp_month: cardData.card.exp_month,
				exp_year: cardData.card.exp_year,
				card_brand: cardData.card.brand,
			}
			cardList.push(cardInfo)
		})

		return cardList
	}

	// Call cloud function which uses stripeId from Users collection to get payment methods from Stripe API
	async function getCards() {
		const retrieveStripeCards = httpsCallable(functions, 'retrieveStripeCards')
		return await retrieveStripeCards({
			stripeCustomerId: stripeCustomerId,
			orgId: global.org,
		})
	}

	return handleGetCards()
}
