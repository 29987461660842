import { useContext, useEffect } from 'react'
import { CheckoutScreenContext, DataContext } from '../../../state/context'
import { useStripe } from '@stripe/react-stripe-js'

const useStripeDigitalWallet = () => {
	const {
		paymentRequest,
		setPaymentRequest,
		prices,
		setDigitalWalletType,
		digitalWalletType,
		setIsDigitalWalletEnabled,
	} = useContext(CheckoutScreenContext)

	const stripe = useStripe()

	useEffect(() => {
		if (stripe) {
			const pr = stripe.paymentRequest({
				country: 'CA',
				currency: 'cad',
				total: {
					label: 'Payment:',
					amount: Math.round(prices.total * 100),
				},
				requestPayerName: true,
				requestPayerEmail: true,
			})

			// Check the availability of the Payment Request API.
			pr.canMakePayment()
				.then(result => {
					if (result) {
						if (result.applePay === true) {
							setIsDigitalWalletEnabled(true)
							setDigitalWalletType('applePay')
						} else if (result.googlePay === true) {
							setIsDigitalWalletEnabled(true)
							setDigitalWalletType('googlePay')
						}
						setPaymentRequest(pr)
					}
				})
				.catch(error => {
					console.log(error)
				})
		}
	}, [stripe, prices])
}

export default useStripeDigitalWallet
