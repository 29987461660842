import React, { useReducer, useState } from 'react'
import { RestaurantScreenContext } from '../../../state/context'

const RestaurantScreenProvider = ({ children }) => {
	const [verticalMarkers, setVerticalMarkers] = useState<number[]>([])
	const [loading, setLoading] = useState(false)
	const [isLoaded, setIsLoaded] = useState(true)
	const [ctgTokens, setCtgTokens] = useState<object>({})
	const [spinner, setSpinner] = useState(true)
	const [storeAddress, setStoreAddress] = useState<any>()
	const [rName, setRName] = useState('')
	const [rSocial, setRSocial] = useState({})
	const [featuredItemsList, setFeaturedItemsList] = useState([])
	const [categoryList, setCategoryList] = useState([])
	const [scrollPos, setScrollPos] = useState(0)
	const [storeOpen, setStoreOpen] = useState(true)
	const [menuList, setMenuList] = useState<any>()
	const [loadingItems, setLoadingItems] = useState(true)
	const [webHero, setWebHero] = useState('')
	const [webLogo, setWebLogo] = useState('')
	const [reset, setReset] = useState([])
	const [isModalVisible, setModalVisible] = useState(false)
	const [isLocModalVisible, setLocModalVisible] = useState(false)
	const [showRightChevron, setShowRightChevron] = useState(true)
	const [showLeftChevron, setShowLeftChevron] = useState(false)
	const [showOtherRestaurants, setShowOtherRestaurants] = useState(true)
	const [updateData, setUpdateData] = useState([])
	const [doneLoadingMenu, setDoneLoadingMenu] = useState(false)
	const [isSearchActive, setSearchActive] = useState(false)
	const [showDropdown, setShowDropdown] = useState(false)
	const [showResults, setShowResults] = useState(false)
	const [searchText, setSearchText] = useState('')

	return (
		<RestaurantScreenContext.Provider
			value={{
				verticalMarkers,
				setVerticalMarkers,
				loading,
				setLoading,
				isLoaded,
				setIsLoaded,
				ctgTokens,
				setCtgTokens,
				spinner,
				setSpinner,
				storeAddress,
				setStoreAddress,
				rName,
				setRName,
				rSocial,
				setRSocial,
				featuredItemsList,
				setFeaturedItemsList,
				categoryList,
				setCategoryList,
				scrollPos,
				setScrollPos,
				storeOpen,
				setStoreOpen,
				menuList,
				setMenuList,
				loadingItems,
				setLoadingItems,
				webHero,
				setWebHero,
				webLogo,
				setWebLogo,
				reset,
				setReset,
				isModalVisible,
				setModalVisible,
				isLocModalVisible,
				setLocModalVisible,
				showRightChevron,
				setShowRightChevron,
				showLeftChevron,
				setShowLeftChevron,
				showOtherRestaurants,
				setShowOtherRestaurants,
				updateData,
				setUpdateData,
				doneLoadingMenu,
				setDoneLoadingMenu,
				searchText,
				setSearchText,
				showResults,
				setShowResults,
				showDropdown,
				setShowDropdown,
				isSearchActive,
				setSearchActive,
			}}
		>
			{children}
		</RestaurantScreenContext.Provider>
	)
}

export default RestaurantScreenProvider
