import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
	banner: {
		paddingVertical: 20,
		paddingHorizontal: 30,
		marginTop: 50,
		marginHorizontal: 5,
		borderRadius: 5,
	},
	bannerText: {
		color: 'white',
		fontWeight: 'bold',
		textAlign: 'center',
	},
})
