import React, { useEffect } from 'react'
import { Feather, Ionicons } from '@expo/vector-icons'
import { View, Dimensions, TouchableOpacity } from 'react-native'
import GlobalStyle, { headerTextSize, titleTextSize } from '../../../../constants/GlobalStyle'
import { getFunctions, httpsCallable } from 'firebase/functions'
import Colors from '../../../../constants/Colors'
import { useContext } from 'react'
import { CheckoutScreenContext, DataContext } from '../../../../state/context'
import { Text } from '../../../../components/Themed'

/**
 * Shows the Awaiting Payment screen on kiosk (waiting for user to pay with card)
 */
export function AwaitingPayment({ title }) {
	const {
		setOrderCreated,
		setPaymentError,
		setConfirmModalVisible,
		cancelButton,
		setCancelButton,
		setOrderPlaced,
		setSpinner,
		setAwaitingTerminalInput,
		checkoutId,
		setCheckoutId,
		items,
	} = useContext(CheckoutScreenContext)
	const { paymentProvider } = useContext(DataContext)

	const functions = getFunctions()

	useEffect(() => {
		if (
			global.kiosk &&
			title === 'Please follow instructions on pinpad to pay now.' &&
			global.readerId === 'tmr_FJkwZAAXaN3B6Y'
		) {
			const simulatePayment = httpsCallable(functions, 'processSimulatedStripeTerminalPayment')
			simulatePayment({
				orgId: global.org,
				readerId: global.readerId,
				isInterac: false,
			})
		}
	}, [])

	useEffect(() => {
		if (cancelButton === 'Cancelling...') {
			cancelOrder()
		}
	}, [cancelButton])

	const cancelOrder = () => {
		setOrderCreated(false)
		setOrderPlaced(false)
		setAwaitingTerminalInput(false)
		setPaymentError(false)
		setSpinner(false)
		setConfirmModalVisible(false)
		setCancelButton('Cancel')
		setCheckoutId('')

		const cancelTerminalPayment = httpsCallable(
			functions,
			paymentProvider === 'stripe' ? 'cancelStripeTerminalPayment' : 'cancelSquareTerminalCheckout'
		)
		cancelTerminalPayment(
			paymentProvider === 'stripe'
				? {
						readerId: global.readerId,
						orgId: global.org,
				  }
				: { checkoutId, restaurantId: items[0].rId, orgId: global.org }
		)
			.then(() => {
				console.log('Payment cancelled.')
			})
			.catch(error => {
				console.log(error)
			})
	}

	return (
		<View
			style={{
				alignItems: 'center',
				justifyContent: 'space-evenly',
				flex: 1,
			}}
		>
			<View
				style={{
					flexDirection: 'row',
					alignItems: 'center',
					paddingLeft: '10%',
					paddingRight: '12%',
					marginTop: '10%',
				}}
			>
				{title === 'Payment error. Please cancel and try again.' ? (
					<Feather
						name={'alert-triangle'}
						size={titleTextSize * 3}
						color={Colors.custom.pinkRed}
						style={{ paddingTop: 15 }}
					/>
				) : (
					<Ionicons
						name={'information-circle'}
						size={titleTextSize * 3}
						color={global.orgColor ? global.orgColor : Colors.custom.restaurantMain}
						style={{ paddingTop: 15 }}
					/>
				)}

				<Text
					style={{
						textAlign: 'center',
						fontSize: titleTextSize,
						fontWeight: 'bold',
						marginLeft: 20,
					}}
				>
					{title}
				</Text>
			</View>

			<TouchableOpacity
				onPress={() => {
					setCancelButton('Cancelling...')
				}}
				style={{
					borderWidth: 5,
					borderColor: '#eaeaea',
					borderRadius: 30,
					marginHorizontal: 15,
					flexDirection: 'row',
					width: '95%',
					minWidth: 300,
					justifyContent: 'center',
				}}
			>
				<Text
					style={[
						GlobalStyle.ctgTitle,
						{
							fontSize: headerTextSize,
							paddingHorizontal: 0,
						},
					]}
				>
					{cancelButton}
				</Text>
			</TouchableOpacity>
		</View>
	)
}
