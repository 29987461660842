import * as React from 'react'
import { ScrollView, Animated, Dimensions, Platform, TouchableOpacity, Text } from 'react-native'
import { View } from '../../components/Themed'
import { useContext, useEffect, useState } from 'react'
import { RootTabScreenProps } from '../../navigation/types'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../state/reducer'
import { DataContext, IdleContext, RestaurantSelectScreenContext } from '../../state/context'
import RetrieveData from '../../helpers/fbDataRetrieval/RetrieveData'
import useRestaurantListener from '../../helpers/fbDataRetrieval/ListenerHooks/useRestaurantListener'
import TapToLogout from '../../components/Kiosk/TapToLogout'
import KioskStartScreen from './Components/KioskStartScreen'
import { OrgLogoHeader } from '../../components/Headers/OrgLogoHeader'
import { BrandList } from './Components/BrandList'
import { Categories } from './Components/Categories'
import NavFooter from '../../components/Footers/NavFooter'
import { WebHeader } from '../../components/Headers/WebHeader'
import CopyrightFooter from '../../components/Footers/CopyrightFooter'
import { LoyaltyHeader } from './Components/LoyaltyHeader'
import useUserListener from '../../helpers/fbDataRetrieval/ListenerHooks/useUserListener'
import POSNavBar from '../../navigation/POSNavBar'
import { RestaurantList } from './Components/RestaurantList'
import { PickupPointModal } from '../../components/PickupPoint'
import { smallTextSize } from '../../constants/GlobalStyle'
import CuisineSelector from './Components/CuisineSelector'

export default function RestaurantSelectScreen({ navigation }: RootTabScreenProps<any>) {
	const loadedData = useSelector<ReduxStoreState, ReduxStoreState['loadedData']>(
		state => state.loadedData
	)

	const organizationLogo = useSelector<ReduxStoreState, ReduxStoreState['organizationLogo']>(
		state => state.organizationLogo
	)

	const { setReset, orgLogo } = useContext(RestaurantSelectScreenContext)

	const { setNavContext } = useContext(IdleContext)

	const {
		setUserData,
		dataIsDoneLoading,
		setDataIsDoneLoading,
		pickupPointsEnabled,
		selectedPickupPoint,
	} = useContext(DataContext)

	const [showKioskStart, setShowKioskStart] = useState(true)

	const [pickupPointModalVisible, setPickupPointModalVisible] = useState(
		selectedPickupPoint === null
	)

	//Refresh
	useEffect(() => {
		setNavContext(navigation)

		const unsubscribe = navigation.addListener('focus', () => {
			setReset([])
		})
		return unsubscribe
	}, [navigation])

	useEffect(() => {
		if (dataIsDoneLoading && loadedData[global.org].singleBrandEnabled && global.kiosk) {
			navigation.navigate('HomeStack', {
				screen: 'Menu',
				params: { rId: loadedData['defaultRestaurant'].id },
			})
		}
	}, [dataIsDoneLoading])

	//listener for restaurant snooze, hours update
	useUserListener({ setUserData })
	useRestaurantListener({ setReset })
	function renderPage() {
		if (loadedData[global.org].singleBrandEnabled) {
			navigation.navigate('HomeStack', {
				screen: 'Menu',
				params: { rId: loadedData['defaultRestaurant'].id },
			})

			return <></>
		}
		return (
			<>
				{Platform.OS === 'web' && (
					<WebHeader
						logo={organizationLogo !== '' ? organizationLogo : orgLogo}
						navigation={navigation}
					/>
				)}
				{pickupPointsEnabled && !global.kiosk && (
					<PickupPointModal
						visible={pickupPointModalVisible}
						setVisible={setPickupPointModalVisible}
					/>
				)}
				<ScrollView
					contentContainerStyle={[
						{ justifyContent: 'space-between' },
						Platform.OS === 'web' && { height: '100%' },
					]}
				>
					{Platform.OS !== 'web' && (
						<OrgLogoHeader
							logo={organizationLogo !== '' ? organizationLogo : orgLogo}
							padding={global.homeLayout === 'restaurants' ? smallTextSize * 2 : 50}
						/>
					)}

					{loadedData[global.org].loyaltyEnabled && !global.kiosk && (
						<LoyaltyHeader navigation={navigation} />
					)}

					{global.homeLayout === 'restaurants' ? (
						<CuisineSelector navigation={navigation} />
					) : (
						// <RestaurantList navigation={navigation} />
						<>
							<Categories navigation={navigation} />
							<BrandList navigation={navigation} />
						</>
					)}

					<View
						style={{
							height:
								global.homeLayout === 'restaurants' && Platform.OS === 'web'
									? 0
									: Dimensions.get('window').height * 0.12,
						}}
					></View>
					{Platform.OS === 'web' && global.homeLayout !== 'restaurants' && <CopyrightFooter />}
				</ScrollView>
				{global.kiosk && !global.pos ? (
					<NavFooter navigation={navigation} checkoutBar={true} />
				) : null}
				{global.pos ? <POSNavBar navigation={navigation} screenName={'HomeStack'} /> : null}
			</>
		)
	}
	if (global.kiosk && global.org !== undefined && !dataIsDoneLoading) {
		return (
			<>
				<RetrieveData referralCode={''} loadingTracker={setDataIsDoneLoading}>
					<TapToLogout navigation={navigation} />
					{dataIsDoneLoading ? (
						<Animated.View style={{ flex: 1 }}>
							{showKioskStart && !global.pos ? (
								<KioskStartScreen
									defaultLogo={organizationLogo}
									imageList={loadedData.promoImages ? loadedData.promoImages : []}
									setVisible={setShowKioskStart}
								/>
							) : (
								renderPage()
							)}
						</Animated.View>
					) : (
						<></>
					)}
				</RetrieveData>
			</>
		)
	} else {
		return (
			<>
				{global.kiosk && <TapToLogout navigation={navigation} />}
				{renderPage()}
			</>
		)
	}
}
