import React, { useContext, useState } from 'react'
import { View, Text, TouchableOpacity, StyleSheet, Platform, Dimensions } from 'react-native'
import {
	largeTextSize,
	mediumTextSize,
	mlTextSize,
	titleTextSize,
} from '../../../constants/GlobalStyle'
import { ItemScreenContext } from '../../../state/context'
import Colors from '../../../constants/Colors'

const QuantitySelector = () => {
	const { quantity, setQuantity } = useContext(ItemScreenContext)

	const incrementQuantity = () => {
		setQuantity(prevQuantity => prevQuantity + 1)
	}

	const decrementQuantity = () => {
		setQuantity(prevQuantity => (prevQuantity > 1 ? prevQuantity - 1 : 1))
	}

	return (
		<>
			<Text style={[styles.stepTextStyle]}>{'QUANTITY'}</Text>
			<View style={styles.wrapper}>
				<View style={styles.container}>
					<TouchableOpacity
						style={[styles.button, quantity === 1 && styles.disabledButton]}
						onPress={decrementQuantity}
						disabled={quantity === 1}
					>
						<Text style={[styles.buttonText, quantity === 1 && styles.disabledButtonText]}>–</Text>
					</TouchableOpacity>
					<View style={styles.quantityContainer}>
						<Text style={styles.quantityText}>{quantity}</Text>
					</View>
					<TouchableOpacity style={styles.button} onPress={incrementQuantity}>
						<Text style={styles.buttonText}>+</Text>
					</TouchableOpacity>
				</View>
			</View>
		</>
	)
}

const styles = StyleSheet.create({
	wrapper: {
		alignItems: 'flex-start',
		justifyContent: 'center',
	},
	container: {
		flexDirection: 'row',
		alignItems: 'center',
		backgroundColor: '#fff',
		borderRadius: 15,
		paddingVertical: 5,
		paddingHorizontal: 10,
		shadowColor: '#000',
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.2,
		shadowRadius: 5,
		elevation: 5,
		width: 'auto',
		height: Dimensions.get('window').height * 0.065,
		minHeight: 50,
		maxHeight: 60,
		marginBottom: 30,
	},
	button: {
		paddingHorizontal: 15,
		marginTop: -3,
	},
	disabledButton: {
		opacity: 0.5,
	},
	buttonText: {
		fontSize: largeTextSize,
		color: '#000',
	},
	disabledButtonText: {
		color: Colors.greyscale[5],
	},
	quantityContainer: {
		minWidth: 60,
		alignItems: 'center',
	},
	quantityText: {
		fontSize: mlTextSize,
		textAlign: 'center',
	},
	stepTextStyle: {
		fontSize: titleTextSize - 6,
		fontWeight: '400',
		color: 'black',
		marginBottom: 10,
	},
})

export default QuantitySelector
