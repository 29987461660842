import { initializeApp } from 'firebase/app'
import {
	FIREBASE_APP_ID,
	FIREBASE_AUTH_DOMAIN,
	FIREBASE_MEASUREMENT_ID,
	FIREBASE_MESSAGING_SENDER_ID,
	FIREBASE_PROJECT_ID,
	FIREBASE_STORAGE_BUCKET,
	GOOGLE_API_KEY,
} from '@env'
import { getReactNativePersistence } from 'firebase/auth/react-native'
import { initializeAuth } from 'firebase/auth'
import { initializeFirestore, getFirestore } from 'firebase/firestore'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Platform } from 'react-native'
import { initializeRNFirebase } from './analytics'

const firebaseConfig = {
	apiKey: GOOGLE_API_KEY,
	authDomain: FIREBASE_AUTH_DOMAIN,
	projectId: FIREBASE_PROJECT_ID,
	storageBucket: FIREBASE_STORAGE_BUCKET,
	messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
	appId: FIREBASE_APP_ID,
	measurementId: FIREBASE_MEASUREMENT_ID,
}

const firebaseApp = initializeApp(firebaseConfig)
const auth = initializeAuth(firebaseApp, {
	persistence: getReactNativePersistence(AsyncStorage),
})
initializeFirestore(firebaseApp, {
	experimentalForceLongPolling: false,
})
const db = getFirestore(firebaseApp)

if (Platform.OS !== 'web') {
	//initialize react-native-firebase pkg to use analytics on mobile
	initializeRNFirebase(firebaseConfig)
}

export { auth, db }
