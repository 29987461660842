import React, { useReducer, useState } from 'react'
import { DataContext } from '../../state/context'

const DataContextProvider = ({ children }) => {
	const [allDataLoaded, setAllDataLoaded] = useState(false)
	const [receiptPrinter, setReceiptPrinter] = useState(null)
	const [printerType, setPrinterType] = useState(null)
	const [isCardValid, setIsCardValid] = useState(false)
	const [paymentMethods, setPaymentMethods] = useState([])
	const [userData, setUserData] = useState(null)
	const [paymentProvider, setPaymentProvider] = useState('')
	const [webAppURL, setWebAppURL] = useState('')
	const [squareLocationId, setSquareLocationId] = useState('')
	const [dataIsDoneLoading, setDataIsDoneLoading] = useState(false)
	const [showKioskStartScreen, setShowKioskStartScreen] = useState(true)
	const [updatedData, setUpdatedData] = useState({})
	const [posProvider, setPosProvider] = useState('')

	/* Loyalty */
	const [redeemedRewards, setRedeemedRewards] = useState([]) //rewards to be used on current transaction
	const [pointsEarned, setPointsEarned] = useState(0) //loyalty points earned on purchase

	/* Multi location */
	const [isSingleBrand, setIsSingleBrand] = useState(false)
	const [showKioskLocations, setShowKioskLocations] = useState(false)
	const [userLocation, setUserLocation] = useState(null)
	const [restaurantList, setRestaurantList] = useState([])
	const [selectedLocation, setSelectedLocation] = useState([])

	/* Kiosk dine in */
	const [dineInEnabled, setDineInEnabled] = useState(false)
	const [isDineIn, setIsDineIn] = useState(false)
	const [isTakeOut, setIsTakeOut] = useState(false)
	const [usesTableNumbers, setUsesTableNumbers] = useState(false)

	/* Delivery */
	const [deliveryEnabled, setDeliveryEnabled] = useState(false)
	const [isDelivery, setIsDelivery] = useState(true)
	const [deliveryProvider, setDeliveryProvider] = useState('')
	const [deliveryEstimate, setDeliveryEstimate] = useState(null)
	const [userDeliveryAddress, setUserDeliveryAddress] = useState('')
	const [userDisplayName, setUserDisplayName] = useState('')
	/* Scheduled Order Modal */
	const [resetTime, setResetTime] = useState([])

	const [defPrep, setDefPrep] = useState(0)

	const [orgDeliveryCoverage, setOrgDeliveryCoverage] = useState(0)
	const [orgDeliveryCoverageType, setOrgDeliveryCoverageType] = useState('')
	const [deliveryActionIfUndeliverable, setDeliveryActionIfUndeliverable] = useState('')
	const [deliveryPickupInstructions, setDeliveryPickupInstructions] = useState('')
	const [contactlessDropoff, setContactlessDropoff] = useState(true)
	const [deliveryMinSpend, setDeliveryMinSpend] = useState(0)
	const [deliveryIsSandbox, setDeliveryIsSandbox] = useState(false)
	const [freeDeliveryMultiplier, setFreeDeliveryMultiplier] = useState(-1)

	const [pickupPointsEnabled, setPickupPointsEnabled] = useState(false)
	const [pickupPointsArray, setPickupPointsArray] = useState([])
	const [selectedPickupPoint, setSelectedPickupPoint] = useState(null)
	const [showKioskPickupPoints, setShowKioskPickupPoints] = useState(false)

	const [activeOrder, setActiveOrder] = useState(null)

	const [activeRID, setActiveRID] = useState('')

	const [transparentLogo, setTransparentLogo] = useState(false)

	const [posLoyaltyEnabled, setPosLoyaltyEnabled] = useState(false)

	return (
		<DataContext.Provider
			value={{
				allDataLoaded,
				setAllDataLoaded,
				receiptPrinter,
				setReceiptPrinter,
				printerType,
				setPrinterType,
				isCardValid,
				setIsCardValid,
				paymentMethods,
				setPaymentMethods,
				userData,
				setUserData,
				deliveryActionIfUndeliverable,
				setDeliveryActionIfUndeliverable,
				deliveryPickupInstructions,
				setDeliveryPickupInstructions,
				redeemedRewards,
				setRedeemedRewards,
				pointsEarned,
				setPointsEarned,
				userLocation,
				setUserLocation,
				restaurantList,
				setRestaurantList,
				selectedLocation,
				setSelectedLocation,
				paymentProvider,
				setPaymentProvider,
				isSingleBrand,
				setIsSingleBrand,
				squareLocationId,
				setSquareLocationId,
				dataIsDoneLoading,
				setDataIsDoneLoading,
				showKioskLocations,
				setShowKioskLocations,
				showKioskStartScreen,
				setShowKioskStartScreen,
				dineInEnabled,
				setDineInEnabled,
				isDineIn,
				setIsDineIn,
				isTakeOut,
				setIsTakeOut,
				usesTableNumbers,
				setUsesTableNumbers,
				deliveryEnabled,
				setDeliveryEnabled,
				isDelivery,
				setIsDelivery,
				setDeliveryProvider,
				deliveryProvider,
				webAppURL,
				posLoyaltyEnabled,
				setPosLoyaltyEnabled,
				setWebAppURL,
				resetTime,
				setResetTime,
				updatedData,
				setUpdatedData,
				posProvider,
				setPosProvider,
				deliveryEstimate,
				setDeliveryEstimate,
				userDeliveryAddress,
				setUserDeliveryAddress,
				userDisplayName,
				setUserDisplayName,
				defPrep,
				setDefPrep,
				orgDeliveryCoverageType,
				setOrgDeliveryCoverageType,
				orgDeliveryCoverage,
				setOrgDeliveryCoverage,
				activeOrder,
				setActiveOrder,
				activeRID,
				setActiveRID,
				contactlessDropoff,
				setContactlessDropoff,
				deliveryMinSpend,
				setDeliveryMinSpend,
				deliveryIsSandbox,
				setDeliveryIsSandbox,
				freeDeliveryMultiplier,
				setFreeDeliveryMultiplier,
				transparentLogo,
				setTransparentLogo,
				pickupPointsEnabled,
				setPickupPointsEnabled,
				pickupPointsArray,
				setPickupPointsArray,
				selectedPickupPoint,
				setSelectedPickupPoint,
				showKioskPickupPoints,
				setShowKioskPickupPoints,
			}}
		>
			{children}
		</DataContext.Provider>
	)
}

export default DataContextProvider
