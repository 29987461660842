import React from 'react'
import { useContext } from 'react'
import { CheckoutScreenContext, DataContext } from '../../../../state/context'
import CustomerName from './CustomerName.kiosk'
import CheckoutNavFooter from '../../../../components/Footers/CheckoutNavFooter'
import DineInTakeOutOption from '../../Components/DineInTakeOutOption'
import { View, Text, Dimensions } from 'react-native'
import LoyaltyPhoneNumber from './LoyaltyPhoneNumber.kiosk'

/**
 * For kiosks, select order type then enter name or table number.
 * If only 1 order type available, ask for name only.
 */
export function DineInOrTakeout({ toggleConfirmModal, children }) {
	const {
		takeOutName,
		setTakeOutName,
		setShowDineInOrTakeout,
		setPhoneNumberInput,
		setShowPOSMethods,
		showKioskLoyaltyEntry,
		posLoyaltyPoints,
		setShowKioskLoyaltyEntry,
	} = useContext(CheckoutScreenContext)

	const {
		dineInEnabled,
		usesTableNumbers,
		isDineIn,
		isTakeOut,
		paymentProvider,
		posLoyaltyEnabled,
	} = useContext(DataContext)

	const handleBackPress = () => {
		if (!global.pos) {
			if (posLoyaltyEnabled && !showKioskLoyaltyEntry) {
				setShowKioskLoyaltyEntry(true)
			} else {
				toggleConfirmModal()
			}
		} else {
			setShowPOSMethods(true)
		}
	}

	const handleContinuePress = () => {
		if (showKioskLoyaltyEntry) {
			setShowKioskLoyaltyEntry(false)
		} else {
			setShowDineInOrTakeout(false)
			setPhoneNumberInput(true)
		}
	}
	return (
		<>
			{posLoyaltyEnabled && posLoyaltyPoints !== 0 && showKioskLoyaltyEntry ? (
				<LoyaltyPhoneNumber
					value={takeOutName}
					setValue={setTakeOutName}
					isTakeOut={!dineInEnabled ? true : isTakeOut}
					showTableNumber={usesTableNumbers}
				/>
			) : (
				<CustomerName
					value={takeOutName}
					setValue={setTakeOutName}
					isTakeOut={!dineInEnabled ? true : isTakeOut}
					showTableNumber={usesTableNumbers}
				>
					<></>
					{/* {dineInEnabled && (
					<DineInTakeOutOption
						handleDineIn={handleDineInPress}
						handleTakeOut={handleTakeOutPress}
						isTakeOut={isTakeOut}
					/>
				)} */}
				</CustomerName>
			)}

			{paymentProvider === 'square' && !showKioskLoyaltyEntry ? (
				children
			) : (
				<CheckoutNavFooter
					onBackPress={handleBackPress}
					onNextPress={
						!showKioskLoyaltyEntry &&
						(takeOutName === null || takeOutName === '') &&
						isDineIn === false
							? null
							: handleContinuePress
					}
					blurNext={
						!showKioskLoyaltyEntry &&
						(takeOutName === null || takeOutName === '') &&
						isDineIn === false
							? true
							: false
					}
					style={{ marginBottom: Dimensions.get('window').height * 0.07, marginHorizontal: '5%' }}
				/>
			)}
		</>
	)
}
