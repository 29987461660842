import React from 'react'
import { View, Dimensions } from 'react-native'
import Svg, { Path } from 'react-native-svg'
import Colors from '../../constants/Colors'
import { SIDEBAR_WIDTH } from '../../constants/GlobalStyle'

const screenWidth = Dimensions.get('window').width

const Triangle = () => {
	return (
		<View
			style={{
				position: 'relative',
				bottom: 49,
				backgroundColor: 'transparent',
			}}
		>
			<Svg height={'50'} width={screenWidth + 40}>
				<Path
					d={`M0 ${'50'} L${screenWidth + 40} ${'50'} L0 0 Z`}
					fill={Colors.custom.appBackground}
				/>
			</Svg>
		</View>
	)
}

export const SidebarCompatibleSharpTriangle = ({ fill = Colors.custom.appBackground }) => {
	const screenWidth = Dimensions.get('window').width
	const size = Dimensions.get('window').height * 0.075
	const trianglePoints = `M0 ${size} L${screenWidth + 40} ${size} L0 0 Z`

	return (
		<View
			style={{
				position: 'relative',
				bottom: size - 5,
				backgroundColor: 'transparent',
				marginBottom: size * -1,
			}}
		>
			<Svg height={size} width={screenWidth + 40}>
				{/* Grey (or colored) triangle */}
				<Path d={trianglePoints} fill={fill} />
			</Svg>

			{/* Shadow casting component */}
			<View
				style={{
					position: 'absolute',
					top: size - 40, // Adjust to align with the bottom edge of the white triangle
					left: 0,
					width: SIDEBAR_WIDTH,
					height: size - 13, // Adjust for desired shadow size
					shadowColor: '#000',
					shadowOffset: { width: 1, height: -5 },
					shadowOpacity: 0.1,
					shadowRadius: 5,
					elevation: 2,
				}}
			/>

			<View
				style={{
					position: 'absolute',
					top: 0,
					left: 0,
					width: SIDEBAR_WIDTH,
					overflow: 'hidden',
					height: size,
				}}
			>
				<Svg height={size} width={screenWidth + 40}>
					{/* White triangle, clipped by its container */}
					<Path d={trianglePoints} fill="white" />
				</Svg>
			</View>
		</View>
	)
}

export const SharpTriangle = ({ fill = Colors.custom.appBackground }) => {
	//100
	const size = Dimensions.get('window').height * 0.075
	return (
		<View
			style={{
				position: 'relative',
				bottom: size - 5,
				marginBottom: size * -1,
			}}
		>
			<Svg height={size} width={screenWidth + 40}>
				<Path d={`M0 ${size} L${screenWidth + 40} ${size} L0 0 Z`} fill={fill} />
			</Svg>
		</View>
	)
}

export const CheckoutTriangle = ({ fill = Colors.custom.appBackground }) => {
	//100
	const size = Dimensions.get('window').height * 0.075
	return (
		<View
			style={{
				position: 'relative',
				bottom: size - 5,
				backgroundColor: 'transparent',
				marginBottom: size * -1,
			}}
		>
			<Svg height={size} width={screenWidth + 200}>
				<Path d={`M0 ${size} L${screenWidth + 200} ${size} L0 0 Z`} fill={fill} />
			</Svg>
		</View>
	)
}

export const ExtraSharpTriangle = () => {
	//150
	const size = Dimensions.get('window').height * 0.1
	return (
		<View
			style={{
				position: 'relative',
				bottom: size - 5,
				backgroundColor: 'transparent',
				marginBottom: size * -1,
			}}
		>
			<Svg height={size} width={screenWidth + 40}>
				<Path
					d={`M0 ${size} L${screenWidth + 40} ${size} L0 0 Z`}
					fill={Colors.custom.appBackground}
				/>
			</Svg>
		</View>
	)
}

export default Triangle
