import * as React from 'react'
import { Image, View } from 'react-native'
import Formatter from '../../helpers/Formatter'
import Spinner from '../../components/LoadingSpinner'
import { NextButton, Separator, Text } from './index'
export function ReceiptContent({ orderData, orgLogo, styles, orgName, docError, navigation }) {
	// Show receipt
	if (orderData !== null && !docError) {
		return (
			<View>
				<View style={styles.receiptContainer}>
					<Image
						source={{ uri: orgLogo }}
						style={{ alignSelf: 'center', width: 120, height: 120 }}
					/>
					<Text style={[styles.title, { marginTop: 25 }]}>{orgName}</Text>
					<Text style={[styles.centerText, { marginTop: 20 }]}>
						Order Date: {new Date(orderData.orderDate._seconds * 1000).toLocaleString()}
					</Text>
					{orderData.pickupDate && (
						<Text style={styles.centerText}>
							Pickup Date: {new Date(orderData.pickupDate._seconds * 1000).toLocaleString()}
						</Text>
					)}

					<Text style={[styles.centerText, { marginTop: 15, marginBottom: 30 }]}>
						{orderData.tableNumber === '' || orderData.tableNumber === null
							? 'Order #' + orderData.orderNumber
							: 'Order #' + orderData.orderNumber + ' for ' + orderData.tableNumber}
					</Text>
					{orderData.orderItems.map((item, index) => (
						<View key={item.name + index} style={{ marginBottom: 10 }}>
							<View key={index} style={styles.itemContainer}>
								<Text style={styles.itemName}>
									{item.quantity}x {item.name}
								</Text>
								{item.priceMoney && (
									<Text style={styles.itemPrice}>
										{Formatter.currencyFormat(item.priceMoney.amount / 100)}
									</Text>
								)}
							</View>

							{item.modifiers.length > 0 && (
								<View style={styles.modifiersContainer}>
									{item.modifiers.map((modifier, mIndex) => (
										<Text key={mIndex} style={styles.modifierText}>
											+ {modifier.name + ' $' + modifier.priceMoney.amount / 100}
										</Text>
									))}
								</View>
							)}
						</View>
					))}
					<Separator style={{ marginTop: 15 }} />

					<View style={styles.priceContainer}>
						<Text style={styles.subFee}>Subtotal</Text>
						<Text style={styles.subFee}>
							{Formatter.currencyFormat(orderData.priceData.subTotal / 100)}
						</Text>
					</View>

					{orderData.priceData.serviceCharge !== 0 && (
						<View style={styles.priceContainer}>
							<Text style={styles.subFee}>Service Charge</Text>
							<Text style={styles.subFee}>
								{Formatter.currencyFormat(orderData.priceData.serviceCharge / 100)}
							</Text>
						</View>
					)}
					{orderData.priceData.discount !== 0 && (
						<View style={styles.priceContainer}>
							<Text style={styles.subFee}>Discount</Text>
							<Text style={styles.subFee}>
								{Formatter.currencyFormat(orderData.priceData.discount / 100)}
							</Text>
						</View>
					)}
					{orderData.priceData.totalTaxes !== 0 && (
						<View style={styles.priceContainer}>
							<Text style={styles.subFee}>Taxes</Text>
							<Text style={styles.subFee}>
								{Formatter.currencyFormat(orderData.priceData.totalTaxes / 100)}
							</Text>
						</View>
					)}
					{orderData.priceData.bagFee + orderData.priceData.bagFeeTax !== 0 && (
						<View style={styles.priceContainer}>
							<Text style={styles.subFee}>Bag Fee</Text>
							<Text style={styles.subFee}>
								{Formatter.currencyFormat(
									(orderData.priceData.bagFee + orderData.priceData.bagFeeTax) / 100
								)}
							</Text>
						</View>
					)}
					{orderData.priceData.tip !== 0 && (
						<View style={styles.priceContainer}>
							<Text style={styles.subFee}>Tip</Text>
							<Text style={styles.subFee}>
								{Formatter.currencyFormat(orderData.priceData.tip / 100)}
							</Text>
						</View>
					)}
					{orderData.priceData.deliveryFee !== 0 && (
						<View style={styles.priceContainer}>
							<Text style={styles.subFee}>Delivery Fee</Text>
							<Text style={styles.subFee}>
								{Formatter.currencyFormat(orderData.priceData.deliveryFee / 100)}
							</Text>
						</View>
					)}
					<Separator style={{ marginTop: 20 }} />

					<View style={styles.priceContainer}>
						<Text style={styles.total}>Total</Text>
						<Text style={styles.total}>
							{Formatter.currencyFormat(orderData.priceData.total / 100)}
						</Text>
					</View>
				</View>
				<NextButton
					style={{ backgroundColor: global.orgColor, marginTop: 30, minWidth: 300 }}
					text="ORDER ONLINE"
					onPress={() => {
						navigation.navigate('HomeStack', { screen: 'RestaurantSelect' })
					}}
				/>
			</View>
		)
	}
	// Loading
	else if (orderData === null && !docError) {
		return (
			<Spinner
				visible={true}
				overlayColor="rgba(255, 255, 255, 1)"
				headerHeight={0}
				footerHeight={0}
				color="black"
			/>
		)
	}
	// Error getting receipt
	else if (docError) {
		return (
			<View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
				<Text style={{ fontSize: 24, fontWeight: 'bold' }}>
					Receipt not found. Please check the URL and try again.
				</Text>
				<NextButton
					style={{ backgroundColor: global.orgColor, marginTop: 30, minWidth: 350 }}
					text="ORDER ONLINE"
					onPress={() => {
						navigation.navigate('HomeStack', { screen: 'RestaurantSelect' })
					}}
				/>
			</View>
		)
	}
}
