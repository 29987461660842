import React from 'react'
import { View, Image, Platform, Dimensions } from 'react-native'
import { SharpTriangle } from '../Headers/Triangle'
import Colors from '../../constants/Colors'
import { headerTextSize } from '../../constants/GlobalStyle'
import { WebHeader } from '../Headers/WebHeader'
import { Text } from '../Themed'

export const RewardsTriangleHeader = ({ title, backFunction, logo = null, navigation = null }) => {
	if (Platform.OS === 'web') {
		return <WebHeader logo={logo} navigation={navigation} />
	}
	return (
		<>
			<View
				style={{
					height: 200 + 500,
					marginTop: -500,
					backgroundColor: global.orgColor ? global.orgColor : Colors.custom.restaurantMain,
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'space-between',
					paddingHorizontal: 20,
				}}
			>
				<Text
					style={{
						fontSize: headerTextSize,
						color: Colors.custom.restaurantMainText,
						marginTop: 485,
					}}
				>
					{title}
				</Text>
				<Image
					style={{
						alignSelf: 'center',
						width: Dimensions.get('window').width * 0.25,
						height: Dimensions.get('window').width * 0.25,
						maxWidth: 150,
						maxHeight: 150,
						marginTop: 500,
					}}
					resizeMethod="resize"
					resizeMode="contain"
					source={require('../../assets/images/chefCoin.png')}
				/>
			</View>
			<View style={{ backgroundColor: Colors.custom.appBackground }}>
				<SharpTriangle />
			</View>
		</>
	)
}
