import { useContext } from 'react'
import { CheckoutScreenContext, DataContext, IdleContext } from '../../../state/context'
import { logBeginCheckoutAnalytics } from '../../../firebase/analytics'
import { auth } from '../../../firebase/config'
import { Platform } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import Formatter from '../../../helpers/Formatter'

export const useToggleConfirmModal = () => {
	const navigation = useNavigation<any>()

	const {
		setSelectedMethods,
		setShowPOSMethods,
		setTypePOS,
		setShowDineInOrTakeout,
		setTableNumber,
		setTakeOutName,
		setPhoneNumberInput,
		setShowAddTip,
		setUseNewPaymentMethod,
		isConfirmModalVisible,
		prices,
		analyticsItems,
		setConfirmModalVisible,
		setIsGuestCheckout,
		savedPhone,
	} = useContext(CheckoutScreenContext)

	const { setIsCardValid, deliveryEnabled, isDelivery } = useContext(DataContext)

	const { setDisableIdleTimer } = useContext(IdleContext)

	const toggleConfirmModal = () => {
		const email = auth.currentUser ? Formatter.decodedEmail(auth.currentUser.email) : ''
		let phone = savedPhone
		if (savedPhone.length === 11 && savedPhone.startsWith('1')) {
			phone = savedPhone.substring(1)
		}
		if (!isConfirmModalVisible) {
			logBeginCheckoutAnalytics(prices.total, analyticsItems)
		}
		setSelectedMethods({
			textMessage: false,
			email: false,
			print: false,
			textUpdates: true,
			showNumPad: false,
			phoneString: global.kiosk ? '' : phone,
			emailString: global.kiosk ? '' : email,
		})
		setIsCardValid(false)
		setDisableIdleTimer(!isConfirmModalVisible)
		setShowPOSMethods(global.pos)
		setTypePOS('')
		setShowDineInOrTakeout(true)
		//setIsDineIn(false)
		//setIsTakeOut(true)
		setTableNumber(null)
		// setTakeOutName(null) // to save the name for guest checkout
		setPhoneNumberInput(false)
		setShowAddTip(!global.kiosk)
		setUseNewPaymentMethod(false)

		if (auth.currentUser || (Platform.OS === 'web' && (!deliveryEnabled || !isDelivery))) {
			setConfirmModalVisible(!isConfirmModalVisible)
		} else {
			navigation.navigate('AccountStack', { screen: 'Account', params: { onCheckout: true } })
		}
		setIsGuestCheckout(false)
	}

	return { toggleConfirmModal }
}
