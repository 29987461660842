import React from 'react'
import { Dimensions, View } from 'react-native'
import Modal from 'react-native-modal/dist/modal'
import { useContext } from 'react'
import { CheckoutScreenContext } from '../../../../state/context'
import GlobalStyle, { MAX_CHECKOUT_WIDTH } from '../../../../constants/GlobalStyle'
import { styles } from '../../Helpers/style'

export function PromoModal({ togglePromoModal, height, children }) {
	const { isPromoModalVisible } = useContext(CheckoutScreenContext)

	return (
		<Modal
			style={[GlobalStyle.modalStyle, { marginTop: 0 }]}
			isVisible={isPromoModalVisible}
			onBackButtonPress={togglePromoModal}
			onBackdropPress={togglePromoModal}
			avoidKeyboard={false}
			useNativeDriver={true}
		>
			<View
				style={[
					styles.modal,
					{
						paddingTop: 30,
						height: height,
					},
				]}
			>
				{children}
			</View>
		</Modal>
	)
}
